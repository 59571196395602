import { Machine, actions as stateActions, spawn } from 'xstate';
import { machine as appsMachine } from './apps';
import { reporting } from '../../socket';

const { assign } = stateActions;

export interface Schema {
    states: {
        loading: {}
        summary: {},
        apps: {},
    }
}

export interface Context {
    apps?: any
    org: string,
    workspace: string,
    summary?: any
}

export type TabEvent = { type: 'TAB', tab: string }
export type LoadedEvent = { type: 'LOADED', summary: any }

export type Event =
    | TabEvent
    | LoadedEvent

const realtime = (context: Context, event: any) => (callback: any, onReceive: any) => {
    const onLoad = (response: any) => callback({ type: 'LOADED', summary: response });

    reporting.summary(context.org, context.workspace, onLoad);

    onReceive((e: any) => {
        switch (e.type) {
            
        }
    })

    return () => { };
}

export const machine = Machine<Context, Schema, Event>(
    {
        id: 'reporting',
        initial: 'apps',
        invoke: [
            { id: 'socket', src: realtime }
        ],
        states: {
            loading: {
                on: {
                    LOADED: [
                        // { cond: 'url', actions: ['loaded'], target: 'select' },
                        { actions: ['loaded'], target: 'summary' }
                    ]
                } as any
            },
            summary: {
                on: {
                    TAB: [
                        { cond: (_, event: TabEvent) => event.tab === 'apps', target: 'apps' },
                    ]
                }  
            },
            apps: {
                entry: [
                    assign({
                        apps: (context) => context.apps || spawn(appsMachine.withContext({ org: context.org, workspace: context.workspace })),
                    }),
                ],
                on: {
                    TAB: [
                        { cond: (_, event: TabEvent) => event.tab === 'summary', target: 'summary' },
                    ]
                }  
            }
        }
    },
    {
        actions: {
            loaded: assign({
                summary: (_, event: any) =>  event.summary
            })
        },
        guards: {
            // url: () => urlApp.test(window.location.pathname)
        }
    }
)