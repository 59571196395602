import React from "react";
import './error.css';

const Error = ({ error, onDismiss }: { error?: string | null, onDismiss?: () => void }) => {
	if (!error)
		return null;

	const messages = {
		'UNAUTHORIZED': 'You are not authorized to view this content'
	}

	return (
		<div className="error modal is-active">
			<div className="modal-background" onClick={onDismiss ? onDismiss : undefined}></div>
			<div className="modal-content">
			<article className="message is-danger">
				<div className="message-body">{messages[error]}</div>
			</article>
			</div>
			{onDismiss && <button className="modal-close is-large" aria-label="close" onClick={onDismiss}></button>}
		</div>
	);
};

export default Error;
