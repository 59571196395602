/* eslint-disable react/display-name */
import React, { FormEvent, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from '../../editing/Header';
import Dependencies from '../../editing/Dependencies';
import History from '../../editing/History';
import Delete from '../Delete';
import Loader from '../../../loader';
import { useActor } from "@xstate/react";
import { Context, Event } from '../../../../machines/build/versionable-item';
import _ from 'lodash';
import NewVersion from '../../editing/NewVersion';
import VersionableItems from '../../versionable/VersionableItems';
import Body from './Body';
import Parameters from './Parameters';
import { ActorRefFrom, StateMachine } from 'xstate';
import { faCloud } from '@fortawesome/free-solid-svg-icons';
import { IActionConnect, IConnectorOperation } from '../../../../../../types/action';
import { IDependency, IVersionableReferenceSelected } from '../../../../../../types/versionable';
import { IVariable } from '../../../../../../types/variable';
import VariableFinder from '../../variable/VariableFinder';
import { OpenAPIV3 } from 'openapi-types';
import { plugins } from '../../../../socket';

interface Props {
    org: string
    workspace: string
    machine: ActorRefFrom<StateMachine<Context<IActionConnect>, any, Event<IActionConnect>>>
}

export default ({ org, workspace, machine }: Props) => {
    const [state, send] = useActor(machine);
    const [isNewVersionVisible, setNewVersionVisible] = useState(false);
    const [document, setDocument] = React.useState<OpenAPIV3.Document | null>(null)

    React.useEffect(() => {
        if (state.context.item?.using)
            plugins.spec.load(org, workspace, state.context.item.using.id, state.context.item.using.version, 'dev', setDocument);
    }, [state.context.item?.using]);

    const onVersion = (version: string) => send({ type: 'VERSION', version });
  
    const onNewVersion = () => setNewVersionVisible(true);
    const onCloseNewVersion = () => setNewVersionVisible(false);

    const onNewVersionCreated = () => {
        setNewVersionVisible(false);
        send({ type: 'NEW_VERSION_CREATED' });
    }
    
    const onNameChange = (e: FormEvent<HTMLInputElement>) => send({ type: 'CHANGE', value: { name: e.currentTarget.value } });
    const onUsingChange = (using: IVersionableReferenceSelected[]) => send({ type: 'CHANGE', value: { using: using[0] }});
    const onIntoChange = (into: string) => send({ type: 'CHANGE', value: { into }});

    const onOperationChange = (e: FormEvent<HTMLSelectElement>) => {
        const [path, method] = _.split(e.currentTarget.value, ' - ');
        
        const operation: IConnectorOperation = {
            path,
            method,
            body: null,
            parameters: _.chain(_.get(document, `paths[${path}][${method}].parameters`)).keyBy('name').mapValues((value) => null).value()
        }

        send({
            type: 'CHANGE',
            value: _.set(state.context.item || {}, `operation`, operation)
        })
    }

    const onBodyChange = (body: IVariable | null, name?: string) => {
        if (name)
            send({
                type: 'CHANGE',
                value: _.set(state.context.item || {}, `operation.body.${name}`, body)
            });
        else
            send({
                type: 'CHANGE',
                value: _.set(state.context.item || {}, `operation.body`, body)
            });
    }

    const onParameterChange = (parameter: IVariable | null, name: string) => {
        send({
            type: 'CHANGE',
            value: _.set(state.context.item || {}, `operation.parameters.${name}`, parameter)
        });
    }

    const onDependencyClick = (item: IDependency) => send({ type: 'ITEM', itemType: item.type, id: item.id, version: item.version });

    const onDelete = () => send('DELETE');

    const operationKey = state.context.item && state.context.item.operation ? `${state.context.item.operation.path} - ${state.context.item.operation.method}` : '';

    const operations = document ? _.flatMap(_.sortBy(_.keys(document.paths)), (path) => {
        return _.keys(document.paths[path])
            .map(method => <option key={`${path} - ${method}`}>{`${path} - ${method}`}</option>);
    }) : [];

    let hasResponse = false;
    let operation: OpenAPIV3.OperationObject | null = null;

    if (state.matches('edit') && state.context.item?.operation) {
        hasResponse = !!_.get(document, `paths[${state.context.item.operation.path}][${state.context.item.operation.method}].responses`);
        operation = _.get(document, `paths[${state.context.item.operation.path}][${state.context.item.operation.method}`) as OpenAPIV3.OperationObject;
    }    

    return (
        <div className="action-editor">
            <Header
                versions={state.context.versions}
                version={state.context.version}
                onVersion={onVersion}
                onNewVersion={onNewVersion}
                users={state.context.users}
                modified={state.context.item ? state.context.item.modified : null}
            />

            <div className="connect p">
                {
                    state.matches('edit') && state.context.item ? (
                        <>
                            <div className="box">
                                <div className="is-flex justify-between mb">
                                    <div>
                                        <FontAwesomeIcon icon={faCloud} className="mr" />
                                        <strong>Execute an API endpoint</strong>
                                    </div>
                                    <button className="button is-danger is-small is-outlined" onClick={onDelete} disabled={state.context.item.readonly}>Delete</button>
                                </div>
                                <div className="field mb">
                                    <div className="control">
                                        <input className="input is-large" type="text" placeholder="Action Name" value={state.context.item.name} onChange={onNameChange} disabled={state.context.item.readonly} />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label">Plugin</label>
                                    <VersionableItems
                                        id="using"
                                        value={state.context.item.using ? [state.context.item.using] : []}
                                        onChange={onUsingChange}
                                        type="plugin"
                                        disabled={state.context.item.readonly} 
                                    />
                                </div>
                                <div className="field">
                                    <label className="label">API Endpoint</label>
                                    <div className="control">
                                        <div className="select is-fullwidth">
                                            <select value={operationKey} onChange={onOperationChange}>
                                                <option>Select an operation</option>
                                                {operations}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {
                                    hasResponse ? (
                                        <div className="field">
                                            <label className="label">Put Response Into Variable</label>
                                            <VariableFinder org={state.context.org} workspace={state.context.workspace} variable={state.context.item.into} onChange={onIntoChange} disabled={state.context.item.readonly} />
                                        </div>
                                    ) : null
                                }
                                <Body 
                                    org={state.context.org}
                                    workspace={state.context.workspace}
                                    operation={operation}
                                    body={state.context.item.operation.body}
                                    onChange={onBodyChange}
                                    disabled={state.context.item.readonly}
                                />
                                <Parameters
                                    org={state.context.org}
                                    workspace={state.context.workspace}
                                    operation={state.context.item.operation} 
                                    document={document}
                                    onChange={onParameterChange}
                                    disabled={state.context.item.readonly}
                                />
                            </div>

                            <div className="columns">
                                <div className="column">
                                    <div className="card mb">
                                        <header className="card-header">
                                            <p className="card-header-title">
                                            Uses
                                            </p>
                                        </header>
                                        <div className="card-content">
                                            <Dependencies org={state.context.org} workspace={state.context.workspace} dependencies={state.context.uses} onClick={onDependencyClick} />
                                        </div>
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="card mb">
                                        <header className="card-header">
                                            <p className="card-header-title">
                                            Used By
                                            </p>
                                        </header>
                                        <div className="card-content">
                                            <Dependencies org={state.context.org} workspace={state.context.workspace} dependencies={state.context.usedBy} onClick={onDependencyClick} />
                                        </div>
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="card">
                                        <header className="card-header">
                                            <p className="card-header-title">
                                                History
                                    </p>
                                        </header>
                                        <div className="card-content">
                                            <History versions={state.context.versions} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null
                }
                {
                    isNewVersionVisible ? (
                        <NewVersion
                            org={state.context.org}
                            workspace={state.context.workspace}
                            id={state.context.id}
                            type="action"
                            onNewVersion={onNewVersionCreated}
                            onClose={onCloseNewVersion}
                        />
                        ) : null
                }
                {state.matches('loading') ? <Loader /> : null}
                <Delete state={state} send={send} />
            </div>
        </div>
    );
}
