import React, { FormEvent, useState } from 'react';
import { useActor } from "@xstate/react";
import { Context, Event, versionableItemMachine } from '../../../machines/build/versionable-item';
import Graph from './Graph';
import State from './State';
import './application.css';
import AppDetails from './App-Details';
import Users from '../editing/Users';
import NewVersion from '../editing/NewVersion';
import Group from '../rules/RootGroup';
import Plugin from '../plugins/Plugin';
import Screen from '../screens/Screen';
import ActionLogic from '../actions/ActionEditor-Logic';
import ActionApp from '../actions/ActionEditor-App';
import ActionConnect from '../actions/connect/ActionEditor-Connect';
import ActionEmail from '../actions/ActionEditor-Email';
import ActionEvent from '../actions/ActionEditor-Event';
import ActionSort from '../actions/ActionEditor-Sort';
import ActionSQL from '../actions/ActionEditor-SQL';
import ActionStart from '../actions/ActionEditor-Start';
import ActionStop from '../actions/ActionEditor-Stop';
import ActionUpdate from '../actions/ActionEditor-Update';
import Delete from '../actions/Delete';
import { ActorRefFrom, StateMachine, interpret } from 'xstate';
import { IApp, IState } from '../../../../../types/app';
import { IDependency, IVersionableItem } from '../../../../../types/versionable';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import { actions, rules, screens } from '../../../socket';

interface Props {
    machine: ActorRefFrom<StateMachine<Context<IApp>, any, Event<IApp>>>
}

const App = ({ machine }: Props) => {
    const [state, send] = useActor(machine);
    const [selectedState, setSelectedState] = useState<string | null>(null);
    const [isNewVersionVisible, setNewVersionVisible] = useState(false);
    const [versionableItem, setVersionableItem] = useState<ActorRefFrom<StateMachine<Context<IVersionableItem>, any, Event<IVersionableItem>>> | null>(null);
    const [versionableItemKind, setVersionableItemKind] = useState<string | undefined>(undefined);

    const onVersion = (e: FormEvent<HTMLSelectElement>) => send({ type: 'VERSION', version: e.currentTarget.value });
    const onClose = () => {
        if (versionableItem) {
            versionableItem.stop?.();
            setVersionableItem(null);
            setVersionableItemKind(undefined);
        }
    }
    
    const onNewVersion = () => setNewVersionVisible(true);
    const onCloseNewVersion = () => setNewVersionVisible(false);

    const onNewVersionCreated = () => {
        setNewVersionVisible(false);
        send({ type: 'NEW_VERSION_CREATED' });
    }

    const onAddState = () => {
        const newState = {
            name: 'New State',
            id: nanoid(7),
            permissions: {
                using: null,
                message: '',
                rules: [],
                continueExecution: false
            },
            screen: null,
            entry: [],
            exit: [],
            on: {}
        }

        send({
            type: 'CHANGE',
            value: {
                ...state.context.item,
                states: {
                    ...state.context.item?.states,
                    [newState.id]: newState
                }
            }
        });
    } 

    const onSelect = (id: string) => setSelectedState(id);
    const onDeselect = () => setSelectedState(null);
    const onNodeDoubleClick = (id: string) => {}

    const onChange = (app: IApp) => send({ type: 'CHANGE', value: app });
    const onDelete = (app: IApp) => send({ type: 'DELETE' });

    const onStateChange = (newState: IState) => {
        send({
            type: 'CHANGE',
            value: {
                ...state.context.item,
                states: {
                    ...state.context.item?.states,
                    [newState.id]: newState
                }
            }
        })
    }

    const onDeleteState = (id: string) => {
        send({
            type: 'CHANGE',
            value: {
                ...state.context.item,
                states: _.omit(state.context.item?.states, id)
            }
        })
    }

    const onEdit = (id: string, version: string, itemType: string, kind?: string) => {
        const itemMachine =  versionableItemMachine(id, itemType).withContext({ org: state.context.org, workspace: state.context.workspace, id, version });
        const itemActor = interpret(itemMachine);
        itemActor.start();

        setVersionableItem(itemActor);
        setVersionableItemKind(kind ? `${itemType}.${kind}` : itemType);
    }

    const onDependencyClick = (item: IDependency) => {
        send({ type: 'ITEM', id: item.id, version: item.version, itemType: item.type });
    }

    const onNewPermissionsRules = (stateId: string) => {
        rules.new(state.context.org, state.context.workspace, 'rules', (response) => {
            if (state.context.item) {
                send({
                    type: 'CHANGE',
                    value: _.set(_.cloneDeep(state.context.item), `states.${stateId}.permissions.rules`, { id: response.id, version: 'working', grouped: false, disabled: false, featureFlags: [], kind: '' })
                });
                onEdit(response.id, 'working', 'screen');
            }
        });
    }

    const onNewEventRules = (stateId: string, id: string) => {
        rules.new(state.context.org, state.context.workspace, 'rules', (response) => {
            if (state.context.item) {
                send({
                    type: 'CHANGE',
                    value: _.set(_.cloneDeep(state.context.item), `states.${stateId}.on.${id}.rules`, _.concat(state.context.item.states[stateId].on[id]?.rules, { id: response.id, version: 'working', grouped: false, disabled: false, featureFlags: [], kind: '', breakpoint: false }))
                });
                onEdit(response.id, 'working', 'screen');
            }
        });
    }
    
    const onNewScreen = (stateId: string) => {
        screens.new(state.context.org, state.context.workspace, 'screen', (response: IVersionableItem) => {
            if (state.context.item) {
                send({
                    type: 'CHANGE',
                    value: _.set(_.cloneDeep<IApp>(state.context.item), `states.${stateId}.screen`, { id: response.id, version: 'working', grouped: false, disabled: false, featureFlags: [], kind: '' })
                });
                onEdit(response.id, 'working', 'screen');
            }
        });
    }

    const onNewEntryAction = (stateId: string, actionType: string) => {
        actions.new(state.context.org, state.context.workspace, actionType, (response: IVersionableItem) => {
            if (state.context.item) {
                send({
                    type: 'CHANGE',
                    value: _.set(_.cloneDeep(state.context.item), `states.${stateId}.entry`, _.concat(state.context.item.states[stateId].entry, { id: response.id, version: 'working', grouped: false, disabled: false, featureFlags: [], kind: actionType, breakpoint: false }))
                });
                onEdit(response.id, 'working', 'action', actionType);
            }
        });
    }

    const onNewExitAction = (stateId: string, actionType: string) => {
        actions.new(state.context.org, state.context.workspace, actionType, (response: IVersionableItem) => {
            if (state.context.item) {
                send({
                    type: 'CHANGE',
                    value: _.set(_.cloneDeep(state.context.item), `states.${stateId}.exit`, _.concat(state.context.item.states[stateId].exit, { id: response.id, version: 'working', grouped: false, disabled: false, featureFlags: [], kind: actionType, breakpoint: false }))
                });
                onEdit(response.id, 'working', 'action', actionType);
            }
        });
    }

    const onNewEventAction = (stateId: string, actionType: string, id: string) => {
        actions.new(state.context.org, state.context.workspace, actionType, (response: IVersionableItem) => {
            if (state.context.item) {
                send({
                    type: 'CHANGE',
                    value:  _.set(_.cloneDeep(state.context.item) as IApp, `states.${stateId}.on.${id}.actions`, _.concat(state.context.item.states[stateId].on[id]?.actions, { id: response.id, version: 'working', grouped: false, disabled: false, featureFlags: [], kind: actionType, breakpoint: false }))
                });
                onEdit(response.id, 'working', 'action', actionType);
            }
        });
    }
    
    if (!state.matches('edit') && !state.matches('item'))
        return null;

    let item : JSX.Element | null = null;
    if (versionableItemKind)
        switch (versionableItemKind.toUpperCase()) {
            case 'RULE':
                item = <Group machine={versionableItem} />
                break;

            case 'PLUGIN':
                item = <Plugin machine={versionableItem} />
                break;

            case 'SCREEN':
                item = <Screen machine={versionableItem} />
                break;

            case 'ACTION.LOGIC':
                item = <ActionLogic machine={versionableItem} />
                break;
            case 'ACTION.APP':
                item = <ActionApp machine={versionableItem} />
                break;

            case 'ACTION.CONNECT':
                item = <ActionConnect machine={versionableItem} />
                break;

            case 'ACTION.EMAIL':
                item = <ActionEmail machine={versionableItem} />
                break;

            case 'ACTION.EVENT':
                item = <ActionEvent machine={versionableItem} />
                break;

            case 'ACTION.SORT':
                item = <ActionSort machine={versionableItem} />
                break;

            case 'ACTION.SQL':
                item = <ActionSQL machine={versionableItem} />
                break;

            case 'ACTION.START':
                item = <ActionStart machine={versionableItem} />
                break;

            case 'ACTION.STOP':
                item = <ActionStop machine={versionableItem} />
                break;

            case 'ACTION.UPDATE':
                item = <ActionUpdate machine={versionableItem} />
                break;
        }

    if (!state.context.item)
        return null;

    return (
        <div className="application is-flex flex-col flex-grow">
            <div className="item-header">
                <div>
                    <div className="select mr">
                        <select value={state.context.item.version} onChange={onVersion}>
                            {state.context.versions?.map((v) => <option value={v.version} key={v.version}>{v.version}</option>)}
                        </select>
                    </div>
                    <button style={{ width: '120px' }} className="button mr is-primary" onClick={onNewVersion} disabled={state.context.item.readonly}>New Version</button>
                    <a style={{ width: '120px' }} className="button is-info mr2" target="_blank" rel="noreferrer" href={`${process.env.API_URL}/run?org=${state.context.org}&workspace=${state.context.workspace}&app=${state.context.item.id}&environment=dev&version=${state.context.item.version}`}>Run App</a>
                </div>
                <div>
                    {state.context.item.modified ? <span className="has-text-grey-dark">Modified: {(new Date(state.context.item.modified)).toLocaleString()}</span> : null}
                    <Users users={state.context.users} />
                </div>
            </div>
            <div className="is-flex flex-grow is-clipped">
                <Graph
                    initial={state.context.item.initial}
                    states={Object.values(state.context.item.states || {})}
                    selected={selectedState}
                    disabled={state.context.item.readonly}
                    onAdd={onAddState}
                    onSelect={onSelect}
                    onDeselect={onDeselect}
                    onDoubleClick={onNodeDoubleClick}
                />
                {
                    selectedState ?
                        <State
                            value={state.context.item.states[selectedState]}
                            states={_.values(state.context.item.states)}
                            onChange={onStateChange}
                            onEdit={onEdit}
                            onDelete={onDeleteState}
                            onNewPermissionsRules={onNewPermissionsRules}
                            onNewEntryAction={onNewEntryAction}
                            onNewExitAction={onNewExitAction}
                            onNewEventAction={onNewEventAction}
                            onNewEventRules={onNewEventRules}
                            onNewScreen={onNewScreen}
                            disabled={state.context.item.readonly}
                        /> :
                        <AppDetails
                            org={state.context.org}
                            workspace={state.context.workspace}
                            value={state.context.item}
                            uses={state.context.uses}
                            usedBy={state.context.usedBy}
                            versions={state.context.versions}
                            onChange={onChange}
                            onEdit={onEdit}
                            onDelete={onDelete}
                            onDependencyClick={onDependencyClick}
                            disabled={state.context.item.readonly}
                        />
                }
            </div>
            {item ? (
                <div className='modal is-active item-editor'>
                    <div className="modal-background" onClick={onClose}></div>
                    <div className="modal-content">
                        {item}
                    </div>
                    <button className="modal-close is-large" aria-label="close" onClick={onClose}></button>
                </div>
            ) : null}
            {isNewVersionVisible ? (
                <NewVersion
                    org={state.context.org}
                    workspace={state.context.workspace}
                    id={state.context.item.id}
                    type="app"
                    onNewVersion={onNewVersionCreated}
                    onClose={onCloseNewVersion}
                />
                ) : null
            }
            <Delete state={state} send={send} />
        </div >
    );
}

export default App;