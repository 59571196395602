import React from "react";
import { useMachine } from "@xstate/react";
import { versionableItemsMachine } from '../../../machines/build/versionable-items'
import Sidebar from "../../build/editing/Sidebar";
import Suite from "./Suite";

interface Props {
    org: string
    workspace: string
}

const Suites = ({ org, workspace }: Props) => {
    const [state, send] = useMachine(versionableItemsMachine('test-suites', 'test-suite', 'testing/suites'), { context: { org, workspace }});

    let editor: JSX.Element | null = null;

    if (state.context.selectedItemActor)
        editor = <Suite machine={state.context.selectedItemActor} />

    return (
        <div className="test-suites overflow-hidden">
            <Sidebar 
                items={state.context.items}
                selected={state.context.selectedItemId}
                send={send}
                loading={state.matches('loading')}
                type="Test Suite"
                allowNew={true}
            />
            {editor}
        </div>
    );
};

export default Suites;
