import { Machine, actions as stateActions, Interpreter } from 'xstate';
import { reporting } from '../../socket';
import _ from 'lodash';

const { send, assign } = stateActions;

export interface Schema {
    states: {
        loading: {},
        select: {}
        edit: {},
        error: {}
    }
}

export type Event =
    | { type: 'LOADED' }
    | { type: 'DISMISS' }
    | { type: 'VERSION' }
    | { type: 'BUILD' }
    | { type: 'STATE' }
    | { type: 'FROM' }
    | { type: 'TO' }
    | { type: 'SELECT' }
    | { type: 'TAB' }
    | { type: 'RUN' }

export interface Context {
    org: string
    workspace: string
    id: string
    environment: string
    error?: string | null
    selected?: string | null
    runs?: any
    version?: string
    build?: number
    state?: string
    from?: string
    to?: string
    variables?: any
    log?: string
    tab: string
}

const urlEnvironment = /reporting\/apps\/.{7}\/(.+)$/i
const urlRun = /reporting\/apps\/.{7}\/(.+)\/runs\/(.{7})$/i

const realtime = (context: Context, event: any) => (callback: any, onReceive: any) => {
    const onApp = (response: any) => callback({ type: 'LOADED', runs: _.keyBy(response, 'id') });
    const onRun = (response: any) => callback({ type: 'RUN', run: response });

    reporting.app(context.org, context.workspace, context.id, context.environment, onApp);

    onReceive((e: any) => {
        switch (e.type) {
            case 'SELECT':
                reporting.run(context.org, context.workspace, context.id, context.environment, e.run, onRun);
                break;
        }
    })

    return () => { };
}

const history = (context: Context) => (callback: any, onReceive: any) => {
    const listener = (event: PopStateEvent) => {
        // const stateMatch = urlState.exec(window.location.pathname);
        // const appMatch = urlApp.exec(window.location.pathname);

        // if (stateMatch)
        //     callback({ type: 'SELECT_STATE', state: stateMatch[1] });

        // if (appMatch)
        //     callback({ type: 'DESELECT' });
    }

    window.addEventListener('popstate', listener);
    return () => window.removeEventListener('popstate', listener);
}

export const machine = Machine<Context, Schema, Event>(
    {
        id: 'app',
        initial: 'loading',
        invoke: [
            { id: 'socket', src: realtime },
            { id: 'history', src: history }
        ],
        states: {
            loading: {
                on: {
                    LOADED: [
                        { cond: 'url', actions: ['loaded'], target: 'select' },
                        { actions: ['loaded'], target: 'edit' }
                    ]
                }
            },
            select: {
                always: {
                    actions: assign<Context, Event>(context => {
                        let environment: string | null = null;
                        let run: string | null = null;

                        const environmentMatch = urlEnvironment.exec(window.location.pathname);
                        const runMatch = urlRun.exec(window.location.pathname);

                        if (runMatch) {
                            environment = runMatch[1];
                            run = _.trim(runMatch[2], '/');
                        }
                        else if (environmentMatch) {
                            environment = _.trim(environmentMatch[1], '/');
                        }

                        return {
                            ...context,
                            selected: run,
                            tab: environment ? environment : undefined,
                        };
                    }),
                    target: 'edit'
                }
            },
            edit: {
                on: {
                    LOADED: { actions: ['loaded'] },
                    VERSION: { actions: ['version'] },
                    BUILD: { actions: ['build'] },
                    STATE: { actions: ['state'] },
                    FROM: { actions: ['from'] },
                    TO: { actions: ['to'] },
                    SELECT: { actions: ['selected', 'selectedRealtime'] },
                    RUN: { actions: ['run'] },
                    TAB: { actions: ['tab'] }
                }
            },
            error: {
                on: {
                    DISMISS: 'edit'
                }
            }
        }
    }, 
    {
        actions: {
            loaded: assign<Context, Event>({
                runs: (context: Context, event: any) => ({ ...context.runs, ...event.runs })
            }),
            selected: assign({
                selected: (_, event: any) => event.run
            }),
            run: assign({
                variables: (_, event: any) => event.run.variables,
                log: (context, event: any) => event.run.log_json,
                runs: (context, event) => {
                    context.runs[event.run.id].state = event.run.state;
                    context.runs[event.run.id].modified = event.run.modified;
                    return context.runs;
                }
            }),
            version: assign({
                version: (_, event: any) => event.value
            }),
            build: assign({
                build: (_, event: any) => event.value
            }),
            state: assign({
                state: (_, event: any) => event.value
            }),
            from: assign({
                from: (_, event: any) => event.value
            }),
            to: assign({
                to: (_, event: any) => event.value
            }),
            tab: assign({
                tab: (_, event: any) => event.tab
            }),
            selectedRealtime: send(
                (context: Context, event: any) => ({
                    type: 'SELECT',
                    run: event.run
                }),
                { to: 'socket' }
            )
        },
        guards: {
            url: () => urlEnvironment.test(window.location.pathname)
        }
    }
)

export const getUrlPart = (interpreter: Interpreter<Context, Schema, Event>) => {
    if (interpreter.state.context.selected)
        return `/environment/${interpreter.state.context.tab}/runs/${interpreter.state.context.selected}`;
    
    return `/environment/${interpreter.state.context.tab}`;
}