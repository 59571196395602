import React from "react";
import { Context, Event, Schema } from '../../../machines/deployment/releases/release';
import { useActor } from "@xstate/react";
import { ActorRefFrom, StateMachine } from "xstate";
import _ from "lodash";
import Users from "../../build/editing/Users";

const Release = ({ machine }: { machine: ActorRefFrom<StateMachine<Context, Schema, Event>> | null }) => {
    const [state, send] = useActor(machine!);
    const [app, setApp] = React.useState('');
    const [version, setVersion] = React.useState('');

    if (!state.matches("edit"))
        return null;

    const onVersionChange = (e) => send({ type: 'CHANGE', value: { version: e.currentTarget.value }});
    const onSummaryChange = (e) => send({ type: 'CHANGE', value: { summary: e.currentTarget.value }});
    const onStatusChange = (e) => send({ type: 'CHANGE', value: { status: e.currentTarget.value }});

    const rollback = (environment: string) => send({ type: 'ROLLBACK', environment });
    const deploy = (environment: string) => send({ type: 'DEPLOY', environment });

    const onAddAppToRelease = () => {
        send({ type: 'ADD_APP', app: { id: app, name: state.context.apps![app].name, version } })
    };

    const onRemoveAppFromRelease = (id: string) => send({ type: 'REMOVE_APP', app: { id } })

    return (
        <div className="release-editor">
            <div className="item-header">
                <div>

                </div>
                <div>
                    {state.context.release?.modified ? <span className="has-text-grey-dark">Modified: {(new Date(state.context.release?.modified)).toLocaleString()}</span> : null}
                    <Users users={state.context.users} />
                </div>
            </div>

            <div className="release">

                <div className="is-flex mb">
                    <div className="field flex-grow">
                        <div className="control">
                            <input className="input is-large" type="text" placeholder="Release Version" value={state.context.release?.version} onChange={onVersionChange} />
                        </div>
                    </div>
                    {/* <button className="button is-danger is-small is-outlined">Delete</button> */}
                </div>

                <div className="field">
                    <label className="label">Summary</label>
                    <div className="control">
                        <textarea className="textarea" value={state.context.release?.summary} onChange={onSummaryChange} />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Status</label>
                    <div className="control">
                        <div className="select">
                            <select value={state.context.release?.status} onChange={onStatusChange}>
                                <option value="GOOD">Good</option>
                                <option value="TESTING">Testing</option>
                                <option value="BROKEN">Broken</option>
                                <option value="PENDING">Pending</option>
                            </select>
                        </div>
                    </div>
                </div>

                <h4 className="is-size-4 mb">Environments</h4>
                <table className="table is-fullwidth">
                    <thead>
                        <tr>
                            <th style={{ width: '1%', whiteSpace: 'nowrap' }}></th>
                            <th>Name</th>
                            <th>Deployed On</th>
                            <th>Deployed By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            _.map(state.context.environments, (environment) => {
                                return (
                                    <tr key={environment.id}>
                                        <td style={{ width: '1%', whiteSpace: 'nowrap' }}>
                                            {
                                                environment.when ? <button className="button is-danger" onClick={rollback.bind(null, environment.id)}>Rollback</button> : <button className="button is-primary" onClick={deploy.bind(null, environment.id)}>Deploy</button>
                                            }
                                        </td>
                                        <td>{environment.name}</td>
                                        <td>{environment.when ? ((new Date(environment.when).toLocaleString())) : ''}</td>
                                        <td>{environment.by}</td>
                                    </tr>
                                )   
                            })
                        }
                    </tbody>
                </table>

                <h4 className="is-size-4 mb">Apps in Release</h4>
                <div className="add-to-release">
                    <div className="select">
                        <select value={app} onChange={(e) => setApp(e.currentTarget.value)}>
                            <option>Select an app</option>
                            {
                                _.chain(state.context.apps)
                                    .values()
                                    .filter(app => _.size(state.context.release?.apps) === 0 || !_.find(state.context.release?.apps, { id: app.id }))
                                    .map(app => <option value={app.id} key={app.id}>{app.name}</option>)
                                    .value()
                            }
                        </select>
                    </div>
                    <div className="select">
                        <select value={version} onChange={(e) => setVersion(e.currentTarget.value)} disabled={!app}>
                            <option>Select a version</option>
                            {
                                // eslint-disable-next-line
                                (app && state.context.apps![app].versions || []).map(version => <option value={version}>{version}</option>)
                            }
                        </select>
                    </div>
                    <button className="button is-primary" disabled={!app || !version} onClick={onAddAppToRelease}>Add to Release</button>
                </div>

                <table className="table is-fullwidth">
                    <thead>
                        <tr>
                            <th style={{ width: '1%', whiteSpace: 'nowrap' }}></th>
                            <th style={{ width: '1%', whiteSpace: 'nowrap' }}>ID</th>
                            <th>Name</th>
                            <th>Version</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            state.context.release?.apps?.map(app => (
                                <tr key={app.id + app.version}>
                                    <td style={{ width: '1%', whiteSpace: 'nowrap' }}><button className="button" onClick={onRemoveAppFromRelease.bind(null, app.id)}>Remove</button></td>
                                    <td style={{ width: '1%', whiteSpace: 'nowrap' }}><a>{app.id}</a></td>
                                    <td>{app.name}</td>
                                    <td>{app.version}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>
        </div>
    );
};

export default Release;
