import React from 'react';
import _ from 'lodash';

interface Props {
    value: string[]
    disabled: boolean
    onChange: (groups: string[]) => void
}

export default ({ value, onChange, disabled }: Props) => {
    const onAddGroup = () => {
        onChange(_.concat(value, ''));
    }

    const onRemoveGroup = (index: number) => {
        value.splice(index, 1);
        onChange(value);
    }

    const onGroupChange = (index: number, group: string) => {
        value[index] = group;
        onChange(value);
    }

    return (
        <div>
            <table className="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th>Group</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (value || []).map((item: any, index: number) => (
                            <tr key={index}>
                                <td>
                                    <input type="text" className="input is-small" value={item} onChange={(e) => onGroupChange(index, e.currentTarget.value)} disabled={disabled} />
                                </td>
                                <td>
                                    <button className="delete" onClick={onRemoveGroup.bind(null, index)} disabled={disabled} />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <button className="button is-small" onClick={onAddGroup} disabled={disabled}>Add Group</button>
        </div>
    )
}
