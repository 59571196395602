import React, { FormEvent } from 'react';
import classnames from 'classnames';
import Permissions from './Permissions';
import Entry from './Entry';
import Screen from './Screen';
import Events from './Events';
import Exit from './Exit';
import { IEvent, IState } from '../../../../../types/app';
import { IVersionableReferenceSelected } from '../../../../../types/versionable';
import _ from 'lodash';

interface IStateProps {
    value: IState
    states: IState[]
    onChange: (value: IState) => void
    onEdit: (id: string, version: string, type: string, kind?: string) => void
    onDelete: (id: string) => void
    onNewPermissionsRules: (state: string) => void
    onNewScreen: (state: string) => void
    onNewEntryAction: (state: string, actionType: string) => void
    onNewExitAction: (state: string, actionType: string) => void
    onNewEventAction: (state: string, actionType: string, id: string) => void
    onNewEventRules: (state: string, id: string) => void
    disabled: boolean
}

const State = ({ value, states, onChange, onEdit, onDelete, onNewPermissionsRules, onNewScreen, onNewEntryAction, onNewExitAction, onNewEventAction, onNewEventRules, disabled }: IStateProps) => {
    const [tab, setTab] = React.useState(0);

    const onNameChange = (e: FormEvent<HTMLInputElement>) => onChange({ ...value, name: e.currentTarget.value });
    const onAuthenticationChange = (using: IVersionableReferenceSelected[]) => onChange({ ...value, permissions: { ...value?.permissions, using: using[0] }});
    const onAuthorizationChange = (rules: IVersionableReferenceSelected[]) => onChange({ ...value, permissions: { ...value?.permissions, rules }});
    const onAuthorizationNew = () => onNewPermissionsRules(value.id);
    const onMessageChange = (message: string) => onChange({ ...value, permissions: { ...value?.permissions, message }});
    const onContinueExecutionChange = (continueExecution: boolean) => onChange({ ...value, permissions: { ...value?.permissions, continueExecution }});
    const onEntryChange = (entry: IVersionableReferenceSelected[]) => onChange({ ...value, entry });
    const onEntryNew = (actionType: string) => onNewEntryAction(value.id, actionType);
    const onScreenChange = (screen: IVersionableReferenceSelected[]) => onChange({ ...value, screen: screen[0] });
    const onScreenNew = () => onNewScreen(value.id);
    const onEventsChange = (events: Record<string, IEvent>) => onChange({ ...value, on: events });
    const onEventsNewAction = (actionType: string, id: string) => onNewEventAction(value.id, actionType, id);
    const onEventsNewRules = (id: string) => onNewEventRules(value.id, id);
    const onExitChange = (exit: IVersionableReferenceSelected[]) => onChange({ ...value, exit });
    const onExitNew = (actionType: string) => onNewExitAction(value.id, actionType);

    if (!value)
        return null;

    return (
        <div className="state">

            <div className="is-flex justify-between mb">
                <h2 className="is-size-4">State Configuration</h2>
                <button className="button is-danger is-small is-outlined" onClick={onDelete.bind(null, value.id)} disabled={disabled}>Delete</button>
            </div>

            <input className="input is-large mb" type="text" placeholder="Name" value={value.name} onChange={onNameChange} disabled={disabled} />

            <div className="tabs options" role="tablist">
                <ul>
                    <li className={classnames({ 'is-active': tab === 0})} role="tab"><a onClick={setTab.bind(null, 0)}>Permissions {value.permissions.rules.length > 0  || value.permissions.using ? ' \u2713' : null}</a></li>
                    <li className={classnames({ 'is-active': tab === 1})} role="tab"><a onClick={setTab.bind(null, 1)}>Entry {value.entry.length > 0 ? ' \u2713' : null}</a></li>
                    <li className={classnames({ 'is-active': tab === 2})} role="tab"><a onClick={setTab.bind(null, 2)}>Screen {value.screen ? ' \u2713' : null}</a></li>
                    <li className={classnames({ 'is-active': tab === 3})} role="tab"><a onClick={setTab.bind(null, 3)}>Events {_.keys(value.on).length > 0 ? ' \u2713' : null}</a></li>
                    <li className={classnames({ 'is-active': tab === 4})} role="tab"><a onClick={setTab.bind(null, 4)}>Exit {value.exit.length > 0 ? ' \u2713' : null}</a></li>
                </ul>
            </div>
            {
                tab === 0 ?
                    <Permissions
                        authentication={value.permissions.using}
                        onAuthenticationChange={onAuthenticationChange}
                        authorization={value.permissions.rules}
                        onAuthorizationChange={onAuthorizationChange}
                        message={value.permissions.message}
                        onMessageChange={onMessageChange}
                        continueExecution={value.permissions.continueExecution}
                        onContinueExecutionChange={onContinueExecutionChange}
                        onEdit={onEdit}
                        onNewRules={onAuthorizationNew}
                        isState={true}
                        disabled={disabled}
                    /> : null
            }            
            {tab === 1 ? <Entry value={value.entry} onChange={onEntryChange} onEdit={onEdit} onNew={onEntryNew} disabled={disabled} /> : null}
            {tab === 2 ? <Screen value={value.screen} onChange={onScreenChange} onEdit={onEdit} onNew={onScreenNew} disabled={disabled} /> : null}
            {tab === 3 ? <Events value={value.on} states={states} hasScreen={!!value.screen} onChange={onEventsChange} onEdit={onEdit} onNewAction={onEventsNewAction} onNewRules={onEventsNewRules} disabled={disabled} /> : null}
            {tab === 4 ? <Exit  value={value.exit} onChange={onExitChange} onEdit={onEdit} onNew={onExitNew} disabled={disabled} /> : null}
        </div>
    );
}

export default State;