import React, { useEffect, useState } from "react";
import { useActor } from "@xstate/react";
import VersionableItems from "../../build/versionable/VersionableItems";
import { ActorRefFrom, StateMachine } from "xstate";
import { Context, Event } from '../../../machines/build/versionable-item';
import _ from "lodash";
import Users from "../../build/editing/Users";
import './suite.css';
import { nanoid } from "nanoid";
import Test from "./Test";
import { ITest, ITestSuite } from "../../../../../types/testing";
import { IVersionableReferenceSelected } from "../../../../../types/versionable";
import { IState } from "../../../../../types/app";
import { testing } from "../../../socket";

const Suite = ({ machine }: { machine: ActorRefFrom<StateMachine<Context<ITestSuite>, any, Event<ITestSuite>>> }) => {
    const [state, send] = useActor(machine);
    const [selectedTest, setSelectedTest] = useState<string>('');
    const [states, setStates] = useState<IState[]>([]);

    const onNewTest = () => {
        send({ 
            type: 'CHANGE',
            value: {
                tests: _.concat(state.context.item?.tests || [], [{ 
                    id: nanoid(),
                    name: 'New Test',
                    order: _.size(state.context.item?.tests),
                    steps: [],
                    startDateTime: '',
                    startState: '',
                    startVariables: {}
                } as ITest])
            }
        });
    }

    const onSelectTest = (value: ITest) => {
        setSelectedTest(value.id);
    }

    const onAppChange = (app: IVersionableReferenceSelected[]) => {
        send({
            type: 'CHANGE',
            value: {
                app: app[0]
            }
        });

        if (app[0])
            testing.getAppStates(state.context.org, state.context.workspace, app[0].id, (states) => setStates(states));
        else
            setStates([]);
    }

    const onTestChange = (value: ITest) => {
        send({
            type: 'CHANGE',
            value: {
                tests: _.map(state.context.item?.tests, (test: ITest) => {
                    return test.id === value.id ? value : test
                })
            }
        })
    }

    const onTestDelete = (value: ITest) => {
        send({
            type: 'CHANGE',
            value: {
                tests: _.filter(state.context.item?.tests, (test: ITest) => {
                    return test.id !== value.id
                })
            }
        })
    }

    if (!state.context.item)
        return null;

    const test = state.context.item.tests.find(t => t.id === selectedTest);

    return (
        <div className="test-suite">
            <div className="item-header">
                <div>
                    <div className="select">
                        <select>
                            {state.context.versions?.map(v => <option value={v.version} key={v.version}>{v.version}</option>)}
                        </select>
                    </div>
                    <button style={{ width: '120px' }} className="button is-primary">New Version</button>
                    <button className="button is-info">Run Test Suite</button>
                </div>
                <div>
                    {state.context.item.modified ? <span className="has-text-grey-dark">Modified: {(new Date(state.context.item.modified)).toLocaleString()}</span> : null}
                    <Users users={state.context.users} />
                </div>
            </div>
            <div className="test-suite-content">
                <div className="control test-suite-name">
                    <input className="input" type="text" placeholder="Test Suite Name" defaultValue="Test Suite 1" />
                </div>

                <VersionableItems
                    id="app-to-test"
                    value={state.context.item.app ? [state.context.item.app] : []}
                    type="app"
                    multiple={false}
                    disabled={state.context.item.readonly}
                    onChange={onAppChange}
                />

                <button className="button is-small delete-test-suite">Delete Test Suite</button>

                <button className="button new-test" onClick={onNewTest}>New Test</button>

                <div className="panel tests">
                    {
                        _.chain(state.context.item.tests)
                            .sortBy('order')
                            .map((test) => <a className="panel-block" key={test.id} onClick={() => onSelectTest(test)}>{test.name}</a>)
                            .value()
                    }
                </div>

                {test ? <Test org={state.context.org} workspace={state.context.workspace} states={states} testSuiteId={state.context.item.id} testSuiteVersion={state.context.item.version} value={test} onChange={onTestChange} onDelete={onTestDelete} disabled={state.context.item.readonly} /> : null}
            </div>
        </div>
    );
};

export default Suite;
