import React, { FormEvent } from 'react';
import classnames from 'classnames';
import Dependencies from '../editing/Dependencies';
import History from '../editing/History';
import Permissions from './Permissions';
import Navigation from './Navigation';
import LookFeel from './LookFeel';
import { IApp, INavigation } from '../../../../../types/app';
import { IDependency, IVersion } from '../../../../../types/versionable';
import _ from 'lodash';

interface IAppDetailsProps {
    org: string
    workspace: string
    value: IApp
    uses?: IDependency[]
    usedBy?: IDependency[]
    versions?: IVersion[]
    onChange: (value: IApp) => void
    onEdit: (id: string, version: string, type: string, kind?: string) => void
    onDelete: (value: IApp) => void
    onDependencyClick: (item: IDependency) => void
    disabled: boolean
}

export default ({ org, workspace, value, uses, usedBy, versions, onChange, onEdit, onDelete, onDependencyClick, disabled }: IAppDetailsProps) => {
    const [tab, setTab] = React.useState(0);

    const onNameChange = (e: FormEvent<HTMLInputElement>) => onChange({ ...value, name: e.currentTarget.value });
    const onInitialChange = (e: FormEvent<HTMLSelectElement>) => onChange({ ...value, initial: e.currentTarget.value });

    const onAuthenticationChange = (using: IVersionableReferenceSelected[]) => onChange({ ...value, permissions: { ...value.permissions, using: using[0] }});
    const onAuthorizationChange = (rules: IVersionableReferenceSelected[]) => onChange({ ...value, permissions: { ...value.permissions, rules }});
    const onMessageChange = (message: string) => onChange({ ...value, permissions: { ...value.permissions, message }});

    const onTitleChange = (title: string) => onChange({ ...value, title });
    const onTabTitleChange = (tabTitle: string) => onChange({ ...value, tabTitle });
    const onThemeChange = (theme: IVersionableReferenceSelected[]) =>  onChange({ ...value, runner: theme[0] });

    const onNavigationChange = (navigation: INavigation) => onChange({ ...value, navigation });

    return (
        <div className="state">

            <div className="is-flex justify-between mb">
                <h2 className="is-size-4">App Configuration</h2>
                <button className="button is-danger is-small is-outlined" onClick={onDelete.bind(null, value)} disabled={disabled}>Delete</button>
            </div>

            <input name="app-name" className="input is-large mb" value={value.name} onChange={onNameChange} disabled={disabled}></input>

            <div className="tabs options" role="tablist">
                <ul>
                    <li className={classnames({ 'is-active': tab === 0})} role="tab"><a onClick={setTab.bind(null, 0)}>Permissions {value.permissions.rules.length > 0 || value.permissions.using ? ' \u2713' : null}</a></li>
                    <li className={classnames({ 'is-active': tab === 1})} role="tab"><a onClick={setTab.bind(null, 1)}>States</a></li>
                    <li className={classnames({ 'is-active': tab === 2})} role="tab"><a onClick={setTab.bind(null, 2)}>Navigation</a></li>
                    <li className={classnames({ 'is-active': tab === 3})} role="tab"><a onClick={setTab.bind(null, 3)}>Look & Feel {value.title || value.tabTitle || value.runner ? ' \u2713' : null}</a></li>
                    <li className={classnames({ 'is-active': tab === 4})} role="tab"><a onClick={setTab.bind(null, 4)}>References</a></li>
                    <li className={classnames({ 'is-active': tab === 5})} role="tab"><a onClick={setTab.bind(null, 5)}>History</a></li>
                </ul>
            </div>

            {
                tab === 0 ?
                    <Permissions
                        authentication={value.permissions.using}
                        onAuthenticationChange={onAuthenticationChange}
                        authorization={value.permissions.rules}
                        onAuthorizationChange={onAuthorizationChange}
                        message={value.permissions.message}
                        onMessageChange={onMessageChange}
                        onEdit={onEdit}
                        isState={false}
                        disabled={disabled}
                    /> : null
            }    
            {
                tab === 1 ? (
                    <div className="field" role="tabpanel">
                        <label className="label">Initial State</label>
                        <div className="control">
                            <div className="select">
                                <select value={value.initial} onChange={onInitialChange} disabled={disabled}>
                                    <option>Select a state</option>
                                    {
                                        Object
                                            .values(value.states)
                                            .map((item: any) => <option value={item.id} key={item.id}>{item.name}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                ) : null
            }
            {
                tab === 2 ? 
                    <Navigation
                        value={value.navigation}
                        states={_.values(value.states)}
                        onChange={onNavigationChange}
                        disabled={disabled} 
                    /> : null
            }
            {
                tab === 3 ?
                    <LookFeel
                        title={value.title}
                        tabTitle={value.tabTitle}
                        theme={value.runner}
                        onTitleChange={onTitleChange}
                        onTabTitleChange={onTabTitleChange}
                        onThemeChange={onThemeChange}
                        disabled={disabled}
                    /> : null
            }
            {tab === 5 ? <History versions={versions} /> : null}
            {tab === 4 ?  (
                <>
                    <h4 className="is-size-4 mb">Uses</h4>
                    <Dependencies org={org} workspace={workspace} dependencies={uses} onClick={onDependencyClick} />

                    <h4 className="is-size-4 mb">Used By</h4>
                    <Dependencies org={org} workspace={workspace} dependencies={usedBy} onClick={onDependencyClick} />
                </>
            ) : null}
        </div>
    );
}