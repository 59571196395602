import _ from 'lodash';
import { Machine, actions, Sender, Receiver } from 'xstate';
import { forwardTo } from 'xstate/lib/actions';
import { deployment } from '../../../socket';

const { assign } = actions;

export interface Schema {
    states: {
        loading: {},
        edit: {},
        error: {}
    }
}

type LoadedEvent = { type: 'LOADED', featureFlags: IFeatureFlag[] }
type RemoveEvent = { type: 'REMOVE', id: string }
type ChangeEvent = { type: 'CHANGE', id: string, name: string, description: string }
type NewEvent = { type: 'NEW',id: string, name: string, description: string }

export type Event =
    | LoadedEvent
    | RemoveEvent
    | ChangeEvent
    | NewEvent
    | { type: 'DISMISS' }

export interface IFeatureFlag {
    id: string
    name: string
    description: string
}

export interface Context {
    error?: string | null
    featureFlags?: IFeatureFlag[]
    org: string
    workspace: string
}

const realtime = (context: Context, event: Event) => (callback: Sender<Event>, onReceive: Receiver<Event>) => {
    const onLoad = (featureFlags: IFeatureFlag[]) => callback({ type: 'LOADED', featureFlags });

    deployment.featureFlags.featureFlags(context.org, context.workspace, onLoad);

    onReceive((e: any) => {
        switch (e.type) {
            case 'CHANGE':
            case 'NEW':
                deployment.featureFlags.change(context.org, context.workspace, { id: e.id, name: e.name, description: e.description });
                break;

            case 'REMOVE':
                deployment.featureFlags.remove(context.org, context.workspace, e.id);
                break;
        }
    })

    return () => {};
}

export const machine = Machine<Context, Schema, Event>({
    id: 'deployment-feature-flags',
    initial: 'loading',
    invoke: {
        id: 'socket',
        src: realtime
    },
    states: {
        loading: {
            on: {
                LOADED: {
                    actions: assign<Context, LoadedEvent>({
                        featureFlags: (_, event) => event.featureFlags,
                    }),
                    target: 'edit'
                }
            }
        },
        edit: {
            on: {
                NEW: {
                    actions: [
                        'new',
                        forwardTo('socket')
                    ]
                },
                CHANGE: {
                    actions: [
                        'change',
                        forwardTo('socket')
                    ]
                },
                REMOVE: {
                    actions: [
                        'remove',
                        forwardTo('socket')
                    ]
                }
            }
        },
        error: {
            on: {
                DISMISS: 'edit'
            }
        }
    }
}, {
    actions: {
        new: assign<any, any>({
            featureFlags: (context: Context, event: NewEvent) => _.concat(context.featureFlags, [{ id: event.id, name: event.name, description: event.description }])
        }),
        change: assign<any, any>({
            featureFlags: (context: Context, event: ChangeEvent) => _.map(context.featureFlags, (flag) => {
                if (flag.id === event.id)
                    flag = { id: event.id, name: event.name, description: event.description }
                
                return flag;
            })
        }),
        remove: assign<any, any>({
            featureFlags: (context: Context, event: RemoveEvent) => _.filter(context.featureFlags, (flag) => flag.id !== event.id)
        })
    }
})