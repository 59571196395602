import React from "react";
import "./graph.css";
import { IState } from "../../../../../types/app";
import * as d3 from 'd3-shape';
import DagreGraph from "./Dagregraph";
import { useHotkeys } from 'react-hotkeys-hook';
import _ from 'lodash';

const truncate = (name: string, length: number) => (name.length > length) ? name.substr(0, length - 1) + '&hellip;' : name;

const Graph = ({ initial, states, selected, disabled, onAdd, onSelect, onDoubleClick, onDeselect }: { initial: string, states: IState[], selected: string | null, disabled: boolean, onAdd: () => void, onSelect: (name: string) => void, onDoubleClick: (id: string) => void, onDeselect: () => void }) => {

    useHotkeys('esc', onDeselect);

    const onGraphClick = () => onDeselect();
    const onNodeClick = (e: any) => onSelect(e.original.id);
    const onNodeDoubleClick = (e: any) => onDoubleClick(e.original.id);

    let nodes = Object
        .values(states)
        .map((state: IState) => ({
            label: `<span title="${state.name}">${truncate(state.name, 14)}</span>`,
            id: state.id,
            config: {
                labelType: 'html',
                width: 100,
                style: selected === state.id ? 'stroke: #1abc9c;' : '',
                id: state.id
            }
        }));

    nodes = _.sortBy(nodes, (node: any) => node.id === initial ? 0 : 1);

    const links = Object.values(states).map((state: IState) => {
        return _.values(state.on)
            .filter(event => event.target !== '_SELF' && event.target !== '_NONE' && event.target !== '_SCREEN' )
            .map((event, index: number) => ({ 
                source: state.id,
                target: event.target,
                label: event.immediate ? '<p style="padding: 4px 8px; margin-right: 4px; border: 2px solid #34495e; border-radius: 8px">Immediate</p>' : `<span style="padding-right: 4px" title="${event.name}">${event.name}</span>`,
                name: `${state.id}-${index}`,
                config: {
                    labelType: 'html',
                    minlen: 2,
                    labelpos: 'c',
                    curve: d3.curveBasis
                }
            }))
    });
    
    return (
        <div className="graph">
            <button className="button is-primary" onClick={onAdd} disabled={disabled}>Add State</button>
                
            <div onClick={onGraphClick}>
                <DagreGraph
                    nodes={nodes}
                    links={[].concat.apply([], links as any)}
                    onNodeClick={onNodeClick}
                    onNodeDoubleClick={onNodeDoubleClick}
                    shape="circle"
                    multigraph={true}
                    config={{
                        marginx: 16,
                        marginy: 16
                    }}
                />
            </div>
        </div>
    )
};

export default Graph;

