import { actions as stateActions, DoneInvokeEvent, createMachine } from 'xstate';
import _ from 'lodash';

const { assign } = stateActions;

export interface Schema {
    states: {
        loadUsers: {}
        loadLicenses: {}
        users: {},
        loadSettings: {}
        settings: {}
    }
}

type AddUserEvent = { type: 'ADD_USER', email: string, name: string, roles: string[] };
type RemoveUserEvent = { type: 'REMOVE_USER', user: string };
type ChangeRoleEvent = { type: 'CHANGE_ROLE', user: string, roles: string[]};
type AddDeniedVariableEvent = { type: 'ADD_DENIED_VARIABLE' };
type RemoveDeniedVariableEvent = { type: 'REMOVE_DENIED_VARIABLE', variable: string };
type ChangeDeniedVariableEvent = { type: 'CHANGE_DENIED_VARIABLE', variable: string, index: number };
type AddCustomComponentEvent = { type: 'ADD_CUSTOM_COMPONENT', component: string };
type RemoveCustomComponentEvent = { type: 'REMOVE_CUSTOM_COMPONENT', component: string };
type UpdateCustomComponentEvent = { type: 'UPDATE_CUSTOM_COMPONENT', component: string, properties: string[] };
type SaveSettingsEvent = { type: 'SAVE_SETTINGS' }
type SaveCustomComponentsEvent = { type: 'SAVE_CUSTOM_COMPONENTS' }

export type Event =
    | AddUserEvent
    | RemoveUserEvent
    | ChangeRoleEvent
    | DoneInvokeEvent<IUser[]>
    | AddDeniedVariableEvent
    | RemoveDeniedVariableEvent
    | ChangeDeniedVariableEvent
    | SaveSettingsEvent
    | AddCustomComponentEvent
    | UpdateCustomComponentEvent
    | RemoveCustomComponentEvent
    | SaveCustomComponentsEvent

interface IUser {
    id: string
    name: string
    email: string
    roles: string[]
}

interface ICustomComponent {
    type: string
    properties: string[]
}

export interface Context {
    org: string
    workspace: string
    users: IUser[]
    denylist: string[]
    customComponents: ICustomComponent[]
}

const fetchUsers = (org: string, workspace: string) =>
    fetch(`${process.env.API_URL}/${org}/workspace/${workspace}/user`, { credentials: 'include' }).then((response) => response.json());

const addUser = (org: string, workspace: string, name: string, email: string, roles: string[]) =>
    fetch(`${process.env.API_URL}/${org}/workspace/${workspace}/user`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name, email, roles }),
        credentials: 'include'
    })
    .then((response) => response.json());

const removeUser = (org: string, workspace: string, user: string) =>
    fetch(`${process.env.API_URL}/${org}/workspace/${workspace}/user/${user}`, {
        method: 'DELETE',
        credentials: 'include'
    })
    .then((response) => response.json());

const changeRole = (org: string, workspace: string, user: string, roles: string[]) =>
    fetch(`${process.env.API_URL}/${org}/workspace/${workspace}/user/${user}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ roles }),
        credentials: 'include'
    });

const fetchDeniedVariables = (org: string, workspace: string) =>
    fetch(`${process.env.API_URL}/${org}/workspace/${workspace}/denylist`, { credentials: 'include' }).then((response) => response.json());

const changeDeniedVariables = (org: string, workspace: string, denylist: string[]) =>
    fetch(`${process.env.API_URL}/${org}/workspace/${workspace}/denylist`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(denylist),
        credentials: 'include'
    }).then((response) => response.json());

const fetchCustomComponents = (org: string, workspace: string) =>
    fetch(`${process.env.API_URL}/${org}/workspace/${workspace}/components`, { credentials: 'include' }).then((response) => response.json());

const saveCustomComponents = (org: string, workspace: string, components: ICustomComponent[]) =>
    fetch(`${process.env.API_URL}/${org}/workspace/${workspace}/components`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(components),
        credentials: 'include'
    })
    .then((response) => response.json());

export const machine = createMachine<Context, Event>(
    {
        id: 'workspace',
        initial: 'loadUsers',
        type: 'parallel',
        states: {
            users: {
                initial: 'loadUsers',
                states: {
                    loadUsers: {
                        // @ts-ignore
                        invoke: {
                            id: 'loadUsers',
                            src: (context: Context, event: DoneInvokeEvent<IUser[]>) => fetchUsers(context.org, context.workspace),
                            onDone: {
                                target: 'edit',
                                actions: assign({ 
                                    users: (context, event: DoneInvokeEvent<IUser[]>) => event.data
                                })
                            }
                        }
                    },
                    edit: {
                        on: {
                            ADD_USER: 'addingUser',
                            REMOVE_USER: 'removingUser',
                            CHANGE_ROLE: 'changingRole'
                        }
                    },
                    addingUser: {
                        // @ts-ignore
                        invoke: {
                            id: 'addUser',
                            src: (context: Context, event: AddUserEvent) => addUser(context.org, context.workspace, event.name, event.email, event.roles),
                            onDone: {
                                target: 'loadUsers'
                            },
                            onError: {
                                target: 'edit'
                            }
                        }
                    },
                    removingUser: {
                        // @ts-ignore
                        invoke: {
                            id: 'removeUser',
                            src: (context: Context, event: RemoveUserEvent) => removeUser(context.org, context.workspace, event.user),
                            onDone: {
                                target: 'loadUsers'
                            },
                            onError: {
                                target: 'edit'
                            }
                        }
                    },
                    changingRole: {
                        // @ts-ignore
                        invoke: {
                            id: 'changeRole',
                            src: (context: Context, event: ChangeRoleEvent) => changeRole(context.org, context.workspace, event.user, event.roles),
                            onDone: {
                                target: 'loadUsers'
                            },
                            onError: {
                                target: 'edit'
                            }
                        }
                    }
                }
            },
            settings: {
                initial: 'loadSettings',
                states: {
                    loadSettings: {
                        // @ts-ignore
                        invoke: {
                            id: 'loadSettings',
                            src: (context: Context, event: DoneInvokeEvent<string[]>) => fetchDeniedVariables(context.org, context.workspace),
                            onDone: {
                                target: 'edit',
                                actions: assign({ 
                                    denylist: (context, event: DoneInvokeEvent<string[]>) => event.data
                                })
                            }
                        }
                    },
                    edit: {
                        // @ts-ignore
                        on: {
                            ADD_DENIED_VARIABLE: {
                                actions: assign<Context, AddDeniedVariableEvent>({
                                    denylist: (context, event) => (context.denylist || []).concat([''])
                                })
                            },
                            REMOVE_DENIED_VARIABLE: {
                                actions: assign<Context, RemoveDeniedVariableEvent>({
                                    denylist: (context, event) => _.without(context.denylist, event.variable)
                                })
                            },
                            CHANGE_DENIED_VARIABLE: {
                                actions: assign<Context, ChangeDeniedVariableEvent>({
                                    denylist: (context, event) => {
                                        context.denylist.splice(event.index, 1, event.variable);
                                        return context.denylist;
                                    }
                                })
                            },
                            SAVE_SETTINGS: {
                                invoke: {
                                    id: 'saveSettings',
                                    src: (context: Context, event: DoneInvokeEvent<any>) => changeDeniedVariables(context.org, context.workspace, context.denylist),
                                    onDone: {
                                        target: 'loadSettings',
                                    }
                                }
                            }
                        }
                    }
                }
            },
            customComponents: {
                initial: 'loadCustomComponents',
                states: {
                    loadCustomComponents: {
                        // @ts-ignore
                        invoke: {
                            id: 'loadCustomComponents',
                            src: (context: Context, event: DoneInvokeEvent<ICustomComponent[]>) => fetchCustomComponents(context.org, context.workspace),
                            onDone: {
                                target: 'edit',
                                actions: assign({ 
                                    customComponents: (context, event: DoneInvokeEvent<ICustomComponent[]>) => event.data
                                })
                            }
                        }
                    },
                    edit: {
                        // @ts-ignore
                        on: {
                            ADD_CUSTOM_COMPONENT: {
                                actions: assign<Context, AddCustomComponentEvent>({
                                    customComponents: (context, event) => (context.customComponents || []).concat([{ type: event.component, properties: [] }])
                                })
                            },
                            REMOVE_CUSTOM_COMPONENT: {
                                actions: assign<Context, RemoveCustomComponentEvent>({
                                    customComponents: (context, event) => _.filter(context.customComponents, (c) => c.type !== event.component)
                                })
                            },
                            UPDATE_CUSTOM_COMPONENT: {
                                actions: assign<Context, UpdateCustomComponentEvent>({
                                    customComponents: (context, event) => _.map(context.customComponents, (c) => {
                                        if (c.type === event.component)
                                            c.properties = event.properties;

                                        return c;
                                    })
                                })
                            },
                            SAVE_CUSTOM_COMPONENTS: {
                                invoke: {
                                    src: (context: Context, event: DoneInvokeEvent<any>) => saveCustomComponents(context.org, context.workspace, context.customComponents),
                                    onDone: {
                                        target: 'loadCustomComponents',
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
)