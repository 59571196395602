import React, { useState } from "react";
import { useActor } from '@xstate/react'
import { Context, Schema } from '../../machines/deployment/deployment';
import { ActorRefFrom, StateMachine } from "xstate";
import Environments from "./environments/Environments";
import Releases from "./releases/Releases";
import FeatureFlags from "./featureFlags/FeatureFlags";
import classNames from "classnames";

const Deployment = ({ machine }: { machine: ActorRefFrom<StateMachine<Context, Schema, any>> | null }) => {
	const [state] = useActor(machine!);
	
	const tabs =[
		// { title: 'Releases', type: 'releases' },
		{ title: 'Releases', type: 'releases' },
		{ title: 'Environments', type: 'environments' },
		{ title: 'Feature Flags', type: 'featureFlags' },
		// { title: 'Plugins', type: 'plugins' },
		// { title: 'Runners', type: 'runners' },
	];

	const [active, setActive] = useState(tabs[0]);

	return (
		<>
			<div className="tabs is-boxed flex-shrink-0">
				<ul>
					{
						tabs.map((tab: any, index: number) => (
							<li className={classNames({ 'is-active': tab.type === active.type })} key={index}>
								<a onClick={setActive.bind(null, tab)}>
									<span>{tab.title}</span>
								</a>
							</li>
						))
					}
				</ul>
			</div>
			{active.type === 'releases' ? <Releases machine={state.context.releases} /> : null}
			{active.type === 'environments' ? <Environments machine={state.context.environments} /> : null}
			{active.type === 'featureFlags' ? <FeatureFlags machine={state.context.featureFlags} /> : null}
			{/* {active.type === 'apps' && state.context.apps ? <Apps machine={state.context.apps} /> : null} */}
			{/* {active.type === 'plugins' && state.context.plugins ? <Plugins machine={state.context.plugins} /> : null} */}
		</>
	);
};

export default Deployment;
