import classnames from "classnames";
import React, { FormEvent, useEffect, useReducer, useRef, useState } from "react";
import { JSONEditor } from "vanilla-jsoneditor";
import './test.css';
import { ITest, ITestStep, ITestStepCheckValue, ITestStepClickButton, ITestStepSetValue, TestStepType } from "../../../machines/testing/suites/test-suite";
import SetComponentValue from "./steps/SetComponentValue";
import ClickButton from "./steps/ClickButton";
import CheckValue from "./assertions/CheckValue";
import _ from "lodash";
import { nanoid } from "nanoid";
import { testing } from '../../../socket';
import { IState } from "../../../../../types/app";
import CheckScreen from "./assertions/CheckScreen";
import { ITestStepCheckScreen } from "../../../../../types/testing";

interface Props {
    org: string
    workspace: string
    states: IState[],
    testSuiteId: string
    testSuiteVersion: string
    value: ITest
    disabled: boolean
    onChange: (value: ITest) => void
    onDelete: (value: ITest) => void
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'reset':
            return { passes: [], failures: {} };

        case 'pass':
            return {
                ...state,
                passes: _.concat(state.passes, [action.stepId])
            };

        case 'fail':
            return {
                ...state,
                failures: {
                    ...state.failures,
                    [action.stepId]: action.message
                }
            };
    }
}

const Test = ({ org, workspace, states, testSuiteId, testSuiteVersion, value, onChange, onDelete, disabled }: Props) => {

    const [state, dispatch] = useReducer(reducer, { passes: [], failures: {} });

    const refContainer = useRef<HTMLDivElement | null>(null);
    const refEditor = useRef<JSONEditor | null>(null);

    const tabs = [
        { title: 'Setup', type: 'setup' },
        // { title: 'Fakes', type: 'fakes' },
        { title: 'Steps', type: 'steps' },
    ];

    const [active, setActive] = useState(tabs[0]);

    const [content, setContent] = useState({
        json: {
            greeting: "Hello World",
            color: "#ff3e00",
            ok: true,
            values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
        },
        text: undefined
    });

    useEffect(() => {
        refEditor.current = new JSONEditor({
            target: refContainer.current,
            props: {
                content: {
                    json: {}
                }
            }
        });

        return () => {
            if (refEditor.current) {
                refEditor.current.destroy();
                refEditor.current = null;
            }
        };
    }, []);

    const onNameChange = (e: FormEvent<HTMLInputElement>) => {
        onChange({
            ...value,
            name: e.currentTarget.value
        })
    }

    const onRun = () => {
        dispatch({ type: 'reset' });

        testing.runTest(
            org,
            workspace,
            testSuiteId,
            testSuiteVersion,
            value.id,
            (response) => {
                dispatch({ type: 'pass', stepId: response.stepId });
            },
            (response) => {
                dispatch({ type: 'fail', stepId: response.stepId, message: response.message });
            }
        );
    }

    const onAddStep = (type: TestStepType) => {
        onChange({
            ...value,
            steps: _.concat(value.steps, [{
                id: nanoid(),
                type
            }])
        })
    }

    const onAddAssertion = (type: TestStepType) => {
        onChange({
            ...value,
            steps: _.concat(value.steps, [{
                id: nanoid(),
                type
            }])
        })
    }

    const onStepChange = (step: ITestStep) => {
        onChange({
            ...value,
            steps: _.map(value.steps, (s) => {
                return s.id === step.id ? step : s;
            })
        })
    }

    const onStepDelete = (step: ITestStep) => {
        onChange({
            ...value,
            steps: _.filter(value.steps, (s) => s.id !== step.id)
        })
    }

    return (
        <div className="test box">
            <div className="test-header">
                <div className="control">
                    <input className="input" type="text" placeholder="Test Name" value={value.name} onChange={onNameChange} />
                </div>
                <button className="button is-info" onClick={onRun}>Run Test</button>
                <button className="button is-small" onClick={() => onDelete(value)}>Delete Test</button>
            </div>

            <div className="tabs is-boxed flex-shrink-0">
                <ul>
                    {
                        tabs.map((tab: any, index: number) => (
                            <li className={classnames({ 'is-active': tab.type === active.type })} key={index}>
                                <a onClick={setActive.bind(null, tab)}>
                                    <span>{tab.title}</span>
                                </a>
                            </li>
                        ))
                    }
                </ul>
            </div>

            {
                active.type === 'setup' ? (
                    <div>
                        <div className="field">
                            <label className="label">Start at State</label>
                            <div className="control">
                                <div className="select">
                                    <select>
                                        <option>Select state</option>
                                        {
                                            states ? states.map(state => <option key={state.id} value={state.id}>{state.name}</option>) : null
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Set Date & Time</label>
                            <div className="control">
                                <input className="input" type="datetime-local" placeholder="Text input" />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Set Initial Variables</label>
                            <div className="control">
                                <div ref={refContainer}></div>
                            </div>
                        </div>
                    </div>
                ) : null
            }
            {
                active.type === 'steps' ? (
                    <>
                        <div className="steps">
                            {
                                _.map(value.steps, (step: ITestStep) => {
                                    let status = 'none';

                                    if (state.passes.includes(step.id))
                                        status = 'pass';
                                    else if (state.failures[step.id])
                                        status = 'fail';

                                    switch (step.type) {
                                        case 'set-value':
                                            return <SetComponentValue org={org} workspace={workspace} value={step as ITestStepSetValue} onChange={onStepChange} onDelete={onStepDelete} disabled={disabled} status={status} message={state.failures[step.id]} key={step.id} />
                                        
                                        case 'click-button':
                                            return <ClickButton value={step as ITestStepClickButton} onChange={onStepChange} onDelete={onStepDelete} disabled={disabled} status={status} message={state.failures[step.id]} key={step.id} />

                                        case 'check-value':
                                            return <CheckValue org={org} workspace={workspace} value={step as ITestStepCheckValue} onChange={onStepChange} onDelete={onStepDelete} disabled={disabled} status={status} message={state.failures[step.id]} key={step.id} />

                                        case 'check-screen':
                                            return <CheckScreen org={org} workspace={workspace} value={step as ITestStepCheckScreen} onChange={onStepChange} onDelete={onStepDelete} disabled={disabled} status={status} message={state.failures[step.id]} key={step.id} />
                                    }
                                })
                            }
                        </div>
                            
                        <div className="buttons">
                            <div className="dropdown is-hoverable is-up">
                                <div className="dropdown-trigger">
                                    <button className="button is-primary" aria-haspopup="true" aria-controls="dropdown-menu4">
                                        Add Step
                                    </button>
                                </div>
                                <div className="dropdown-menu" role="menu">
                                    <div className="dropdown-content">
                                        <div className="dropdown-item" role="menuitem" onClick={onAddStep.bind(null, 'set-value')}>Set Value</div>
                                        <div className="dropdown-item" role="menuitem" onClick={onAddStep.bind(null, 'click-button')}>Click Button</div>
                                    </div>
                                </div>
                            </div>
                            <div className="dropdown is-hoverable is-up">
                                <div className="dropdown-trigger">
                                    <button className="button is-primary" aria-haspopup="true" aria-controls="dropdown-menu4">
                                        Add Assertion
                                    </button>
                                </div>
                                <div className="dropdown-menu" role="menu">
                                    <div className="dropdown-content">
                                        <div className="dropdown-item" role="menuitem" onClick={onAddAssertion.bind(null, 'check-value')}>Check Value</div>
                                        <div className="dropdown-item" role="menuitem" onClick={onAddAssertion.bind(null, 'check-screen')}>Check Screen</div>
                                    </div>
                                </div>
                            </div>
                            <button className="button is-danger">Record Steps</button>
                        </div>
                    </>
                ) : null}
        </div>
    )
}

export default Test;