import { io, Socket } from 'socket.io-client';
import { IVersionableReference } from '../../types/versionable';
import { OpenAPIV3 } from 'openapi-types';
import { IState } from '../../types/app';

let socket: Socket;

export const connect = (onConnected: () => void, onReconnect: () => void, onDisconnected: () => void) => {
    socket = io(`${process.env.API_URL}/design`, {
        transports: ['websocket'],
        closeOnBeforeunload: false
    });

    socket.on('disconnect', (reason) => {
        onDisconnected();

        if (reason === 'io server disconnect') {
            socket.connect();
            onReconnect();
        }
    });

    socket.io.on('reconnect', onReconnect);

    socket.on("connect", () => {
        socket.sendBuffer = [];
        onConnected();
    });
}

export const disconnect = (org: string, workspace: string) => {
    socket.emit('org::disconnect', { org, workspace });
    socket.disconnect();
}

export const profile = (org: string, workspace: string, callback: any) => {
    socket.emit('profile', { org, workspace }, callback)
}

export const organizations = {
    query: (callback: any) => {
        socket.emit('organizations', null, callback);
    },
    create: (name: string, callback: any) => {
        socket.emit('new::organization', { name }, callback);
    }
}

export const workspaces = {
    query: (org: string, callback: any) => {
        socket.emit('workspaces', { org }, callback);
    },
    get: (org: string, workspace: string, callback: any) => {
        socket.emit('workspace', { org, workspace }, callback)
    },
    create: (org: string, name: string, description: string, callback: any) => {
        socket.emit('new::workspace', { org, name, description }, callback);
    }
}

export const versionableItems = (kind: string) => ({
    new: (org: string, workspace: string, type: string | undefined, callback: any) => {
        socket.emit(`new::${kind}`, { org, workspace, type }, callback);
    },
    edit: (
        org: string,
        workspace: string,
        onLoad: (items: IVersionableReference[]) => void,
        onNew: (item: IVersionableReference) => void,
        onDelete: (response: { id: string }) => void,
        onRename: (response: { id: string, name: string}) => void
    ) => {
        socket.emit(`edit::${kind}`, { org, workspace }, onLoad);
        socket.on(`new::${kind}`, onNew);
        socket.on(`delete::${kind}`, onDelete);
        socket.on(`rename::${kind}`, onRename)
    },
    delete: (org: string, workspace: string, id: string) => {
        socket.emit(`delete::${kind}`, { org, workspace, id });
    },
    filterByApp: (org: string, workspace: string, id: string, onLoad: any) => {
        socket.emit(`filter::${kind}`, { org, workspace, id }, onLoad);
    }
})

export const apps = versionableItems('app');
export const actions = versionableItems('action');
export const rules = versionableItems('rule');
export const screens = versionableItems('screen');
export const runners = versionableItems('runner');
export const plugins = {
    ...versionableItems('plugin'),
    spec: {
        load: (org: string, workspace: string, id: string, version: string, environment: string, callback: (spec: OpenAPIV3.Document) => void) => {
            socket.emit(`spec::plugin;load`, { org, workspace, id, version, environment, }, callback);
        },
        save: (org: string, workspace: string, id: string, version: string, environment: string, spec: OpenAPIV3.Document, callback: (spec: OpenAPIV3.Document) => void) => {
            socket.emit(`spec::plugin;save`, { org, workspace, id, version, environment, spec }, callback);
        },
    },
}
export const testSuites = versionableItems('test-suite');

export const edit = (org: string, workspace: string, id: string, version: string | undefined, type: string, onLoad: any, onChange: any, onDelete: any, onJoined: any, onLeft: any) => {
    socket.emit(`edit::versionable`, { org, workspace, id, version, type }, onLoad);
    
    socket.on(`edit::versionable-${id};changed`, onChange);
    socket.on(`edit::versionable-${id};deleted`, onDelete);
    socket.on(`edit::versionable-${id};joined`, onJoined);
    socket.on(`edit::versionable-${id};left`, onLeft);
}

export const change = (org: string, workspace: string, id: string, type: string, patch: any) => {
    socket.emit(`edit::versionable;change`, { org, workspace, id, type, patch });
}

export const remove = (org: string, workspace: string, id: string, type: string) => {
    socket.emit(`edit::versionable;delete`, { org, workspace, id, type });
}

export const close = (org: string, workspace: string, id: string, version: string, type: string) => {
    socket.emit(`edit::versionable;close`, { org, workspace, id, version, type });
}

export const references = (
    org: string,
    workspace: string,
    type: string,
    callback: (references: IVersionableReference[]) => void,
    rename: (reference: IVersionableReference) => void,
    remove: (refernce: IVersionableReference) => void) => {
    socket.emit(`references::${type}`, { org, workspace }, callback);
    socket.on(`references::${type}`, callback);
    socket.on(`references::${type};rename`, rename);
    socket.on(`references::${type};delete`, remove);
}

export const values = (org: string, workspace: string, callback: (values: string[]) => void) => {
    socket.emit(`references::value`, { org, workspace }, callback);
}

export const events = (org: string, workspace: string, callback: (events: string[]) => void) => {
    socket.emit(`references::events`, { org, workspace }, callback);
}

export const describe = (org: string, workspace: string, id: string, type: string, callback: any) => {
    socket.emit(`edit::versionable;describe`, { org, workspace, id, type }, callback);
}

export const version = (org: string, workspace: string, id: string, version: string, type: string, callback: any) => {
    socket.emit(`edit::versionable;new-version`, { org, workspace, id, version, type }, callback);
}

export const versions = (org: string, workspace: string, id: string, type: string, callback: any) => {
    socket.emit(`edit::versionable;versions`, { org, workspace, id, type }, callback);
}

export const testing = {
    runTest: (org: string, workspace: string, testSuiteId: string, testSuiteVersion: string, testId: string, onStepPassed: (response: any) => void, onStepFailed: (response: any) => void) => {
        socket.emit(`testing::runTest`, { org, workspace, testSuiteId, testSuiteVersion, testId });
        socket.on('testing::test::step;passed', onStepPassed);
        socket.on('testing::test::step;failed', onStepFailed);
    },
    getAppStates: (org: string, workspace: string, appId: string, callback: (states: IState[]) => void) => {
        socket.emit(`testing::getAppStates`, { org, workspace, appId }, callback);
    }
}

export const reporting = {
    summary: (org: string, workspace: string, onLoad: any) => {
        socket.emit('reporting::summary', { org, workspace }, onLoad);
    },
    apps: (org: string, workspace: string, onLoad: any) => {
        socket.emit(`reporting::apps`, { org, workspace }, onLoad);
    },
    app: (org: string, workspace: string, id: string, environment: string, onLoad: any) => {
        socket.emit(`reporting::app`, { org, workspace, id, environment }, onLoad);
        socket.on(`reporting::app-${id};${environment};changed`, onLoad);
    },
    run: (org: string, workspace: string, app: string, environment: string, id: string, onLoad: any) => {
        socket.emit(`reporting::app;run`, { org, workspace, app, environment, id }, onLoad);
        socket.on(`reporting::app-${app};${environment};run-${id};changed`, onLoad);
    },
    close: (org: string, workspace: string, id: string) => {
        
    }
}

export const deployment = {
    app: {
        apps: (org: string, workspace: string, onLoad: any) => {
            socket.emit(`deployment::apps`, { org, workspace }, onLoad);
        },
        versions: (org: string, workspace: string, id: string, onLoad: any) => {
            socket.emit(`deployment::app`, { org, workspace, id }, onLoad);
        },
        changes: (org: string, workspace: string, id: string, version: string, onLoad: any) => {
            socket.emit(`deployment::app;changes`, { org, workspace, id, version }, onLoad);
        },
        promote: (org: string, workspace: string, id: string, version: string, environment: string, onLoad: any) => {
            socket.emit(`deployment::app;promote`, { org, workspace, id, version, environment }, onLoad);
        },
        default: (org: string, workspace: string, id: string, version: string, environment: string) => {
            socket.emit(`deployment::app;default`, { org, workspace, id, version, environment });
        },
        disable: (org: string, workspace: string, id: string, version: string, environment: string) => {
            socket.emit(`deployment::app;disable`, { org, workspace, id, version, environment });
        },
        enable: (org: string, workspace: string, id: string, version: string, environment: string) => {
            socket.emit(`deployment::app;enable`, { org, workspace, id, version, environment });
        },
    },
    plugin: {
        plugins: (org: string, workspace: string, onLoad: any) => {
            socket.emit(`deployment::plugins`, { org, workspace}, onLoad);
        },
        versions: (org: string, workspace: string, id: string, onLoad: any) => {
            socket.emit(`deployment::plugin;versions`, { org, workspace, id }, onLoad);
        },
        plugin: (org: string, workspace: string, id: string, version: string, onLoad: any) => {
            socket.emit(`deployment::plugin;version`, { org, workspace, id, version }, onLoad);
        },
        deploy: (org: string, workspace: string, id: string, version: string, environment: string, plugin: any) => {
            socket.emit(`deployment::plugin;deploy`, { org, workspace, id, version, environment, plugin });
        }
    },
    environment: {
        environments: (org: string, workspace: string, onLoad: (environment: any[]) => void) => {
            socket.emit(`deployment::environments`, { org, workspace }, onLoad);
        },
        environment: (org: string, workspace: string, id: string, onLoad: (environment: any) => void) => {
            socket.emit(`deployment::environment`, { org, workspace, id }, onLoad);
        },
        change: (org: string, workspace: string, id: string, patch: any) => {
            socket.emit(`deployment::environment;change`, { org, workspace, id, patch})
        },
        new: (org: string, workspace: string, onNew: (environment: any[]) => void) => {
            socket.emit(`deployment::environments;new`, { org, workspace }, onNew);
        },
    },
    releases: {
        new: (org: string, workspace: string, onLoad: (release: any) => void) => {
            socket.emit('deployment::releases;new', { org, workspace }, onLoad);
        },
        releases: (org: string, workspace: string, onLoad: (releases: any[]) => void) => {
            socket.emit(`deployment::releases`, { org, workspace }, onLoad);
        },
        release: (org: string, workspace: string, id: string, onLoad: (release: any) => void) => {
            socket.emit(`deployment::release`, { org, workspace, id }, onLoad);
        },
        change: (org: string, workspace: string, id: string, patch: any) => {
            socket.emit(`deployment::release;change`, { org, workspace, id, patch})
        },
        add: (org: string, workspace: string, id: string, app: any) => {
            socket.emit(`deployment::release;add`, { org, workspace, id, app})
        },
        remove: (org: string, workspace: string, id: string, app: any) => {
            socket.emit(`deployment::release;remove`, { org, workspace, id, app})
        },
        deploy: (org: string, workspace: string, id: string, environment: string, callback: (environments: any[]) => void) => {
            socket.emit('deployment::release;deploy', { org, workspace, id, environment }, callback)
        },
        rollback: (org: string, workspace: string, id: string, environment: string, callback: (environments: any[]) => void) => {
            socket.emit('deployment::release;rollback', { org, workspace, id, environment }, callback)
        }
    },
    featureFlags: {
        featureFlags: (org: string, workspace: string, onLoad: (featureFlags: any[]) => void) => {
            socket.emit('deployment::featureFlags', { org, workspace }, onLoad);
        },
        change: (org: string, workspace: string, featureFlag: any) => {
            socket.emit('deployment::featureFlags;change', { org, workspace, item: featureFlag });
        },
        remove: (org: string, workspace: string, id: string) => {
            socket.emit('deployment::featureFlags;remove', { org, workspace, id });
        }
    }
}