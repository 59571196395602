import React from "react";
import classnames from 'classnames';
import { useActor } from '@xstate/react';
import { Context, Event, Schema } from '../../machines/organization/organization';
import './organization.css';
import Licensing from "./Licensing";
import { ActorRefFrom, StateMachine } from "xstate";

const Organization = ({ machine }: { machine: ActorRefFrom<StateMachine<Context, Schema, Event>> | null }) => {
    const [state, send] = useActor(machine!);
    const [tab, setTab] = React.useState(0);

    const [email, setEmail] = React.useState('');
    const [name, setName] = React.useState('');

    const [workspaceName, setWorkspaceName] = React.useState('');
    const [workspaceDescription, setWorkspaceDescription] = React.useState('');

    const onAddAdmin = () => send({ type: 'ADD_ADMIN', email, name });
    const onRemoveAdmin = (user: string) => send({ type: 'REMOVE_ADMIN', user });

    const onAddWorkspace = () => send({ type: 'ADD_WORKSPACE', name: workspaceName, description: workspaceDescription });

    if (!state.matches('edit'))
        return null;

    return (
        <section className="organization">
            <div className="tabs is-boxed">
                <ul>
                    <li className={classnames({ 'is-active': tab === 0 })}><a onClick={setTab.bind(null, 0)}>Workspaces</a></li>
                    <li className={classnames({ 'is-active': tab === 1 })}><a onClick={setTab.bind(null, 1)}>Administrators</a></li>
                    <li className={classnames({ 'is-active': tab === 2 })}><a onClick={setTab.bind(null, 2)}>Licensing</a></li>
                </ul>
            </div>

            {
                tab === 0 ? (
                    <div className="p">
                        <table className="table is-fullwidth">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    state.context.workspaces.map(workspace => (
                                        <tr>
                                            <td>{workspace.name}</td>
                                            <td>{workspace.description}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <h4 className="is-size-4 mb">Create Workspace</h4>
                        <div className="add-workspace field">
                            <label className="label">Name</label>
                            <div className="control">
                                <input className="input" type="text" required value={workspaceName} onChange={(e) => setWorkspaceName(e.currentTarget.value)} />
                            </div>
                        </div>
                        <div className="add-workspace field">
                            <label className="label">Description</label>
                            <div className="control">
                                <textarea className="textarea" required value={workspaceDescription} onChange={(e) => setWorkspaceDescription(e.currentTarget.value)} />
                            </div>
                        </div>
                        <button className="button is-success" disabled={workspaceName === '' || workspaceDescription === ''} onClick={onAddWorkspace}>Create Workspace</button>
                    </div>
                ) : null
            }

            {
                tab === 1 ? (
                    <div className="p">
                        <table className="table is-fullwidth">
                            <thead>
                                <tr>
                                    <th style={{ width: '1%', whiteSpace: 'nowrap' }}></th>
                                    <th>Email</th>
                                    <th>Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    state.context.admins.map(admin => (
                                        <tr>
                                            <td style={{ width: '1%', whiteSpace: 'nowrap' }}><button className="button" onClick={onRemoveAdmin.bind(null, admin.id)}>Remove</button></td>
                                            <td><a href={`mailto:${admin.email}`}>{admin.email}</a></td>
                                            <td>{admin.name}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <h4 className="is-size-4 mb">Add Administrator</h4>
                        <div className="add-user mb">
                            <div className="field">
                                <label className="label">Email</label>
                                <div className="control">
                                    <input className="input" type="email" required value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Name</label>
                                <div className="control">
                                    <input className="input" type="text" required value={name} onChange={(e) => setName(e.currentTarget.value)} />
                                </div>
                            </div>
                        </div>
                        <button className="button is-success" onClick={onAddAdmin} disabled={email === '' || name === ''}>Add Administrator</button>
                    </div>
                ) : null
            }

            {
                tab === 2 ? (
                    <>
                        <article className="message is-primary">
                            <div className="message-body">
                                AppWire is currently free whilst in beta. Your organization has been automatically licensed.
                            </div>
                        </article>
                        <Licensing state={state} send={send} />
                    </>
                ) : null
            }
        </section>
    );
};

export default Organization;

