import React, { FormEvent } from 'react';
import Users from './Users';
import './editing.css';

export default ({ versions, version, onVersion, onNewVersion, modified, users }: any) => {
    const onVersionSelect = (e: FormEvent<HTMLSelectElement>) => onVersion(e.currentTarget.value);

    return (
        <div className="item-header">
            <div>
                <div className="select mr">
                    <select value={version} onChange={onVersionSelect} data-testid="item-versions-select">
                        {(versions || []).map((v: any) => <option value={v.version} key={v.version}>{v.version}</option>)}
                    </select>
                </div>
                <button className="button is-primary" onClick={onNewVersion}>New Version</button>
            </div>
            <div>
                {modified ? <span className="has-text-grey-dark">Modified: {(new Date(modified)).toLocaleString()}</span> : null}
                <Users users={users} />
            </div>
        </div>
    );
}