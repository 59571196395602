import { IVersionableReference, IVersionableReferences } from '../../../../../types/versionable';
import * as Socket from '../../../socket';
import _ from 'lodash';

const listeners: any = {
    app: [],
    action: [],
    rule: [],
    screen: [],
    plugin: [],
    runner: [],
    featureFlag: []
};

const references: IVersionableReferences = {
    app: [],
    action: [],
    rule: [],
    screen: [],
    plugin: [],
    runner: [],
    featureFlag: []
}

export const setup = (org: string, workspace: string, type: string) => {
    Socket.references(
        org,
        workspace,
        type,
        (items: IVersionableReference[]) => {
            references[type] = items;
            _.forEach(listeners[type], (listener) => listener(references[type]));
        },
        (item: IVersionableReference) => {
            references[type] = _.map(references[type], (reference) => {
                return reference.id === item.id ? { ...reference, name: item.name } : reference;
            });
            _.forEach(listeners[type], (listener) => listener(references[type]));
        },
        (item: IVersionableReference) => {
            _.remove(references[type], { id: item.id });
            _.forEach(listeners[type], (listener) => listener(references[type]));
        }
    );
}

export const subscribe = (type: string, callback: (items: IVersionableReference[]) => void): void => {
    listeners[type].push(callback);
    callback(references[type]);
}

export const unsubscribe = (type: string, callback: (items: IVersionableReference[]) => void) => {
    listeners[type] = listeners[type].filter((listener: any) => listener !== callback);
}

export const get = (type: string, id: string) => _.find(references[type], { id });

export const getAll = (type: string) => references[type];