import React from 'react';
import _ from 'lodash';

interface IColumn {
    name: string
    key: string
}

interface Props {
    value: {
        columns: IColumn[],
        auto: boolean
    }
    disabled: boolean
    onChange: (columns: IColumn[]) => void
    onToggleAutoCreate: (autoCreate: boolean) => void
}

export default ({ value, onChange, onToggleAutoCreate, disabled }: Props) => {
    
    const onToggleAuto = () => onToggleAutoCreate(!_.get(value, 'columns.auto', false));

    const onAddColumn = () => {
        onChange(_.concat(_.get(value, 'columns', []), { name: '', key: ''}));
    }

    const onRemoveColumn = (index: number) => {
        value.columns.splice(index, 1);
        onChange(value.columns);
    }

    const onNameChange = (index: number, name: string) => {
        value.columns[index].name = name;
        onChange(value.columns);
    }

    const onPropertyChange = (index: number, key: string) => {
        value.columns[index].key = key;
        onChange(value.columns);
    };

    return (
        <div>
            <div className="field" onClick={onToggleAuto}>
                <input type="checkbox" className="switch is-rounded" defaultChecked={value?.auto} />
                <label>Auto create columns</label>
            </div>
            <table className="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Property</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (value?.columns || []).map((column: any, index: number) => (
                            <tr key={index}>
                                <td>
                                    <input type="text" className="input is-small" value={column.name} onChange={(e) => onNameChange(index, e.currentTarget.value)} disabled={disabled || value.auto} />
                                </td>
                                <td>
                                    <input type="text" className="input is-small" value={column.key} onChange={(e) => onPropertyChange(index, e.currentTarget.value)} disabled={disabled || value.auto} />
                                </td>
                                <td>
                                    <button className="delete" onClick={onRemoveColumn.bind(null, index)} disabled={disabled || value.auto} />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <button className="button is-small" onClick={onAddColumn} disabled={disabled || value?.auto}>Add Column</button>
        </div>
    )
}
