import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from '@fortawesome/free-solid-svg-icons'

export default ({ users }: any) => {
    if (!users || users.length === 0)
        return null;

    return (
        <div className="dropdown is-hoverable is-right ml">
            <div className="dropdown-trigger">
                <span className="icon" style={{ width: '40px', height: '32px' }}>
                    <FontAwesomeIcon icon={faUsers} size="2x" />
                </span>
            </div>
            <div className="dropdown-menu" role="menu">
                <div className="dropdown-content">
                    <div className="dropdown-item">
                        <p><strong>Collaborating With</strong></p>
                        <ul>
                            {users.map((user: any) => <li key={user.name}>{user.name}</li>)}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}