import React from "react";
import { Context, Event, Schema } from '../../../machines/reporting/apps';
import './app.css';

import { useActor } from "@xstate/react";
import classnames from "classnames";
import Environment from "./Environment";
import Sidebar from "../../build/editing/Sidebar";
import { ActorRefFrom, StateMachine } from "xstate";

const Apps = ({ machine }: { machine: ActorRefFrom<StateMachine<Context, Schema, Event>> | null }) => {
    const [tab, setTab] = React.useState('dev');
    const [state, send] = useActor(machine!);

    let editor: JSX.Element | null = null;
    if (state.context.selected)
        editor = (
            <div className="reporting-app p">
                <div className="tabs is-boxed">
                    <ul>
                        <li className={classnames({ 'is-active': tab === 'dev'})}>
                            <a onClick={setTab.bind(null, 'dev')}>Development</a>
                        </li>
                        <li className={classnames({ 'is-active': tab === 'test'})}>
                            <a onClick={setTab.bind(null, 'test')}>Testing</a>
                        </li>
                        <li className={classnames({ 'is-active': tab === 'prod'})}>
                            <a onClick={setTab.bind(null, 'prod')}>Production</a>
                        </li>
                    </ul>
                </div>

                <Environment machine={state.context.dev} active={tab === 'dev'} />
                <Environment machine={state.context.test} active={tab === 'test'} />
                <Environment machine={state.context.prod} active={tab === 'prod'} />
            </div>
        )

    return (
        <div className="apps">
            <Sidebar 
                items={state.context.apps}
                selected={state.context.selected}
                send={send}
                loading={state.matches('loading')}
                type="App"
                allowNew={false}
            />
            {editor}
        </div>
    );
};

export default Apps;
