import React from "react";
import { Event } from '../../../machines/deployment/environments/environment';
import { useActor } from "@xstate/react";
import { ActorRef } from "xstate";
import _ from "lodash";
import Select from "react-select";

const Environment = ({ machine }: { machine: ActorRef<Event> | null }) => {
    const [state, send] = useActor(machine!);

    if (!state.matches("edit"))
        return null;

    // const onDefaultChanged = () => send({ type: 'CHANGE', value: { default: !state.context.environment?.default } });
    // const onProtectedChanged = () => send({ type: 'CHANGE', value: { protected: !state.context.environment?.protected } });
    const onVariableValueChange = (name: string, value: string) => send({ type: 'CHANGE', value: { variables: { ...state.context.environment?.variables, [name]: value } } });
    const onRemoveVariable = (key: string) => send({ type: 'CHANGE', value: { variables: _.omit(state.context.environment?.variables, key) }});
    const onAddVariable = () => send({ type: 'CHANGE', value: { variables: { ...state.context.environment?.variables, variable: '' }}});
    
    const onVariableNameChange = (oldName: string, newName: string) => {
        if (!newName)
            return;

        const value = state.context.environment?.variables[oldName];

        send({ 
            type: 'CHANGE',
            value: { 
                variables: { 
                    ..._.omit(state.context.environment?.variables, oldName), 
                    [newName]: value 
                }
            } 
        });
    }
    
    const featureFlags = _.map(state.context.featureFlags, (featureFlag) => ({ label: featureFlag.name, value: featureFlag.id }));
    const selectedFeatureFlags = _.filter(featureFlags, (featureFlag) => _.includes(state.context.environment.featureFlags, featureFlag.value));

    const onFeatureFlagChange = (featureFlags: any) => {
        send({ type: 'CHANGE', value: { featureFlags: _.map(featureFlags, (item) => item.value )} });
    }

    return (
        <div className="environment">
            <h3 className="is-size-3 mb">{state.context.environment.name}</h3>

            {/* <div className="field" onClick={onDefaultChanged}>
                <input type="checkbox" className="switch is-rounded" checked={state.context.environment?.default} disabled={false} />
                <label>Default Environment</label>
                <p className="help">App's run without specifying an environment will run in this environment</p>
            </div>
            <div className="field" onClick={onProtectedChanged}>
                <input type="checkbox" className="switch is-rounded" checked={state.context.environment?.protected} disabled={false} />
                <label>Protected by login</label>
                <p className="help">The user must be logged in to this workspace to run apps in this environment</p>
            </div> */}

            <h4 className="is-size-4 mb">Variables</h4>
            <div className="mb">
                <table className="table is-fullwidth">
                    <thead>
                        <tr>
                            <th style={{ width: '1%', whiteSpace: 'nowrap' }}></th>
                            <th>Name</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            _.keys(state.context.environment?.variables).map(key => (
                                <tr key={key}>
                                    <td style={{ width: '1%', whiteSpace: 'nowrap' }}><button className="button" onClick={onRemoveVariable.bind(null, key)}>Remove</button></td>
                                    <td>
                                    <input type="text" className="input" value={key} onChange={(e) => onVariableNameChange(key, e.currentTarget.value)} />
                                    </td>
                                    <td>
                                        <input type="text" className="input" value={state.context.environment!.variables[key]} onChange={(e) => onVariableValueChange(key, e.currentTarget.value)} />
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                <button className="button is-primary" onClick={onAddVariable}>Add Environment Variable</button>
            </div>

            <h4 className="is-size-4 mb">Feature Flags</h4>
            <div className="mb">
                <Select
                    isMulti={true}
                    options={featureFlags}
                    value={selectedFeatureFlags}
                    onChange={onFeatureFlagChange}
                />
            </div>

            <h4 className="is-size-4 mb">Releases</h4>
            {
                state.context.releases.map(release => (
                    <div className="box mb" key={release.id}>
                        <div className="is-flex justify-between mb">
                            <h5 className="is-size-5"><strong>{release.version}</strong></h5>
                            <p>{`${(new Date(release.when)).toLocaleString()} ${release.by}`}</p>
                        </div>
                        <p className="mb">{release.summary}</p>
                        <h5 className="is-size-5 mb">Apps</h5>
                        <table className="table is-fullwidth">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Version</th>
                                    <th>Run App</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    _.map(release.apps, app => (
                                        <tr key={app.id}>
                                            <td>{app.id}</td>
                                            <td>{app.name}</td>
                                            <td>{app.version}</td>
                                            <td><a href={`${process.env.API_URL}/run?org=${state.context.org}&workspace=${state.context.workspace}&app=${app.id}&environment=${state.context.environment.id}&version=${app.version}`} target="_blank" rel="noreferrer">Run</a></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
            </table>
                    </div>
                ))
            }
            
        </div>
    );
};

export default Environment;
