import { createMachine, actions as stateActions, spawn, ActorRefFrom, StateMachine } from 'xstate';
import { connect, organizations, workspaces, disconnect, profile } from '../socket';
import { machine as deploymentMachine } from './deployment/deployment';
import { machine as workspaceMachine } from './workspace/workspace';
import { machine as organizationMachine } from './organization/organization';
import { machine as reportingMachine } from './reporting/reporting';
import { Context as VersionableItemsContext, Event as VersionableItemsEvent, versionableItemsMachine } from './build/versionable-items';
import * as References from '../components/build/versionable/references';
import _ from 'lodash';
import { IUser } from '../../../types/user';

const { send, assign, forwardTo, sendTo } = stateActions;

export type SelectEvent = { type: 'SELECT', value: any };
export type AuthenticatedEvent = { type: 'AUTHENTICATED', session: string };
export type OrganizationsEvent = { type: 'ORGANIZATIONS', session: string };
export type OrganizationsCreateEvent = { type: 'ORGANIZATIONS.CREATE', name: string };
export type WorkspacesEvent = { type: 'WORKSPACES', session: string, org: string };
export type WorkspacesCreateEvent = { type: 'WORKSPACES.CREATE', org: string, name: string, description: string };
export type WorkspaceEvent = { type: 'WORKSPACE', session: string, org: string, workspace: string };
export type ReceivedEvent = { type: 'RECEIVED', data: any };
export type ReadyEvent = { type: 'READY' };
export type NavigateEvent = { type: 'NAVIGATE', target: string };
export type TabEvent = { type: 'TAB', tab: string }
export type ProfileEvent = { type: 'PROFILE', org: string, workspace: string }
export type LogoutEvent = { type: 'LOGOUT' }
export type ConnectedEvent = { type: 'CONNECTED' }
export type ReconnectEvent = { type: 'RECONNECT' }
export type ReconnectedEvent = { type: 'RECONNECTED' }
export type DisconnectEvent = { type: 'DISCONNECTED' }
export type UnauthenticatedEvent = { type: 'UNAUTHENTICATED' }
export type ItemEvent = { type: 'ITEM', itemType: string, id: string, version: string }
export type SwitchWorkspaceEvent = { type: 'SWITCH_WORKSPACE', workspace: string }
export type SwitchOrganizationEvent = { type: 'SWITCH_ORGANIZATION', org: string }
export type UnloadEvent = { type: 'UNLOAD' }
export type FilterEvent = { type: 'FILTER', app: string }

export type Event =
    | SelectEvent
    | AuthenticatedEvent
    | OrganizationsEvent
    | OrganizationsCreateEvent
    | WorkspacesEvent
    | WorkspacesCreateEvent
    | WorkspaceEvent
    | ReceivedEvent
    | ReadyEvent
    | NavigateEvent
    | TabEvent
    | ProfileEvent
    | LogoutEvent
    | ConnectedEvent
    | ReconnectEvent
    | ReconnectedEvent
    | DisconnectEvent
    | UnauthenticatedEvent
    | ItemEvent
    | SwitchWorkspaceEvent
    | SwitchOrganizationEvent
    | UnloadEvent
    | FilterEvent

export interface Context {
    login: any;
    apps: ActorRefFrom<StateMachine<VersionableItemsContext, any, VersionableItemsEvent>> | null
    actions: ActorRefFrom<StateMachine<VersionableItemsContext, any, VersionableItemsEvent>> | null
    rules: ActorRefFrom<StateMachine<VersionableItemsContext, any, VersionableItemsEvent>> | null
    screens: ActorRefFrom<StateMachine<VersionableItemsContext, any, VersionableItemsEvent>> | null
    plugins: ActorRefFrom<StateMachine<VersionableItemsContext, any, VersionableItemsEvent>> | null
    runners: ActorRefFrom<StateMachine<VersionableItemsContext, any, VersionableItemsEvent>> | null
    organizations: any[],
    organizationError?: any
    org: string,
    workspaces: any[]
    workspaceError?: any
    workspace: string,
    deployment: any,
    workspaceMachine: any
    organizationMachine: any
    reporting: any
    tab: string
    user: IUser,
    disconnected: boolean
}

const validateSession = () =>
    fetch(
        `${process.env.API_URL}/design/authenticate`,
        {
            method: 'POST',
            credentials: 'include'
        }
    )
    .then(response => {
        if (!response.ok)
            throw new Error(response.status.toString());
    })

const getSession = (code: string) =>
    fetch(
        `${process.env.API_URL}/design/authenticate?code=${code}`,
        {
            method: 'POST',
            credentials: 'include'
        }
    )
    .then(response => {
        if (!response.ok)
            throw new Error(response.status.toString());

        return response;
    })
    .then(response => response.text());

const logout = () =>
    fetch(
        `${process.env.API_URL}/design/logout`,
        {
            method: 'POST',
            credentials: 'include'
        }
    )

export const realtime = (context: Context) => (callback: any, onReceive: any) => {

    const onOrganizations = (response: any) => callback({ type: 'RECEIVED', data: response });
    const onOrganizationNew = (response: any) => callback({ type: 'RECEIVED', data: response });

    const onWorkspaces = (response: any) => callback({ type: 'RECEIVED', data: response });
    const onWorkspacesNew = (response: any) => callback({ type: 'RECEIVED', data: response });
    const onWorkspace = () => callback({ type: 'READY' })

    const onProfile = (response: any) => callback({ type: 'RECEIVED', data: response });

    onReceive((e: Event) => {
        switch (e.type) {
            case 'AUTHENTICATED':
                connect(
                    () => {
                        callback({ type: 'CONNECTED' });
                    },
                    () => {
                        window.location.reload();
                    },
                    () => {
                        callback({ type: 'DISCONNECTED' });
                    }
                );
                break;

            case 'ORGANIZATIONS':
                // TODO: I don't know why waiting 50ms makes rendering work when refreshing. This race condition is tricky
                setTimeout(() => organizations.query(onOrganizations), 50);
                break;

            case 'ORGANIZATIONS.CREATE':
                organizations.create(e.name, onOrganizationNew);
                break;

            case 'WORKSPACES':
                setTimeout(() => workspaces.query(e.org, onWorkspaces), 50);
                break;

            case 'WORKSPACES.CREATE':
                workspaces.create(e.org, e.name, e.description, onWorkspacesNew);
                break;

            case 'WORKSPACE':
                requestIdleCallback(() => workspaces.get(e.org, e.workspace, onWorkspace));
                break;

            case 'PROFILE':
                requestIdleCallback(() => profile(e.org, e.workspace, onProfile));
                break;

            case 'UNLOAD':
                
        }
    });
}

export const history = (context: Context) => (callback: any, onReceive: any) => {
    const listener = (event: PopStateEvent) => {
        if (_.some(['build', 'deployment', 'reporting', 'workspace', 'orgnaization'], (item) => _.endsWith(window.location.pathname, item)))
            callback({ type: 'NAVIGATE', target: _.last(_.split(window.location.pathname, '/')) })
    }

    window.addEventListener('popstate', listener);
    return () => window.removeEventListener('popstate', listener);
}

const urlOrganization = /^\/(.{7})\//i;
const urlWorkspace = /^\/.{7}\/(.{7})\//i;
const urlBuild = /^\/.{7}\/(.{7})\/build/i;
const urlBuildApps = /^\/.{7}\/(.{7})\/build\/apps\/(.{7})/i;
const urlBuildPlugins = /^\/.{7}\/(.{7})\/build\/plugins\/?(.{7})?/i;
const urlBuildActions = /^\/.{7}\/(.{7})\/build\/actions\/?(.{7})?/i;
const urlBuildRules = /^\/.{7}\/(.{7})\/build\/rules\/?(.{7})?/i;
const urlBuildScreens = /^\/.{7}\/(.{7})\/build\/screens\/?(.{7})?/i;
const urlBuildRunners = /^\/.{7}\/(.{7})\/build\/themes\/?(.{7})?/i;
const urlTesting = /^\/.{7}\/(.{7})\/testing/i;
const urlReporting = /^\/.{7}\/(.{7})\/reporting/i;
const urlDeployment = /^\/.{7}\/(.{7})\/deployment/i;
const urlOrganizationEdit = /^\/.{7}\/(.{7})\/organization/i;
const urlWorkspaceEdit = /^\/.{7}\/(.{7})\/workspace/i;

// const urlParts: any = {
//     apps: getAppsUrlPart,
//     plugins: getPluginsUrlPart,
//     actions: getActionsUrlPart,
//     rules: getRulesUrlPart,
//     screens: getScreensUrlPart,
//     runners: getRunnersUrlPart
// }

export const machine = createMachine<Context, Event>(
    {
        schema: {
            context: {} as Context,
            events: {} as Event
        },
        id: 'root',
        initial: 'idle',
        invoke: [
            { id: 'socket', src: realtime },
            { id: 'history', src: history }
        ],
        states: {
            idle: {
                always: [
                    {
                        target: 'token',
                        cond: () => window.location.search.indexOf(`code=`) !== -1
                    },
                    {
                        target: 'validating',
                    },
                ]
            },
            validating: {
                invoke: {
                    id: 'login',
                    src: (context, event) => validateSession(),
                    onDone: {
                        target: 'authenticated'
                    },
                    onError: {
                        target: 'unauthenticated',
                    }
                }    
            },
            token: {
                invoke: {
                    id: 'token',
                    src: (context, event) => {
                        const search = new window.URLSearchParams(window.location.search);
                        return getSession(search.get('code') as string);
                    },
                    onDone: {
                        actions: [
                            () => window.history.replaceState({}, document.title, window.location.pathname)
                        ],
                        target: 'authenticated'
                    },
                    onError: {
                        target: 'unauthenticated',
                    }
                }
            },
            unauthenticated: {
                entry: [
                    () =>{ window.location.replace(`https://appwire.auth.eu-west-2.amazoncognito.com/login?client_id=4cvr7memtqc1naed93us8pc2me&response_type=code&scope=email+openid+profile&redirect_uri=${process.env.APP_URL}`) }
                ]
            },
            authenticated: {
                entry: send('AUTHENTICATED', { to: 'socket' }),
                on: {
                    CONNECTED: {
                        target: 'loadOrganizations'
                    }
                }
            },
            loadOrganizations: {
                entry: send('ORGANIZATIONS', { to: 'socket' }),
                on: {
                    RECEIVED: [
                        { cond: 'urlOrganization', actions: ['organization'], target: 'loadWorkspaces' },
                        { cond: 'manyResults', actions: ['organization'], target: 'organizations' },
                        { cond: 'oneResult', actions: ['organization'], target: 'loadWorkspaces' },
                        { cond: 'noResults', target: 'newOrganization' } 
                    ]
                }
            },
            organizations: {
                on: {
                    SELECT: {
                        actions: assign({
                            org: (context, event) => event.value.id
                        }),
                        target: 'loadWorkspaces'
                    }
                }
            },
            newOrganization: {
                on: {
                    'ORGANIZATIONS.CREATE': {
                        actions: forwardTo('socket')
                    },
                    RECEIVED: [
                        { 
                            cond: (context: Context, event: ReceivedEvent) => event.data.error,
                            actions: assign<Context, ReceivedEvent>({
                                organizationError: (context, event) => event.data.error
                            })
                        },
                        {
                            cond: (context: Context, event: ReceivedEvent) => !event.data.error,
                            actions: assign<Context, ReceivedEvent>({
                                org: (context, event) => event.data.id,
                                organizations: (context, event) => [event.data]
                            }),
                            target: 'newWorkspace'
                        }
                    ]
                }
            },
            loadWorkspaces: {
                entry: send((context) => ({ type: 'WORKSPACES', org: context.org }), { to: 'socket'}),
                on: {
                    RECEIVED: [
                        { cond: 'urlWorkspace', actions: ['workspace'], target: 'profile' },
                        { cond: 'manyResults', actions: ['workspace'], target: 'workspaces' },
                        { cond: 'oneResult', actions: ['workspace'], target: 'profile' },
                        { cond: 'noResults', target: 'newWorkspace' } 
                    ]
                }
            },
            workspaces: {
                on: {
                    SELECT: {
                        actions: assign({
                            workspace: (context, event) => event.value.id
                        }),
                        target: 'profile'
                    },
                }
            },
            newWorkspace: {
                on: {
                    'WORKSPACES.CREATE': {
                        actions: forwardTo('socket')
                    },
                    RECEIVED: [
                        { 
                            cond: (context: Context, event: ReceivedEvent) => event.data.error ,
                            actions: assign<Context, ReceivedEvent>({
                                workspaceError: (context, event) => event.data.error
                            })
                        },
                        {
                            cond: (context: Context, event: ReceivedEvent) => !event.data.error,
                            actions: assign<Context, ReceivedEvent>({
                                workspace: (context, event) => event.data.id,
                                workspaces: (context, event) => [event.data]
                            }),
                            target: 'profile'
                        }
                    ]
                }
            },
            profile: {
                entry: send(
                    (context, event) => ({
                        type: 'PROFILE',
                        org: context.org,
                        workspace: context.workspace
                    }),
                    { to: 'socket' }
                ),
                on: {
                    RECEIVED: {
                        actions: assign<Context, ReceivedEvent>({
                            user: (context, event) => event.data
                        }),
                        target: 'setup'
                    }
                }
            },
            setup: {
                entry: [
                    assign<Context, any>((context, event) => {
                        context.apps?.stop();
                        context.screens?.stop();
                        context.actions?.stop();
                        context.rules?.stop();
                        context.plugins?.stop();
                        context.runners?.stop();
                        context.deployment?.stop();
                        context.reporting?.stop();
    
                        context.workspaceMachine?.stop();
                        context.organizationMachine?.stop();
    
                        return {
                            apps: null,
                            actions:null,
                            rules: null,
                            screens: null,
                            plugins: null,
                            runners: null,
                            deployment: null,
                            workspaceMachine: null,
                            organizationMachine: null,
                            reporting: null,
                            tab: 'apps'
                        }
                    }),
                    send(
                        (context, event) => ({
                            type: 'WORKSPACE',
                            org: context.org,
                            workspace: context.workspace
                        }),
                        { to: 'socket' }
                    ),
                ],
                on: {
                    READY: [
                        { cond: 'urlBuildApps', target: 'build.apps' },
                        { cond: 'urlBuildPlugins', target: 'build.plugins' },
                        { cond: 'urlBuildActions', target: 'build.actions' },
                        { cond: 'urlBuildRules', target: 'build.rules' },
                        { cond: 'urlBuildScreens', target: 'build.screens' },
                        { cond: 'urlBuildRunners', target: 'build.runners' },
                        { cond: 'urlBuild', target: 'build' },
                        { cond: 'urlTesting', target: 'testing' },
                        { cond: 'urlReporting', target: 'reporting' },
                        { cond: 'urlDeployment', target: 'deployment' },
                        { cond: 'urlOrganizationEdit', target: 'organization' },
                        { cond: 'urlWorkspaceEdit', target: 'workspace' },
                        { target: 'build' }
                    ]
                },
                exit: [
                    (context: Context) => {
                        References.setup(context.org, context.workspace, 'app');
                        References.setup(context.org, context.workspace, 'rule');
                        References.setup(context.org, context.workspace, 'plugin');
                        References.setup(context.org, context.workspace, 'action');
                        References.setup(context.org, context.workspace, 'screen');
                        References.setup(context.org, context.workspace, 'runner');
                        References.setup(context.org, context.workspace, 'featureFlag');
                    },
                    assign({
                        apps: (context) => context.apps || spawn(versionableItemsMachine('app', 'app', 'apps').withContext({ org: context.org, workspace: context.workspace }), { name: 'build-apps' }),
                        actions: (context) => context.actions || spawn(versionableItemsMachine('action', 'action', 'actions').withContext({ org: context.org, workspace: context.workspace }), { name: 'build-actions' }),
                        plugins: (context) => context.plugins || spawn(versionableItemsMachine('plugin', 'plugin', 'plugins').withContext({ org: context.org, workspace: context.workspace }), { name: 'build-plugins' }),
                        rules: (context) => context.rules || spawn(versionableItemsMachine('rules', 'rule', 'rules').withContext({ org: context.org, workspace: context.workspace }), { name: 'build-rules' }),
                        screens: (context) => context.screens || spawn(versionableItemsMachine('screen', 'screen', 'screens').withContext({ org: context.org, workspace: context.workspace }), { name: 'build-screens' }),
                        runners: (context) => context.rules || spawn(versionableItemsMachine('runner', 'runner', 'themes').withContext({ org: context.org, workspace: context.workspace }), { name: 'build-themes' })
                    }),
                    assign({
                        deployment: (context) => spawn(deploymentMachine.withContext({ org: context.org, workspace: context.workspace }), { name: 'deployment' }),
                    }),
                    assign({
                        reporting: (context) => spawn(reportingMachine.withContext({ org: context.org, workspace: context.workspace }), { name: 'reporting' }),
                    })                    
                ],
            },
            build: {
                entry: [
                    'setBuildUrl'
                ],
                initial: 'apps',
                states: {
                    apps: {},
                    plugins: {},
                    actions: {},
                    rules: {},
                    screens: {
                        // always: {
                        //     actions: sendTo((context) => context.screens, 'SELECT_FROM_URL')
                        // }
                    },
                    runners: {},
                },
                on: {
                    NAVIGATE: [
                        { cond: 'testing', target: 'testing' },
                        { cond: 'reporting', target: 'reporting' },
                        { cond: 'deployment', target: 'deployment' },
                        { cond: 'workspace', target: 'workspace' },
                        { cond: 'organization', target: 'organization' },
                    ],
                    TAB: [
                        { cond: (_, event: TabEvent) => event.tab === 'apps', target: '.apps', actions: ['setBuildUrl'] },
                        { cond: (_, event: TabEvent) => event.tab === 'plugins', target: '.plugins', actions: ['setBuildUrl'] },
                        { cond: (_, event: TabEvent) => event.tab === 'actions', target: '.actions', actions: ['setBuildUrl'] },
                        { cond: (_, event: TabEvent) => event.tab === 'rules', target: '.rules', actions: ['setBuildUrl'] },
                        { cond: (_, event: TabEvent) => event.tab === 'screens', target: '.screens', actions: ['setBuildUrl'] },
                        { cond: (_, event: TabEvent) => event.tab === 'runners', target: '.runners', actions: ['setBuildUrl'] },
                    ],
                    ITEM: [
                        {
                            actions: [
                                send<Context, ItemEvent>((context, event) => ({
                                    type: 'SELECT',
                                    value: event.id,
                                    version: event.version
                                }), {
                                    to: (context, event) => context[`${event.itemType}s`] 
                                }),
                                send<Context, ItemEvent>((context, event) => ({
                                    type: 'TAB',
                                    tab: `${event.itemType}s`
                                }))
                            ]
                        }
                    ],
                    FILTER: {
                        actions: [
                            forwardTo('build-apps'),
                            forwardTo('build-actions'),
                            forwardTo('build-plugins'),
                            forwardTo('build-rules'),
                            forwardTo('build-screens'),
                            forwardTo('build-themes'),
                        ]
                    }
                }
            },
            testing: {
                entry: [
                    'setNavUrl'
                ],
                initial: 'suites',
                states: {
                    suites: {}
                },
                on: {
                    NAVIGATE: [
                        { cond: 'build', target: 'build' },
                        { cond: 'reporting', target: 'reporting' },
                        { cond: 'workspace', target: 'workspace' },
                        { cond: 'organization', target: 'organization' },
                    ]
                }
            },
            deployment: {
                entry: [
                    'setNavUrl'
                ],
                on: {
                    NAVIGATE: [
                        { cond: 'build', target: 'build' },
                        { cond: 'testing', target: 'testing' },
                        { cond: 'reporting', target: 'reporting' },
                        { cond: 'workspace', target: 'workspace' },
                        { cond: 'organization', target: 'organization' },
                    ]
                }
            },
            reporting: {
                entry: [
                    'setNavUrl'
                ],
                on: {
                    NAVIGATE: [
                        { cond: 'build', target: 'build' },
                        { cond: 'testing', target: 'testing' },
                        { cond: 'deployment', target: 'deployment' },
                        { cond: 'workspace', target: 'workspace' },
                        { cond: 'organization', target: 'organization' },
                    ]
                }
            },
            workspace: {
                entry: [
                    'setNavUrl',
                    assign({
                        workspaceMachine: (context) => spawn(workspaceMachine.withContext({ org: context.org, workspace: context.workspace, users: [], denylist: [], customComponents: [] } )),
                    }),
                ],
                on: {
                    NAVIGATE: [
                        { cond: 'build', target: 'build' },
                        { cond: 'testing', target: 'testing' },
                        { cond: 'reporting', target: 'reporting' },
                        { cond: 'deployment', target: 'deployment' },
                        { cond: 'organization', target: 'organization' },
                    ]
                },
                exit: [
                    assign({
                        workspaceMachine: (context) => {
                            context.workspaceMachine.stop();
                            return null;
                        }
                    }),
                ]
            },
            organization: {
                entry: [
                    'setNavUrl',
                    assign({
                        organizationMachine: (context) => spawn(organizationMachine.withContext({ org: context.org, workspace: context.workspace, workspaces: [], admins: [] } as any )),
                    }),
                ],
                on: {
                    NAVIGATE: [
                        { cond: 'build', target: 'build' },
                        { cond: 'testing', target: 'testing' },
                        { cond: 'reporting', target: 'reporting' },
                        { cond: 'deployment', target: 'deployment' },
                        { cond: 'workspace', target: 'workspace' },
                    ]
                },
                exit: [
                    assign({
                        organizationMachine: (context) => {
                            context.organizationMachine.stop();
                            return null;
                        }
                    }),
                ]
            },
            logout: {
                invoke: {
                    id: 'logout',
                    src: logout,
                    onDone: {
                        actions: ['disconnect', 'logout'],
                        target: 'unauthenticated'
                    },
                    onError: {
                    }
                }
            }
        },
        // @ts-ignore
        on: {
            UNAUTHENTICATED: {
                target: 'unauthenticated'
            },
            LOGOUT: {
                target: 'logout'
            },
            RECONNECT: {
                cond: 'loggedInToWorkspace',
                actions: send({ type: 'RECONNECTED' }, { to: 'socket' })
            },
            DISCONNECTED: {
                actions: assign({
                    disconnected: () => true
                })
            },
            SWITCH_WORKSPACE: {
                actions: [
                    assign({
                        workspace: (context, event) => event.workspace
                    }),
                    (context, event) => {
                        window.history.replaceState(null, '', `/${context.org}/${event.workspace}/`)
                    }
                ],
                target: 'setup'
            },
            SWITCH_ORGANIZATION: {
                actions: [
                    assign({
                        org: (context, event) => event.org,
                        workspace: () => null
                    }),
                    (context, event) => {
                        window.history.replaceState(null, '', `/${event.org}`)
                    }
                ],
                target: 'loadWorkspaces'
            },
            UNLOAD: {
                actions: ['disconnect']
            }
        }
    },
    {
        actions: {
            organization: assign<Context, any>({ 
                organizations: (context, event) => event.data,
                org: (context, event) => {
                    const match = urlOrganization.exec(window.location.pathname);
                    return match ? match[1] : event.data[0].id;
                }
            }),
            workspace: assign<Context, any>({ 
                workspaces: (context, event) => event.data,
                workspace: (context, event) => {
                    const match = urlWorkspace.exec(window.location.pathname);
                    return match ? match[1] : event.data[0].id;
                }
            }),
            setNavUrl: (context: Context, event: any, meta: any) => {
                window.history.pushState(null, '', `/${context.org}/${context.workspace}/${meta.state.value}`)
            },
            setBuildUrl: (context: Context, event: any, meta: any) => {
                
                const match = /^\/.{7}\/.{7}\/build\/(.+)\//i.exec(window.location.pathname);

                // if (event.tab)
                //     window.history.pushState(null, '', `/${context.org}/${context.workspace}${urlParts[event.tab]((context )[event.tab])}`);

                // else if (!match)
                //     window.history.pushState(null, '', `/${context.org}/${context.workspace}${urlParts.apps(context.apps)}`);
            },
            disconnect: (context) => disconnect(context.org, context.workspace),
            logout: [
                assign<Context, LogoutEvent>((context, event) => {
                    context.apps?.stop!();
                    context.screens?.stop!();
                    context.actions?.stop!();
                    context.rules?.stop!();
                    context.plugins?.stop!();
                    context.runners?.stop!();
                    context.deployment?.stop!();
                    context.reporting?.stop!();

                    context.workspaceMachine?.stop!();
                    context.organizationMachine?.stop!();

                    return {
                        login: null,
                        apps: null,
                        actions:null,
                        rules: null,
                        screens: null,
                        plugins: null,
                        runners: null,
                        organizations: [],
                        org: '',
                        workspaces: [],
                        workspace: '',
                        deployment: null,
                        workspaceMachine: null,
                        organizationMachine: null,
                        reporting: null,
                        tab: 'apps'
                    }
                })
            ]
        },
        guards: {
            loggedInToWorkspace: (context: Context) => context.org && context.workspace,
            noResults: (context: Context, event: any) => event.data.length === 0,
            oneResult: (context: Context, event: any) => event.data.length === 1,
            manyResults: (context: Context, event: any) => event.data.length > 1,
            build: (context: Context, event: NavigateEvent) => event.target === 'build',
            testing: (context: Context, event: NavigateEvent) => event.target === 'testing',
            reporting: (context: Context, event: NavigateEvent) => event.target === 'reporting',
            deployment: (context: Context, event: NavigateEvent) => event.target === 'deployment',
            workspace: (context: Context, event: NavigateEvent) => event.target === 'workspace' && _.includes(context.user.roles, 'workspace-admin'),
            organization: (context: Context, event: NavigateEvent) => event.target === 'organization' && _.includes(context.user.roles, 'organization-admin'),
            urlOrganization: (context: Context, event: any) => {
                const match = urlOrganization.exec(window.location.pathname);
                if (match)
                    return event.data.find((result: any) => result.id.toLowerCase() === match[1].toLowerCase());

                return false;
            },
            urlWorkspace: (context: Context, event: any) => {
                const match = urlWorkspace.exec(window.location.pathname);
                if (match)
                    return event.data.find((result: any) => result.id.toLowerCase() === match[1].toLowerCase());

                return false;
            },
            urlBuild: () => urlBuild.test(window.location.pathname),
            urlBuildApps: () => urlBuildApps.test(window.location.pathname),
            urlBuildPlugins: () => urlBuildPlugins.test(window.location.pathname),
            urlBuildActions: () => urlBuildActions.test(window.location.pathname),
            urlBuildRules: () => urlBuildRules.test(window.location.pathname),
            urlBuildScreens: () => urlBuildScreens.test(window.location.pathname),
            urlBuildRunners: () => urlBuildRunners.test(window.location.pathname), 
            urlTesting: (context: Context) => urlTesting.test(window.location.pathname) && _.includes(context.user.roles, 'app-admin'),
            urlReporting: (context: Context) => urlReporting.test(window.location.pathname) && _.includes(context.user.roles, 'app-admin'),
            urlDeployment: (context: Context) => urlDeployment.test(window.location.pathname) && _.includes(context.user.roles, 'app-admin'),
            urlWorkspaceEdit: (context: Context) => urlWorkspaceEdit.test(window.location.pathname)  && _.includes(context.user.roles, 'workspace-admin'),
            urlOrganizationEdit: (context: Context) => urlOrganizationEdit.test(window.location.pathname) && _.includes(context.user.roles, 'organization-admin')
        }
    }
)

