import React, { FormEvent } from 'react';
import { ITestStep, ITestStepClickButton } from '../../../../machines/testing/suites/test-suite';

type status = 'pass' | 'fail' | 'none';

interface Props {
    value: ITestStepClickButton
    disabled: boolean
    status?: status
    message?: string
    onChange: (step: ITestStep) => void
    onDelete: (step: ITestStep) => void
}

const ClickButton = ({ value, onChange, onDelete, status, message, disabled }: Props) => {

    const onLabelChange = (e: FormEvent<HTMLInputElement>) => {
        onChange({ ...value, label: e.currentTarget.value } as ITestStep);
    }

    return (
        <div className="step">
            {/* <div>
                <span className="icon">
                    <FontAwesomeIcon icon={faHandPointer} />
                </span>
                <h6 className="is-size-6">Click Button</h6>
            </div> */}
            <h6 className="is-size-6">Click Button</h6>
            <div className="buttons">
                {/* <button className="button is-primary is-small">Save</button> */}
                <button className="button is-small" onClick={() => onDelete(value)} disabled={disabled}>Delete</button>
            </div>
            <div className="click-button">
                <div className="field">
                    <label className="label">Label</label>
                    <div className="control">
                        <input className="input" type="text" value={value.label} onChange={onLabelChange} disabled={disabled} />
                    </div>
                </div>
            </div>
            {
                status === 'pass' ? <article className="message is-success status"><div className="message-body">Passed</div></article> : null
            }
            {
                status === 'fail' ? <article className="message is-danger status"><div className="message-body">{message}</div></article> : null
            }
        </div>
    )
}

export default ClickButton;