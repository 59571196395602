import React, { FormEvent } from 'react';
import { IComponentPropertyParameters } from '../../../machines/build/screens/screen';

interface Props {
    value: IComponentPropertyParameters
    propertyId: string
    onChange: (parameters: IComponentPropertyParameters) => void
    disabled: boolean
}

const Parameters = ({ value, propertyId, onChange, disabled }: Props) => {

    const onSearchChange = (e: FormEvent<HTMLInputElement>) => onChange({ ...value, search: e.currentTarget.value });
    const onOffsetChange = (e: FormEvent<HTMLInputElement>) => onChange({ ...value, offset: e.currentTarget.valueAsNumber });
    const onLimitChange = (e: FormEvent<HTMLInputElement>) => onChange({ ...value, limit: e.currentTarget.valueAsNumber });
    const onFileChange = (e: FormEvent<HTMLInputElement>) => onChange({ ...value, file: e.currentTarget.value });
    const onFileNameChange = (e: FormEvent<HTMLInputElement>) => onChange({ ...value, fileName: e.currentTarget.value });

    const searchInput = (
        <tr>
            <td>Search</td>
            <td><input type="text" className="input is-small" value={value.search} onChange={onSearchChange} disabled={disabled} /></td>
        </tr>
    );

    const offsetInput = (
        <tr>
            <td>Offset</td>
            <td><input type="number" className="input is-small" value={value.offset} onChange={onOffsetChange} disabled={disabled} /></td>
        </tr>
    )

    const limitInput = (
        <tr>
            <td>Limit</td>
            <td><input type="number" className="input is-small" value={value.limit} onChange={onLimitChange} disabled={disabled} /></td>
        </tr>
    )

    const fileInput = (
        <tr>
            <td>File</td>
            <td><input type="text" className="input is-small" value={value.file} onChange={onFileChange} disabled={disabled} /></td>
        </tr>
    )

    const fileNameInput = (
        <tr>
            <td>Filename</td>
            <td><input type="text" className="input is-small" value={value.fileName} onChange={onFileNameChange} disabled={disabled} /></td>
        </tr>
    )

    return (
        <table className="table is-striped is-fullwidth">
            <thead>
                <tr>
                    <th>Parameter</th>
                    <th>Map To</th>
                </tr>
            </thead>
            <tbody>
                {propertyId !== 'uploadAction' ? searchInput : null}
                {propertyId !== 'uploadAction' ? offsetInput : null}
                {propertyId !== 'uploadAction' ? limitInput : null}
                {propertyId === 'uploadAction' ? fileInput : null}
                {propertyId === 'uploadAction' ? fileNameInput : null}
            </tbody>
        </table>
    )
}

export default Parameters;
