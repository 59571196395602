import React, { FormEvent } from 'react';
import ValueSelector from '../variable/ValueSelector';
import { IRule } from '../../../../../types/rule';
import { IVariable } from '../../../../../types/variable';

interface IRuleProps {
    org: string
    workspace: string
    value: IRule
    onChange: (value: IRule) => void
    onDelete: (value: IRule) => void
    disabled: boolean
}

export default ({ org, workspace, value, onChange, onDelete, disabled }: IRuleProps) => {
    const onLeftChange = (left: IVariable | null) => onChange({ ...value, left });
    const onRightChange = (right: IVariable | null) => onChange({ ...value, right });
    const onComparisonChange = (e: FormEvent<HTMLSelectElement>) => onChange({ ...value, comparison: e.currentTarget.value })
    
    return (
        <div className="rule">
            <ValueSelector
                org={org}
                workspace={workspace}
                variable={value.left}
                onChange={onLeftChange}
                disabled={disabled}
            />
            <div className="select">
                <select value={value.comparison} onChange={onComparisonChange} disabled={disabled}>
                    <option value="=">Is</option>
                    <option value="!=">Is Not</option>
                    <option value="~">Contains</option>
                    <option value="!~">Does Not Contain</option>
                    <option value=">">Greater Than</option>
                    <option value=">=">Greater Than Or Equal To</option>
                    <option value="<">Less Than</option>
                    <option value="<=">Less Than Or Equal To</option>
                </select>
            </div>
            <ValueSelector
                org={org}
                workspace={workspace}
                variable={value.right}
                onChange={onRightChange}
                disabled={disabled}
                allowNothing={true}
            />
            <button className="button is-outlined" onClick={onDelete.bind(null, value)} disabled={disabled}>Delete Rule</button>
        </div>
    )
}