import _ from 'lodash'

const t = {
    onchange: {
        id: 'onchange',
        name: 'On Change'
    },
    onclick: {
        id: 'onclick',
        name: 'On Click'
    },
    onupload: {
        id: 'onupload',
        name: 'On Upload Complete'
    },
    onclose: {
        id: 'onclose',
        name: 'On Close'
    }
}

export default {
    '': {},
    text: _.pick(t, ['onchange']),
    textarea: _.pick(t, ['onchange']),
    number: _.pick(t, ['onchange']),
    slider: _.pick(t, ['onchange']),
    date: _.pick(t, ['onchange']),
    'date-time': _.pick(t, ['onchange']),
    button: _.pick(t, ['onclick']),
    select: _.pick(t, ['onchange'].sort()),
    table: _.pick(t, ['onchange'].sort()),
    screen: _.pick(t, [].sort()),
    repeater: _.pick(t, [].sort()),
    display: {},
    checkbox: _.pick(t, ['onchange']),
    tabs: {},
    heading: {},
    'file-upload': _.pick(t, ['onupload']),
    image: {},
    modal: _.pick(t, ['onclose']),
    tree: _.pick(t, ['onchange'].sort()),
    list: _.pick(t, ['onchange'].sort()),
    alert: {},
    chart: _.pick(t, ['onchange'].sort()),
    'world-map': _.pick(t, ['onchange'].sort()),
    progress: {}
} as any