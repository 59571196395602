import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import VersionableItems from '../versionable/VersionableItems';
import 'bulma-switch/dist/css/bulma-switch.min.css';
import { IEvent, IState } from '../../../../../types/app';
import { IVersionableReferenceSelected } from '../../../../../types/versionable';
import _ from 'lodash';
import NewAction from '../actions/NewAction';
import classnames from 'classnames';
import './events.css';
import { subscribe, unsubscribe } from '../versionable/references';
import { nanoid } from 'nanoid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePause } from '@fortawesome/free-solid-svg-icons'
import { faCirclePause as farCirclePause } from '@fortawesome/free-regular-svg-icons'

interface EventProps {
    value: IEvent,
    states: IState[]
    hasScreen: boolean
    onChange: (event: IEvent) => void
    onDelete: (id: string) => void
    onEdit: (id: string, version: string, type: string, kind?: string) => void
    onNewAction: (actionType: string, id: string) => void
    onNewRules: (id: string) => void
    disabled: boolean
}

const Event = ({ value, states, hasScreen, onChange, onEdit, onDelete, onNewAction, onNewRules, disabled }: EventProps) => {

    const [tab, setTab] = useState(0);
    const [featureFlags, setFeatureFlags] = useState([]);

    const featureFlagCallback = useCallback((items: any) => {
        setFeatureFlags(items);
    }, [setFeatureFlags]);

    useEffect(() => {
        subscribe('featureFlag', featureFlagCallback);
        return () => unsubscribe('featureFlag', featureFlagCallback);
    }, []);

    const onNameChange = (e: FormEvent<HTMLInputElement>) => onChange({ ...value, name: e.currentTarget.value });
    const onTypeChange = (e: FormEvent<HTMLSelectElement>) => onChange({ ...value, immediate: e.currentTarget.value === 'immediate' });
    const onSaveChange = () => onChange({ ...value, saveValues: !value.saveValues });
    const onValidateChange = () => onChange({ ...value, validateValues: !value.validateValues });
    const onTargetChange = (e: FormEvent<HTMLSelectElement>) => onChange({ ...value, target: e.currentTarget.value });
    const onFeatureFlagChange = (e: FormEvent<HTMLSelectElement>) => onChange({ ...value, featureFlags: [e.currentTarget.value] });
    const onClearFeatureFlags = () => onChange({ ...value, featureFlags: [] });
    const onConditionsChange = (rules: IVersionableReferenceSelected[]) => onChange({ ...value, rules });
    const onActionsChange = (actions: IVersionableReferenceSelected[]) => onChange({ ...value, actions });
    const onBreakpoint = () => onChange({ ...value, breakpoint: !value.breakpoint });

    const hasValues = hasScreen && (value.saveValues || value.validateValues);
    const hasConditions = !_.isEmpty(value.rules);
    const hasActions = !_.isEmpty(value.actions);
    const hasFeatureFlags = !_.isEmpty(value.featureFlags);

    return (
        <div className="event mb">
            <header>
                <span className="icon is-medium fa-lg breakpoint-toggle" role="button" aria-checked={value.breakpoint} onClick={onBreakpoint}>
                    {value.breakpoint ? <FontAwesomeIcon icon={faCirclePause}  /> : <FontAwesomeIcon icon={farCirclePause} />}
                </span>
                {
                    value.immediate ?
                        <strong className="flex-grow mr">Immediately execute this event</strong> :
                        <div className="control">
                            <input className="input mr" type="text" placeholder="Event Name" value={value.name} onChange={onNameChange} disabled={disabled} />
                        </div>
                }
                <button className="button is-outlined is-small" onClick={onDelete.bind(null, value.id)} disabled={disabled}>Delete</button>
            </header>
            <div className="tabs">
                <ul>
                    <li className={classnames({ 'is-active': tab === 0 })}><a onClick={setTab.bind(null, 0)}>Values {hasValues ? ' \u2713' : null}</a></li>
                    <li className={classnames({ 'is-active': tab === 1 })}><a onClick={setTab.bind(null, 1)}>Conditions {hasConditions ? ' \u2713' : null}</a></li>
                    <li className={classnames({ 'is-active': tab === 2 })}><a onClick={setTab.bind(null, 2)}>Actions {hasActions ? ' \u2713' : null}</a></li>
                    <li className={classnames({ 'is-active': tab === 3 })}><a onClick={setTab.bind(null, 3)}>Type</a></li>
                    <li className={classnames({ 'is-active': tab === 4 })}><a onClick={setTab.bind(null, 4)}>Feature Flags {hasFeatureFlags ? ' \u2713' : null}</a></li>
                </ul>
            </div>
            {
                tab === 0 && hasScreen ? (
                    <>
                        <div className="field" onClick={onSaveChange}>
                            <input type="checkbox" className="switch is-rounded" checked={value.saveValues} disabled={disabled} />
                            <label>Save values entered on the screen</label>
                        </div>
                        <div className="field" onClick={onValidateChange}>
                            <input type="checkbox" className="switch is-rounded" checked={value.validateValues} disabled={disabled} />
                            <label>Validate values entered on the screen</label>
                        </div>
                    </>
                ) : null
            }
            {
                tab === 0 && !hasScreen ? (
                    <p>select a screen</p>
                ) : null
            }
            {
                tab === 1 ? (
                    <>
                        <VersionableItems
                            id={`${value.name}-conditions`}
                            value={value.rules || []}
                            onChange={onConditionsChange}
                            onEdit={onEdit}
                            type="rule"
                            multiple={true}
                            disabled={disabled}
                            canBreakpoint={true}
                            canDisable={true}
                        />
                        <button className="button is-primary is-small mt mb" onClick={() => onNewRules(value.id)}>New Rules</button>
                    </>
                ) : null
            }
            {
                tab === 2 ? (
                    <>
                        <VersionableItems
                            id={`${value.name}-actions`}
                            value={value.actions || []}
                            onChange={onActionsChange}
                            onEdit={onEdit}
                            type="action"
                            multiple={true}
                            disabled={disabled}
                            canDisable={true}
                            canBreakpoint={true}
                        />
                        <NewAction onNew={(actionType: string) => onNewAction(actionType, value.id)} className="mb" />
                    </>
                ) : null
            }
            {
                tab === 3 ? (
                    <>
                        <div className="field">
                            <div className="control">
                                <div className="select is-fullwidth">
                                    <select value={value.immediate ? 'immediate' : 'named'} onChange={onTypeChange} disabled={disabled}>
                                        <option value="named">Named</option>
                                        <option value="immediate">Immediate</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null
            }
            {
                tab === 4 ? (
                    <>
                        <div className="field has-addons">
                            <div className="control is-expanded">
                                <div className="select is-fullwidth">
                                    <select value={value.featureFlags[0]} onChange={onFeatureFlagChange} disabled={disabled}>
                                        <option>Select a Feature Flag</option>
                                        {
                                             _.map(featureFlags, (featureFlag: any) => (
                                                <option value={featureFlag.id} key={featureFlag.id}>{featureFlag.name}</option>
                                             ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="control">
                                <a className="button" role="button" onClick={onClearFeatureFlags}>Clear</a>
                            </div>
                        </div>
                    </>
                ) : null
            }
            <div className="field">
                <label className="label">Transition To</label>
                <div className="control">
                    <div className="select is-fullwidth">
                        <select value={value.target} onChange={onTargetChange} disabled={disabled}>
                            <option value="_NONE">None</option>
                            <option value="_SCREEN">Display the Screen again</option>
                            <option value="_SELF">Self - Executes Exit then Entry Actions</option>
                            {_.map(states, state => <option value={state.id}>{state.name}</option>)}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface IEventsProps {
    value: Record<string, IEvent>
    states: IState[]
    hasScreen: boolean
    onChange: (events: Record<string, IEvent>) => void
    onEdit: (id: string, version: string, type: string, kind?: string) => void
    onNewAction: (actionType: string, id: string) => void
    onNewRules: (id: string) => void
    disabled: boolean
}

const Events = ({ value, states, hasScreen, onChange, onEdit, onNewAction, onNewRules, disabled }: IEventsProps) => {

    const onEventAdd = () => {
        const id: string = nanoid(7);

        onChange({
            ...value,
            [id]: {
                id,
                name: '',
                saveValues: false,
                validateValues: false,
                rules: [],
                actions: [],
                target: '_NONE',
                order: _.keys(value).length,
                immediate: false,
                featureFlags: []
            }
        });
    }

    const onEventChange = (event: IEvent) => {
        onChange({
            ...value,
            [event.id]: event
        });
    }

    const onEventDelete = (id: string) => {
        onChange(_.omit(value, id));
    }

    return (
        <div className="events flex-grow overflow-y-auto" role="tabpanel">
            {
                _.values(value).map((event) => (
                    <Event
                        value={event}
                        states={states}
                        hasScreen={hasScreen}
                        onChange={onEventChange}
                        onEdit={onEdit}
                        onDelete={onEventDelete}
                        onNewAction={onNewAction}
                        onNewRules={onNewRules}
                        disabled={disabled}
                        key={event.id}
                    />
                ))
            }
            <button className="button is-primary" onClick={onEventAdd} disabled={disabled}>Add Event</button>
        </div>
    );
}

export default Events;