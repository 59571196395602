/* eslint-disable react/display-name */
import React from 'react';
import _ from 'lodash';
import { Change, IVersion } from '../../../../../types/versionable';

const changes = (version: IVersion) => {
    if (version.version === 'working')
        return null;

    const items = Array.isArray(version.changes) ? version.changes : (version.changes[''].changes || []) as Change[]
    return _.map(items, (change: Change, index) => <li key={index}>{change.change}</li>);
}

export default ({ versions }: { versions?: IVersion[] }) => {
    if (!versions || versions.length === 1)
        return <p>No history exists for this item</p>

    return (
        <div className="history">
        {
            versions.slice(1).map(item => (
                <div key={item.version}>
                    <h4 className="is-size-4 mb">{item.version} - <small>{(new Date(item.when)).toLocaleString()}</small></h4>
                    <ul>{changes(item)}</ul>
                </div>
            ))
        }
        </div>
    );
}