import React from "react";
import { Context, Event } from '../../../machines/build/versionable-items';
import Screen from './Screen';
import Error from '../../Error';
import "./screens.css";
import { useActor } from "@xstate/react";
import Sidebar from "../editing/Sidebar";
import { ActorRefFrom, StateMachine } from "xstate";

const Screens = ({ machine }: { machine: ActorRefFrom<StateMachine<Context, any, Event>> }) => {
    const [state, send] = useActor(machine);

    const onNew = () => send('NEW');

    let editor: JSX.Element | null = null;
    if (state.context.selectedItemActor)
        editor = <Screen machine={state.context.selectedItemActor} />
    else
        editor = <div className="has-text-centered is-flex justify-center flex-col flex-grow items-center">
            <h2 className="title mb is-size-1">Screens</h2>
            <h3 className="subtitle">Capture input from users with Forms and display data</h3>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="241" viewBox="0 0 1446 276" height="276" className="mb screens-placeholder">
                <path strokeWidth="15.652" strokeLinejoin="round" d="M130.826-529.174h1184.348V805.174H130.826z"/>
                <g>
                    <path strokeWidth="15.839" strokeLinejoin="round" d="M177.195-340.673h1064.16v176.16H177.196z"/>
                    <path d="M175.276-406.17h681.516M174.143-108.382h448.57" strokeWidth="12" strokeLinecap="round"/>
                </g>
                <g>
                    <path strokeWidth="15.839" strokeLinejoin="round" d="M177.195 67.05h1064.16v176.16H177.196z"/>
                    <path d="M175.276 1.553h681.516M174.143 299.34h448.57" strokeWidth="12" strokeLinecap="round"/>
                </g>
                <g>
                    <path strokeWidth="15.839" strokeLinejoin="round" d="M177.195 465.723h1064.16v176.16H177.196z"/>
                    <path d="M175.276 400.227h681.516M174.143 698.014h448.57" strokeWidth="12" strokeLinecap="round"/>
                </g>
                <circle cx="238.887" cy="-247.308" r="18"/>
                <circle cx="308.465" cy="-247.308" r="18"/>
                <circle cx="378.042" cy="-247.308" r="18"/>
                <circle cx="447.62" cy="-247.308" r="18"/>
                <circle cx="517.198" cy="-247.308" r="18"/>
                <g transform="translate(4.469 405.836)">
                    <circle cx="238.887" cy="-247.308" r="18"/>
                    <circle cx="308.465" cy="-247.308" r="18"/>
                    <circle cx="378.042" cy="-247.308" r="18"/>
                    <circle cx="447.62" cy="-247.308" r="18"/>
                    <circle cx="517.198" cy="-247.308" r="18"/>
                </g>
                <g transform="translate(-3.613 807.203)">
                    <circle cx="238.887" cy="-247.308" r="18"/>
                    <circle cx="308.465" cy="-247.308" r="18"/>
                    <circle cx="378.042" cy="-247.308" r="18"/>
                    <circle cx="447.62" cy="-247.308" r="18"/>
                    <circle cx="517.198" cy="-247.308" r="18"/>
                </g>
            </svg>
            <button className="button is-primary is-large" onClick={onNew}>Create a new Screen</button>
        </div>

    return (
        <div className="screens overflow-hidden">
            <Sidebar 
                items={state.context.items}
                selected={state.context.selectedItemId}
                send={send}
                loading={state.matches('loading') || state.matches('filtering')}
                type="Screen"
                allowNew={true}
            />
            {editor}
            <Error error={state.context.error} />
        </div>
    );
};

export default Screens;
