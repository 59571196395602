import { Machine, spawn, actions } from 'xstate';
import { IVersionableReference } from '../../../../../types/versionable';
import { deployment } from '../../../socket';
import { machine as releaseMachine } from './release';

const { assign, forwardTo, send } = actions;

export interface Schema {
    states: {
        loading: {},
        edit: {},
        error: {}
    }
}

type LoadedEvent = { type: 'LOADED', releases: IVersionableReference[] }
type SearchEvent = { type: 'SEARCH', value: string }
type SelectEvent = { type: 'SELECT', value: string }
type NewReleaseEvent = { type: 'NEW_RELEASE', release: IVersionableReference }

export type Event =
    | LoadedEvent
    | SearchEvent
    | SelectEvent
    | NewReleaseEvent
    | { type: 'DISMISS' }
    | { type: 'NEW' }

export interface Context {
    error?: string | null,
    selected?: string | null,
    releases?: IVersionableReference[],
    search?: string,
    machine?: any,
    org: string
    workspace: string
}

const realtime = (context: Context, event: Event) => (callback: any, onReceive: any) => {
    const onLoad = (releases: IVersionableReference[]) => callback({ type: 'LOADED', releases });
    const onNew = (release: IVersionableReference) => callback({ type: 'NEW_RELEASE', release });

    deployment.releases.releases(context.org, context.workspace, onLoad);

    onReceive((e: any) => {
        switch (e.type) {
            case 'NEW':
                deployment.releases.new(context.org, context.workspace, onNew);
                break;
        }
    })

    return () => {};
}

export const machine = Machine<Context, Schema, Event>({
    id: 'deployment-releases',
    initial: 'loading',
    invoke: {
        id: 'socket',
        src: realtime
    },
    states: {
        loading: {
            on: {
                LOADED: {
                    actions: assign<Context, LoadedEvent>({
                        releases: (_, event) => event.releases
                    }),
                    target: 'edit'
                }
            }
        },
        edit: {
            on: {
                SEARCH: {
                    actions: assign<Context, SearchEvent>({
                        search: (_, event) => event.value
                    }),
                    target: 'loading'
                },
                SELECT: {
                    actions: assign<Context, SelectEvent>({
                        selected: (context, event) => {
                            if (context.machine)
                                context.machine.stop!();

                            return event.value
                        },
                        machine: (context, event) => 
                            spawn(releaseMachine.withContext({ org: context!.org, workspace: context!.workspace, id: event.value }), { name: 'deployment-release' })
                    })
                },
                NEW: {
                    actions: forwardTo('socket')
                },
                NEW_RELEASE: {
                    actions: [
                        assign<Context, NewReleaseEvent>({
                            releases: (context, event) => (context.releases || []).concat([event.release])
                        }),
                        send((context, event) => ({ type: 'SELECT', value: event.release.id }))
                    ]
                }
            }
        },
        error: {
            on: {
                DISMISS: 'edit'
            }
        }
    }
})