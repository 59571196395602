import React, { useState } from "react";
import { Context, Event, Schema } from '../../../machines/deployment/featureFlags/featureFlags';
import { useActor } from "@xstate/react";
import { ActorRefFrom, StateMachine } from "xstate";
import './feature-flags.css';
import _ from "lodash";
import { nanoid } from "nanoid";

const FeatureFlags = ({ machine }: { machine: ActorRefFrom<StateMachine<Context, Schema, Event>> | null }) => {
    const [state, send] = useActor(machine!);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const onRemove = (id: string) => send({ type: 'REMOVE', id });
    const onChange = (id: string, name: string, description: string) => send({ type: 'CHANGE', id, name, description });
    const onNew = () => send({ type: 'NEW', id: nanoid(7), name, description });

    const isNewEnabled = name && !_.find(state.context.featureFlags, (flag) => flag.name === name);

    return (
        <div className="feature-flags">
            <p className="mb">Manage feature flags that can be used to toggle functionality on & off at runtime without deploying new app versions</p>
            <table className="table is-fullwidth">
                <thead>
                    <tr>
                        <th style={{ width: '1%', whiteSpace: 'nowrap' }}></th>
                        <th>Name</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        _.map(state.context.featureFlags, flag => (
                            <tr key={flag.id}>
                                <td style={{ width: '1%', whiteSpace: 'nowrap' }}>
                                    <button className="button" onClick={onRemove.bind(null, flag.id)}>Remove</button>
                                </td>
                                <td>
                                    <input className="input" type="text" placeholder="Name" value={flag.name} onChange={(e) => onChange(flag.id, e.currentTarget.value, flag.description)} />
                                </td>
                                <td>
                                    <input className="input" type="text" placeholder="Description" value={flag.description} onChange={(e) => onChange(flag.id, flag.name, e.currentTarget.value)} />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <h4 className="is-size-4 mb">Add Feature Flag</h4>
            <div className="add-feature-flag">
                <div className="field">
                    <label className="label">Name</label>
                    <div className="control">
                        <input className="input" type="text" placeholder="Name" value={name} onChange={(e) => setName(e.currentTarget.value)} />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Description</label>
                    <div className="control">
                        <textarea className="textarea" placeholder="Description" value={description} onChange={(e) => setDescription(e.currentTarget.value)} />
                    </div>
                </div>
                <button className="button is-success" onClick={onNew} disabled={!isNewEnabled}>Add Feature Flag</button>
            </div>
        </div>
    );
};

export default FeatureFlags;

