import React from 'react';
import _ from 'lodash';

interface ITab {
    group: string
    label: string
}

interface Props {
    value: ITab[]
    disabled: boolean
    onChange: (tabs: ITab[]) => void
}

export default ({ value, onChange, disabled }: Props) => {
    const onAddTab = () => {
        onChange(_.concat(_.get(value, 'tabs', []), { group: '', label: ''}));
    }
    
    const onRemoveTab = (index: number) => {
        value.splice(index, 1);
        onChange(value);
    }

    const onGroupChange = (index: number, group: string) => {
        value[index].group = group;
        onChange(value);
    }

    const onLabelChange = (index: number, label: string) => {
        value[index].label = label;
        onChange(value);
    };

    return (
        <div>
            <table className="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th>Group</th>
                        <th>Label</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (value || []).map((tab: any, index: number) => (
                            <tr key={index}>
                                <td>
                                    <input type="text" className="input is-small" value={tab.group} onChange={(e) => onGroupChange(index, e.currentTarget.value)} disabled={disabled} />
                                </td>
                                <td>
                                    <input type="text" className="input is-small" value={tab.label} onChange={(e) => onLabelChange(index, e.currentTarget.value)} disabled={disabled} />
                                </td>
                                <td>
                                    <button className="delete" onClick={onRemoveTab.bind(null, index)} disabled={disabled} />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <button className="button is-small" onClick={onAddTab} disabled={disabled}>Add Tab</button>
        </div>
    )
}
