/* eslint-disable react/display-name */
import React, { FormEvent, useState } from 'react';
import Header from '../editing/Header';
import Dependencies from '../editing/Dependencies';
import History from '../editing/History';
import Delete from './Delete';
import { useActor } from "@xstate/react";
import { Context, Event, } from '../../../machines/build/versionable-item';
import './action-editor.css';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/markdown/markdown';
import NewVersion from '../editing/NewVersion';
import { ActorRefFrom, StateMachine } from 'xstate';
import VersionableItems from '../versionable/VersionableItems';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { IActionGroup } from '../../../../../types/action';
import { IDependency, IVersionableReferenceSelected } from '../../../../../types/versionable';

export default ({ machine }: { machine: ActorRefFrom<StateMachine<Context<IActionGroup>, any, Event<IActionGroup>>> }) => {
    const [state, send] = useActor(machine!);
    const [isNewVersionVisible, setNewVersionVisible] = useState(false);

    const onVersion = (version: string) => send({ type: 'VERSION', version });
  
    const onNewVersion = () => setNewVersionVisible(true);
    const onCloseNewVersion = () => setNewVersionVisible(false);

    const onNewVersionCreated = () => {
        setNewVersionVisible(false);
        send({ type: 'NEW_VERSION_CREATED' });
    }

    const onNameChange = (e: FormEvent<HTMLInputElement>) => send({ type: 'CHANGE', value: { name: e.currentTarget.value } });
    const onItemsChange = (items: IVersionableReferenceSelected[]) => send({ type: 'CHANGE', value: { items }});

    const onDependencyClick = (item: IDependency) => send({ type: 'ITEM', itemType: item.type, id: item.id, version: item.version });

    const onDelete = () => send('DELETE');

    return (
        <div className="action-editor">
            <Header
                versions={state.context.versions}
                version={state.context.version}
                onVersion={onVersion}
                onNewVersion={onNewVersion}
                users={state.context.users}
                modified={state.context.item ? state.context.item.modified : null}
            />

            <div className="p">
                {
                    state.matches('edit') && state.context.item ? (
                        <>
                            <div className="box">
                                <div className="is-flex justify-between mb">
                                    <div>
                                        <FontAwesomeIcon icon={faLayerGroup} className="mr" />
                                        <strong>Group</strong>
                                    </div>
                                    <button className="button is-danger is-small is-outlined" onClick={onDelete} disabled={state.context.item.readonly}>Delete</button>
                                </div>
                                <p className="mb">
                                    Execute multiple actions. Changes made to Run variables in one Action will be available in the next Action. The combined
                                    changes to the Run variables will be applied to the Run after the last Action in the group completes. If any action fails
                                    the group will stop executing and any changes to the Run variables will not be applied.
                                </p>
                                <div className="field mb">
                                    <div className="control">
                                        <input className="input is-large" type="text" placeholder="Action Name" value={state.context.item.name} onChange={onNameChange} disabled={state.context.item.readonly} />
                                    </div>
                                </div>
                                <p className="mb has-text-grey-dark">Click an Action to select it then press <strong>G</strong> to group or un-group it, double-click on a Action to open it</p>
                                <VersionableItems
                                    id="items"
                                    value={state.context.item?.items || []}
                                    onChange={onItemsChange}
                                    multiple={true}
                                    type="action"
                                    disabled={state.context.item.readonly}
                                />
                            </div>

                            <div className="columns">
                                <div className="column">
                                    <div className="card mb">
                                        <header className="card-header">
                                            <p className="card-header-title">
                                            Uses
                                            </p>
                                        </header>
                                        <div className="card-content">
                                            <Dependencies org={state.context.org} workspace={state.context.workspace} dependencies={state.context.uses} onClick={onDependencyClick} />
                                        </div>
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="card mb">
                                        <header className="card-header">
                                            <p className="card-header-title">
                                            Used By
                                            </p>
                                        </header>
                                        <div className="card-content">
                                            <Dependencies org={state.context.org} workspace={state.context.workspace} dependencies={state.context.usedBy} onClick={onDependencyClick} />
                                        </div>
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="card">
                                        <header className="card-header">
                                            <p className="card-header-title">
                                                History
                                    </p>
                                        </header>
                                        <div className="card-content">
                                            <History versions={state.context.versions} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null
                }
                {
                    isNewVersionVisible ? (
                        <NewVersion
                            org={state.context.org}
                            workspace={state.context.workspace}
                            id={state.context.id}
                            type="action"
                            onNewVersion={onNewVersionCreated}
                            onClose={onCloseNewVersion}
                        />
                        ) : null
                }
                <Delete state={state} send={send} />
            </div>
        </div>
    );
}