import React, { FormEvent } from 'react';
import './variable-operation.css';
import { Controlled as CodeMirror } from 'react-codemirror2';
import classnames from 'classnames';
import VariableFinder from './VariableFinder';
import InputDateTime from './InputDateTime';
import '../../../../node_modules/codemirror/lib/codemirror.css';
import '../../../../node_modules/codemirror/theme/material.css';
import 'codemirror/mode/javascript/javascript';
import VersionableItems from '../versionable/VersionableItems';
// import VariableTransform from './VariableTransform';
import { IVariable, VariableLiteralKind, VariableType } from '../../../../../types/variable';
import { IVersionableReferenceSelected } from '../../../../../types/versionable';
import MarkdownEditor from '../editing/MarkdownEditor';

interface Props {
    org: string
    workspace: string
    variable: IVariable | null
    onChange: (value: IVariable | null) => void
    disabled: boolean
    type?: VariableLiteralKind
    allowRules?: boolean
    allowNothing?: boolean
    small?: boolean
    light?: boolean
}

const ValueSelector = ({ org, workspace, variable, onChange, type, disabled, allowRules, allowNothing, small, light }: Props) => {
    const [selectedTab, setSelectedTab] = React.useState<"write" | "preview">("write");

    const onTypeChange = (type: VariableType) => onChange({ type });
    const onPathChange = (path: string) => onChange({ type: 'variable', path });
    
    const onLiteralKindChange = (literalKind: VariableLiteralKind) => onChange({ type: 'literal', literalKind });
    const onLiteralStringChange = (e: FormEvent<HTMLInputElement>) => onChange({ type: 'literal', literalKind: 'string', literal: e.currentTarget.value });
    const onLiteralMultilineStringChange = (e: FormEvent<HTMLTextAreaElement>) => onChange({ type: 'literal', literalKind: 'string_multilne', literal: e.currentTarget.value });
    const onLiteralMarkdownChange = (literal: string) => onChange({ type: 'literal', literalKind: 'markdown', literal });
    const onLiteralNumberChange = (e: FormEvent<HTMLInputElement>) => onChange({ type: 'literal', literalKind: 'number', literal: e.currentTarget.valueAsNumber });
    const onLiteralDateTimeChange = (literal: string) => onChange({ type: 'literal', literalKind: 'datetime', literal });
    const onLiteralBooleanChange = (literal: boolean) => onChange({ type: 'literal', literalKind: 'boolean', literal });

    const onExpressionChange = (code: string) => onChange({ type: 'expression', code });

    const onRuleChange = (rule: IVersionableReferenceSelected[]) => onChange({ type: 'rule', rule: rule[0] });

    const onReset = () => onChange(null);

    let content: JSX.Element | null = null;
    const buttonClassName = classnames('button', { 'is-small': small, 'is-light': light, 'is-link': !light });

    if (variable === null || variable === undefined)
        content = (
            <div className="buttons">
                <button className={buttonClassName} onClick={() => onTypeChange('literal')} disabled={disabled}>Literal</button>
                <button className={buttonClassName} onClick={() => onTypeChange('variable')} disabled={disabled}>Variable</button>
                <button className={buttonClassName} onClick={() => onTypeChange('expression')} disabled={disabled}>Logic</button>
                {type === 'boolean' && allowRules === true && <button className={buttonClassName} onClick={() => onTypeChange('rule')} disabled={disabled}>Rule</button>}
                {allowNothing ? <button className={buttonClassName} onClick={() => onTypeChange('nothing')} disabled={disabled}>Nothing</button> : null}
                {/* {state.context.allowTransform ? <button className={buttonClassName} onClick={() => send('TRANSFORM')} disabled={disabled}>Transform</button> : null} */}
                {/* {state.context.required ? null : <button className={buttonClassName} onClick={() => send('NOTHING')} disabled={disabled}>Nothing</button>} */}
            </div>
        );
    else {
        switch (true) {
            case variable.type === 'variable':
                content = (
                    <VariableFinder
                        org={org} 
                        workspace={workspace}
                        variable={variable!.path}
                        onChange={onPathChange}
                        onClear={onReset}
                        disabled={false}
                        focusOnMount={true}
                    />
                )
                break;

            case variable.type === 'literal' && !variable.literalKind && !type:
                content = (
                    <div className="select">
                        <select onChange={(e: FormEvent<HTMLSelectElement>) => onLiteralKindChange(e.currentTarget.value as VariableLiteralKind)}>
                            <option>Select a data type for the literal value</option>
                            <option value="string">Text</option>
                            <option value="string_multiline">Text - multiline</option>
                            <option value="markdown">Markdown</option>
                            <option value="number">Number</option>
                            <option value="boolean">Boolean</option>
                            <option value="datetime">DateTime</option>
                        </select>
                    </div>
                )
                break;

            case variable.type === 'literal' && (variable.literalKind === 'string' || type === 'string'):
                content = (
                    <>
                        <input className="input" type="text" value={variable!.literal as string} onChange={onLiteralStringChange} disabled={disabled} />
                        <button className="delete" onClick={onReset} disabled={disabled} />
                    </>
                )
                break;

            case variable.type === 'literal' && (variable.literalKind === 'string_multilne' || type === 'string_multilne'):
                content = (
                    <>
                        <textarea className="textarea" value={variable!.literal as string} onChange={onLiteralMultilineStringChange} disabled={disabled} />
                        <button className="delete" onClick={onReset} disabled={disabled} />
                    </>
                )
                break;

            case variable.type === 'literal' && (variable.literalKind === 'markdown' || type === 'markdown'):
                content = (
                    <>
                        <MarkdownEditor
                            id="value-selector-literal-markdown"
                            value={variable!.literal as string}
                            onChange={onLiteralMarkdownChange}
                            disabled={disabled}
                        />
                        <button className="delete" onClick={onReset} disabled={disabled} />
                    </>
                )
                break;

            case variable.type === 'literal' && (variable.literalKind === 'number' || type === 'number'):
                content = (
                    <>
                        <input className="input" type="number" value={variable!.literal as number} onChange={onLiteralNumberChange} disabled={disabled} />
                        <button className="delete" onClick={onReset} disabled={disabled} />
                    </>
                )
                break;

            case variable.type === 'literal' && (variable.literalKind === 'datetime' || type === 'datetime'):
                content = (
                    <>
                        <InputDateTime value={variable!.literal as string} onChange={onLiteralDateTimeChange} disabled={disabled} />
                        <button className="delete" onClick={onReset} disabled={disabled} />
                    </>
                )
                break;

            case variable.type === 'literal' && (variable.literalKind === 'boolean' || type === 'boolean'):
                content = (
                    <>
                        <div className="field" onClick={() => onLiteralBooleanChange(!variable.literal as boolean)}>
                            <input type="checkbox" className="switch is-rounded" defaultChecked={variable.literal as boolean} />
                            <label></label>
                        </div>
                        <button className="delete" onClick={onReset} disabled={disabled} />
                    </>
                )
                break;

            case variable.type === 'expression' || variable.type === 'logic':
                content = (
                    <>
                        <CodeMirror
                            value={variable.code || ''}
                            options={{
                                theme: 'material',
                                mode: 'javascript',
                                lineNumbers: true,
                            }}
                            onBeforeChange={(editor, data, code) => {
                                if (!disabled)
                                    onExpressionChange(code);
                            }}
                        />
                        <button className="delete" onClick={onReset} disabled={disabled} />
                    </>
                )
                break;

            case variable.type === 'rule':
                content = (
                    <>
                        <VersionableItems
                            id="rule"
                            value={variable.rule ? [variable.rule] : []}
                            onChange={onRuleChange}
                            type="rule" 
                            disabled={disabled} 
                        />
                        <button className="delete" onClick={onReset} disabled={disabled} />
                    </>
                );
                break;

            // case state.matches('transform'):
            //     content = (
            //         <>
            //             <VariableTransform
            //                 transform={state.context.variable?.transform}
            //                 value={state.context.variable?.value}
            //                 onChange={(variable) => send({ type: 'CHANGE', ...variable })}
            //                 disabled={disabled}
            //             />
            //             <button className="delete" onClick={() => send('CLEAR')} disabled={disabled} />
            //         </>
            //     );
            //     break;

            case variable.type === 'nothing':
                content = (
                    <>
                        <span className="tag is-large">Nothing</span>
                        <button className="delete" onClick={onReset} disabled={disabled} />
                    </>
                );
                break;
        }
    }

    return (
        <div className="variable-operation is-flex items-center">              
            {content}               
        </div>  
    )
}

export default ValueSelector;