/* eslint-disable react/display-name */
import React, { FormEvent } from 'react';
import { IPluginSQL } from '../../../machines/build/plugins/plugin';

interface Props {
    item: IPluginSQL
    onChange: (value: Partial<IPluginSQL>) => void
}

export default ({ item, onChange }: Props) => {

    const onTypeChange = (e: FormEvent<HTMLSelectElement>) => onChange({ databaseType: e.currentTarget.value });
    const onHostChange = (e: FormEvent<HTMLInputElement>) => onChange({ host: e.currentTarget.value });
    const onPortChange = (e: FormEvent<HTMLInputElement>) => onChange({ port: e.currentTarget.valueAsNumber });
    const onNameChange = (e: FormEvent<HTMLInputElement>) => onChange({ databaseName: e.currentTarget.value });
    const onUsernameChange = (e: FormEvent<HTMLInputElement>) => onChange({ username: e.currentTarget.value });
    const onPasswordChange = (e: FormEvent<HTMLInputElement>) => onChange({ password: e.currentTarget.value });

    return (
        <>
            <div className="field">
                <label className="label">Database Type</label>
                <div className="control">
                    <div className="select">
                        <select value={item.databaseType} onChange={onTypeChange} disabled={item.readonly}>
                            <option>Select Database Type</option>
                            <option value="pg">Postgresql</option>
                            <option value="mysql">MySql</option>
                            <option value="msssql">SQL Server</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className="field">
                <label className="label">Host</label>
                <div className="control">
                    <input className="input" type="text" placeholder="Host" value={item.host} onChange={onHostChange} disabled={item.readonly} />
                </div>
            </div>

            <div className="field">
                <label className="label">Port</label>
                <div className="control">
                    <input className="input" type="number" placeholder="Port" value={item.port} onChange={onPortChange} disabled={item.readonly} />
                </div>
            </div>

            <div className="field">
                <label className="label">Database Name</label>
                <div className="control">
                    <input className="input" type="text" placeholder="Database Name" value={item.databaseName} onChange={onNameChange} disabled={item.readonly} />
                </div>
            </div>

            <div className="field">
                <label className="label">Username</label>
                <div className="control">
                    <input className="input" type="text" placeholder="Username" value={item.username} onChange={onUsernameChange} disabled={item.readonly} />
                </div>
            </div>

            <div className="field">
                <label className="label">Password</label>
                <div className="control">
                    <input className="input" type="password" placeholder="Password" value={item.password} onChange={onPasswordChange} disabled={item.readonly} />
                </div>
            </div>

            {/* <button className="button is-info">Test Connection</button> */}
        </>
    );
}