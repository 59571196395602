import _ from 'lodash';
import { spawn, forwardTo, Interpreter, sendParent, actions as stateActions, createMachine, Sender, Receiver } from 'xstate';
import { IVersionableReference } from '../../../../types/versionable';
import { versionableItems, close } from '../../socket';
import { ItemEvent } from '../root';
import { versionableItemMachine } from './versionable-item';

const { sendTo, assign } = stateActions;

type DismissEvent = { type: 'DISMISS' }
type NewEvent = { type: 'NEW', itemType?: string }
type LoadedEvent = { type: 'LOADED', items: IVersionableReference[] }
type SearchEvent = { type: 'SEARCH', search: string }
type SelectEvent = { type: 'SELECT', value: string }
type OnNewEvent = { type: 'ON_NEW', item: IVersionableReference }
type OnDeletedEvent = { type: 'ON_DELETED', id: string }
type OnRenameEvent = { type: 'ON_RENAME', id: string, name: string }
type FilterEvent = { type: 'FILTER', app: string }
type FilteredEvent = { type: 'FILTERED', items: IVersionableReference[] }

export type Event =
    | DismissEvent
    | NewEvent
    | LoadedEvent
    | SearchEvent
    | SelectEvent
    | ItemEvent
    | FilterEvent
    | FilteredEvent
    | OnNewEvent
    | OnDeletedEvent
    | OnRenameEvent

export interface Context {
    org: string
    workspace: string
    items?: IVersionableReference[],
    error?: string | null,
    selectedItemId?: string,
    search?: string,
    selectedItemActor?: any,
    filter?: string
}

export const versionableItemsMachine = (id: string, type: string, urlType: string) => {

    const urlVersionableItems = RegExp(`build/${urlType}/(.{7})`, 'i');
    const versionableItemsSocket = versionableItems(type);

    const realtime = (context: Context) => (callback: Sender<Event>, onReceive: Receiver<Event>) => {
        const onLoad = (response: IVersionableReference[]) => callback({ type: 'LOADED', items: response });
        const onNew = (response: IVersionableReference) => callback({ type: 'ON_NEW', item: response });
        const onDelete = (response: { id: string }) => callback({ type: 'ON_DELETED', ...response });
        const onRename = (response: { id: string, name: string}) => callback({ type: 'ON_RENAME', ...response });

        versionableItemsSocket.edit(context.org, context.workspace, onLoad, onNew, onDelete, onRename);

        onReceive((e: Event) => {
            switch (e.type) {
                case 'NEW':
                    versionableItemsSocket.new(context.org, context.workspace, e.itemType, (response: IVersionableReference) => {
                        callback({ type: 'ON_NEW', item: response });
                        callback({ type: 'SELECT', value: response.id });
                    })
                    break;

                case 'FILTER':
                    versionableItemsSocket.filterByApp(context.org, context.workspace, e.app, (response: IVersionableReference[]) => callback({ type: 'FILTERED', items: response }));
                    break;
            }
        })
    }

    const history = () => (callback: Sender<Event>) => {
        const listener = () => {
            const match = urlVersionableItems.exec(window.location.pathname);

            if (match)
                callback({ type: 'SELECT', value: match[1] });
        }

        window.addEventListener('popstate', listener);
        return () => window.removeEventListener('popstate', listener);
    }

    return createMachine({
        schema: {
            context: {} as Context,
            events: {} as Event
        },
        id,
        initial: 'loading',
        invoke: [
            { id: 'socket', src: realtime },
            { id: 'history', src: history }
        ],
        on: {
            ITEM: {
                actions: sendParent<Context, ItemEvent>((context, event) => event)
            },
            FILTER: {
                actions: assign<Context, FilterEvent>({
                    filter: (context, event) => event.app
                }),
                target: 'filtering'
            }
        },
        states: {
            filtering: {
                entry: ['filter'],
                on: {
                    FILTERED: {
                        actions: ['loaded'],
                        target: 'idle'
                    }
                }
            },
            loading: {
                on: {
                    LOADED: [
                        { cond: 'url', actions: ['loaded'], target: 'select' },
                        { actions: ['loaded'], target: 'idle' }
                    ]
                }
            },
            select: {
                always: {
                    actions: 'selectFromUrl',
                    target: 'idle'
                }
            },
            idle: {
                on: {
                    NEW: {
                        actions: forwardTo('socket')
                    },
                    SEARCH: {
                        actions: ['search'],
                        target: 'loading'
                    },
                    SELECT: {
                        actions: ['select', 'setUrlFromSelection'],
                    },
                    ON_NEW: {
                        actions: ['onNew']
                    },
                    ON_RENAME: {
                        actions: ['onRename']
                    },
                    ON_DELETED: {
                        actions: ['onDeleted', 'setUrlFromDeletion'],
                    }
                }
            },
            error: {
                on: {
                    DISMISS: 'idle'
                }
            }
        }
    },
    {
        actions: {
            filter: sendTo('socket', (context) => ({
                type: 'FILTER',
                app: context.filter
            })),
            loaded: assign<Context, LoadedEvent>({
                items: (context, event) => event.items
            }),
            selectFromUrl: assign((context) => {
                const match = urlVersionableItems.exec(window.location.pathname);

                if (match)
                    return {
                        ...context,
                        selectedItemId: match[1],
                        selectedItemActor: spawn(versionableItemMachine(match[1], type).withContext({ org: context.org, workspace: context.workspace, id: match[1], version: '' }), { name: `versionable-item-${type}` })
                    }
                else
                    return context;
            }),
            search: assign<Context, SearchEvent>({
                search: (context, event) => event.search
            }),
            select: assign<Context, SelectEvent>({
                selectedItemId: (context, event) => {
                    if (context.selectedItemId)
                        close(context.org, context.workspace, context.selectedItemId, context.selectedItemActor.getSnapshot().context.version, type);

                    if (context.selectedItemActor)
                        context.selectedItemActor.stop();

                    return event.value;
                },
                selectedItemActor: (context, event) => 
                    spawn(versionableItemMachine(event.value, type).withContext({ org: context.org, workspace: context.workspace, id: event.value, version: '' }), {  name: `versionable-item-${type}` })
            }),
            setUrlFromSelection: (context: Context, event: SelectEvent) => {
                window.history.pushState(null, '', `/${context.org}/${context.workspace}/build/${urlType}/${event.value}`);
            },
            onNew: assign<Context, OnNewEvent>({
                items: (context, event) => _.concat(context.items, [event.item]) as IVersionableReference[]
            }),
            onRename: assign<Context, OnRenameEvent>({
                items: (context, event) => _.map(context.items, (item => item.id === event.id ? { ...item, name: event.name } : item))
            }),
            onDeleted: assign<Context, OnDeletedEvent>({
                items: (context, event) => _.filter(context.items, (item) => item.id !== event.id),
                selectedItemActor: (context, event) => {
                    if (context.selectedItemId === event.id && context.selectedItemActor) {
                        context.selectedItemActor.stop();
                        return null;
                    }
                    else
                        return context.selectedItemActor;
                },
                selectedItemId: (context, event) => {
                    if (context.selectedItemId === event.id)
                        return undefined;
                    else
                        return context.selectedItemId;
                },
            }),
            setUrlFromDeletion: (context: Context, event: OnDeletedEvent) => {
                if (window.location.pathname.endsWith(`/${urlType}/${event.id}`)) {
                    window.history.replaceState(null, '', `/${context.org}/${context.workspace}/build/${urlType}`);
                }
            }
        },
        guards: {
            url: () => {
                return urlVersionableItems.test(window.location.pathname)
            }
        }
    })
}

export const getUrlPart = (interpreter: Interpreter<Context, any, Event>) => {
    if (interpreter.getSnapshot().context.selectedItemActor)
        return `/build/themes/${interpreter.getSnapshot().context.selectedItemId}`;

    return '/build/themes';
}