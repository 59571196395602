/* eslint-disable react/display-name */
import React, { FormEvent } from 'react';
import { useTextAreaMarkdownEditor, bold, italic, strikethrough, code, quote, link, header, orderedListCommand, unorderedListCommand } from "react-mde";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBold, faItalic, faListUl, faListOl, faStrikethrough, faQuoteRight, faCode, faLink, faHeading } from '@fortawesome/free-solid-svg-icons';
import './markdown-editor.css'

interface Props {
    id: string
    value: string
    onChange: (value: string) => void
    disabled: boolean
}

export default ({ id, value, onChange, disabled }: Props) => {
    const { ref, commandController } = useTextAreaMarkdownEditor({
        commandMap: {
            bold: bold,
            italic: italic,
            code: code,
            strikethrough: strikethrough,
            quote: quote,
            link: link,
            header: header,
            orderedList: orderedListCommand,
            unorderedList: unorderedListCommand
        }
    });

    const onMarkdownChange = (e: FormEvent<HTMLTextAreaElement>) => {
        onChange(e.currentTarget.value);
    }

    return (
        <div className="markdown-editor">
            <div className="buttons has-addons">
                <button title="Heading" className="button" disabled={disabled} onClick={async () => await commandController.executeCommand("header")}><span className="icon"><FontAwesomeIcon icon={faHeading} /></span></button>
                <button title="Bold" className="button" disabled={disabled} onClick={async () => await commandController.executeCommand("bold")}><span className="icon"><FontAwesomeIcon icon={faBold} /></span></button>
                <button title="Italic" className="button" disabled={disabled} onClick={async () => await commandController.executeCommand("italic")}><span className="icon"><FontAwesomeIcon icon={faItalic} /></span></button>
                <button title="Strikethrough" className="button" disabled={disabled} onClick={async () => await commandController.executeCommand("strikethrough")}><span className="icon"><FontAwesomeIcon icon={faStrikethrough} /></span></button>
                <button title="Link" className="button" disabled={disabled} onClick={async () => await commandController.executeCommand("link")}><span className="icon"><FontAwesomeIcon icon={faLink} /></span></button>
                <button title="Quote" className="button" disabled={disabled} onClick={async () => await commandController.executeCommand("quote")}><span className="icon"><FontAwesomeIcon icon={faQuoteRight} /></span></button>
                <button title="Code" className="button" disabled={disabled} onClick={async () => await commandController.executeCommand("code")}><span className="icon"><FontAwesomeIcon icon={faCode} /></span></button>
                <button title="Unordered List" className="button" disabled={disabled} onClick={async () => await commandController.executeCommand("orderedList")}><span className="icon"><FontAwesomeIcon icon={faListUl} /></span></button>
                <button title="Ordered List" className="button" disabled={disabled} onClick={async () => await commandController.executeCommand("unorderedList")}><span className="icon"><FontAwesomeIcon icon={faListOl} /></span></button>
            </div>
            <textarea ref={ref} className="textarea" rows={8} data-test-id={`${id}-editor`} value={value} onChange={onMarkdownChange} disabled={disabled} />
        </div>
    )
}