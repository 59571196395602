import React from 'react';
import Dependencies from '../editing/Dependencies';

export default ({ state, send }: any) => {
    const hasDependencies = state.context.usedBy && state.context.usedBy.length > 0;

    return state.matches('edit') && state.context.confirmDelete ? (
        <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card">
                <section className="modal-card-body">
                    {
                        hasDependencies ? (
                            <>
                                <h5 className="is-size-5 has-text-centered mb">This item is used by another item and cannot be deleted</h5>
                                <Dependencies org={state.context.org} workspace={state.context.workspace} dependencies={state.context.usedBy} />
                            </>
                        ): (
                            <h5>This item is not used by another item and can be deleted</h5>
                        )
                    }
                </section>
                <footer className="modal-card-foot">
                    {hasDependencies ? null : <button className="button is-primary" onClick={() => send('CONFIRM_DELETE')}>Delete</button>}
                    <button className="button" onClick={() => send('CANCEL_DELETE')}>Cancel</button>
                </footer>
            </div>
        </div>
    ) : null
} 