import React from "react";
import { Context, Event } from '../../../machines/build/versionable-items';
import App from './App';
import Error from '../../Error';
import "./apps.css";

import { useActor } from "@xstate/react";
import Sidebar from "../editing/Sidebar";
import { ActorRefFrom, StateMachine } from "xstate";

interface Props {
    machine: ActorRefFrom<StateMachine<Context, any, Event>> | null
}

const Apps = ({ machine }: Props) => {
    const [state, send] = useActor(machine);

    const onNew = () => send('NEW');

    let editor: JSX.Element | null = null;
    if (state.context.selectedItemActor)
        editor = <App machine={state.context.selectedItemActor} />
    else
        editor = <div className="has-text-centered is-flex justify-center flex-col flex-grow items-center intro">
            <h2 className="title mb is-size-1">Apps</h2>
            <h3 className="subtitle">Create connected multi-user experiences</h3>
            <svg xmlns="http://www.w3.org/2000/svg" width="500" height="250" viewBox="0 0 132.292 66.146" className="mb">
                <defs>
                    <marker id="b" refX="0" refY="0" orient="auto" overflow="visible">
                        <path d="M2.308 0l-3.46 2v-4l3.46 2z" fillRule="evenodd" stroke="#34495e" strokeWidth=".4pt" fill="#34495e" />
                    </marker>
                    <marker id="a" refX="0" refY="0" orient="auto" overflow="visible">
                        <path d="M-10 0l-4 4L0 0l-14-4 4 4z" fillRule="evenodd" stroke="#34495e" strokeWidth=".8pt" fill="#34495e" />
                    </marker>
                    <marker id="c" refX="0" refY="0" orient="auto" overflow="visible">
                        <path d="M-10 0l-4 4L0 0l-14-4z" fill="#34495e" fillRule="evenodd" stroke="#34495e" strokeWidth=".8pt" />
                    </marker>
                    <marker id="d" refX="0" refY="0" orient="auto" overflow="visible">
                        <path d="M2.308 0l-3.46 2v-4z" fill="#34495e" fillRule="evenodd" stroke="#34495e" strokeWidth=".4pt" />
                    </marker>
                    <marker id="e" refX="0" refY="0" orient="auto" overflow="visible">
                        <path d="M-10 0l-4 4L0 0l-14-4z" fill="#34495e" fillRule="evenodd" stroke="#34495e" strokeWidth=".8pt" />
                    </marker>
                    <marker id="f" refX="0" refY="0" orient="auto" overflow="visible">
                        <path d="M2.308 0l-3.46 2v-4z" fill="#34495e" fillRule="evenodd" stroke="#34495e" strokeWidth=".4pt" />
                    </marker>
                </defs>
                <g fill="none" stroke="#34495e" strokeWidth="1.058">
                    <circle cx="20.713" cy="33.073" r="13.229" className="state-1" />
                    <circle cx="66.55" cy="33.073" r="13.229" className="state-2" />
                    <g transform="translate(0 -.069)">
                        <circle cx="110.249" cy="16.036" r="13.229" className="state-3" />
                        <circle cx="110.249" cy="50.247" r="13.229" className="state-3" />
                    </g>
                    <path d="M36.19 33.073h12.026" markerMid="url(#a)" markerEnd="url(#b)" className="transition-arrow" />
                    <path d="M81.169 26.907l11.309-4.094" markerMid="url(#c)" markerEnd="url(#d)" className="transition-arrow" />
                    <path d="M81.3 39.646l10.677 5.534" markerMid="url(#e)" markerEnd="url(#f)" className="transition-arrow" />
                </g>
            </svg>
            <button className="button is-primary is-large" style={{ alignSelf: 'center' }} onClick={onNew}>Create a new App</button>
        </div>

    return (
        <div className="apps">
            <Sidebar 
                items={state.context.items}
                selected={state.context.selectedItemId}
                send={send}
                loading={state.matches('loading') || state.matches('filtering')}
                type="App"
                allowNew={true}
            />
            {editor}
            <Error error={state.context.error} />
        </div>
    );
};

export default Apps;
