import React from "react";
import VersionableItems from '../versionable/VersionableItems';
import { IVersionableReferenceSelected } from '../../../../../types/versionable';
import NewAction from '../actions/NewAction';

interface Props {
    value?: IVersionableReferenceSelected[]
    onChange: (value: IVersionableReferenceSelected[]) => void
    onEdit: (id: string, version: string, type: string, kind?: string) => void
    onNew: (actionType: string) => void
    disabled: boolean
}

const Exit = ({ value, onChange, onEdit, onNew, disabled }: Props) => {
    return (
        <div className="flex-grow" role="tabpanel">
            <p className="mb">
                Execute actions when exiting this state. Actions will be executed in sequence. Actions that are grouped together (indented) will be
                executed in parallel
            </p>
            <p className="mb has-text-grey-dark">Click an Action to select it then press <strong>G</strong> to group or un-group it, double-click on a Action to open it</p>
            <VersionableItems
                id="entry"
                value={value || []}
                onChange={onChange}
                onEdit={onEdit}
                type="action"
                multiple={true}
                disabled={disabled}
                canDisable={true}
                canBreakpoint={true}
                canFeatureFlag={true}
            />
            <NewAction onNew={onNew} />
        </div>
    );
}

export default Exit;