import React, { FormEvent } from 'react';
import { IVersionableReferenceSelected } from '../../../../../types/versionable';
import VersionableItems from '../versionable/VersionableItems';

interface Props {
    title: string
    onTitleChange: (value: string) => void
    tabTitle: string
    onTabTitleChange: (value: string) => void
    theme: IVersionableReferenceSelected | null
    onThemeChange: (value: IVersionableReferenceSelected[]) => void
    disabled: boolean
}

const LookFeel = ({ theme, onThemeChange, title, onTitleChange, tabTitle, onTabTitleChange, disabled }: Props) => {

    const onTitleInputChange = (e: FormEvent<HTMLInputElement>) => onTitleChange(e.currentTarget.value);
    const onTabTitleInputChange = (e: FormEvent<HTMLInputElement>) => onTabTitleChange(e.currentTarget.value);

    return (
        <section role="tabpanel">
            <div className="field">
                <label className="label">Title</label>
                <div className="control">
                    <input className="input" type="text" placeholder="Title" value={title} onChange={onTitleInputChange} disabled={disabled} />
                </div>
            </div>
            <div className="field">
                <label className="label">Browser Tab Title</label>
                <div className="control">
                    <input className="input" type="text" placeholder="Browser Tab Title" value={tabTitle} onChange={onTabTitleInputChange} disabled={disabled} />
                </div>
            </div>
            <div className="field">
                <label className="label">Theme</label>
                <VersionableItems
                    id="entry"
                    value={theme ? [theme] : []}
                    onChange={onThemeChange}
                    type="runner"
                    multiple={true}
                    disabled={disabled}
                />
            </div>
        </section>
    );
}

export default LookFeel;