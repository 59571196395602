import { OpenAPIV3 } from "openapi-types"
import _ from 'lodash';


export const isMultipartRequestBody = (operation: OpenAPIV3.OperationObject) => {
    if (operation && operation.requestBody) {
        return !!_.get(operation.requestBody, 'content.multipart/form-data');
    }

    return false;
}

export const getParameters = (operation : OpenAPIV3.OperationObject) => {
    if (operation) {
        return operation.parameters || {}
    }

    return {}
}

export const getMultipartParameters = (operation: OpenAPIV3.OperationObject) => {
    if (operation && operation.requestBody) {
        const content = _.get(operation.requestBody, 'content.multipart/form-data') as OpenAPIV3.MediaTypeObject;
        if (content && content.schema) {
            return (content.schema as OpenAPIV3.SchemaObject).properties;
        }
    }

    return {};
}
