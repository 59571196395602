import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import classnames from "classnames";

interface IProps {
    onNew: (actionType: string) => void
    className?: string
}

export default ({ onNew, className }: IProps) => (
    <div className={classnames("dropdown is-hoverable mt", className)}>
        <div className="dropdown-trigger">
            <button className="button is-primary is-small" aria-haspopup="true">
                <span>New Action</span>
                <span className="icon is-small">
                    <FontAwesomeIcon icon={faAngleDown} />
                </span>
            </button>
        </div>
        <div className="dropdown-menu" role="menu">
            <div className="dropdown-content">
                <div className="dropdown-item" onClick={onNew.bind(null, 'connect')}>Execute an API endpoint</div>
                <div className="dropdown-item" onClick={onNew.bind(null, 'sql')}>SQL Query</div>
                <div className="dropdown-item" onClick={onNew.bind(null, 'email')}>Send Email</div>
                <div className="dropdown-item" onClick={onNew.bind(null, 'update')}>Update a Variable</div>
                <div className="dropdown-item" onClick={onNew.bind(null, 'logic')}>Perform Logic</div>
                <div className="dropdown-item" onClick={onNew.bind(null, 'event')}>Send Event</div>
                <div className="dropdown-item" onClick={onNew.bind(null, 'group')}>Group</div>
                <div className="dropdown-item" onClick={onNew.bind(null, 'log')}>Log</div>
                <div className="dropdown-item" onClick={onNew.bind(null, 'loop')}>Loop</div>
                <div className="dropdown-item" onClick={onNew.bind(null, 'app')}>Start an App</div>
                <div className="dropdown-item" onClick={onNew.bind(null, 'stop')}>Stop an App</div>
                <div className="dropdown-item" onClick={onNew.bind(null, 'resume')}>Resume an App</div>
            </div>
        </div>
    </div>
)