import React from "react";
import { useActor } from "@xstate/react";
import { Context, Event } from '../../../machines/build/versionable-items';
import Runner from "./Runner";
import './runners.css';
import Sidebar from "../editing/Sidebar";
import { ActorRefFrom, StateMachine } from "xstate";

const Runners = ({ machine }: { machine: ActorRefFrom<StateMachine<Context, any, Event>> | null }) => {
    const [state, send] = useActor(machine!);

    const onNew = () => send('NEW');

    let editor: JSX.Element | null = null;
    if (state.context.selectedItemActor)
        editor = <Runner machine={state.context.selectedItemActor} />
    else
        editor = <div className="has-text-centered is-flex justify-center flex-col flex-grow items-center">
            <h2 className="title mb is-size-1">Themes</h2>
            <h3 className="subtitle">Adding branding and customize look & feel</h3>
            <svg xmlns="http://www.w3.org/2000/svg" width="216.663" height="250" className="mb">
                <path className="fade" d="M45.833 183.334h25c2.3 0 4.166-1.867 4.166-4.167s-1.867-4.166-4.167-4.166h-25a4.168 4.168 0 00-4.166 4.166c0 2.3 1.866 4.167 4.167 4.167z"/>
                <path className="pop" d="M215.442 59.556L157.11 1.224a4.154 4.154 0 00-4.542-.904 4.173 4.173 0 00-2.57 3.85v41.666c0 11.683 9.15 20.833 20.833 20.833h16.666c2.3 0 4.166-1.867 4.166-4.166 0-2.3-1.866-4.167-4.166-4.167H170.83c-7.125 0-12.5-5.375-12.5-12.5V14.228l49.999 50v164.938c0 6.892-5.608 12.5-12.5 12.5H20.833c-6.891 0-12.5-5.608-12.5-12.5V20.836c0-6.891 5.609-12.5 12.5-12.5h116.665c2.3 0 4.166-1.866 4.166-4.166 0-2.3-1.867-4.166-4.166-4.166H20.833C9.346.004 0 9.349 0 20.837v208.33C0 240.654 9.346 250 20.833 250H195.83c11.487 0 20.833-9.346 20.833-20.833V62.502a4.175 4.175 0 00-1.22-2.946z"/>
                <path className="fade" d="M45.833 208.333h41.666c2.3 0 4.166-1.866 4.166-4.166 0-2.3-1.867-4.167-4.166-4.167H45.833a4.168 4.168 0 00-4.167 4.167c0 2.3 1.866 4.166 4.167 4.166z"/>
                <path className="fade" d="M133.331 179.167c0-2.3-1.866-4.166-4.166-4.166H87.499a4.168 4.168 0 00-4.166 4.166c0 2.3 1.866 4.167 4.166 4.167h41.666c2.3 0 4.166-1.867 4.166-4.167z"/>
                <path className="fade" d="M174.997 95.835c0-2.3-1.867-4.166-4.166-4.166H45.833a4.168 4.168 0 00-4.167 4.166v58.333c0 2.3 1.867 4.166 4.167 4.166h25a4.163 4.163 0 002.945-1.22l26.22-26.22 9.554 9.553a4.162 4.162 0 005.892 0l17.887-17.887 17.888 17.887a4.162 4.162 0 005.891 0 4.161 4.161 0 000-5.891l-20.833-20.833a4.161 4.161 0 00-5.892 0l-17.887 17.887-9.554-9.554a4.162 4.162 0 00-5.891 0L69.107 150H50v-49.999h116.665v50H95.832a4.168 4.168 0 00-4.167 4.166c0 2.3 1.867 4.166 4.167 4.166h74.999c2.3 0 4.166-1.867 4.166-4.166z"/>
                <path className="fade" d="M58.333 116.668c0 4.596 3.737 8.334 8.333 8.334 4.595 0 8.333-3.738 8.333-8.334s-3.737-8.333-8.333-8.333-8.333 3.737-8.333 8.333z"/>
                <path className="fade" d="M104.165 208.333h58.332c2.3 0 4.167-1.866 4.167-4.166 0-2.3-1.867-4.167-4.167-4.167h-58.332a4.168 4.168 0 00-4.167 4.167c0 2.3 1.867 4.166 4.167 4.166z"/>
                <path className="fade" d="M45.833 75.002h41.666c2.3 0 4.166-1.867 4.166-4.166 0-2.3-1.867-4.167-4.166-4.167H45.833a4.167 4.167 0 000 8.333z"/>
                <path className="fade" d="M174.997 179.167c0-2.3-1.867-4.166-4.166-4.166h-25.004a4.168 4.168 0 00-4.167 4.166c0 2.3 1.867 4.167 4.167 4.167h25.004c2.3 0 4.166-1.867 4.166-4.167z"/>
            </svg>
            <button className="button is-primary is-large" onClick={onNew}>Create a new Theme</button>
        </div>

    return (
        <div className="runners overflow-hidden">
            <Sidebar 
                items={state.context.items}
                selected={state.context.selectedItemId}
                send={send}
                loading={state.matches('loading')}
                type="Theme"
                allowNew={true}
            />
            {editor}
        </div>
    );
};

export default Runners;
