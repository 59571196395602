/* eslint-disable react/display-name */
import React, { FormEvent } from 'react';
import { IPluginEmail } from '../../../machines/build/plugins/plugin';

interface Props {
    item: IPluginEmail
    onChange: (value: Partial<IPluginEmail>) => void
}

export default ({ item, onChange }: Props) => {

    const onSecureChange = () => onChange({ secure: !item.secure });
    const onHostChange = (e: FormEvent<HTMLInputElement>) => onChange({ host: e.currentTarget.value });
    const onPortChange = (e: FormEvent<HTMLInputElement>) => onChange({ port: e.currentTarget.valueAsNumber });
    const onUsernameChange = (e: FormEvent<HTMLInputElement>) => onChange({ username: e.currentTarget.value });
    const onPasswordChange = (e: FormEvent<HTMLInputElement>) => onChange({ password: e.currentTarget.value });

    return (
        <>
            <div className="field" onClick={onSecureChange}>
                <input type="checkbox" className="switch is-rounded" checked={item.secure} />
                <label>Secure (TLS)</label>
            </div>

            <div className="field">
                <label className="label">Host</label>
                <div className="control">
                    <input className="input" type="text" placeholder="Host" value={item.host} onChange={onHostChange} disabled={item.readonly} />
                </div>
            </div>

            <div className="field">
                <label className="label">Port</label>
                <div className="control">
                    <input className="input" type="number" placeholder="Port" value={item.port} onChange={onPortChange} disabled={item.readonly} />
                </div>
            </div>

            <div className="field">
                <label className="label">Username</label>
                <div className="control">
                    <input className="input" type="text" placeholder="Username" value={item.username} onChange={onUsernameChange} disabled={item.readonly} />
                </div>
            </div>

            <div className="field">
                <label className="label">Password</label>
                <div className="control">
                    <input className="input" type="password" placeholder="Password" value={item.password} onChange={onPasswordChange} disabled={item.readonly} />
                </div>
            </div>

            {/* <button className="button is-info">Test Connection</button> */}
        </>
    );
}