/* eslint-disable react/display-name */
import React, { FormEvent, useState } from 'react';
import VariableFinder from '../variable/VariableFinder';
import VersionableItems from '../versionable/VersionableItems';
import Header from '../editing/Header';
import Dependencies from '../editing/Dependencies';
import History from '../editing/History';
import Delete from './Delete';
import { useActor } from "@xstate/react";
import { Context, Event } from '../../../machines/build/versionable-item';
import _ from 'lodash';
import './action-editor.css';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/markdown/markdown';
import NewVersion from '../editing/NewVersion';
import { ActorRefFrom, StateMachine } from 'xstate';
import ValueSelector from '../variable/ValueSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop } from '@fortawesome/free-solid-svg-icons';
import { IActionApp } from '../../../../../types/action';
import { IDependency, IVersionableReferenceSelected } from '../../../../../types/versionable';
import { IVariable } from '../../../../../types/variable';

export default ({ machine }: { machine: ActorRefFrom<StateMachine<Context<IActionApp>, any, Event<IActionApp>>> }) => {
    const [state, send] = useActor(machine);
    const [isNewVersionVisible, setNewVersionVisible] = useState(false);

    const onVersion = (version: string) => send({ type: 'VERSION', version });
  
    const onNewVersion = () => setNewVersionVisible(true);
    const onCloseNewVersion = () => setNewVersionVisible(false);

    const onNewVersionCreated = () => {
        setNewVersionVisible(false);
        send({ type: 'NEW_VERSION_CREATED' });
    }

    const onNameChange = (e: FormEvent<HTMLInputElement>) => send({ type: 'CHANGE', value: { name: e.currentTarget.value } });
    const onModeChange = (e: FormEvent<HTMLSelectElement>) => send({ type: 'CHANGE', value: { mode: e.currentTarget.value } });
    const onIntoChange = (into: string) => send({ type: 'CHANGE', value: { into } });
    const onAppChange = (app: IVersionableReferenceSelected[]) => send({ type: 'CHANGE', value: { app: app[0] }})

    const onAddParameter = () => send({ type: 'CHANGE', value: { ...state.context.item, inputs: _.concat(state.context.item?.inputs, [{ into: '', value: null }]) } });
    const onRemoveParameter = (index: number) => send({ type: 'CHANGE', value: { ...state.context.item, inputs: state.context.item?.inputs.filter((input, i) => i !== index) } });
    const onChangeParameterInto = (index: number, into: string) => send({
        type: 'CHANGE', 
        value: { 
            ...state.context.item,
            inputs: _.map(state.context.item?.inputs, ((input, i) => index === i ? { ...input, into } : input))
        }
    });
    const onChangeParameterValue = (index: number, value: IVariable | null) => send({
        type: 'CHANGE', 
        value: { 
            ...state.context.item,
            inputs: _.map(state.context.item?.inputs, ((input, i) => index === i ? { ...input, value } : input))
        } 
    });

    const onDependencyClick = (item: IDependency) => send({ type: 'ITEM', itemType: item.type, id: item.id, version: item.version });

    const onDelete = () => send('DELETE');

    return (
        <div className="action-editor">
            <Header
                versions={state.context.versions}
                version={state.context.version}
                onVersion={onVersion}
                onNewVersion={onNewVersion}
                users={state.context.users}
                modified={state.context.item ? state.context.item.modified : null}
            />

            <div className="event p">
                {
                    state.matches('edit') && state.context.item ? (
                        <>
                            <div className="box">
                                <div className="is-flex justify-between mb">
                                    <div>
                                        <FontAwesomeIcon icon={faDesktop} className="mr" />
                                        <strong>Start an App</strong>
                                    </div>
                                    <button className="button is-danger is-small is-outlined" onClick={onDelete} disabled={state.context.item.readonly}>Delete</button>
                                </div>
                                <div className="field mb">
                                    <div className="control">
                                        <input className="input is-large" type="text" placeholder="Action Name" value={state.context.item.name} onChange={onNameChange} disabled={state.context.item.readonly} />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label">App to Start</label>
                                    <VersionableItems
                                        id="apps"
                                        value={state.context.item.app ? [state.context.item.app] : []}
                                        onChange={onAppChange}
                                        type="app"
                                        disabled={state.context.item.readonly} 
                                    />
                                </div>
                                <div className="field">
                                    <label className="label">Mode</label>
                                    <div className="select">
                                        <select value={state.context.item.mode} onChange={onModeChange} disabled={state.context.item.readonly}>
                                            <option value="start">Start</option>
                                            <option value="join">Start and Join</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label">Save Reference To</label>
                                    <VariableFinder org={state.context.org} workspace={state.context.workspace} variable={state.context.item.into} onChange={onIntoChange} disabled={state.context.item.readonly} />
                                </div>
                                
                                <h5 className="title is-5">Inputs</h5>
                                <table className="table is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Value</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _.map(state.context.item.inputs, (input, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <input className="input" value={input.into} onChange={(e) => onChangeParameterInto(index, e.currentTarget.value)} />
                                                    </td>
                                                    <td>
                                                        <ValueSelector
                                                            org={state.context.org}
                                                            workspace={state.context.workspace}
                                                            variable={input.value}
                                                            onChange={variable => onChangeParameterValue(index, variable)}
                                                            small={true}
                                                            disabled={state.context.item.readonly} 
                                                        />
                                                    </td>
                                                    <td>
                                                        <button className="button is-small is-outlined" onClick={onRemoveParameter.bind(null, index)}>Remove</button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <button className="button is-primary is-small is-outlined" onClick={onAddParameter}>Add Input</button>
                            </div>

                            <div className="columns">
                                <div className="column">
                                    <div className="card mb">
                                        <header className="card-header">
                                            <p className="card-header-title">
                                            Uses
                                            </p>
                                        </header>
                                        <div className="card-content">
                                            <Dependencies org={state.context.org} workspace={state.context.workspace} dependencies={state.context.uses} onClick={onDependencyClick} />
                                        </div>
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="card mb">
                                        <header className="card-header">
                                            <p className="card-header-title">
                                            Used By
                                            </p>
                                        </header>
                                        <div className="card-content">
                                            <Dependencies org={state.context.org} workspace={state.context.workspace} dependencies={state.context.usedBy} onClick={onDependencyClick} />
                                        </div>
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="card">
                                        <header className="card-header">
                                            <p className="card-header-title">
                                                History
                                    </p>
                                        </header>
                                        <div className="card-content">
                                            <History versions={state.context.versions} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null
                }
                {
                    isNewVersionVisible ? (
                        <NewVersion
                            org={state.context.org}
                            workspace={state.context.workspace}
                            id={state.context.id}
                            type="action"
                            onNewVersion={onNewVersionCreated}
                            onClose={onCloseNewVersion}
                        />
                        ) : null
                }
                <Delete state={state} send={send} />
            </div>
        </div>
    );
}