import React from "react";
import { useActor } from '@xstate/react';
import { Context, Schema, Event } from '../../machines/reporting/reporting';
import Apps from './apps/Apps';
// import Summary from './Summary';
import './reporting.css';
import { ActorRefFrom, StateMachine } from "xstate";

const Reporting = ({ machine }: { machine: ActorRefFrom<StateMachine<Context, Schema, Event>> | null }) => {
	const [state] = useActor(machine!);

	// let editor: JSX.Element | null = null;
	// switch (true) {
	// 	case state.matches('summary'):
	// 		editor = <Summary usage={state.context.summary} />;
	// 		break;

	// 	case state.matches('apps'):
	// 		editor = <Apps machine={state.context.apps} />;
	// 		break;
	// } 

	return (
		<>
			{/* <div className="tabs is-boxed flex-shrink-0">
				<ul>
					{
						tabs.map((tab: any, index: number) => (
							<li className={state.matches(tab.type) ? 'is-active' : ''} key={tab.type}>
								<a onClick={onClick.bind(null, tab.type)}>
									<span>{tab.title}</span>
								</a>
							</li>
						))
					}
				</ul>
			</div>
			{editor} */}
			<Apps machine={state.context.apps} />
		</>
	);
};

export default Reporting;
