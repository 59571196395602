import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash';
import { subscribe, unsubscribe } from '../versionable/references';

interface Props {
    onChange: (featureFlags: string[]) => void
    value?: string[]
}

export default ({ value, onChange }: Props) => {
    const [featureFlags, setFeatureFlags] = useState([]);
    
    const featureFlagCallback = useCallback((items: any) => {
        setFeatureFlags(items);
    }, [setFeatureFlags]);

    useEffect(() => {
        subscribe('featureFlag', featureFlagCallback);
        return () => unsubscribe('featureFlag', featureFlagCallback);
    }, []);

    const onFeatureFlagClick = (id: string) => {
        onChange([id]);
    }

    const onClearFeatureFlag = () => {
        onChange([]);
    }

    return (
        <div className={classnames('dropdown', 'is-right', 'is-hoverable', 'feature-flags-selector', 'mr')} title="Feature Flags">
            <div className="dropdown-trigger">
                <span className={classnames('icon', { 'has-text-link': !_.isEmpty(value) }, { 'has-text-grey-light': _.isEmpty(value) }, { 'has-background-grey-lighter': !_.isEmpty(value) })} aria-haspopup="true" role="button">
                    <FontAwesomeIcon icon={faFlag} />
                </span>
            </div>
            <div className="dropdown-menu" role="menu">
                <div className="dropdown-content">
                    <a className="dropdown-item" onClick={onClearFeatureFlag}>Remove Feature Flag</a>
                    <hr className="dropdown-divider" />
                    {
                        _.map(featureFlags, (featureFlag: any) => {
                            const isSelected = _.includes(value, featureFlag.id);
                        
                            return (
                                <a className={classnames('dropdown-item', { 'is-active': isSelected })} onClick={() => onFeatureFlagClick(featureFlag.id)} key={featureFlag.id}>
                                    {featureFlag.name}
                                </a>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    )
}