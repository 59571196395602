import React, { FormEvent } from 'react';
import Rule from './Rule';
import _ from 'lodash';
import { IRule, IRuleGroup } from '../../../../../types/rule';

interface INestedGroupProps {
    org: string
    workspace: string
    value: IRuleGroup
    onChange: (value: IRuleGroup) => void
    onDelete: ((value: IRuleGroup) => void) | null
    disabled: boolean
}

const NestedGroup = ({ org, workspace, value, onChange, onDelete, disabled }: INestedGroupProps) => {

    const onGroupingChange = (e: FormEvent<HTMLSelectElement>) => onChange({ ...value, grouping: e.currentTarget.value });
    
    const onAddRule = () => onChange({
        ...value,
        rules: _.concat(value.rules, { comparison: '=', left: null, right: null, order: _.size(value.rules) + _.size(value.groups) })
    });
    
    const onAddGroup = () => onChange({
        ...value,
        groups: _.concat(value.groups, { grouping: 'ANY', rules: [], groups: [], order: _.size(value.rules) + _.size(value.groups) })
    })
    
    const onDeleteGroup = (group: IRuleGroup) => {
        const groups = [...value.groups];
        groups.splice(group.order, 1);
        onChange({ ...value, groups });
    }

    const onDeleteRule = (rule: IRule) => {
        const rules = [...value.rules];
        rules.splice(rule.order, 1);
        onChange({ ...value, rules });
    }

    const onGroupChange = (group: IRuleGroup) => {
        const groups = [...value.groups];
        groups[group.order] = group;
        onChange({ ...value, groups });
    }

    const onRuleChange = (rule: IRule) => {
        const rules = [...value.rules];
        rules[rule.order] = rule;
        onChange({ ...value, rules });
    }

    return (
        <div className="group">   
            <div className="is-flex justify-between mb">
                <div className="select">
                    <select value={value.grouping} onChange={onGroupingChange} disabled={disabled}>
                        <option value="all">All</option>
                        <option value="any">Any</option>
                    </select>
                </div>
                {onDelete !== null && <button className="button is-outlined" onClick={onDelete.bind(null, value)} disabled={disabled}>Delete Group</button>}
            </div>
            {
                _.chain(value.rules)
                    .concat(value.groups as any)
                    .sortBy('order')
                    .map((item: any, index: number) => {
                        if (item.grouping)
                            return (
                                <NestedGroup
                                    org={org}
                                    workspace={workspace}
                                    value={item}
                                    onChange={onGroupChange}
                                    onDelete={onDeleteGroup}
                                    disabled={disabled}
                                    key={index} 
                                />
                            );
                        else
                        return (
                            <Rule
                                org={org}
                                workspace={workspace}
                                value={item}
                                onChange={onRuleChange}
                                onDelete={onDeleteRule}
                                disabled={disabled}
                                key={index} 
                            />
                        );
                    })
                    .value()                    
            }
            <div className="buttons">
                <button className="button is-info" onClick={onAddRule} disabled={disabled}>Add Rule</button>
                <button className="button is-info" onClick={onAddGroup} disabled={disabled}>Add Group</button>
            </div>
        </div>
    );
}

export default NestedGroup;