/* eslint-disable react/display-name */
import React, { FormEvent, useState } from "react";
import classnames from 'classnames';
import "./screen.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import Grid from "./Grid";
import Component from './Component';
import Header from '../editing/Header';
import { useActor } from "@xstate/react";
import { Context, Event } from '../../../machines/build/versionable-item';
import NewVersion from "../editing/NewVersion";
import History from '../editing/History';
import Delete from '../actions/Delete';
import Dependencies from "../editing/Dependencies";
import { ActorRefFrom, StateMachine } from "xstate";
import { IComponent, IDependency, ILayout, IScreen } from "../../../interfaces";
import _ from "lodash";
import { customAlphabet } from "nanoid";

export default ({ machine }: { machine: ActorRefFrom<StateMachine<Context<IScreen>, any, Event<IScreen>>> }) => {
    const [state, send] = useActor(machine);
    const [tab, setTab] = React.useState(0);
    const [isNewVersionVisible, setNewVersionVisible] = useState(false);
    const [device, setDevice] = React.useState('lg');
    const [selectedComponentId, setSelectedComponentId] = React.useState<string | null>(null);

    const onVersion = (version: string) => send({ type: 'VERSION', version });
  
    const onNewVersion = () => setNewVersionVisible(true);
    const onCloseNewVersion = () => setNewVersionVisible(false);

    const onNewVersionCreated = () => {
        setNewVersionVisible(false);
        send({ type: 'NEW_VERSION_CREATED' });
    }

    const onNameChange = (e: FormEvent<HTMLInputElement>) => send({ type: 'CHANGE', value: { name: e.currentTarget.value } });
    const onTitleChange = (e: FormEvent<HTMLInputElement>) => send({ type: 'CHANGE', value: { title: e.currentTarget.value } });
    const onSubtitleChange = (e: FormEvent<HTMLInputElement>) => send({ type: 'CHANGE', value: { subtitle: e.currentTarget.value } });
    
    const onAddComponent = () => {
        if (!state.context.item)
            return null;

        const getLastItem = (device: string) => {
            const item = _.last(
                _.values(state.context.item?.components).sort(
                    (a, b) => (a.layouts[device].y + a.layouts[device].h) - (b.layouts[device].y + b.layouts[device].h)
                )
            )
    
            return item ? item.layouts[device] : { y: 0, h: 0 }
        }

        const lastItems = {
            lg: getLastItem('lg'),
            md: getLastItem('md'),
            sm: getLastItem('sm')
        };

        const id = customAlphabet('abcdefghijklmnopqrstuvwxyz', 7)();

        const layout = { i: id, x: 0, y: 0, w: 12, h: 1 };

        send({
            type: 'CHANGE',
            value: {
                components: {
                    ...state.context.item.components,
                    [id]: {
                        id,
                        name: '',
                        type: 'text',
                        layouts: {
                            lg: { ...layout, y: lastItems.lg.y + lastItems.lg.h},
                            md: { ...layout, y: lastItems.md.y + lastItems.md.h},
                            sm: { ...layout, y: lastItems.sm.y + lastItems.sm.h}
                        },
                        properties: {},
                        triggers: {},
                        featureFlags: []
                    }
                }
            }
        });

        setSelectedComponentId(id);
    }

    const onComponentChange = (component: IComponent) => {
        send({ 
            type:'CHANGE',
            value: {
                components: {
                    ...state.context.item?.components,
                    [component.id]: {
                        ...component
                    }
                }
            }
        });
    };

    const onComponentDelete = (component: IComponent) => {
        send({
            type: 'CHANGE',
            value: {
                components: _.omit(state.context.item?.components, component.id)
            }
        })
    };

    const onLayoutChange = (layouts: Record<string, ILayout>) => {
        send(
            {
                type: 'CHANGE',
                value: {
                    components: _.mapValues(state.context.item?.components, (component) => {
                        return {
                            ...component,
                            layouts: {
                                ...component.layouts,
                                [device]: _.omit(layouts[component.id], 'i') as ILayout
                            }
                        }
                    })
                }
            }
        )
    }

    const onDependencyClick = (item: IDependency) => send({ type: 'ITEM', itemType: item.type, id: item.id, version: item.version });

    const onDelete = () => send('DELETE');

    return (
        <div className="screen-editor flex-grow">
            <Header
                versions={state.context.versions}
                version={state.context.version}
                onVersion={onVersion}
                onNewVersion={onNewVersion}
                users={state.context.users}
                modified={state.context.item ? state.context.item.modified : null}
            />
            <div className="screen overflow-hidden">
                {
                    state.matches('edit') && state.context.item ? (
                        <>
                            <Grid
                                device={device}
                                screen={state.context.item}
                                selectedComponentId={selectedComponentId}
                                onAddComponent={onAddComponent}
                                onDeSelectComponent={() => setSelectedComponentId(null)}
                                onSelectComponent={(id: string) => setSelectedComponentId(id)}
                                onLayoutChange={onLayoutChange}
                                onSelectDevice={setDevice}
                            />
                            <div className="sidebar">
                                {
                                    selectedComponentId ?
                                        <Component
                                            org={state.context.org}
                                            workspace={state.context.workspace}
                                            component={state.context.item.components[selectedComponentId]}
                                            onChange={onComponentChange}
                                            onDelete={onComponentDelete}
                                            disabled={state.context.item.readonly}
                                            key={selectedComponentId} 
                                        /> :
                                        <div className="p">
                                            <div className="is-flex mb">
                                                <input name="screen-name" className="input is-large mr" value={state.context.item.name} onChange={onNameChange} disabled={state.context.item.readonly}></input>
                                                <button className="button is-danger is-small is-outlined" onClick={onDelete} disabled={state.context.item.readonly}>Delete</button>
                                            </div>

                                            <div className="tabs options">
                                                <ul>
                                                    <li className={classnames({ 'is-active': tab === 0})}><a onClick={setTab.bind(null, 0)}>Settings</a></li>
                                                    <li className={classnames({ 'is-active': tab === 4})}><a onClick={setTab.bind(null, 1)}>References</a></li>
                                                    <li className={classnames({ 'is-active': tab === 5})}><a onClick={setTab.bind(null, 2)}>History</a></li>
                                                </ul>
                                            </div>

                                            {
                                                tab === 0 ? (
                                                    <>
                                                        <div className="field">
                                                            <label className="label">Title</label>
                                                            <div className="control">
                                                                <input className="input" type="text" placeholder="Title" value={state.context.item.title} onChange={onTitleChange} disabled={state.context.item.readonly} />
                                                            </div>
                                                        </div>
                                                        <div className="field">
                                                            <label className="label">Subtitle</label>
                                                            <div className="control">
                                                                <input className="input" type="text" placeholder="Title" value={state.context.item.subtitle} onChange={onSubtitleChange} disabled={state.context.item.readonly} />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : null
                                            }

                                            {tab === 1 ?  (
                                                <>
                                                    <h4 className="is-size-4 mb">Uses</h4>
                                                    <Dependencies org={state.context.org} workspace={state.context.workspace} dependencies={state.context.uses} onClick={onDependencyClick} />

                                                    <h4 className="is-size-4 mb">Used By</h4>
                                                    <Dependencies org={state.context.org} workspace={state.context.workspace} dependencies={state.context.usedBy} onClick={onDependencyClick} />
                                                </>
                                            ) : null}

                                            {tab === 2 ? <History versions={state.context.versions} /> : null}
                                        </div>
                                }
                            </div>
                        </>
                    ) : null
                }
                {
                    isNewVersionVisible ? (
                        <NewVersion
                            org={state.context.org}
                            workspace={state.context.workspace}
                            id={state.context.id}
                            type="screen"
                            onNewVersion={onNewVersionCreated}
                            onClose={onCloseNewVersion}
                        />
                        ) : null
                }
                <Delete state={state} send={send} />
            </div>
        </div>
    );
};
