import React from 'react';
import './component.css';
import properties from '../../../machines/build/screens/properties';
import ValueSelector from '../variable/ValueSelector';
import Columns from './Columns';
import Tabs from './Tabs';
import Datasets from './Datasets';
import VersionableItems from '../versionable/VersionableItems';
import Parameters from './Parameters';
import Groups from './Groups';
import { IVariable } from '../../../../../types/variable';
import { IVersionableReferenceSelected } from '../../../../../types/versionable';
import VariableFinder from '../variable/VariableFinder';
import ChartLabels from './ChartLabels';
import IconPicker from '../IconPicker';
import { IComponentPropertyParameters } from '../../../machines/build/screens/screen';

interface IProps {
    org: string
    workspace: string
    componentType: string
    id: string
    kind: string
    value: any | null
    onChange: (id: string, value: any) => void
    disabled: boolean
    parameters?: IComponentPropertyParameters
}

export default ({ org, workspace, componentType, id, kind, value, parameters, onChange, disabled } : IProps) => {

    const onVariableChange = (variable: string) =>  onChange(id, { type: 'variable', path: variable });
    const onValueChange = (value: IVariable | null) => onChange(id, value);
    const onScreenChange = (value: IVersionableReferenceSelected[]) => onChange(id, value);
    const onActionChange = (value: IVersionableReferenceSelected[]) => onChange(id, value);
    const onParametersChange = (parameters: IComponentPropertyParameters) => onChange(id, { ...value, parameters });
    const onOptionChange = (e: React.FormEvent<HTMLSelectElement>) => onChange(id, e.currentTarget.value);
    const onColumnsChange = (columns: any[]) => onChange(id, { columns, auto: false });
    const onToggleAutoCreateColumns = (autoCreate: boolean) => onChange(id, { auto: autoCreate });
    const onTabsChange = (tabs: any[]) => onChange(id, tabs);
    const onIconChange = (icon: string) => onChange(id, icon);
    const onGroupsChange = (groups: string[]) => onChange(id, groups);
    const onChartLabelsChange = (labels: string[]) => onChange(id, labels);
    const onDatasetsChange = (datasets: any[]) => onChange(id, datasets);

    const { name, options, type } = properties[componentType][id];
    
    let editor: JSX.Element | null = null;

    switch (kind.toUpperCase()) {
        case 'VARIABLE':
            editor = <VariableFinder org={org} workspace={workspace} variable={value?.path || ''} onChange={onVariableChange} disabled={disabled} />
            break;

        case 'VALUE':
            editor = <ValueSelector
                org={org}
                workspace={workspace}
                variable={value}
                onChange={onValueChange}
                disabled={disabled}
                small={true}
                allowRules={true}
                type={type}
            />
            break;

        case 'COLUMNS':
            editor = (
                <Columns
                    value={value}
                    onChange={onColumnsChange}
                    onToggleAutoCreate={onToggleAutoCreateColumns}
                    disabled={disabled} 
            />);
            break;

        case 'OPTIONS':
            editor = (
                <div className="select is-fullwidth">
                    <select onChange={onOptionChange} value={value} disabled={disabled}>
                        <option>Select an option</option>
                        {options.map((option: any) => <option value={option.value} key={option.value}>{option.name}</option>)}
                    </select>
                </div>
            );
            break;

        case 'SCREEN':
            editor = <VersionableItems
                id={id}
                value={value ? [value] : []}
                onChange={onScreenChange}
                type="screen" 
                disabled={disabled} 
            />
            break;

        case 'ACTION':
            editor = (
                <>
                    <VersionableItems
                        id={id}
                        value={value ? [value] : []}
                        onChange={onActionChange}
                        type="action" 
                        disabled={disabled} 
                    />
                    <Parameters value={parameters || {}} propertyId={id} onChange={onParametersChange} disabled={disabled} />
                </>
            )
            break;

        case 'TABS':
            editor = <Tabs value={value} onChange={onTabsChange} disabled={disabled} />
            break;

        case 'GROUPS':
            editor = <Groups value={value} onChange={onGroupsChange} disabled={disabled} />
            break;
        
        case 'ICON':
            editor = <IconPicker value={value} onChange={onIconChange} disabled={disabled} />
            break;

        case 'DATASETS':
            editor = <Datasets org={org} workspace={workspace} value={value} onChange={onDatasetsChange} disabled={disabled} />
            break;
        
        case 'LABELS':
            editor = <ChartLabels value={value} onChange={onChartLabelsChange} disabled={disabled} />
            break;
    }

    return (
        <div className="page-component-property mb" data-testid={`${id}-component-property`}>
            <h4 className="is-size-6 flex-grow mb" style={{ alignSelf: 'flex-end' }}><strong>{name}</strong></h4>
            {editor}
        </div>
    );
}
