import { applyPatch, compare } from 'fast-json-patch';
import _ from 'lodash';
import { actions, Sender, Receiver, createMachine } from 'xstate';
import { getAll, subscribe, unsubscribe } from '../../../components/build/versionable/references';
import { deployment } from '../../../socket';

const { assign, send, cancel } = actions;

export interface Schema {
    states: {
        loading: {},
        edit: {},
        error: {}
    }
}

type ChangeEvent = { type: 'CHANGE', value: Partial<IEnvironment> };
type PatchedEvent = { type: 'PATCHED' };
type ChangedEvent = { type: 'CHANGED', patch: any[] }
type DismissEvent = { type: 'DISMISS' }
type LoadedEvent = { type: 'LOADED', environment: IEnvironment, releases: IRelease[], featureFlags: IFeatureFlag[] }
type FeatureFlagsEvent = { type: 'FEATURE_FLAGS', featureFlags: IFeatureFlag[] }

export type Event =
    | LoadedEvent
    | PatchedEvent
    | ChangedEvent
    | DismissEvent
    | ChangeEvent
    | FeatureFlagsEvent

interface IEnvironment {
    id: string
    name: string
    default: boolean
    protected: boolean
    variables: {
        [name: string]: string   
    }
    featureFlags: string[]
}

interface IRelease {
    id: string
    version: string
    by: string
    when: string
}

interface IFeatureFlag {
    id: string
    name: string
}

export interface Context {
    error?: string | null
    id: string
    oldEnvironment?: IEnvironment
    environment?: IEnvironment
    releases?: IRelease[]
    featureFlags?: IFeatureFlag[]
    machine?: any
    org: string
    workspace: string
}

const realtime = (context: Context, event: Event) => (callback: Sender<Event>, onReceive: Receiver<Event>) => {
    const onLoad = (response: any) => {
        callback({ type: 'LOADED', environment: response.environment, releases: response.releases, featureFlags: getAll('featureFlag') });
    }

    deployment.environment.environment(context.org, context.workspace, context.id, onLoad);

    onReceive((e: any) => {
        switch (e.type) {
            case 'PATCH':
                deployment.environment.change(context.org, context.workspace, context.id, e.patches);
                callback('PATCHED');
                break;
        }
    })

    const featureFlags = (featureFlags: IFeatureFlag[]) => callback({ type: 'FEATURE_FLAGS', featureFlags });
    subscribe('featureFlag', featureFlags);

    return () => {
        unsubscribe('featureFlag', featureFlags)
    };
}

export const machine = createMachine<Context, Event>({
    schema: {
        context: {} as Context,
        events: {} as Event
    },
    id: 'deployment-environment',
    initial: 'loading',
    invoke: {
        id: 'socket',
        src: realtime
    },
    states: {
        loading: {
            on: {
                LOADED: {
                    actions: ['loaded', 'copy'],
                    target: 'edit'
                }
            }
        },
        edit: {
            on: {
                CHANGE: {
                    actions: ['change', 'cancelPatch', 'patch']
                },
                PATCHED: {
                    actions: ['copy']
                },
                CHANGED: [
                    { actions: 'changed' },
                ],
                FEATURE_FLAGS: {
                    actions: 'featureFlag'
                }
            }
        },
        error: {
            on: {
                DISMISS: 'edit'
            }
        }
    }
}, {
    actions: {
        loaded: assign<Context, any>({
            environment: (_, event) => event.environment,
            releases: (_, event) => event.releases,
            featureFlags: (context, event) => event.featureFlags
        }),
        change: assign<Context, any>({
            environment: (context, event) => ({ ...context.environment, ...event.value } as IEnvironment)
        }),
        patch: send(
            (context, event) => ({
                type: 'PATCH',
                environment: _.get(context, 'environment.id'),
                patches: compare(context.oldEnvironment!, context.environment!),
            }),
            {
                id: 'patch',
                to: 'socket',
                delay: 300
            }
        ),
        cancelPatch: cancel('patch'),
        changed: assign<Context, any>({
            environment: (context, event) => ({
                ...context.environment,
                ...applyPatch(context.environment, event.patch).newDocument
            } as IEnvironment),
        }),
        copy: assign({
            oldEnvironment: (context) => context.environment
        }),
        featureFlags: assign<Context, any>({
            featureFlags: (context, event) => event.featureFlags
        })
    }
})