import React, { FormEvent } from 'react';
import { isValid, parseISO } from 'date-fns';
import './input-datetime.css';

interface IInputDateTimeProps {
    value: string
    disabled: boolean
    onChange: (value: any) => void
    autoFocus?: boolean
}

const isTime = (value: string) => {
    return value ? value.indexOf('T') !== -1 : false
}

const getDate = (value: string) => {
    return value && value.split('T')[0];
}

const getTime = (value: string) => {
    return value && value.split('T')[1].substring(0, 5);
}
class InputDateTime extends React.Component<IInputDateTimeProps, any> {

    onTypeChange = (e: FormEvent<HTMLSelectElement>) => {
        if (e.currentTarget.value === 'datetime')
            this.props.onChange("");
        else
            this.props.onChange(e.currentTarget.value);
    }

    onIsTimeChange = () => {
        if (isTime(this.props.value)) {
            this.props.onChange(this.props.value.split('T')[0]);
        }
        else {
            this.props.onChange(`${this.props.value}T00:00:00Z`);
        }
    }

    onDateChange = (e: FormEvent<HTMLInputElement>) => {
        if (isTime(this.props.value)) {
            this.props.onChange(`${e.currentTarget.value}T${getTime(this.props.value)}:00Z`);
        }
        else
            this.props.onChange(e.currentTarget.value);
    }

    onTimeChange = (e: FormEvent<HTMLInputElement>) => {
        this.props.onChange(`${getDate(this.props.value)}T${e.currentTarget.value}:00Z`);
    }

    public render() {
        const isDateTime = isValid(parseISO(this.props.value));

        let date = '';
        let time = '';

        if (isDateTime) {
            date = getDate(this.props.value);
            time = getTime(this.props.value);
        }

        return (
            <div className="input-datetime is-flex flex-grow">
                {
                    !isDateTime ?
                    (
                        <div className="select">
                            <select value={this.props.value} onChange={this.onTypeChange} disabled={this.props.disabled}>
                                <option>Select an option</option>
                                <option value="__NOW__">Now</option>
                                <option value="__TODAY__">Today</option>
                                <option value="__YESTERDAY__">Yesterday</option>
                                <option value="__TOMORROW__">Tomorrow</option>
                                <option value="__MONDAY__">Monday</option>
                                <option value="__TUESDAY__">Tuesday</option>
                                <option value="__WEDNESDAY__">Wednesday</option>
                                <option value="__THURSDAY__">Thursday</option>
                                <option value="__FRIDAY__">Friday</option>
                                <option value="__SATURDAY__">Saturday</option>
                                <option value="__SUNDAY__">Sunday</option>
                                <option value="__WEEKDAY__">Weekday</option>
                                <option value="__WEEKEND__">Weekend</option>
                                <option value="datetime">DateTime</option>
                            </select>
                        </div>
                    ) : null
                }
                {
                    isDateTime ? 
                    (
                        <input className="input" type="date" value={date} onChange={this.onDateChange} disabled={this.props.disabled} autoFocus={this.props.autoFocus} />
                    ) : null
                }
                {
                    isDateTime ? 
                    (
                        <div className="field" onClick={this.onIsTimeChange}>
                            <input type="checkbox" className="switch is-rounded" checked={isTime(this.props.value)} disabled={this.props.disabled} autoFocus={this.props.autoFocus} />
                            <label>Time</label>
                        </div>
                    ) : null
                }
                {
                    isDateTime && isTime(this.props.value) ? 
                    (
                        <input className="input" type="time" value={time} onChange={this.onTimeChange} disabled={this.props.disabled} autoFocus={this.props.autoFocus} />
                    ) : null
                }
            </div>
        );
    }
}

export default InputDateTime;