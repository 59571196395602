/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult
} from "react-beautiful-dnd";
import Select from 'react-select'
import { IVersionableReferenceSelected, IVersionableReference } from '../../../../../types/versionable';
import VersionableItem from './VersionableItem';
import './versionable-items.css';
import { subscribe, unsubscribe } from './references';
import arrayMove from 'array-move';
import _ from 'lodash';

interface Props {
    id: string
    value: IVersionableReferenceSelected[]
    onChange: (value: IVersionableReferenceSelected[]) => void
    onEdit?: (id: string, version: string, type: string, kind?: string) => void
    type: string
    disabled: boolean
    multiple?: boolean
    canDisable?: boolean
    canBreakpoint?: boolean
    canFeatureFlag?: boolean
}

export default ({ id, value, onChange, onEdit, type, multiple, canDisable, canBreakpoint, canFeatureFlag, disabled }: Props) => {
    const [items, setItems] = useState<IVersionableReference[]>([]);
    const [highlight, setHighlight] = useState<string>('');

    useEffect(() => {
        const reload = (items: IVersionableReference[]) => setItems(items);
        subscribe(type, reload);
        return () => unsubscribe(type, reload);
    }, []);

    const onDragEnd = (result: DropResult) => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }
        
        onChange(arrayMove<IVersionableReferenceSelected>(value, source.index, destination.index));
    };

    const onHighlight = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
        if (highlight !== id) {
            setHighlight(id);
            e.currentTarget.focus();
        }
        else
            setHighlight('');
    }

    const onVersion = (e: React.ChangeEvent<HTMLSelectElement>) => {
        onChange(value.map(item => item.id === e.target.getAttribute('data-id') ? { ...item, version: e.target.value } : item));
    }

    const onRemove = (id: string) => {
        if (!disabled)
            onChange(value.filter(item => item.id !== id));
    }

    const onSelect = (newValue: any) => {
        if (!disabled) {
            const item = _.find(items, { id: newValue.value });

            if (item)
                onChange(value.concat([{ id: item.id, version: item.versions[0], grouped: false, kind: item.kind, disabled: false, featureFlags: [], breakpoint: false }]));
        }
    }

    const onGroup = (id: string) => {
        if (!disabled)
            onChange(value.map(item => item.id === id ? { ...item, grouped: true } : item));
    }

    const onUnGroup = (id: string) => {
        if (!disabled)
            onChange(value.map(item => item.id === id ? { ...item, grouped: false } : item));
    }

    const onDoubleClick = (id: string, version: string, kind?: string) => {
        onEdit?.(id, version, type, kind);
    }

    const onDisable = (id: string) => {
        if (!disabled)
            onChange(value.map(item => item.id === id ? { ...item, disabled: !item.disabled } : item));
    }

    const onBreakpoint = (id: string) => {
        if (!disabled)
            onChange(value.map(item => item.id === id ? { ...item, breakpoint: !item.breakpoint } : item));
    }

    const onFeatureFlag = (id: string, featureFlags: string[]) => {
        if (!disabled)
            onChange(value.map(item => item.id === id ? { ...item, featureFlags } : item));
    }

    const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (highlight) {
            const item = _.find(value, { id: highlight });
            if (item)
                item.grouped ? onUnGroup(item.id) : onGroup(item.id);
        }
    }

    if (items.length === 0)
        return null;

    let selector: JSX.Element | null = null;

    if (multiple || value.length === 0) {
        const options = items!
            .filter((item: IVersionableReference) => !value.some(selected => item.id === selected.id))
            .sort((a, b) => {
                if ( a.name < b.name )
                    return -1;

                if ( a.name > b.name )
                    return 1;

                return 0;
            })
            .map((item: IVersionableReference) => ({ value: item.id, label: item.name }));

        selector = <Select options={options} onChange={onSelect} placeholder={`Select a ${type}`} className="versionable-item-select" data-testid={`${id}-versionable-item-selector`} />
    }
    
    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={id}>
                    {(provided, snapshot) => (
                        <div
                            className="panel versionable-items"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            onKeyPress={onKeyPress}
                        >
                            {value.map((item: IVersionableReferenceSelected, index: number) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                >
                                    {
                                        (provided, snaphost) => {
                                            const className = classnames(
                                                'panel-block is-flex items-center versionable-item',
                                                { grouped: item.grouped },
                                                { 'is-active': highlight === item.id }
                                            )
                                        
                                            return (
                                                <div
                                                    key={item.id}
                                                    className={className}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    onClick={(e) => onHighlight(e, item.id)}
                                                    onDoubleClick={(e) => onDoubleClick(item.id, item.version, items?.find(i => i.id === item.id)?.kind)}
                                                    tabIndex={index}
                                                >
                                                    <VersionableItem
                                                        item={{
                                                            ...item,
                                                            ...items!.find(i => i.id === item.id)
                                                        } as any}
                                                        version={item.version}
                                                        grouped={item.grouped}
                                                        disabled={disabled}
                                                        onVersion={onVersion}
                                                        onRemove={onRemove}
                                                        onDisable={canDisable ? onDisable : undefined}
                                                        onBreakpoint={canBreakpoint ? onBreakpoint : undefined}
                                                        onFeatureFlag={canFeatureFlag && onFeatureFlag as any}
                                                    />
                                                </div>
                                                                            
                                            );
                                        }
                                    }
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {selector}
        </>
    );
}