import React, { FormEvent, useState } from "react";
import classnames from 'classnames';
import './login.css';

export default ({ send, org, error }: any) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const onNameChange = (e: FormEvent<HTMLInputElement>) => setName(e.currentTarget.value);
    const onDescriptionChange = (e: FormEvent<HTMLTextAreaElement>) => setDescription(e.currentTarget.value);
    const onCreate = () => send({ type: 'WORKSPACES.CREATE', org, name, description });

    return (
        <div className="box orgs">
            <h2 className="is-size-4 has-text-centered mb">Create a workspace</h2>
            <div className="field">
                <label className="label">Name</label>
                <div className="control mb">
                    <input className={classnames('input', { 'is-danger': error })} type="text" placeholder="Name" value={name} onChange={onNameChange}/>
                </div>
                {error ? <p className="help is-danger">{error}</p> : null}
            </div>
            <div className="field">
                <label className="label">Description (optional)</label>
                <div className="control mb">
                    <textarea className="textarea" placeholder="Description" value={description} onChange={onDescriptionChange}/>
                </div>
            </div>
            <button className="button is-success" onClick={onCreate} disabled={!name}>Create</button>
        </div>
    );
}