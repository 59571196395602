import _ from 'lodash'

export const p = {
    content: {
        id: 'content',
        name: 'Content',
        kind: 'value',
        type: 'markdown',
    },
    label: {
        id: 'label',
        name: 'Label',
        kind: 'value',
        type: 'string', 
    },
    placeholder: {
        id: 'placeholder',
        name: 'Placeholder',
        kind: 'value',
        type: 'string',
    },
    required: {
        id: 'required',
        name: 'Required',
        kind: 'value',
        type: 'boolean',
    },
    disabled: {
        id: 'disabled',
        name: 'Disabled',
        kind: 'value',
        type: 'boolean'
    },
    enabled: {
        id: 'enabled',
        name: 'Enabled',
        kind: 'value',
        type: 'boolean'
    },
    readonly: {
        id: 'readonly',
        name: 'Read-Only',
        kind: 'value',
        type: 'boolean'
    },
    visible: {
        id: 'visible',
        name: 'Visible',
        kind: 'value',
        type: 'boolean'
    },
    hidden: {
        id: 'hidden',
        name: 'Hidden',
        kind: 'value',
        type: 'boolean'
    },
    help: {
        id: 'help',
        name: 'Help',
        kind: 'value',
        type: 'string'
    },
    rows: {
        id: 'rows',
        name: 'Rows',
        kind: 'value',
        type: 'number'
    },
    min: {
        id: 'min',
        name: 'Minimum',
        kind: 'value',
        type: 'number'
    },
    max: {
        id: 'max',
        name: 'Maximum',
        kind: 'value',
        type: 'number'
    },
    step: {
        id: 'step',
        name: 'Step',
        kind: 'value',
        type: 'number'
    },
    columns: {
        id: 'columns',
        name: 'Columns',
        kind: 'columns'
    },
    labelKey: {
        id: 'labelKey',
        name: 'Label Property',
        kind: 'value',
        type: 'string'
    },
    valueKey: {
        id: 'valueKey',
        name: 'Value Property',
        kind: 'value',
        type: 'string'
    },
    size: {
        id: 'size',
        name: 'Size',
        kind: 'options',
        options: [
            { name: 'Small', value: 'small' },
            { name: 'Normal', value: 'normal' },
            { name: 'Medium', value: 'medium' },
            { name: 'Large', value: 'large'}
        ],
        default: 'normal'
    },
    color: {
        id: 'color',
        name: 'Color',
        kind: 'options',
        options: [
            { name: 'Primary', value: 'primary' },
            { name: 'Secondary', value: 'secondary' },
            { name: 'Success', value: 'success' },
            { name: 'Warning', value: 'warning'},
            { name: 'Danger', value: 'danger'}
        ],
        default: 'primary'
    },
    screen: {
        id: 'screen',
        name: 'Screen',
        kind: 'screen'
    },
    dataSource: {
        id: 'dataSource',
        name: 'Data Source',
        // kind: [
        //     { name: 'Value', id: 'value' },
        //     { name: 'Action', id: 'action' }
        // ]
        kind: 'value'
    },
    dataSourceAction: {
        id: 'dataSourceAction',
        name: 'Data Source Action',
        kind: 'action'
    },
    validate: {
        id: 'validate',
        name: 'Validate',
        kind: 'value',
        type: 'boolean'
    },
    searchable: {
        id: 'searchable',
        name: 'Searchable',
        kind: 'value',
        type: 'boolean'
    },
    context: {
        id: 'context',
        name: 'Context',
        kind: 'variable'
    },
    alignment: {
        id: 'alignment',
        name: 'Alignment',
        kind: 'options',
        options: [
            { name: 'Left', value: 'left' },
            { name: 'Centered', value: 'centered' },
            { name: 'Right', value: 'right' }
        ]
    },
    tabStyle: {
        id: 'tabStyle',
        name: 'Style',
        kind: 'options',
        options: [
            { name: 'Default', value: '' },
            { name: 'Box', value: 'box' },
            { name: 'Toggle', value: 'toggle' },
            { name: 'Toggle - Rounded', value: 'toggle-rounded'}
        ]
    },
    fullWidth: {
        id: 'fullWidth',
        name: 'Full Width',
        kind: 'value',
        type: 'boolean'
    },
    tabs: {
        id: 'tabs',
        name: 'Tabs',
        kind: 'tabs'
    },
    groups: {
        id: 'groups',
        name: 'Groups',
        kind: 'group'
    },
    class: {
        id: 'class',
        name: 'Class',
        kind: 'value',
        type: 'string',
    },
    title: {
        id: 'title',
        name: 'Title',
        kind: 'value',
        type: 'string',
    },
    uploadAction: {
        id: 'uploadAction',
        name: 'Upload Action',
        kind: 'action'
    },
    url: {
        id: 'url',
        name: 'Url',
        kind: 'value',
        type: 'string'
    },
    style: {
        id: 'style',
        name: 'Style',
        kind: 'options',
        options: [
            { name: 'Filled', value: 'filled' },
            { name: 'Outline', value: 'outline' }
        ],
        default: 'filled'
    },
    itemClass: {
        id: 'itemClass',
        name: 'Item Class',
        kind: 'value',
        type: 'string'
    },
    valueUnknown: {
        id: 'valueUnknown',
        name: 'Value',
        kind: 'value',
        type: 'unknown'
    },
    selectedProperty: {
        id: 'selectedProperty',
        name: 'Selected Property',
        kind: 'value',
        type: 'string'
    },
    width: {
        id: 'width',
        name: 'Width',
        kind: 'value',
        type: 'number'
    },
    height: {
        id: 'height',
        name: 'Height',
        kind: 'value',
        type: 'number'
    },
    open: {
        id: 'open',
        name: 'Open',
        kind: 'value',
        type: 'boolean'
    },
    icon: {
        id: 'icon',
        name: 'Icon',
        kind: 'icon'
    },
    wrap: {
        id: 'wrap',
        name: 'Wrap',
        kind: 'value',
        type: 'number'
    },
    chartLabels: {
        id: 'chartLabels',
        name: 'Labels',
        kind: 'labels'
    },
    datasets: {
        id: 'datasets',
        name: 'Datasets',
        kind: 'datasets'
    }
}

const value = () => ({
    value: {
        id: 'value',
        name: 'Value',
        kind: 'variable',
        default: true
    }
})

const withValue = (props: string[]) => {
    return {
        ..._.pick(p, props.sort()),
        ...value()
    }
}

export default {
    '': {},
    text: withValue(['label', 'placeholder', 'required', 'disabled', 'enabled', 'readonly', 'help', 'hidden', 'visible', 'tab', 'class']),
    textarea: withValue(['label', 'placeholder', 'required', 'disabled', 'enabled', 'readonly', 'help', 'rows', 'tab', 'class', 'hidden', 'visible']),
    number: withValue(['label', 'placeholder', 'required', 'disabled', 'enabled', 'readonly', 'help', 'min', 'max', 'step', 'tab', 'class', 'hidden', 'visible']),
    slider: withValue(['label', 'required', 'disabled', 'enabled', 'readonly', 'help', 'min', 'max', 'step', 'tab', 'class', 'hidden', 'visible']),
    date: withValue(['label', 'placeholder', 'required', 'disabled', 'enabled', 'readonly', 'help', 'min', 'max', 'tab', 'class', 'hidden', 'visible']),
    'date-time': withValue(['label', 'placeholder', 'required', 'disabled', 'enabled', 'readonly', 'help', 'min', 'max', 'tab', 'class', 'hidden', 'visible']),
    time: withValue(['label', 'placeholder', 'required', 'disabled', 'enabled', 'readonly', 'help', 'visible', 'hidden', 'min', 'max', 'tab', 'class']),
    select: withValue(['dataSource', 'dataSourceAction', 'label', 'placeholder', 'required', 'disabled', 'enabled', 'help', 'labelKey', 'valueKey', 'tab', 'class', 'visible', 'hidden', 'selectedProperty']),
    table: withValue(['label', 'required', 'disabled', 'help', 'columns', 'dataSource', 'dataSourceAction', 'searchable', 'tab', 'class']),
    button: _.pick(p, ['label', 'disabled', 'enabled', 'help', 'size', 'color', 'style', 'validate', 'tab', 'class', 'hidden', 'visible'].sort()),
    screen: _.pick(p, ['screen', 'tab', 'class', 'hidden', 'visible']),
    display: _.pick(p, ['content', 'visible', 'hidden', 'tab', 'class'].sort()),
    checkbox: withValue(['label', 'tab', 'class', 'hidden', 'visible', 'disabled', 'enabled', 'readonly', 'help']),
    repeater: _.pick(p, ['screen', 'context', 'tab', 'class', 'title', 'itemClass', 'hidden', 'visible', 'orientation', 'wrap'].sort()),
    tabs: _.pick(p, ['alignment', 'fullWidth', 'tabStyle', 'size', 'tabs', 'class'].sort()),
    heading: _.pick(p, ['title', 'size', 'hidden', 'visible'].sort()),
    'file-upload': _.pick(p, ['class', 'label', 'uploadAction', 'disabled', 'enabled', 'help', 'visible', 'hidden'].sort()),
    image: _.pick(p, ['label', 'title', 'class', 'url', 'visible', 'hidden', 'width', 'height'].sort()),
    progress: _.pick(p, ['label', 'valueUnknown', 'class', 'max', 'visible', 'hidden', 'visible'].sort()),
    modal: _.pick(p, ['title', 'open', 'screen', 'class']),
    tree: withValue(['dataSource', 'dataSourceAction', 'label', 'required', 'help', 'labelKey', 'valueKey', 'tab', 'class', 'visible', 'hidden', 'selectedProperty']),
    list: withValue(['dataSource', 'dataSourceAction', 'label', 'required', 'help', 'labelKey', 'valueKey', 'tab', 'class', 'visible', 'hidden', 'selectedProperty']),
    alert: _.pick(p, ['content', 'visible', 'hidden', 'tab', 'class', 'color', 'style', 'icon'].sort()),
    chart: withValue(['visible', 'hidden', 'tab', 'class', 'title', 'chartLabels', 'datasets'].sort()),
    'world-map': withValue(['visible', 'hidden', 'tab', 'class', 'title', 'dataSource'].sort()),
} as any
