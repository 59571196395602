import { Machine, actions as stateActions, DoneInvokeEvent } from 'xstate';

const { assign } = stateActions;

export enum LicenseType {
    Named = 0,
    Unauthenticated = 1
}

export interface Schema {
    states: {
        load: {}
        loadAdmins: {}
        loadWorkspaces: {}
        edit: {}
        savingAdmin: {}
        removingAdmin: {}
    }
}

type AddAdminEvent = { type: 'ADD_ADMIN', email: string, name: string };
type RemoveAdminEvent = { type: 'REMOVE_ADMIN', user: string };
type AddWorkspaceEvent = { type: 'ADD_WORKSPACE', name: string, description: string };

export type Event =
    | AddAdminEvent
    | RemoveAdminEvent
    | AddWorkspaceEvent

interface IAdmin {
    id: string
    name: string
    email: string
}

interface IWorkspace {
    id: string
    name: string
    description: string
}

interface ILicense {
    quota: number
    rate: string
    rate_demand: string
    ratio: number
    current_period_start: number
    current_period_end: number
    active: boolean
}

interface IUsage {
    id: string
    name: string
    type: LicenseType
    usage: number
}

export interface Context {
    apps?: any
    workspace: string
    org: string,
    workspaces: IWorkspace[],
    admins: IAdmin[],
    license?: ILicense,
    usage: IUsage[]
}

interface IOrganizationLoadResponse {
    workspaces: IWorkspace[],
    admins: IAdmin[],
    license: ILicense,
    usage: IUsage[]
}

const fetchOrg = (org: string) =>
    fetch(`${process.env.API_URL}/${org}`, { credentials: 'include' }).then((response) => response.json());

const fetchAdmins = (org: string) =>
    fetch(`${process.env.API_URL}/${org}/admin`, { credentials: 'include' }).then((response) => response.json());

const fetchWorkspaces = (org: string) =>
    fetch(`${process.env.API_URL}/${org}/workspace`, { credentials: 'include' }).then((response) => response.json());

const addWorkspace = (org: string, name: string, description: string) =>
    fetch(`${process.env.API_URL}/${org}/workspace`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name, description }),
        credentials: 'include'
    })

const addUserAsAdmin = (org: string, email: string, name: string) =>
    fetch(`${process.env.API_URL}/${org}/admin`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, name }),
        credentials: 'include'
    })
    .then((response) => response.json());

const removeUserAsAdmin = (org: string, id: string) =>
    fetch(`${process.env.API_URL}/${org}/admin/${id}`, { method: 'DELETE', credentials: 'include' })

export const machine = Machine<Context, Schema, Event>(
    {
        id: 'organization',
        initial: 'load',
        states: {
            load: {
                invoke: {
                    id: 'load',
                    src: (context: Context, event) => fetchOrg(context.org),
                    onDone: {
                        target: 'edit',
                        actions: assign<Context, DoneInvokeEvent<IOrganizationLoadResponse>>({ 
                            admins: (context, event) => event.data.admins,
                            workspaces: (context, event) => event.data.workspaces,
                            license: (context, event) => event.data.license,
                            usage: (context, event) => event.data.usage
                        })
                    },
                    onError: {
                        target: 'edit'
                    }
                }
            },
            loadAdmins: {
                invoke: {
                    id: 'loadAdmins',
                    src: (context: Context, event) => fetchAdmins(context.org),
                    onDone: {
                        target: 'edit',
                        actions: assign({ admins: (context, event) => event.data })
                    },
                    onError: {
                        target: 'edit'
                    }
                }
            },
            loadWorkspaces: {
                invoke: {
                    id: 'loadWorkspaces',
                    src: (context: Context, event) => fetchWorkspaces(context.org),
                    onDone: {
                        target: 'edit',
                        actions: assign({ workspaces: (context, event) => event.data })
                    },
                    onError: {
                        target: 'edit'
                    }
                }
            },
            edit: {
                on: {
                    ADD_ADMIN: 'savingAdmin',
                    REMOVE_ADMIN: 'removingAdmin',
                    ADD_WORKSPACE: 'addingWorkspace'
                }
            },
            savingAdmin: {
                // @ts-ignore
                invoke: {
                    id: 'addAdmin',
                    src: (context: Context, event: AddAdminEvent) => addUserAsAdmin(context.org, event.email, event.name),
                    onDone: {
                        target: 'loadAdmins'
                    },
                    onError: {
                        target: 'edit'
                    }
                }
            },
            removingAdmin: {
                // @ts-ignore
                invoke: {
                    id: 'removeAdmin',
                    src: (context: Context, event: RemoveAdminEvent) => removeUserAsAdmin(context.org, event.user),
                    onDone: {
                        target: 'loadAdmins'
                    },
                    onError: {
                        target: 'edit'
                    }
                }
            },
            addingWorkspace: {
                // @ts-ignore
                invoke: {
                    id: 'addWorkspace',
                    src: (context: Context, event: any) => addWorkspace(context.org, event.name, event.description),
                    onDone: {
                        target: 'loadWorkspaces'
                    },
                    onError: {
                        target: 'edit'
                    }
                }
            }
        }
    },
)