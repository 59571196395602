import _ from 'lodash';

interface IChartLabelsProps {
    value: string[]
    onChange: (labels: string[]) => void
    disabled: boolean
}

export default ({ value, onChange, disabled }: IChartLabelsProps) => {
    const onAddLabel = () => {
        onChange(_.concat(value, ''));
    }

    const onLabelChange = (label: string, index: number) => {
        value[index] = label;
        onChange(value);
    }

    const onRemoveLabel = (index: number) => {
        onChange(value.filter((label: string, i: number) => i !== index));
    }

    return (
        <div className="chart-labels">
            <table className="table is-striped is-fullwidth">
                <tbody>
                    {
                        (value || []).map((label: string, index: number) => (
                            <tr key={index}>
                                <td>
                                    <input type="text" className="input is-small" value={label} onChange={(e) => onLabelChange(e.currentTarget.value, index)} disabled={disabled} />
                                </td>
                                <td style={{ width: '1%', whiteSpace: 'nowrap' }}>
                                    <button className="delete" onClick={onRemoveLabel.bind(null, index)} disabled={disabled} />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <button className="button is-small" onClick={onAddLabel} disabled={disabled}>Add Label</button>
        </div>
    );
}
