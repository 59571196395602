import React from "react";
import { useActor } from '@xstate/react'
import { Context, Event, Schema } from '../../machines/workspace/workspace';
import { ActorRefFrom, StateMachine } from "xstate";
import classnames from "classnames";
import Select from 'react-select'
import _ from "lodash";
import './workspace.css';
import { p } from '../../machines/build/screens/properties';

type RoleOption = { value: string, label: string }

const Workspace = ({ machine }: { machine: ActorRefFrom<StateMachine<Context, Schema, Event>> | null }) => {
    const [state, send] = useActor(machine!);

    const [tab, setTab] = React.useState(0);
    const [email, setEmail] = React.useState('');
    const [name, setName] = React.useState('');
    const [roles, setRoles] = React.useState<RoleOption[]>([]);
    const [customComponentType, setCustomComponentType] = React.useState('');

    const onAddUser = () => send({ type: 'ADD_USER', name, email, roles: _.map(roles, (option) => option.value) });
    const onRemoveUser = (user: string) => send({ type: 'REMOVE_USER', user });
    const onChangeRole = (user: string, roles: any[]) => send({ type: 'CHANGE_ROLE', user, roles: roles.map(role => role.value) });

    const onAddVariable = () => send({ type: 'ADD_DENIED_VARIABLE' });
    const onRemoveVariable = (variable: string) => send({ type: 'REMOVE_DENIED_VARIABLE', variable });
    const onChangeVariable = (variable: string, index: number) => send({ type: 'CHANGE_DENIED_VARIABLE', variable, index });
    const onSaveSettings = () => send({ type: 'SAVE_SETTINGS' });

    const onAddCustomComponent = () => send({ type: 'ADD_CUSTOM_COMPONENT', component: customComponentType });
    const onRemoveCustomComponent = (component: string) => send({ type: 'REMOVE_CUSTOM_COMPONENT', component });
    const onUpdateCustomComponent = (component: string, properties: string[]) => send({ type: 'UPDATE_CUSTOM_COMPONENT', component, properties });
    const onSaveCustomComponents = () => send({ type: 'SAVE_CUSTOM_COMPONENTS' });
    
    const options: RoleOption[] = [
        { value: 'app-builder', label: 'App Builder' },
        { value: 'app-admin', label: 'App Admin' },
        { value: 'workspace-admin', label: 'Workspace Admin' },
        { value: 'read-only', label: 'Read Only' }
    ];

    const customComponentProperties = _.map(_.values(p), (prop) => ({ label: prop.name, value: prop.id }));

    const onCustomComponentPropertiesChange = (component: string, newValue: any) => {
        onUpdateCustomComponent(component, _.map(newValue, 'value'))
    }

    return (
        <section className="workspace">
            <div className="tabs is-boxed">
                <ul>
                    <li className={classnames({ 'is-active': tab === 0})}><a onClick={setTab.bind(null, 0)}>Users</a></li>
                    <li className={classnames({ 'is-active': tab === 1})}><a onClick={setTab.bind(null, 1)}>Settings</a></li>
                    <li className={classnames({ 'is-active': tab === 2})}><a onClick={setTab.bind(null, 2)}>Custom Components</a></li>
                </ul>
            </div>

            {
                tab === 0 ? (
                    <div className="p">
                        <table className="table is-fullwidth">
                            <thead>
                                <tr>
                                    <th style={{ width: '1%', whiteSpace: 'nowrap' }}></th>
                                    <th>Email</th>
                                    <th>Name</th>
                                    <th>Role</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    state.context.users.map(user => (
                                        <tr>
                                            <td style={{ width: '1%', whiteSpace: 'nowrap' }}><button className="button" onClick={onRemoveUser.bind(null, user.id)}>Remove</button></td>
                                            <td><a href={`mailto:${user.email}`}>{user.email}</a></td>
                                            <td>{user.name}</td>
                                            <td>
                                                <Select
                                                    isMulti={true}
                                                    /* @ts-ignore */
                                                    options={options}
                                                    value={_.intersectionWith(options, user.roles, (a, b) => a.value === b)}
                                                    onChange={(e) => onChangeRole(user.id, e as any)}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <h4 className="is-size-4 mb">Add User</h4>
                        <div className="add-user">
                            <div className="field">
                                <label className="label">Email</label>
                                <div className="control">
                                    <input className="input" type="email" required value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Name</label>
                                <div className="control">
                                    <input className="input" type="text" required value={name} onChange={(e) => setName(e.currentTarget.value)} />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Role</label>
                                <Select
                                    isMulti={true}
                                    options={options}
                                    value={roles}
                                    onChange={(selected: readonly RoleOption[]) => setRoles(selected as RoleOption[])}
                                />
                            </div>
                            <button className="button is-success" disabled={email === '' || name === '' || roles.length === 0} onClick={onAddUser}>Add User</button>
                        </div>
                    </div>
                ) : null
            }
            {
                tab === 1 ? (
                    <div className="p settings">
                        <h4 className="title is-size-4">Variable Denylist</h4>
                        <p className="mb">Add variable names that won't be sent to the client</p>
                        <button className="button is-primary mb" onClick={onAddVariable}>Add Variable</button>
                        <ul className="mb">
                            {
                                _.map(state.context.denylist, (variable, index) => {
                                    return (
                                        <li key={index} className="mb">
                                            <input className="input" value={variable} onChange={onChangeVariable.bind(null, variable, index)} />
                                            <button className="delete" onClick={onRemoveVariable.bind(null, variable)}>x</button>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <button className="button is-success" onClick={onSaveSettings}>Save Settings</button>
                    </div>
                ) : null
            }
            {
                tab === 2 ? (
                    <div className="p components">
                        <h4 className="title is-size-4">Custom Screen Components</h4>
                        <p className="mb">Configure custom screen components that will be available in the screen editor</p>
                        <table className="table is-fullwidth">
                            <thead>
                                <tr>
                                    <th style={{ width: '1%', whiteSpace: 'nowrap' }}></th>
                                    <th>Type</th>
                                    <th>Properties</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    
                                    _.map(state.context.customComponents, (component, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{ width: '1%', whiteSpace: 'nowrap' }}>
                                                    <button className="button" onClick={onRemoveCustomComponent.bind(null, component.type)}>Remove</button>
                                                </td>
                                                <td>{component.type}</td>
                                                <td>
                                                    <Select 
                                                        isMulti={true}
                                                        options={customComponentProperties}
                                                        value={_.map(component.properties, (prop) => ({ value: prop, label: p[prop].name }))}
                                                        onChange={onCustomComponentPropertiesChange.bind(null, component.type)}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <h4 className="is-size-4 mb">Add Custom Component</h4>
                        <div className="add-user mb">
                            <div className="field">
                                <label className="label">Type</label>
                                <div className="control">
                                    <input className="input" type="text" required value={customComponentType} onChange={(e) => setCustomComponentType(e.currentTarget.value)} />
                                </div>
                            </div>
                            <button className="button is-pimary" disabled={customComponentType === ''} onClick={onAddCustomComponent}>Add Custom Component</button>
                        </div>
                        <button className="button is-success" onClick={onSaveCustomComponents}>Save Custom Components</button>
                    </div>
                ) : null
            }
        </section>
    );
};

export default Workspace;
