import { Machine, spawn, actions, Sender, Receiver } from 'xstate';
import { IVersionableReference } from '../../../../../types/versionable';
import { deployment } from '../../../socket';
import { machine as environmentMachine } from './environment';

const { assign } = actions;

export interface Schema {
    states: {
        loading: {},
        edit: {},
        error: {}
    }
}

type LoadedEvent = { type: 'LOADED', environments: IVersionableReference[] }
type SelectEvent = { type: 'SELECT', value: string }

export type Event =
    | LoadedEvent
    | SelectEvent
    | { type: 'DISMISS' }

export interface Context {
    error?: string | null
    selected?: string | null
    environments?: IVersionableReference[]
    machine?: any
    org: string
    workspace: string
}

const realtime = (context: Context, event: Event) => (callback: Sender<Event>, onReceive: Receiver<Event>) => {
    const onLoad = (environments: IVersionableReference[]) => callback({ type: 'LOADED', environments });

    deployment.environment.environments(context.org, context.workspace, onLoad);

    return () => {};
}

export const machine = Machine<Context, Schema, Event>({
    id: 'deployment-environments',
    initial: 'loading',
    invoke: {
        id: 'socket',
        src: realtime
    },
    states: {
        loading: {
            on: {
                LOADED: {
                    actions: assign<Context, LoadedEvent>({
                        environments: (_, event) => event.environments,
                    }),
                    target: 'edit'
                }
            }
        },
        edit: {
            on: {
                SELECT: {
                    actions: assign<Context, SelectEvent>({
                        selected: (context, event) => {
                            if (context.machine)
                                context.machine.stop!();

                            return event.value
                        },
                        machine: (context, event) => 
                            spawn(environmentMachine.withContext({ org: context!.org, workspace: context!.workspace, id: event.value }), { name: 'deployment-environment' })
                    })
                }
            }
        },
        error: {
            on: {
                DISMISS: 'edit'
            }
        }
    }
})