import React from "react";
import { Context, Event, Schema } from '../../../machines/deployment/environments/environments';
import { useActor } from "@xstate/react";
import { ActorRefFrom, StateMachine } from "xstate";
import Environment from "./Environment";
import './environments.css';

const Environments = ({ machine }: { machine: ActorRefFrom<StateMachine<Context, Schema, Event>> | null }) => {
    const [state, send] = useActor(machine!);

    let editor: JSX.Element | null = null;
    if (state.context.selected)
        editor = <Environment machine={state.context.machine} />

    return (
        <div className="environments">
            <div className="items">
                {
                    state.context.environments?.map((environment) => {
                        return (
                            <div className="box is-size-4" onClick={() => send({ type: 'SELECT', value: environment.id })} key={environment.id}>
                                <label className="">{environment.name}</label>
                            </div>
                        );
                    })
                }
            </div>
            {editor}
        </div>
    );
};

export default Environments;
