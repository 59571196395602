import React, { FormEvent } from 'react';
import { nanoid } from 'nanoid'
import './component.css';
import triggers from '../../../machines/build/screens/triggers';
import { events } from '../../../socket';
import _ from 'lodash';
import VariableFinder from '../variable/VariableFinder';
import { ITrigger } from '../../../machines/build/screens/screen';

interface Props {
    org: string
    workspace: string
    componentType: string
    id: string
    value: ITrigger | null
    onChange: (id: string, value: any) => void
    disabled: boolean
}

export default ({ org, workspace, componentType, id, value, onChange, disabled }: Props) => {

    const pathsInputId = React.useRef(nanoid());
    const [paths, setPaths] = React.useState<string[]>([]);

    const onEventChange = (e: FormEvent<HTMLInputElement>) => onChange(id, { ...value, event: e.currentTarget.value });
        
    const onCaptureContextChange = (variable: string) => {
        if (variable)
            onChange(id, { ...value, context: { capture: true, variable }});
        else
            onChange(id, { ...value, context: { capture: false, variable: null }});
    }

    React.useEffect(() => {
        events(org, workspace, setPaths);
    }, []);

    return (
        <div className="page-component-property mb">
            <h4 className="is-size-6 flex-grow mb"><strong>{triggers[componentType][id].name}</strong></h4>
            <div className="field">
                <div className="control">
                    <input list={pathsInputId.current} type="text" className="input" value={value?.event} onChange={onEventChange} disabled={disabled} placeholder="Event to execute" />
                    <datalist id={pathsInputId.current}>
                        {_.map(paths, (path) => <option value={path} key={path} />)}
                    </datalist>
                </div>
            </div>
            <div className="field">
                <label className="label">Capture Context</label>
                <div className="control">
                    <VariableFinder 
                        org={org}
                        workspace={workspace}
                        disabled={disabled}
                        onChange={onCaptureContextChange}
                        variable={value?.context?.variable || ''}
                    />
                </div>
            </div>
        </div>
    );
}
