import React from 'react';
import VersionableItems from '../versionable/VersionableItems';
import { IVersionableReferenceSelected } from '../../../../../types/versionable';
import MarkdownEditor from '../editing/MarkdownEditor';

interface Props {
    authentication?: IVersionableReferenceSelected | null
    onAuthenticationChange: (value: IVersionableReferenceSelected[]) => void
    authorization?: IVersionableReferenceSelected[] | null
    onAuthorizationChange: (value: IVersionableReferenceSelected[]) => void
    message?: string | null
    onMessageChange: (value: string) => void
    continueExecution?: boolean | null
    onContinueExecutionChange?: (value: boolean) => void
    onEdit: (id: string, version: string, type: string, kind?: string) => void
    onNewRules?: () => void
    isState: boolean
    disabled: boolean
}

const Permissions = ({ authentication, onAuthenticationChange, authorization, onAuthorizationChange, isState, message, continueExecution, onContinueExecutionChange: onContinueExecutionChnage, disabled, onMessageChange, onEdit, onNewRules }: Props) => {
    const [selectedTab, setSelectedTab] = React.useState<"write" | "preview">("write");

    return (
        <div className="permissions flex-grow overflow-y-auto">
            <div className="mb">
                <h4 className="is-size-5 mb">Authentication</h4>
                <p className="mb">
                    Require that the executing user is authenticated with the selected plugins.
                </p>
                <VersionableItems
                    id="authentication"
                    value={authentication ? [authentication] : []}
                    onChange={onAuthenticationChange}
                    type="plugin"
                    disabled={disabled}
                />
            </div>

            <div className="mb">
                <h4 className="is-size-5 mb">Authorization</h4>
                <p className="mb">
                    Restrict this state from being executed by selecting one or more rules. If the rules defined below do not pass the app will wait
                    until the rules do pass before continuing.
                </p>
            </div>

            <div className="mb">
                <p className="mb has-text-grey-dark">Drag & drop to re-order Rules, double-click on a Rule to open it</p>
                <VersionableItems
                    id="authorization"
                    value={authorization || []}
                    onChange={onAuthorizationChange}
                    onEdit={onEdit}
                    type="rule"
                    multiple={true}
                    disabled={disabled}
                    canBreakpoint={isState}
                    canDisable={true}
                />
            </div>
            {
                onNewRules ? (
                    <button className="button is-primary is-small mb" onClick={onNewRules}>New Rules</button>
                ) : null
            }
            {
                onContinueExecutionChnage ? (
                    <div className="field" onClick={() => onContinueExecutionChnage(!continueExecution)}>
                        <input type="checkbox" className="switch is-rounded" checked={!!continueExecution} />
                        <label>Continue execution if the user is not authorized</label>
                    </div>
                ) : null
            }
            <div>
                <label className="label">Message to be displayed if the rules defined do not pass.</label>
                <MarkdownEditor
                    id="permissions"
                    value={message || ''}
                    onChange={onMessageChange}
                    disabled={disabled}
                />
            </div>
        </div>
    );
}

export default Permissions;