import React, { FormEvent, useState } from 'react';
import VariableFinder from '../variable/VariableFinder';
import ValueSelector from '../variable/ValueSelector';
import { Controlled as CodeMirror } from 'react-codemirror2';
import Header from '../editing/Header';
import Dependencies from '../editing/Dependencies';
import History from '../editing/History';
import Delete from './Delete';
import { useActor } from "@xstate/react";
import { Context, Event } from '../../../machines/build/versionable-item';
import VersionableItems from '../versionable/VersionableItems';
import _ from 'lodash';
import './action-editor.css';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/sql/sql';
import NewVersion from '../editing/NewVersion';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { ActorRefFrom, StateMachine } from 'xstate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { IActionSql, ISqlParameter } from '../../../../../types/action';
import { IDependency, IVersionableReferenceSelected } from '../../../../../types/versionable';
import { IVariable } from '../../../../../types/variable';
import arrayMove from 'array-move';

// eslint-disable-next-line react/display-name
export default ({ machine }: { machine: ActorRefFrom<StateMachine<Context<IActionSql>, any, Event<IActionSql>>> }) => {
    const [state, send] = useActor(machine);
    const [isNewVersionVisible, setNewVersionVisible] = useState(false);

    const onVersion = (version: string) => send({ type: 'VERSION', version });
  
    const onNewVersion = () => setNewVersionVisible(true);
    const onCloseNewVersion = () => setNewVersionVisible(false);

    const onNewVersionCreated = () => {
        setNewVersionVisible(false);
        send({ type: 'NEW_VERSION_CREATED' });
    }

    const onNameChange = (e: FormEvent<HTMLInputElement>) => send({ type: 'CHANGE', value: { name: e.currentTarget.value } });
    const onIntoChange = (into: string) => send({ type: 'CHANGE', value: { into } });
    const onUsingChange = (using: IVersionableReferenceSelected[]) => send({ type: 'CHANGE', value: { using: using[0] }});

    const onDependencyClick = (item: IDependency) => send({ type: 'ITEM', itemType: item.type, id: item.id, version: item.version });

    const onDelete = () => send('DELETE');

    const onNewParameter = () => {
        const parameters = state.context.item?.parameters || []

        send({
            type: 'CHANGE',
            value: {
                parameters: parameters.concat([{
                    order: parameters.length,
                    variable: null
                }])
            }
        });
    }

    const onChangeParameter = (variable: IVariable | null, index: number) => {
        send({
            type: 'CHANGE',
            value: {
                parameters: _.map(state.context.item?.parameters, (parameter, i) => {
                    if (index === i)
                        parameter.variable = variable;

                    return parameter;
                })
            }
        });
    }
    
    const onDeleteParameter = (parameter: ISqlParameter) => {
        const parameters = state.context.item?.parameters || []

        send({
            type: 'CHANGE',
            value: {
                parameters: parameters.filter((p, index) => index !== parameter.order)
            }
        });
    }

    const onDragEnd = (result: DropResult) => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        const parameters = state.context.item?.parameters || []

        send({
            type: 'CHANGE',
            value: {
                parameters: arrayMove(parameters, source.index, destination.index)
                .map((parameter, index) => {
                    parameter.order = index;
                    return parameter;
                })
            }
        });
    };

    return (
        <div className="action-editor">
            <Header
                versions={state.context.versions}
                version={state.context.version}
                onVersion={onVersion}
                onNewVersion={onNewVersion}
                users={state.context.users}
                modified={state.context.item ? state.context.item.modified : null}
            />

            <div className="sql p">
                {
                    state.matches('edit') && state.context.item ? (
                        <>
                            <div className="box">
                                <div className="is-flex justify-between mb">
                                    <div>
                                        <FontAwesomeIcon icon={faDatabase} className="mr" />
                                        <strong>SQL Query</strong>
                                    </div>
                                    <button className="button is-danger is-small is-outlined" onClick={onDelete} disabled={state.context.item.readonly}>Delete</button>
                                </div>
                                <div className="field mb">
                                    <div className="control">
                                        <input className="input is-large" type="text" placeholder="Action Name" value={state.context.item.name} onChange={onNameChange} disabled={state.context.item.readonly} />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label">Plugin</label>
                                    <VersionableItems
                                        id="using"
                                        value={state.context.item.using ? [state.context.item.using] : []}
                                        onChange={onUsingChange}
                                        type="plugin"
                                        disabled={state.context.item.readonly} 
                                    />
                                </div>
                                <div className="field">
                                    <label className="label">Into Variable</label>
                                    <VariableFinder org={state.context.org} workspace={state.context.workspace} variable={state.context.item.into} onChange={onIntoChange} disabled={state.context.item.readonly} />
                                </div>
                                <div className="field">
                                    <label className="label">SQL to Execute</label>
                                    <CodeMirror
                                        value={state.context.item.sql}
                                        options={{
                                            theme: 'material',
                                            mode: 'sql',
                                            lineNumbers: true,
                                        }}
                                        onBeforeChange={(editor, data, sql) => {
                                            send({ type: 'CHANGE', value: { sql } });
                                        }}
                                    />
                                </div>
                                <div className="field">
                                    <label className="label">Parameters</label>
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId={state.context.item.id}>
                                            {(provided, snapshot) => (
                                                <div
                                                    className="list is-hoverable mb"
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {
                                                        _.map(state.context.item.parameters, (parameter, index) => (
                                                            <Draggable
                                                                key={index}
                                                                draggableId={index.toString()}
                                                                index={index}
                                                            >
                                                            {
                                                                (provided, snaphost) => {
                                                                    return (
                                                                        <div 
                                                                            key={index}
                                                                            className="list-item is-flex items-center"
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <span className="mr"><strong>{index}.</strong></span>
                                                                            <div className="flex-grow mr2">
                                                                                <ValueSelector
                                                                                    org={state.context.org}
                                                                                    workspace={state.context.workspace}
                                                                                    variable={parameter.variable}
                                                                                    onChange={(variable) => onChangeParameter(variable, index)}
                                                                                    small={true}
                                                                                    disabled={state.context.item.readonly}
                                                                                />
                                                                            </div>
                                                                            <button className="button is-small is-outlined" onClick={onDeleteParameter.bind(null, parameter)}>Remove</button>
                                                                        </div>
                                                                    )
                                                                }
                                                            }
                                                            </Draggable>
                                                        ))
                                                    }
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    <button className="button is-info" onClick={onNewParameter}>New Parameter</button>
                                </div>
                            </div>

                            <div className="columns">
                                <div className="column">
                                    <div className="card mb">
                                        <header className="card-header">
                                            <p className="card-header-title">
                                            Uses
                                            </p>
                                        </header>
                                        <div className="card-content">
                                            <Dependencies org={state.context.org} workspace={state.context.workspace} dependencies={state.context.uses} onClick={onDependencyClick} />
                                        </div>
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="card mb">
                                        <header className="card-header">
                                            <p className="card-header-title">
                                            Used By
                                            </p>
                                        </header>
                                        <div className="card-content">
                                            <Dependencies org={state.context.org} workspace={state.context.workspace} dependencies={state.context.usedBy} onClick={onDependencyClick} />
                                        </div>
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="card">
                                        <header className="card-header">
                                            <p className="card-header-title">
                                                History
                                    </p>
                                        </header>
                                        <div className="card-content">
                                            <History versions={state.context.versions} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null
                }
                {
                    isNewVersionVisible ? (
                        <NewVersion
                            org={state.context.org}
                            workspace={state.context.workspace}
                            id={state.context.id}
                            type="action"
                            onNewVersion={onNewVersionCreated}
                            onClose={onCloseNewVersion}
                        />
                        ) : null
                }
                <Delete state={state} send={send} />
            </div>
        </div>
    );
}