import React from "react";
import { nanoid } from 'nanoid'
import "./variable-finder.css";
import { values } from '../../../socket';
import _ from 'lodash';

interface IVariableFinderProps {
    org: string,
    workspace: string,
    variable?: string | undefined
    onChange?: (variable: string) => void,
    onClear?: () => void,
    disabled: boolean,
    focusOnMount?: boolean
}

const VariableFinder = ({ org, workspace, variable, onChange, onClear, disabled, focusOnMount }: IVariableFinderProps) => {

    const dataListId = React.useRef(nanoid());
    const input = React.useRef<HTMLInputElement>(null);

    const [paths, setPaths] = React.useState<string[]>([]);

    const onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        onChange && onChange(e.currentTarget.value);
    };

    React.useEffect(() => {
        values(org, workspace, setPaths);

        if (focusOnMount)
            input.current!.focus();
    }, []);

    return (
        <div className="variable-finder is-fullwidth field has-addons">
            <p className="control">
                <a className="button is-static">Variable</a>
            </p>
            <div className="control is-expanded">
                <input ref={input} list={dataListId.current} autoComplete="off" className="input is-fullwidth" value={variable} onChange={onInputChange} disabled={disabled} />
                <datalist id={dataListId.current}>
                    {_.map(paths, (path) => <option value={path} key={path} />)}
                </datalist>
            </div>
            {onClear ? <button className="delete" onClick={onClear} /> : null}
            {/* <p className="help">Specify variable path as a <a href="https://goessner.net/articles/JsonPath/" target="_blank" rel="noopener noreferrer">JSON Path</a></p> */}
        </div>
    );
}

export default VariableFinder;
