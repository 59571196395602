/* eslint-disable react/display-name */
import React, { FormEvent, useMemo, useState } from 'react';
import tinycolor from "tinycolor2";
import Header from '../editing/Header';
import History from '../editing/History';
import Delete from '../actions/Delete';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { useActor } from "@xstate/react";
import { Context, Event, IDependency, IRunner } from '../../../machines/build/versionable-item';
import NewVersion from '../editing/NewVersion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/css/css';
import Dependencies from '../editing/Dependencies';
import { ActorRefFrom, StateMachine } from 'xstate';
import classnames from 'classnames';

const ColorPicker = ({ id, label, variable, value, onChange, disabled, revertTo }: any) => {
    const color = useMemo(() => {
        return tinycolor(value);
    }, [value]);

    const onColorPickerChange = (e: FormEvent<HTMLInputElement>) => {
        onChange(variable, e.currentTarget.value);
    }

    const onInputChange = (e: FormEvent<HTMLInputElement>) => {
        onChange(variable, e.currentTarget.value);
    }

    const onRevert = () => onChange(variable, revertTo);

    return (
        <div className="field">
            <label className="label">{label}</label>
            <div className="color-picker">
                <input 
                    type="color"
                    value={color.toHexString()}
                    onChange={onColorPickerChange}
                    className="input"
                    disabled={disabled}
                />
                <input
                    type="text"
                    value={value}
                    onChange={onInputChange}
                    className="input"
                    list="variables"
                    id={id}
                    disabled={disabled}
                />
                <button className="button" title="Revert to default" onClick={onRevert}>
                    <span className="icon">
                        <FontAwesomeIcon icon={faUndo} />
                    </span>
                </button>
            </div>
        </div>
    );
};

const TextPicker = ({ id, label, variable, value, onChange, disabled, revertTo }: any) => {
    const onInputChange = (e: FormEvent<HTMLInputElement>) => {
        onChange(variable, e.currentTarget.value);
    }

    const onRevert = () => onChange(variable, revertTo);

    return (
        <div className="field is-grouped text-picker">
            <label className="label">{label}</label>
            <div className="control">
                <input className="input" type="text" data-variable={variable} value={value} onChange={onInputChange} disabled={disabled} id={id} />
            </div>
            <button className="button" title="Revert to default" onClick={onRevert}>
                <span className="icon">
                    <FontAwesomeIcon icon={faUndo} />
                </span>
            </button>
        </div>
    )
}

const Colors = ({ runner, onChange, disabled }: { runner: IRunner, onChange: any, disabled: boolean }) => {
    return (
        <section className="box">
            <h3 className="is-size-4 mb">Colors</h3>
            <ColorPicker label="Primary" id="theme-primary" variable={'primary'} value={runner!.variables.primary} onChange={onChange} disabled={disabled} revertTo="#3081f7" />
            <ColorPicker label="Secondary" variable={'secondary'} value={runner!.variables.secondary} onChange={onChange} disabled={disabled} revertTo="#3298dc" />
            <ColorPicker label="Success" variable={'success'} value={runner!.variables.success} onChange={onChange} disabled={disabled} revertTo="#198754" />
            <ColorPicker label="Warning" variable={'warning'} value={runner!.variables.warning} onChange={onChange} disabled={disabled} revertTo="#ffc107" />
            <ColorPicker label="Danger" variable={'danger'} value={runner!.variables.danger} onChange={onChange} disabled={disabled} revertTo="#dc3545" />
            <ColorPicker label="Gray" variable={'gray'} value={runner!.variables.gray} onChange={onChange} disabled={disabled} revertTo="#f2f7f8" />
            <ColorPicker label="Light Gray" variable={'grayLight'} value={runner!.variables.grayLight} onChange={onChange} disabled={disabled} revertTo="#dbdbdb" />
            <ColorPicker label="Dark Gray" variable={'grayDark'} value={runner!.variables.grayDark} onChange={onChange} disabled={disabled} revertTo="#e9ecef" />
            <ColorPicker label="Black" variable={'black'} value={runner!.variables.black} onChange={onChange} disabled={disabled} revertTo="#2c2b2e" />
        </section>
    );
}

const Grid = ({ runner, onChange, disabled }: { runner: IRunner, onChange: any, disabled: boolean }) => {
    return (
        <section className="box">
            <h3 className="is-size-4 mb">Grid</h3>
            <TextPicker id="grid-margin" label="Margin" variable={'gridMargin'} value={runner?.variables.gridMargin} onChange={onChange} disabled={disabled} revertTo="1.5em" />
            <TextPicker id="grid-padding" label="Padding" variable={'gridPadding'} value={runner?.variables.gridPadding} onChange={onChange} disabled={disabled} revertTo="1.5em" />
            <TextPicker id="row-gap" label="Row Gap" variable={'gridRowGap'} value={runner?.variables.gridRowGap} onChange={onChange} disabled={disabled} revertTo="2rem" />
            <TextPicker id="column-gap" label="Column Gap" variable={'gridColumnGap'} value={runner?.variables.gridColumnGap} onChange={onChange} disabled={disabled} revertTo="2rem" />
            <ColorPicker id="grid-background" label="Grid Background" variable={'gridBackgroundColor'} value={runner?.variables.gridBackgroundColor} onChange={onChange} disabled={disabled} revertTo="#ffffff" />
            <ColorPicker id="background" label="Background" variable={'backgroundColor'} value={runner?.variables.backgroundColor} onChange={onChange} disabled={disabled} revertTo="var(--gray)" />
        </section>
    );
}

const Typography = ({ runner, onChange, disabled }: { runner: IRunner, onChange: any, disabled: boolean }) => {
    return (
        <section className="box">
            <h3 className="is-size-4 mb">Typography</h3>
            <TextPicker id="font-family" label="Font Family" variable={'fontFamily'} value={runner?.variables.fontFamily} onChange={onChange} disabled={disabled} revertTo={`-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";`}/>
            <ColorPicker id="font-color" label="Font Color" variable={'fontColor'} value={runner!.variables.fontColor} onChange={onChange} disabled={disabled} revertTo="var(--black)" />
            <TextPicker id="font-size" label="Font Size" variable={'fontSize'} value={runner?.variables.fontSize} onChange={onChange} disabled={disabled} revertTo="1.6em" />
            <TextPicker id="line-height" label="Line Height" variable={'lineHeight'} value={runner?.variables.lineHeight} onChange={onChange} disabled={disabled} revertTo="1.6" />
            <TextPicker id="heading-1-size" label="Heading 1 Size" variable={'h1FontSize'} value={runner?.variables.h1FontSize} onChange={onChange} disabled={disabled} revertTo="calc(var(--font-size) * 2.125)" />
            <TextPicker id="heading-2-size" label="Heading 2 Size" variable={'h2FontSize'} value={runner?.variables.h2FontSize} onChange={onChange} disabled={disabled} revertTo="calc(var(--font-size) * 1.5)" />
            <TextPicker id="heading-3-size" label="Heading 3 Size" variable={'h3FontSize'} value={runner?.variables.h3FontSize} onChange={onChange} disabled={disabled} revertTo="calc(var(--font-size) * 1.25)" />
            <TextPicker id="heading-4-size" label="Heading 4 Size" variable={'h4FontSize'} value={runner?.variables.h4FontSize} onChange={onChange} disabled={disabled} revertTo="calc(var(--font-size) * 1.125)" />
            <TextPicker id="heading-5-size" label="Heading 5 Size" variable={'h5FontSize'} value={runner?.variables.h5FontSize} onChange={onChange} disabled={disabled} revertTo="calc(var(--font-size) * 1)" />
            <TextPicker id="heading-6-size" label="Heading 6 Size" variable={'h6FontSize'} value={runner?.variables.h6FontSize} onChange={onChange} disabled={disabled} revertTo="calc(var(--font-size) * 1)" />
        </section>
    );
}

const Borders = ({ runner, onChange, disabled }: { runner: IRunner, onChange: any, disabled: boolean }) => {
    return (
        <section className="box">
            <h3 className="is-size-4 mb">Borders</h3>
            <ColorPicker id="border-color" label="Border Color" variable={'borderColor'} value={runner!.variables.borderColor} onChange={onChange} disabled={disabled} revertTo="var(--gray-light)" />
            <TextPicker id="border-width" label="Border Width" variable={'borderWidth'} value={runner?.variables.borderWidth} onChange={onChange} disabled={disabled} revertTo="2px" />
            <TextPicker id="border-radius" label="Border Radius" variable={'borderRadius'} value={runner?.variables.borderRadius} onChange={onChange} disabled={disabled} revertTo="4px" />
        </section>
    );
}

const Forms = ({ runner, onChange, disabled }: { runner: IRunner, onChange: any, disabled: boolean }) => {
    return (
        <section className="box">
            <h3 className="is-size-4 mb">Forms</h3>
            <TextPicker id="input-padding" label="Padding" variable={'inputPadding'} value={runner?.variables.inputPadding} onChange={onChange} disabled={disabled} revertTo="0.5em" />
            <ColorPicker id="input-border-color" label="Border Color" variable={'inputBorderColor'} value={runner!.variables.inputBorderColor} onChange={onChange} disabled={disabled} revertTo="var(--border-color)" />
            <TextPicker id="input-border-width" label="Border Width" variable={'inputBorderWidth'} value={runner?.variables.inputBorderWidth} onChange={onChange} disabled={disabled} revertTo="var(--border-width)" />
            <TextPicker id="input-border-radius" label="Border Radius" variable={'inputBorderRadius'} value={runner?.variables.inputBorderRadius} onChange={onChange} disabled={disabled} revertTo="var(--border-radius)" />
        </section>
    );
}

const Buttons = ({ runner, onChange, disabled }: { runner: IRunner, onChange: any, disabled: boolean }) => {
    return (
        <section className="box">
            <h3 className="is-size-4 mb">Buttons</h3>
            <ColorPicker id="button-color" label="Text Color" variable={'buttonColor'} value={runner!.variables.buttonColor} onChange={onChange} disabled={disabled} revertTo="#ffffff" />
            <TextPicker id="button-padding" label="Padding" variable={'buttonPadding'} value={runner?.variables.buttonPadding} onChange={onChange} disabled={disabled} revertTo="0.5em" />
            <TextPicker id="button-border-width" label="Border Width" variable={'buttonBorderWidth'} value={runner?.variables.buttonBorderWidth} onChange={onChange} disabled={disabled} revertTo="var(--border-width)" />
            <TextPicker id="button-border-radius" label="Border Radius" variable={'buttonBorderRadius'} value={runner?.variables.buttonBorderRadius} onChange={onChange} disabled={disabled} revertTo="var(--border-radius)" />
        </section>
    );
}

const Tables = ({ runner, onChange, disabled }: { runner: IRunner, onChange: any, disabled: boolean }) => {
    return (
        <section className="box">
            <h3 className="is-size-4 mb">Table</h3>
            <TextPicker id="table-padding" label="Cell Padding" variable={'tablePadding'} value={runner?.variables.tablePadding} onChange={onChange} disabled={disabled} revertTo="0.75em 1em" />
            <ColorPicker id="table-border-color" label="Border Color" variable={'tableBorderColor'} value={runner!.variables.tableBorderColor} onChange={onChange} disabled={disabled} revertTo="var(--border-color)" />
            <TextPicker id="table-border-width" label="Border Width" variable={'tableBorderWidth'} value={runner?.variables.tableBorderWidth} onChange={onChange} disabled={disabled} revertTo="var(--border-width)" />
        </section>
    );
}

const Navigation = ({ runner, onChange, send, disabled }: { runner: IRunner, onChange: any, send: any, disabled: boolean }) => {
    const onHeaderImageSrcChange = (e: FormEvent<HTMLInputElement>) => send({
        type: 'CHANGE',
        value: {
            navigation: {
                image: {
                    ...runner!.navigation.image,
                    src: e.currentTarget.value
                }
            }
        }
    });

    const onHeaderImageWidthChange = (e: FormEvent<HTMLInputElement>) => send({
        type: 'CHANGE',
        value: {
            navigation: {
                image: {
                    ...runner!.navigation.image,
                    width: e.currentTarget.valueAsNumber
                }
            }
        }
    });

    const onHeaderImageHeightChange = (e: FormEvent<HTMLInputElement>) => send({
        type: 'CHANGE',
        value: {
            navigation: {
                image: {
                    ...runner!.navigation.image,
                    height: e.currentTarget.valueAsNumber
                }
            }
        }
    });
    
    return (
        <section className="box">
            <h3 className="is-size-4 mb">Navigation</h3>
            <TextPicker id="navigation-padding" label="Padding" variable={'navigationPadding'} value={runner?.variables.navigationPadding} onChange={onChange} disabled={disabled} revertTo="1em" />
            <TextPicker id="navigation-width" label="Width" variable={'navigationWidth'} value={runner?.variables.navigationWidth} onChange={onChange} disabled={disabled} revertTo="240px" />
            <div className="field">
                <label className="label">Image Url</label>
                <div className="control">
                    <input className="input" type="text" value={runner?.navigation.image.src} onChange={onHeaderImageSrcChange} disabled={disabled} />
                </div>
            </div>
            <div className="field">
                <label className="label">Image Width</label>
                <div className="control">
                    <input className="input" type="number" value={runner?.navigation.image.width} onChange={onHeaderImageWidthChange} disabled={disabled} />
                </div>
            </div>
            <div className="field">
                <label className="label">Image Height</label>
                <div className="control">
                    <input className="input" type="number" value={runner?.navigation.image.height} onChange={onHeaderImageHeightChange} disabled={disabled} />
                </div>
            </div>
        </section>
    );
}

const Tabs = ({ runner, onChange, disabled }: { runner: IRunner, onChange: any, disabled: boolean }) => {
    return (
        <section className="box">
            <h3 className="is-size-4 mb">Tabs</h3>
            <TextPicker id="tab-item-padding" label="Item Padding" variable={'tabItemPadding'} value={runner?.variables.tabItemPadding} onChange={onChange} disabled={disabled} revertTo="0.5em 1.5em" />
            <TextPicker id="tab-border-radius" label="Border Radius" variable={'tabBorderRadius'} value={runner?.variables.tabBorderRadius} onChange={onChange} disabled={disabled} revertTo="var(--border-radius)" />
        </section>
    );
}

const Alerts = ({ runner, onChange, disabled }: { runner: IRunner, onChange: any, disabled: boolean }) => {
    return (
        <section className="box">
            <h3 className="is-size-4 mb">Alerts</h3>
            <TextPicker id="alert-padding" label="Padding" variable={'alertPadding'} value={runner?.variables.alertPadding} onChange={onChange} disabled={disabled} revertTo="1em" />
            <TextPicker id="alert-border-width" label="Border Width" variable={'alertBorderWidth'} value={runner?.variables.alertBorderWidth} onChange={onChange} disabled={disabled} revertTo="var(--border-width)" />
            <TextPicker id="alert-border-radius" label="Border Radius" variable={'alertBorderRadius'} value={runner?.variables.alertBorderRadius} onChange={onChange} disabled={disabled} revertTo="var(--border-radius)" />
        </section>
    );
}

const ModalDialog = ({ runner, onChange, disabled }: { runner: IRunner, onChange: any, disabled: boolean }) => {
    return (
        <section className="box">
            <h3 className="is-size-4 mb">Modal Dialog</h3>
            <TextPicker id="modal-padding" label="Padding" variable={'modalPadding'} value={runner!.variables.modalPadding} onChange={onChange} disabled={disabled} revertTo="1em" />
            <ColorPicker id="modal-border-color" label="Border Color" variable={'modalBorderColor'} value={runner!.variables.modalBorderColor} onChange={onChange} disabled={disabled} revertTo="var(--border-color)" />
            <TextPicker id="modal-border-width" label="Border Width" variable={'modalBorderColor'} value={runner?.variables.modalBorderWidth} onChange={onChange} disabled={disabled} revertTo="var(--border-width)" />
            <TextPicker id="modal-border-radius" label="Border Radius" variable={'modalBorderColor'} value={runner?.variables.modalBorderRadius} onChange={onChange} disabled={disabled} revertTo="var(--border-radius)" />
        </section>
    );
}

export default ({ machine }: { machine: ActorRefFrom<StateMachine<Context<IRunner>, any, Event<IRunner>>> }) => {
    const [state, send] = useActor(machine);
    const [isNewVersionVisible, setNewVersionVisible] = useState(false);

    const onVersion = (version: string) => send({ type: 'VERSION', version });
  
    const onNewVersion = () => setNewVersionVisible(true);
    const onCloseNewVersion = () => setNewVersionVisible(false);

    const onNewVersionCreated = () => {
        setNewVersionVisible(false);
        send({ type: 'NEW_VERSION_CREATED' });
    }

    const onDependencyClick = (item: IDependency) => send({ type: 'ITEM', itemType: item.type, id: item.id, version: item.version });

    const onNameChange = (e: FormEvent<HTMLInputElement>) => send({ type: 'CHANGE', value: { name: e.currentTarget.value } });

    const onVariableChange = (variable: string, value: string) =>
        send({
            type: 'CHANGE',
            value: {
                variables: {
                    ...state.context.item?.variables,
                    [variable]: value
                }
            }
        })

    const onDelete = () => send('DELETE');

    const [selectedVariableGroup, setSelectedVariableGroup] = useState('colors');
    const [tab, setTab] = useState('variables');

    return (
        <div className="runner-editor flex-grow overflow-hidden is-flex flex-col">
            <Header
                versions={state.context.versions}
                version={state.context.version}
                onVersion={onVersion}
                onNewVersion={onNewVersion}
                users={state.context.users}
                modified={state.context.item ? state.context.item.modified : null}
            />

            <div className="p overflow-y-auto">
                <div>
                    {
                        state.matches('edit') ? (
                            <>
                                <div className="box">
                                    <div className="is-flex mb runner-header">
                                        <div className="field flex-grow mr">
                                            <div className="control">
                                                <input className="input is-large" type="text" placeholder="Theme Name" value={state.context.item.name} onChange={onNameChange} disabled={state.context.item.readonly} data-testid="theme-name" />
                                            </div>
                                        </div>
                                        <button className="button is-danger is-small is-outlined" onClick={onDelete} disabled={state.context.item.readonly}>Delete</button>
                                    </div>

                                    <div className="tabs" role="tablist">
                                        <ul>
                                            <li onClick={() => setTab('variables')} className={classnames({ 'is-active': tab === 'variables' })} role="tab"><a>Variables</a></li>
                                            <li onClick={() => setTab('meta')} className={classnames({ 'is-active': tab === 'meta' })} role="tab"><a>Meta Tags</a></li>
                                            <li onClick={() => setTab('stylesheets')} className={classnames({ 'is-active': tab === 'stylesheets' })} role="tab"><a>Stylesheets</a></li>
                                            <li onClick={() => setTab('styles')} className={classnames({ 'is-active': tab === 'styles' })} role="tab"><a>Inline Styles</a></li>
                                        </ul>
                                    </div>

                                    {
                                        tab === 'variables' ? (
                                            <>
                                                <aside className="menu">
                                                    <ul className="menu-list" role="menu">
                                                        <li onClick={() => setSelectedVariableGroup('colors')} role="menuitem">
                                                            <a className={classnames({ 'is-active': selectedVariableGroup === 'colors' })}>Colors</a>
                                                        </li>
                                                        <li onClick={() => setSelectedVariableGroup('grid')} role="menuitem">
                                                            <a className={classnames({ 'is-active': selectedVariableGroup === 'grid' })}>Grid</a>
                                                        </li>
                                                        <li onClick={() => setSelectedVariableGroup('typography')} role="menuitem">
                                                            <a className={classnames({ 'is-active': selectedVariableGroup === 'typography' })}>Typography</a>
                                                        </li>
                                                        <li onClick={() => setSelectedVariableGroup('borders')} role="menuitem">
                                                            <a className={classnames({ 'is-active': selectedVariableGroup === 'borders' })}>Borders</a>
                                                        </li>
                                                        <li onClick={() => setSelectedVariableGroup('forms')} role="menuitem">
                                                            <a className={classnames({ 'is-active': selectedVariableGroup === 'forms' })}>Forms</a>
                                                        </li>
                                                        <li onClick={() => setSelectedVariableGroup('buttons')} role="menuitem">
                                                            <a className={classnames({ 'is-active': selectedVariableGroup === 'buttons' })}>Buttons</a>
                                                        </li>
                                                        <li onClick={() => setSelectedVariableGroup('tables')} role="menuitem">
                                                            <a className={classnames({ 'is-active': selectedVariableGroup === 'tables' })}>Tables</a>
                                                        </li>
                                                        <li onClick={() => setSelectedVariableGroup('navigation')} role="menuitem">
                                                            <a className={classnames({ 'is-active': selectedVariableGroup === 'navigation' })}>Navigation</a>
                                                        </li>
                                                        <li onClick={() => setSelectedVariableGroup('tabs')} role="menuitem">
                                                            <a className={classnames({ 'is-active': selectedVariableGroup === 'tabs' })}>Tabs</a>
                                                        </li>
                                                        <li onClick={() => setSelectedVariableGroup('alert')} role="menuitem">
                                                            <a className={classnames({ 'is-active': selectedVariableGroup === 'alert' })}>Alert</a>
                                                        </li>
                                                        <li onClick={() => setSelectedVariableGroup('modaldialog')} role="menuitem">
                                                            <a className={classnames({ 'is-active': selectedVariableGroup === 'modaldialog' })}>Modal Dialog</a>
                                                        </li>
                                                    </ul>
                                                </aside>

                                                <datalist id="variables">
                                                    <option value="var(--primary)" />
                                                    <option value="var(--primary-light)" />
                                                    <option value="var(--primary-dark)" />
                                                    <option value="var(--secondary)" />
                                                    <option value="var(--secondary-light)" />
                                                    <option value="var(--secondary-dark)" />
                                                    <option value="var(--success)" />
                                                    <option value="var(--success-light)" />
                                                    <option value="var(--success-dark)" />
                                                    <option value="var(--warning)" />
                                                    <option value="var(--warning-light)" />
                                                    <option value="var(--warning-dark)" />
                                                    <option value="var(--danger)" />
                                                    <option value="var(--danger-light)" />
                                                    <option value="var(--danger-dark)" />
                                                    <option value="var(--gray)" />
                                                    <option value="var(--gray-light)" />
                                                    <option value="var(--gray-dark)" />
                                                    <option value="var(--black)" />
                                                    <option value="var(--border-color)" />
                                                    <option value="var(--border-width)" />
                                                    <option value="var(--border-radius)" />
                                                </datalist>

                                                {selectedVariableGroup === 'colors' && <Colors runner={state.context.item} onChange={onVariableChange} disabled={state.context.item!.readonly} />}
                                                {selectedVariableGroup === 'grid' && <Grid runner={state.context.item} onChange={onVariableChange} disabled={state.context.item!.readonly} />}
                                                {selectedVariableGroup === 'typography' && <Typography runner={state.context.item} onChange={onVariableChange} disabled={state.context.item!.readonly} />}
                                                {selectedVariableGroup === 'borders' && <Borders runner={state.context.item} onChange={onVariableChange} disabled={state.context.item!.readonly} />}
                                                {selectedVariableGroup === 'forms' && <Forms runner={state.context.item} onChange={onVariableChange} disabled={state.context.item!.readonly} />}
                                                {selectedVariableGroup === 'buttons' && <Buttons runner={state.context.item} onChange={onVariableChange} disabled={state.context.item!.readonly} />}
                                                {selectedVariableGroup === 'tables' && <Tables runner={state.context.item} onChange={onVariableChange} disabled={state.context.item!.readonly} />}
                                                {selectedVariableGroup === 'navigation' && <Navigation runner={state.context.item} onChange={onVariableChange} disabled={state.context.item!.readonly} send={send} />}
                                                {selectedVariableGroup === 'tabs' && <Tabs runner={state.context.item} onChange={onVariableChange} disabled={state.context.item!.readonly} />}
                                                {selectedVariableGroup === 'alert' && <Alerts runner={state.context.item} onChange={onVariableChange} disabled={state.context.item!.readonly} />}
                                                {selectedVariableGroup === 'modaldialog' && <ModalDialog runner={state.context.item} onChange={onVariableChange} disabled={state.context.item!.readonly} />}
                                            </>
                                        ) : null
                                    }

                                    {
                                        tab === 'meta' ? (
                                            <div className="field">
                                                <label className="label">Meta</label>
                                                <CodeMirror
                                                    value={state.context.item!.meta}
                                                    options={{
                                                        theme: 'material',
                                                        mode: 'htmlmixed',
                                                        lineNumbers: true,
                                                    }}
                                                    onBeforeChange={(editor, data, meta) => {
                                                        send({ type: 'CHANGE', value: { meta } });
                                                    }}
                                                />
                                            </div>
                                        ) : null
                                    }

                                    {
                                        tab === 'stylesheets' ? (
                                            <div className="field">
                                                <label className="label">Stylesheets</label>
                                                <CodeMirror
                                                    value={state.context.item!.stylesheets}
                                                    options={{
                                                        theme: 'material',
                                                        mode: 'htmlmixed',
                                                        lineNumbers: true,
                                                    }}
                                                    onBeforeChange={(editor, data, stylesheets) => {
                                                        send({ type: 'CHANGE', value: { stylesheets } });
                                                    }}
                                                />
                                            </div>
                                        ) : null
                                    }

                                    {
                                        tab === 'styles' ? (
                                            <div className="field">
                                                <label className="label">Inline Styles</label>
                                                <CodeMirror
                                                    value={state.context.item!.styles}
                                                    options={{
                                                        theme: 'material',
                                                        mode: 'css',
                                                        lineNumbers: true,
                                                    }}
                                                    onBeforeChange={(editor, data, styles) => {
                                                        send({ type: 'CHANGE', value: { styles } });
                                                    }}
                                                />
                                            </div>
                                        ) : null
                                    }
                                </div>

                                <div className="columns">
                                    <div className="column">
                                        <div className="card mb">
                                            <header className="card-header">
                                                <p className="card-header-title">
                                                    Uses
                                                </p>
                                            </header>
                                            <div className="card-content">
                                                <Dependencies org={state.context.org} workspace={state.context.workspace} dependencies={state.context.uses} onClick={onDependencyClick} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="column">
                                        <div className="card mb">
                                            <header className="card-header">
                                                <p className="card-header-title">
                                                    Used By
                                                </p>
                                            </header>
                                            <div className="card-content">
                                                <Dependencies org={state.context.org} workspace={state.context.workspace} dependencies={state.context.usedBy} onClick={onDependencyClick} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="column">
                                        <div className="card">
                                            <header className="card-header">
                                                <p className="card-header-title">
                                                    History
                                                </p>
                                            </header>
                                            <div className="card-content">
                                                <History versions={state.context.versions} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null
                    }
                </div>
                {
                    isNewVersionVisible ? (
                        <NewVersion
                            org={state.context.org}
                            workspace={state.context.workspace}
                            id={state.context.id}
                            type="runner"
                            onNewVersion={onNewVersionCreated}
                            onClose={onCloseNewVersion}
                        />
                        ) : null
                }
                <Delete state={state} send={send} />
            </div>
        </div>
    );
}