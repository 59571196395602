import React, { FormEvent, useEffect, useState } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { describe, version } from '../../../socket';

interface IDescribe {
    [name: string]: {
        type: string
        changes: any[],
        version: {
            major: number,
            minor: number,
            patch: number
        }
    }
}

interface INewVersionProps {
    org: string
    workspace: string
    id: string
    type: string
    onNewVersion: () => void
    onClose: () => void
}

export default ({ org, workspace, id, type, onNewVersion, onClose }: INewVersionProps) => {
    
    const [describeResponse, setDescribeResponse] = useState<IDescribe | null>(null);
    const [isLoading, setLoading] = useState(true);
    const [major, setMajor] = useState(0);
    const [minor, setMinor] = useState(0);
    const [patch, setPatch] = useState(0);

    const onMajorChange = (e: FormEvent<HTMLInputElement>) => setMajor(e.currentTarget.valueAsNumber);
    const onMinorChange = (e: FormEvent<HTMLInputElement>) => setMinor(e.currentTarget.valueAsNumber)
    const onPatchChange = (e: FormEvent<HTMLInputElement>) => setPatch(e.currentTarget.valueAsNumber)

    const hasChanges = () => describeResponse && (describeResponse[''].changes.length > 0 || Object.keys(describeResponse).length > 1);

    const onCreateNewVersion = () => {
        setLoading(true);
        version(org, workspace, id, `${major}.${minor}.${patch}`, type, () => onNewVersion());
    }

    useEffect(() => {
        describe(org, workspace, id, type, (response) => {
            setDescribeResponse(response);
            setLoading(false);

            if (response[''].version) {
                setMajor(response[''].version.major);
                setMinor(response[''].version.minor);
                setPatch(response[''].version.patch);
            }
        });
    }, [org, workspace, id, type]);

    const invalidVersion = _.isNil(major) 
        || _.isNaN(major)
        || _.isNil(minor)
        || _.isNaN(minor)
        || _.isNil(patch)
        || _.isNaN(patch);

    let content: JSX.Element | null = null;
    if (!isLoading && hasChanges() && describeResponse) {
        content = (
            <>
                <h4 className="is-size-4 mb">Changes</h4>
                <ul className="mb">
                    {(describeResponse[''].changes || []).map((item: any) => (<li>{item.change}</li>))}
                </ul>

                {
                    _.chain(_.keys(describeResponse))
                        .filter(key => key !== '')
                        .map(key => {
                            return (
                                <>
                                    <h4 className="is-size-5">{key} <small><em>{describeResponse[key].type}</em></small></h4>
                                    <ul className="mb">
                                        {_.map(describeResponse[key].changes, (item) => <li>{item.change}</li>)}
                                    </ul>
                                </>
                            )
                        })
                        .value()
                }

                <h4 className="is-size-4 mb">Version Number</h4>
                <p className="mb">Recommend version number based on current changes: {describeResponse[''].version.major}.{describeResponse[''].version.minor}.{describeResponse[''].version.patch}</p>
                <div className="is-flex mb">
                    <div className="field mr">
                        <label className="label">Major</label>
                        <div className="control">
                            <input className="input" type="number" placeholder="Major" value={major} onChange={onMajorChange} />
                        </div>
                    </div>
                    <div className="field mr">
                        <label className="label">Minor</label>
                        <div className="control">
                            <input className="input" type="number" placeholder="Minor" value={minor} onChange={onMinorChange} />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">Patch</label>
                        <div className="control">
                            <input className="input" type="number" placeholder="Patch" value={patch} onChange={onPatchChange} />
                        </div>
                    </div>
                </div>
            </>
        )
    }
    else if (!isLoading && !hasChanges()) {
        content = (
            <>
                <h3 className="is-size-3 has-text-centered mb">No Changes</h3>
            </>
        )
    }

    const className = classnames('modal', 'new-version', 'is-active')

    return (
        <div className={className}>
            <div className="modal-background"></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">New Version</p>
                    <button className="delete" aria-label="close" onClick={onClose}></button>
                </header>
                <section className="modal-card-body">
                   {content}
                </section>
                <footer className="modal-card-foot">
                    <button className="button" onClick={onClose}>Cancel</button>
                    <button className="button is-success" onClick={onCreateNewVersion} disabled={!hasChanges() || invalidVersion}>Create New Version</button>
                </footer>
            </div>
            <button className="modal-close is-large" aria-label="close" onClick={onClose}></button>
        </div>
    )
}