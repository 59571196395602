import React from "react";
import { Context, Event } from '../../../machines/build/versionable-items';
import Group from './RootGroup';
import Error from '../../Error';
import "./rules.css";
import { useActor } from "@xstate/react";
import Sidebar from "../editing/Sidebar";
import { ActorRefFrom, StateMachine } from "xstate";

const Rules = ({ machine }: { machine: ActorRefFrom<StateMachine<Context, any, Event>> }) => {
    const [state, send] = useActor(machine);

    const onNew = () => send('NEW');

    let editor: JSX.Element | null = null;
    if (state.context.selectedItemActor)
        editor = <Group machine={state.context.selectedItemActor} />
    else
        editor = <div className="has-text-centered is-flex justify-center flex-col flex-grow items-center">
            <h2 className="title mb is-size-1">Rules</h2>
            <h3 className="subtitle">Logic gates and permissioning</h3>
            <div className="flip-container mb">
                <div className="flipper">
                    <div className="front">
                        <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 39.687 39.688" className="mb"> 
                            <path d="M.508 11.904L11.785.557l15.998-.05L39.13 11.786l.05 15.998L27.902 39.13l-15.998.05L.557 27.902z" fill="#34495e" stroke="#34495e" strokeWidth="1.014" />
                            <path d="M1.797 12.434L12.322 1.843l14.932-.046 10.59 10.525.047 14.932-10.526 10.59-14.931.047L1.843 27.365z" fill="none" stroke="#fff" strokeWidth=".947" />
                            <text x="5.55" y="23.691" font-weight="400" font-size="10.583" font-family="sans-serif" fill="#fff" strokeWidth=".265">
                                <tspan x="5.55" y="23.691">STOP</tspan>
                            </text>
                        </svg>
                    </div>
                    <div className="back">
                        <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 39.687 39.688">
                            <circle cx="19.844" cy="19.844" r="19.844" fill="#34495e"/>
                            <text x="11.573" y="23.691" font-weight="400" font-size="10.583" font-family="sans-serif" fill="#fff" strokeWidth=".265">
                                <tspan x="11.573" y="23.691">GO</tspan>
                            </text>
                        </svg>
                    </div>
                </div>
            </div>
            <button className="button is-primary is-large" style={{ alignSelf: 'center' }} onClick={onNew}>Create a New Rule Group</button>
        </div>

    return (
        <div className="groups">
            <Sidebar 
                items={state.context.items}
                selected={state.context.selectedItemId}
                send={send}
                loading={state.matches('loading') || state.matches('filtering')}
                type="Screen"
                allowNew={true}
            />
            {editor}
            <Error error={state.context.error} />
        </div>
    );
};

export default Rules;
