import React, { FormEvent } from 'react';
import { INavigation, INavigationItem, IState } from '../../../../../types/app';
import _ from 'lodash';
import IconPicker from '../IconPicker';

interface INavigationItemProps {
    value: INavigationItem
    states: IState[]
    disabled: boolean
    onChange: (value: INavigationItem) => void
    onDelete: (order: number) => void
}

const Item = ({ value, states, disabled, onChange, onDelete }: INavigationItemProps) => {

    const onIconChange = (icon: string) => onChange({ ...value, icon });
    const onLabelChange = (e: FormEvent<HTMLInputElement>) => onChange({ ...value, label: e.currentTarget.value });
    const onTargetChange = (e: FormEvent<HTMLSelectElement>) => onChange({ ...value, target: e.currentTarget.value });

    return (
        <tr key={value.order}>
            <td>
                <IconPicker value={value.icon} onChange={onIconChange} disabled={disabled} />
            </td>
            <td>
                <input className="input" value={value.label} onChange={onLabelChange} disabled={disabled} />
            </td>
            <td>
                <div className="select is-fullwidth">
                    <select value={value.target} onChange={onTargetChange} disabled={disabled}>
                        {[
                            <option>Select a state</option>,
                            ..._.map(states, (state) => <option value={state.id}>{state.name}</option>)
                        ]}
                    </select>
                </div>
            </td>
            <td>
                <button className="button is-small is-outlined" onClick={onDelete.bind(null, value.order)} disabled={disabled}>Delete</button>
            </td>
        </tr>
    )
}

interface INavigationProps {
    value: INavigation
    states: IState[]
    onChange: (value: INavigation) => void
    disabled: boolean
}

const Navigation = ({ value, states, onChange, disabled }: INavigationProps) => {

    const onEnabledChanged = () => onChange({ ...value, enabled: !value.enabled });
    
    const onAddNavigationItem = () => onChange({
        ...value,
        items: [
            ...value.items || [],
            { target: '', label: '', order: value.items?.length || 0, icon: '' }
        ]
    });

    const onDeleteNavigationItem = (order: number) => onChange({
        ...value,
        items: value.items.filter(item => item.order !== order)
    });
    
    const onChangeNavigationItem = (newItem: INavigationItem) => onChange({
        ...value,
        items: value.items.map(item => item.order === newItem.order ? newItem : item)
    });

    return (
        <div role="tabpanel">
            <div className="field" onClick={onEnabledChanged}>
                <input type="checkbox" className="switch is-rounded" checked={value.enabled} disabled={disabled} />
                <label>Show a navigation bar</label>
            </div>
            <div className="field">
                <label className="label">Items</label>
                <table className="table is-striped is-hoverable is-fullwidth">
                    <thead>
                        <tr>
                            <td>Icon</td>
                            <td>Label</td>
                            <td>Navigate to State</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            _.map(
                                value.items,
                                (item) => <Item value={item} states={states} onChange={onChangeNavigationItem} onDelete={onDeleteNavigationItem}  disabled={disabled} />
                            )
                        }
                    </tbody>
                </table>

                <button className="button is-small is-link is-outlined" onClick={onAddNavigationItem} disabled={disabled}>Add Navigation Item</button>
            </div>
        </div>
    );
}

export default Navigation;