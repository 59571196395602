import React, { useState, FormEvent } from "react";
import { useHotkeys } from 'react-hotkeys-hook';
import classnames from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import Loader from '../../loader';
import "./sidebar.css";
import { IVersionableReference } from "../../../../../types/versionable";
import _ from "lodash";

export interface ISidebarProps {
    items: IVersionableReference[] | undefined
    selected: string | null | undefined
    loading: boolean
    send: Function
    type: string
    allowNew: boolean
}

const Sidebar = ({ items, selected, loading, send, type, allowNew }: ISidebarProps) => {
    const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void

    const [search, setSearch] = useState('');
    const [sort, setSort] = useState('name');

    const toggle = () => {
        const collapsed = sessionStorage.getItem('appwire-design-sidebar-collapsed') === 'true';
        sessionStorage.setItem('appwire-design-sidebar-collapsed', (!collapsed).toString());
        forceUpdate();
    }

    useHotkeys('ctrl+alt+o', toggle);

    const onSelect = (value: string) => send({ type: 'SELECT', value });
    const onNew = () => send('NEW');
    const onSearchChange = (e: FormEvent<HTMLInputElement>) => setSearch(e.currentTarget.value);
    const onSortChange = (e: FormEvent<HTMLSelectElement>) => setSort(e.currentTarget.value);

    const filteredItems = (items || []).filter((item) => item.name.toUpperCase().indexOf(search.toUpperCase()) !== -1);

    const collapsed = sessionStorage.getItem('appwire-design-sidebar-collapsed') === 'true';
    const sidebarClassName = classnames('sidebar', { collapsed });

    return (
        <div className={sidebarClassName}>
            <header>
                <button className="button mb expand" onClick={toggle} title="Ctrl + Alt + O">
                    <span className="icon">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </span>
                </button>

                <div className="is-flex mb">
                    {allowNew ? <button className="button flex-grow mr" onClick={onNew}>New {type}</button> : null}

                    <button className="button collapse" onClick={toggle} title="Ctrl + Alt + O">
                        <span className="icon">
                            <FontAwesomeIcon icon={faAngleDoubleLeft} />
                        </span>
                    </button>
                </div>

                <div className="sort-by">
                    <input
                        className="input"
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={onSearchChange}
                    />

                    <div className="select">
                        <select value={sort} onChange={onSortChange}>
                            <option value="name">Name</option>
                            <option value="when">Modified</option>
                        </select>
                    </div>
                </div>
            </header>

            <ol>
                {
                    _.chain(filteredItems)
                        .orderBy(sort, sort === 'name' ? 'asc' : 'desc')
                        .map((item) => (
                            <li
                                className={`${selected && item.id === selected ? 'is-selected has-background-primary' : ''}`}
                                onClick={onSelect.bind(null, item.id)}
                                key={item.id}
                                data-testid={item.id}
                                id={item.id}
                            >
                                {item.name}
                            </li>
                        ))
                        .value()
                }
            </ol>
            {loading ? <Loader /> : null}
        </div>
    );
};

export default Sidebar;
