import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faGripLines, faCirclePause } from '@fortawesome/free-solid-svg-icons'
import { faCirclePause as farCirclePause } from '@fortawesome/free-regular-svg-icons'
import { IVersionableReference } from '../../../../../types/versionable';
import FeatureFlagSelector from '../featureFlags/FeatureFlagSelector';

interface Props {
    item?: IVersionableReference,
    version: string,
    grouped: boolean,
    disabled: boolean
    onVersion: any,
    onRemove: (id: string) => void,
    onDisable?: (id: string) => void,
    onBreakpoint?: (id: string) => void
    onFeatureFlag?: (id: string, featureFlags: string[]) => void
}

export default ({ item, version, grouped, disabled, onVersion, onDisable, onBreakpoint, onRemove, onFeatureFlag }: Props) => {
    if (!item)
        return null;

    const onFeatureFlagChange = (featureFlags: string[]) => {
        onFeatureFlag && onFeatureFlag(item.id, featureFlags);
    }

    return (
        <>
            {grouped ? <FontAwesomeIcon icon={faGripLines} /> : null}
            {
                onBreakpoint ? (
                    <span className="icon breakpoint-toggle" role="button" aria-checked={item.breakpoint} onClick={onBreakpoint.bind(null, item.id)}>
                        {item.breakpoint ? <FontAwesomeIcon icon={faCirclePause} /> : <FontAwesomeIcon icon={farCirclePause} />}
                    </span>
                ): null
            }
            <p className="flex-grow">{item.name}</p>
            {onFeatureFlag ? <FeatureFlagSelector value={item.featureFlags} onChange={onFeatureFlagChange!} /> : null}
            {
                onDisable ? (
                    <div className="mr" onClick={() => onDisable(item.id)} style={{ marginTop: '4px' }} title="Toggle enabled / disabled">
                        <input type="checkbox" className="switch is-rounded" checked={!item!.disabled} />
                        <label></label>
                    </div>
                ) : null
            }
            <div className="select is-small mr">
                <select value={version} onChange={onVersion} data-id={item.id} disabled={disabled} title="Version">
                    {
                        item.versions.map((version: any) => <option value={version} key={version}>{version}</option>)
                    }
                </select>
            </div>
            <span className="icon" onClick={onRemove.bind(null, item.id)}>
                <FontAwesomeIcon icon={faTimes} />
            </span>
        </>
    )
}