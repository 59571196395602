/* eslint-disable react/display-name */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faTabletAlt, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import ReactGridLayout, { WidthProvider, Layout } from 'react-grid-layout';
import classnames from 'classnames';
import _ from 'lodash';
import { get } from '../versionable/references';

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css';
import { IComponent, ILayout, IProperty, IScreen } from '../../../../../types/screen';

const titles = {
    alert: "Alert",
    button: "Button",
    checkbox: "Checkbox",
    display: "Display Content",
    'file-upload': "File Upload",
    heading: "Heading",
    image: "Image",
    date: "Input Date",
    'date-time': "Input Date & Time",
    modal: 'Modal Dialog',
    number: "Input Number",
    text: "Input Text",
    textarea: "Input Text - Multiline",
    time: "Input Time",
    progress: "Progress",
    repeater: "Repeater",
    screen: "Screen",
    select: "Select",
    slider: "Slider",
    table: "Table",
    tabs: "Tabs",
    list: "List",
    tree: "Tree",
    chart: "Chart",
    'world-map': "World Map"
}

const propertySummary = (property: IProperty) => {
    if (!property || !property.value)
        return '';

    if (property.kind === 'action')
        return get('action', property.value.id)?.name;

    if (property.kind === 'screen')
        return get('screen', property.value.id)?.name;

    if (property.kind === 'columns')
        return property.value.auto ? 'Auto Columns' : `${_.size(property.value.columns)} columns`;

    return property.value.literal || property.value.name || property.value.path || '';
}

const summary = (...summaries) => {
    return summaries.filter(s => !!s).join(' | ');
}

const summaries = {
    alert: (component: IComponent) => ``,
    button: (component: IComponent) => summary(propertySummary(component.properties.label), component.triggers.onclick?.event),
    checkbox: (component: IComponent) => summary(propertySummary(component.properties.label), propertySummary(component.properties.value)),
    display: (component: IComponent) => ``,
    'file-upload': (component: IComponent) => summary(propertySummary(component.properties.uploadAction), component.triggers.onupload?.event),
    heading: (component: IComponent) => summary(propertySummary(component.properties.title)),
    date: (component: IComponent) => summary(propertySummary(component.properties.label), propertySummary(component.properties.value)),
    'date-time': (component: IComponent) => summary(propertySummary(component.properties.label), propertySummary(component.properties.value)),
    image: (component: IComponent) => summary(propertySummary(component.properties.url)),
    number: (component: IComponent) => summary(propertySummary(component.properties.label), propertySummary(component.properties.value)),
    slider: (component: IComponent) => summary(propertySummary(component.properties.label), propertySummary(component.properties.value)),
    text: (component: IComponent) => summary(propertySummary(component.properties.label), propertySummary(component.properties.value)),
    textarea: (component: IComponent) => summary(propertySummary(component.properties.label), propertySummary(component.properties.value)),
    time: (component: IComponent) => summary(propertySummary(component.properties.label), propertySummary(component.properties.value)),
    progress: (component: IComponent) => summary(propertySummary(component.properties.label), propertySummary(component.properties.valueUnknown)),
    repeater: (component: IComponent) => summary(propertySummary(component.properties.screen), propertySummary(component.properties.context)),
    screen: (component: IComponent) => summary(propertySummary(component.properties.label), propertySummary(component.properties.screen)),
    select: (component: IComponent) => summary(propertySummary(component.properties.label), propertySummary(component.properties.dataSource) || propertySummary(component.properties.dataSourceAction), propertySummary(component.properties.value)),
    table: (component: IComponent) => summary(propertySummary(component.properties.columns), propertySummary(component.properties.dataSource) || propertySummary(component.properties.dataSourceAction), propertySummary(component.properties.value)),
    tabs: (component: IComponent) => summary(propertySummary(component.properties.label), propertySummary(component.properties.value)),
    modal: (component: IComponent) => summary(propertySummary(component.properties.title), propertySummary(component.properties.screen)),
    tree: (component: IComponent) => summary(propertySummary(component.properties.label), propertySummary(component.properties.dataSource) || propertySummary(component.properties.dataSourceAction), propertySummary(component.properties.value)),
    list: (component: IComponent) => summary(propertySummary(component.properties.label), propertySummary(component.properties.dataSource) || propertySummary(component.properties.dataSourceAction), propertySummary(component.properties.value)),
    chart: (component: IComponent) => ``,
    'world-map': (component: IComponent) => ``,
    unknown: (component: IComponent) => ``
}

const Component = ({ item, device }: { item: IComponent, device: string }) => {
    const title = item.name ? item.name : titles[item.type];

    return (
        <>
            <span className="component-position">{`${item.layouts[device].x}, ${item.layouts[device].y}, ${item.layouts[device].w}, ${item.layouts[device].h}`}</span>
            <div className="box component-preview">
                <p title={title}><strong>{title}</strong></p>
                <p title={(summaries[item.type] || summaries.unknown)(item)}>{(summaries[item.type] || summaries.unknown)(item)}</p>
            </div>
        </>
    )
}

const GridLayout = WidthProvider(ReactGridLayout);

interface IGridProps {
    screen: IScreen
    device: string
    selectedComponentId: string | null
    onAddComponent: () => void
    onSelectComponent: (id: string) => void
    onDeSelectComponent: () => void
    onLayoutChange: (layouts: Record<string, ILayout>) => void
    onSelectDevice: (device: string) => void;
}

export default ({ screen, device, selectedComponentId, onAddComponent, onSelectComponent, onDeSelectComponent, onLayoutChange, onSelectDevice }: IGridProps) => {
    const onSelectDesktop = () => onSelectDevice('lg');
    const onSelectTablet = () => onSelectDevice('md');
    const onSelectMobile= () => onSelectDevice('sm');

    const onComponentClick = (e: React.MouseEvent<HTMLDivElement>) => {
        onSelectComponent(e.currentTarget.id);
        e.preventDefault()
        e.stopPropagation()
    }

    const onGridLayoutChange = (layout: Layout[]) => {
        onLayoutChange(_.keyBy(layout.map(item => _.pick(item, 'i', 'x', 'y', 'w', 'h')), 'i'));
    }

    const layout = Object
        .values(screen.components)
        .map((component) => ({
            ...(component.layouts)[device],
            i: component.id,
            ...(screen.readonly ? { isDraggable: false, isResizable: false } : {})
        }));

    return (
        <div className="layout" onClick={onDeSelectComponent}>
            <div className="is-flex mb justify-between">
                <button className="button is-primary mr" onClick={onAddComponent} disabled={screen.readonly}>Add Component</button>

                <div className="field has-addons">
                    <p className="control">
                        <button className={classnames('button', { 'is-info': device === 'lg' })} onClick={onSelectDesktop}>
                            <span className="icon is-small">
                                <FontAwesomeIcon icon={faDesktop} />
                            </span>
                            <span>Desktop</span>
                        </button>
                    </p>
                    <p className="control">
                        <button className={classnames('button', { 'is-info': device === 'md' })} onClick={onSelectTablet}>
                            <span className="icon is-small">
                                <FontAwesomeIcon icon={faTabletAlt} />
                            </span>
                            <span>Tablet</span>
                        </button>
                    </p>
                    <p className="control">
                        <button className={classnames('button', { 'is-info': device === 'sm' })} onClick={onSelectMobile}>
                            <span className="icon is-small">
                                <FontAwesomeIcon icon={faMobileAlt} />
                            </span>
                            <span>Mobile</span>
                        </button>
                    </p>
                </div>
            </div>
            <div className="grid-container has-background-grey-lighter">
                <GridLayout isBounded={true} compactType={null} cols={12} rowHeight={120} layout={layout} resizeHandles={['ne', 'se', 'sw']} onDragStop={onGridLayoutChange} onResizeStop={onGridLayoutChange}>
                    {
                        Object
                            .values(screen.components)
                            .map((item: any) => 
                                <div
                                    key={item.id}
                                    id={item.id}
                                    className={classnames({ selected: selectedComponentId === item.id})}
                                    onClick={onComponentClick}
                                >
                                    <Component item={item} device={device} />
                                </div> 
                        )
                    }
                </GridLayout>
            </div>
        </div>
    )
}
