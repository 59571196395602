import { applyPatch, compare } from 'fast-json-patch';
import _ from 'lodash';
import { Machine, actions, send, forwardTo } from 'xstate';
import { cancel } from 'xstate/lib/actions';
import { IUser } from '../../../../../types/user';
import { deployment } from '../../../socket';

const { assign } = actions;

export interface Schema {
    states: {
        loading: {},
        edit: {},
        error: {}
    }
}

type LoadedEvent = { type: 'LOADED', release: IRelease, apps: any }
type ChangeEvent = { type: 'CHANGE', value: Partial<IRelease> };
type PatchedEvent = { type: 'PATCHED' };
type ChangedEvent = { type: 'CHANGED', patch: any[] }
type DeployEvent = { type: 'DEPLOY', environment: string }
type RollbackEvent = { type: 'ROLLBACK', environment: string }
type DeploysEvent = { type: 'DEPLOYS', environments: IEnvironment[] }
type AddAppEvent = { type: 'ADD_APP', app: any }
type RemoveAppEvent = { type: 'REMOVE_APP', app: any }
type AddedAppEvent = { type: 'ADDED_APP', app: any }
type RemovedAppEvent = { type: 'REMOVED_APP', app: any }

export type Event =
    | LoadedEvent
    | ChangeEvent
    | PatchedEvent
    | ChangedEvent
    | DeployEvent
    | RollbackEvent
    | DeploysEvent
    | { type: 'DISMISS' }
    | AddAppEvent
    | RemoveAppEvent
    | AddedAppEvent
    | RemovedAppEvent

interface IRelease {
    id: string
    version: string
    summary: string
    status: 'GOOD' | 'TESTING' | 'BROKEN' | 'WAITING'
    apps: [{
        id: string
        name: string
        version: string
    }]
    modified: string
}

interface IApp {
    id: string
    name: string
    versions: string[]
}

interface IEnvironment {
    id: string
    name: string
    when: string
    by: string
}

export interface Context {
    error?: string | null,
    id: string,
    oldRelease?: IRelease
    release?: IRelease,
    apps?: {
        [id: string]: IApp
    }
    environments?: IEnvironment[]
    search?: string
    machine?: any
    org: string
    workspace: string
    users?: IUser[]
}

const realtime = (context: Context, event: Event) => (callback: any, onReceive: any) => {
    const onLoad = ({ release, apps, environments }) => callback({ type: 'LOADED', release, apps, environments });

    deployment.releases.release(context.org, context.workspace, context.id, onLoad);

    onReceive((e: any) => {
        switch (e.type) {
            case 'PATCH':
                deployment.releases.change(context.org, context.workspace, context.id, e.patches);
                callback('PATCHED');
                break;

            case 'ADD_APP':
                deployment.releases.add(context.org, context.workspace, context.id, e.app);
                break;

            case 'REMOVE_APP':
                deployment.releases.remove(context.org, context.workspace, context.id, e.app);
                break;
            
            case 'DEPLOY':
                deployment.releases.deploy(context.org, context.workspace, context.id, e.environment, (environments) => callback({ type: 'DEPLOYS', environments }));
                break;

            case 'ROLLBACK':
                deployment.releases.rollback(context.org, context.workspace, context.id, e.environment, (environments) => callback({ type: 'DEPLOYS', environments }))
                break;
        }
    })


    return () => {};
}

export const machine = Machine<Context, Schema, Event>({
    id: 'deployment-release',
    initial: 'loading',
    invoke: {
        id: 'socket',
        src: realtime
    },
    states: {
        loading: {
            on: {
                LOADED: {
                    actions: ['loaded', 'copy'],
                    target: 'edit'
                }
            }
        },
        edit: {
            on: {
                CHANGE: {
                    actions: ['change', 'cancelPatch', 'patch']
                },
                PATCHED: {
                    actions: ['copy']
                },
                CHANGED: [
                    { actions: 'changed' },
                ],
                ADD_APP: {
                    actions: ['addApp', forwardTo('socket')]
                },
                REMOVE_APP: {
                    actions: ['removeApp', forwardTo('socket')]
                },
                DEPLOY: {
                    actions: ['deploy']
                },
                ROLLBACK: {
                    actions: ['rollback']
                },
                DEPLOYS: {
                    actions: ['deploys']
                }
            }
        },
        error: {
            on: {
                DISMISS: 'edit'
            }
        }
    }
}, {
    actions: {
        loaded: assign<Context, any>({
            release: (_, event) => event.release,
            apps: (context, event) => _.keyBy(event.apps, 'id'),
            environments: (_, event) => event.environments,
        }),
        change: assign<Context, any>({
            release: (context, event) => ({ ...context.release, ...event.value } as IRelease)
        }),
        patch: send(
            (context, event) => ({
                type: 'PATCH',
                release: _.get(context, 'release.id'),
                patches: compare(context.oldRelease!, context.release!),
            }),
            {
                id: 'patch',
                to: 'socket',
                delay: 300
            }
        ),
        cancelPatch: cancel('patch'),
        changed: assign<Context, any>({
            release: (context, event) => ({
                ...context.release,
                ...applyPatch(context.release, event.patch).newDocument
            } as IRelease),
        }),
        copy: assign({
            oldRelease: (context) => context.release
        }),
        addApp: assign<Context, any>({
            release: (context, event) => ({
                ...context.release,
                apps: _.concat(context.release?.apps, [event.app])
            } as IRelease)
        }),
        removeApp: assign<Context, any>({
            release: (context, event) => ({
                ...context.release,
                apps: _.filter(context.release?.apps, app => app.id !== event.app.id)
            } as IRelease)
        }),
        deploy: forwardTo('socket'),
        rollback: forwardTo('socket'),
        deploys: assign<Context, any>({
            environments: (context, event) => event.environments
        })
    }
})