import React, { useState } from "react";
import { Context, Event } from "../../machines/root";
import { State } from "xstate";
import classnames from "classnames";
import './testing.css';
import Suites from "./suites/Suites";

const Testing = ({ state }: { state: State<Context, Event, any> }) => {
	const tabs = [
		{ title: 'Test Suites', type: 'suites' },
		// { title: 'Assertions', type: 'assertions' },
		// { title: 'Fakes', type: 'fakes' },
		// { title: 'Test Cases', type: 'cases' },
	];

	const [active, setActive] = useState(tabs[0]);

	let editor: JSX.Element | null = null;
	switch (true) {
		case state.matches({ testing: 'suites' }):
			editor = <Suites org={state.context.org} workspace={state.context.workspace} />;
			break;

		case state.matches({ testing: 'assertions' }):
			break;

		case state.matches({ testing: 'fakes' }):
			break;

		case state.matches({ testing: 'cases' }):
			break;
	}

	return (
		<div className="testing">
			<div className="tabs is-boxed flex-shrink-0">
				<ul>
					{
						tabs.map((tab: any, index: number) => (
							<li className={classnames({ 'is-active': tab.type === active.type })} key={index}>
								<a onClick={setActive.bind(null, tab)}>
									<span>{tab.title}</span>
								</a>
							</li>
						))
					}
				</ul>
			</div>
			{editor}
		</div>
	);
};

export default Testing;
