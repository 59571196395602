import React, { useEffect } from "react";
import Navigation from "./Navigation";
import Deployment from './deployment/Deployment';
import Build from "./build/Build";
import Workspace from "./workspace/Workspace";
import Organization from "./organization/Organization";
import Reporting from "./reporting/Reporting";
import { State } from "xstate";
import { Context, Event, Schema } from "../machines/root";
import classnames from "classnames";
import Testing from "./testing/Testing";

const Application = ({ state, send }: { state: State<Context, Event, Schema>, send: Function }) => {
	useEffect(() => {
		const onBeforeUnload = () => {
			send({ type: 'UNLOAD' });
		};

		window.addEventListener("beforeunload", onBeforeUnload);
	})


	let view: JSX.Element | null = null;
	switch (true) {
		case state.matches('build'):
			view = <Build state={state} send={send} />;
			break;

		case state.matches('testing'):
			view = <Testing state={state} send={send} />;
			break;

		case state.matches('deployment'):
			view = <Deployment machine={state.context.deployment} />;
			break;

		case state.matches('reporting'):
			view = <Reporting machine={state.context.reporting} />;
			break;

		case state.matches('workspace'):
			view = <Workspace machine={state.context.workspaceMachine} />;
			break;

		case state.matches('organization'):
			view = <Organization machine={state.context.organizationMachine} />;
			break;
	}

	return (
		<div className="app is-flex flex-col">
			<Navigation state={state} send={send} />
			{view}
			<div className={classnames('modal', { 'is-active': state.context.disconnected })}>
				<div className="modal-background"></div>
				<div className="modal-content">
					<article className="message is-info">
						<div className="message-body">
							We've lost connection to AppWire. When the connection is restored we will refresh the browser automatically. Or you
							can manual refresh <a href={window.location.toString()}>here</a>
						</div>
					</article>
				</div>
			</div>
		</div>
	);
};

export default Application;
