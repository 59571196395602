import React, { FormEvent, useState } from "react";

export default ({ items, onSelect }: any) => {
    const [search, setSearch] = useState('');

    const onSearchChange = (e: FormEvent<HTMLInputElement>) => setSearch(e.currentTarget.value);

    const filtered = (items || []).filter((workspace: any) => workspace.name.toUpperCase().indexOf(search.toUpperCase()) !== -1);

    return (
        <div className="box workspaces">
            <h2 className="is-size-3 has-text-centered mb">Workspaces</h2>
            <div className="control mb">
                <input className="input" type="search" value={search} onChange={onSearchChange}/>
            </div>
            <nav className="panel">
                {filtered.map((workspace: any) => (
                    <a 
                        className="panel-block" 
                        onClick={() => onSelect(workspace)}
                        key={workspace.id}
                    >
                        {workspace.name}
                    </a>
                ))}
            </nav>
        </div>
    );
}