/* eslint-disable react/display-name */
import React, { FormEvent, useState } from 'react';
import ValueSelector from '../variable/ValueSelector';
import Header from '../editing/Header';
import Dependencies from '../editing/Dependencies';
import History from '../editing/History';
import Delete from './Delete';
import { useActor } from "@xstate/react";
import { Context, Event } from '../../../machines/build/versionable-item';
import './action-editor.css';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/markdown/markdown';
import NewVersion from '../editing/NewVersion';
import { ActorRefFrom, StateMachine } from 'xstate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/free-solid-svg-icons';
import { IActionEvent } from '../../../../../types/action';
import { IDependency } from '../../../../../types/versionable';
import { IVariable } from '../../../../../types/variable';

export default ({ machine }: { machine: ActorRefFrom<StateMachine<Context<IActionEvent>, any, Event<IActionEvent>>> }) => {
    const [state, send] = useActor(machine);
    const [isNewVersionVisible, setNewVersionVisible] = useState(false);

    const onVersion = (version: string) => send({ type: 'VERSION', version });
  
    const onNewVersion = () => setNewVersionVisible(true);
    const onCloseNewVersion = () => setNewVersionVisible(false);

    const onNewVersionCreated = () => {
        setNewVersionVisible(false);
        send({ type: 'NEW_VERSION_CREATED' });
    }

    const onNameChange = (e: FormEvent<HTMLInputElement>) => send({ type: 'CHANGE', value: { name: e.currentTarget.value } });
    const onEventChange = (event: IVariable | null) => send({ type: 'CHANGE', value: { event }});
    const onTargetTypeChange = (e: FormEvent<HTMLSelectElement>) => send({ type: 'CHANGE', value: { targetType: e.currentTarget.value }});
    const onTargetChange = (target: IVariable | null) => send({ type: 'CHANGE', value: { target }});
    const onDelayChange = (delay: IVariable | null) => send({ type: 'CHANGE', value: { delay }});

    const onDependencyClick = (item: IDependency) => send({ type: 'ITEM', itemType: item.type, id: item.id, version: item.version });

    const onDelete = () => send('DELETE');

    return (
        <div className="action-editor">
            <Header
                versions={state.context.versions}
                version={state.context.version}
                onVersion={onVersion}
                onNewVersion={onNewVersion}
                users={state.context.users}
                modified={state.context.item ? state.context.item.modified : null}
            />

            <div className="event p">
                {
                    state.matches('edit') && state.context.item ? (
                        <>
                            <div className="box">
                                <div className="is-flex justify-between mb">
                                    <div>
                                        <FontAwesomeIcon icon={faCogs} className="mr" />
                                        <strong>Send Event</strong>
                                    </div>
                                    <button className="button is-danger is-small is-outlined" onClick={onDelete} disabled={state.context.item.readonly}>Delete</button>
                                </div>
                                <div className="field mb">
                                    <div className="control">
                                        <input className="input is-large" type="text" placeholder="Action Name" value={state.context.item.name} onChange={onNameChange} disabled={state.context.item.readonly} />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label">Event to Send</label>
                                    <ValueSelector
                                        org={state.context.org}
                                        workspace={state.context.workspace}
                                        variable={state.context.item.event}
                                        onChange={onEventChange}
                                        disabled={state.context.item.readonly}
                                    />
                                </div>
                                <div className="field">
                                    <label className="label">Event Target</label>
                                    <div className="select">
                                        <select value={state.context.item.targetType} onChange={onTargetTypeChange} disabled={state.context.item.readonly}>
                                            <option value="self">Self</option>
                                            <option value="parent">Parent</option>
                                            <option value="specific">Specific</option>
                                        </select>
                                    </div>
                                    {
                                        state.context.item.targetType === 'specific' ? 
                                        <ValueSelector
                                            org={state.context.org}
                                            workspace={state.context.workspace}
                                            variable={state.context.item.target}
                                            onChange={onTargetChange}
                                            disabled={state.context.item.readonly}
                                        /> : null
                                    }
                                </div>
                                <div className="field">
                                    <label className="label">Delay</label>
                                    <ValueSelector
                                        org={state.context.org}
                                        workspace={state.context.workspace}
                                        variable={state.context.item.delay}
                                        onChange={onDelayChange}
                                        disabled={state.context.item.readonly}
                                    />
                                </div>
                            </div>

                            <div className="columns">
                                <div className="column">
                                    <div className="card mb">
                                        <header className="card-header">
                                            <p className="card-header-title">
                                            Uses
                                            </p>
                                        </header>
                                        <div className="card-content">
                                            <Dependencies org={state.context.org} workspace={state.context.workspace} dependencies={state.context.uses} onClick={onDependencyClick} />
                                        </div>
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="card mb">
                                        <header className="card-header">
                                            <p className="card-header-title">
                                            Used By
                                            </p>
                                        </header>
                                        <div className="card-content">
                                            <Dependencies org={state.context.org} workspace={state.context.workspace} dependencies={state.context.usedBy} onClick={onDependencyClick} />
                                        </div>
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="card">
                                        <header className="card-header">
                                            <p className="card-header-title">
                                                History
                                    </p>
                                        </header>
                                        <div className="card-content">
                                            <History versions={state.context.versions} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null
                }
                {
                    isNewVersionVisible ? (
                        <NewVersion
                            org={state.context.org}
                            workspace={state.context.workspace}
                            id={state.context.id}
                            type="action"
                            onNewVersion={onNewVersionCreated}
                            onClose={onCloseNewVersion}
                        />
                        ) : null
                }
                <Delete state={state} send={send} />
            </div>
        </div>
    );
}