import React from "react";
import { Context, Event, Schema } from '../../../machines/deployment/releases/releases';
import { useActor } from "@xstate/react";
import Sidebar from "../../build/editing/Sidebar";
import { ActorRefFrom, StateMachine } from "xstate";
import Release from "./Release";
import './releases.css';

const Releases = ({ machine }: { machine: ActorRefFrom<StateMachine<Context, Schema, Event>> | null }) => {
    const [state, send] = useActor(machine!);

    let editor: JSX.Element | null = null;
    if (state.context.selected)
        editor = <Release machine={state.context.machine} />

    return (
        <div className="releases">
            <Sidebar 
                items={state.context.releases}
                selected={state.context.selected}
                send={send}
                loading={state.matches('loading')}
                type="Release"
                allowNew={true}
            />
            {editor}
        </div>
    );
};

export default Releases;
