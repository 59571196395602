/* eslint-disable react/display-name */
import React from 'react';
import ValueSelector from '../../variable/ValueSelector';
import _ from 'lodash';
import { IVariable } from '../../../../../../types/variable';
import { OpenAPIV3 } from 'openapi-types';
import { getMultipartParameters, isMultipartRequestBody } from '../../../../machines/build/utils/openapi';

type Body = IVariable | { [name: string]: IVariable | null } | null;

interface IBodyProps {
    org: string
    workspace: string
    operation: OpenAPIV3.OperationObject | null
    body: Body,
    onChange: (body: IVariable | null, name?: string) => void
    disabled: boolean
}

export default ({ org, workspace, body, operation, onChange, disabled }: IBodyProps) => {
    if (!operation || !operation.requestBody)
        return null;

    if (isMultipartRequestBody(operation)) {
        const parameters = getMultipartParameters(operation);

        return (
            <div className="field">
                <label className="label">Request Body</label>
                <table className="table is-fullwidth">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            _.mapValues(parameters, (parameter: OpenAPIV3.BaseSchemaObject, name: string) => (
                                <tr key={name}>
                                    <td>{name}</td>
                                    <td>
                                    <ValueSelector
                                        org={org}
                                        workspace={workspace}
                                        variable={body ? body[name] : null}
                                        onChange={(body) => onChange(body, name)}
                                        allowNothing={true}
                                        disabled={disabled}
                                    />
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        );
    }
    else
        return (
            <div className="field">
                <label className="label">Request Body</label>
                <ValueSelector
                    org={org}
                    workspace={workspace}
                    variable={body as IVariable | null}
                    onChange={onChange}
                    allowNothing={true}
                    disabled={disabled}
                />
            </div>
        );
}

