/* eslint-disable react/react-in-jsx-scope */
import VersionableItems from '../versionable/VersionableItems';
import { IVersionableReferenceSelected } from '../../../../../types/versionable';

interface Props {
    value: IVersionableReferenceSelected | null
    onChange: (value: IVersionableReferenceSelected[]) => void
    onEdit: (id: string, version: string, type: string, kind?: string) => void
    onNew: () => void
    disabled: boolean
}

export const Screen = ({ value, onChange, onEdit, onNew, disabled }: Props) => {

    return (
        <div className="flex-grow" role="tabpanel">
            <p className="mb">
                Display a Screen to the executing users.
            </p>
            <VersionableItems
                id="screen"
                value={value ? [value] : []}
                onChange={onChange}
                onEdit={onEdit}
                type="screen"
                multiple={false}
                disabled={disabled}
            />
            <button className="button is-primary is-small" onClick={onNew}>New Screen</button>
        </div>
    );
}

export default Screen;