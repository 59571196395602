/* eslint-disable react/display-name */
import React, { FormEvent, useState } from 'react';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult
} from "react-beautiful-dnd";
import VariableFinder from '../variable/VariableFinder';
import ValueSelector from '../variable/ValueSelector';
import Header from '../editing/Header';
import Dependencies from '../editing/Dependencies';
import History from '../editing/History';
import Delete from './Delete';
import NewVersion from '../editing/NewVersion';
import { useActor } from "@xstate/react";
import { Context, Event } from '../../../machines/build/versionable-item';
import './action-editor.css';
import { ActorRefFrom, StateMachine } from 'xstate';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEquals } from '@fortawesome/free-solid-svg-icons';
import CreatableSelect from 'react-select/creatable';
import VersionableItems from '../versionable/VersionableItems';
import { IActionUpdate, IUpdate, UpdateType } from '../../../../../types/action';
import { IDependency, IVersionableReferenceSelected } from '../../../../../types/versionable';
import { IVariable } from '../../../../../types/variable';
import arrayMove from 'array-move';

interface IItemProps {
    provided: any
    org: string
    workspace: string
    item: any
    disabled: boolean
    index: number
    onChange: (item: any, index: number) => void
    onDelete: (index: number) => void
}

const Filter = ({ provided, org, workspace, item, index, disabled, onChange, onDelete }: IItemProps) => {

    const onUpdateChange = (update: string) => onChange({ ...item, update }, index);
    const onFilterChange = (filter: IVersionableReferenceSelected[]) => onChange({ ...item, filter: filter[0] }, index);
    const onTargetChange = (target: string) => onChange({ ...item, target }, index);

    return (
        <div
            className="list-item filter"
            key={item.order}
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <span {...provided.dragHandleProps} style={{ fontSize: '2em' }}>&#9776;</span>
            <div className="field">
                <label className="label">Filter</label>
                <VariableFinder org={org} workspace={workspace} variable={item.update} onChange={onUpdateChange} disabled={disabled} />
            </div>
            <div className="field">
                <label className="label">Rule to Filter By</label>
                <VersionableItems
                    id="filter"
                    value={item.filter ? [item.filter] : []}
                    onChange={onFilterChange}
                    type="rule"
                    disabled={disabled}
                />
            </div>
            <div className="field">
                <label className="label">Put sorted stuff into</label>
                <VariableFinder org={org} workspace={workspace} variable={item.target} onChange={onTargetChange} disabled={disabled} />
            </div>
            <button className="button is-small" onClick={onDelete.bind(null, index)} disabled={disabled}>Delete</button>
        </div>
    );
}

const Pick = ({ provided, org, workspace, item, index, disabled, onChange, onDelete }: IItemProps) => {

    const onUpdateChange = (update: string) => onChange({ ...item, update }, index);
    const onPropertyChange = (properties) => onChange({ ...item, pick: properties.map(p => p.value) }, index);
    const onTargetChange = (target: string) => onChange({ ...item, target }, index);

    const options = (item.pick || []).map(v => ({ label: v, value: v }));

    return (
        <div
            className="list-item pick"
            key={item.order}
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <span {...provided.dragHandleProps} style={{ fontSize: '2em' }}>&#9776;</span>
            <div className="field">
                <label className="label">Pick Properties From</label>
                <VariableFinder org={org} workspace={workspace} variable={item.update} onChange={onUpdateChange} disabled={disabled} />
            </div>
            <div className="field">
                <label className="label">Properties to Keep</label>
                <CreatableSelect
                    defaultValue={options}
                    isMulti={true}
                    options={options}
                    onChange={onPropertyChange}
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        Menu: () => null,
                        MenuList: () => null,
                    }}
                    placeholder="Enter property names"
                />
            </div>
            <div className="field">
                <label className="label">Put picked stuff into</label>
                <VariableFinder org={org} workspace={workspace} variable={item.target} onChange={onTargetChange} disabled={disabled} />
            </div>
            <button className="button is-small" onClick={onDelete.bind(null, index)} disabled={disabled}>Delete</button>
        </div>
    );
}

const Omit = ({ provided, org, workspace, item, index, disabled, onChange, onDelete }: IItemProps) => {

    const onUpdateChange = (update: string) => onChange({ ...item, update }, index);
    const onPropertyChange = (properties) => onChange({ ...item, omit: properties.map(p => p.value) }, index);
    const onTargetChange = (target: string) => onChange({ ...item, target }, index);

    const options = (item.omit || []).map(v => ({ label: v, value: v }));

    return (
        <div
            className="list-item omit"
            key={item.order}
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <span {...provided.dragHandleProps} style={{ fontSize: '2em' }}>&#9776;</span>
            <div className="field">
                <label className="label">Omit Properties From</label>
                <VariableFinder org={org} workspace={workspace} variable={item.update} onChange={onUpdateChange} disabled={disabled} />
            </div>
            <div className="field">
                <label className="label">Properties to Remove</label>
                <CreatableSelect
                    defaultValue={options}
                    isMulti={true}
                    options={options}
                    onChange={onPropertyChange}
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        Menu: () => null,
                        MenuList: () => null,
                    }}
                    placeholder="Enter property names"
                />
            </div>
            <div className="field">
                <label className="label">Put omitted stuff into</label>
                <VariableFinder org={org} workspace={workspace} variable={item.target} onChange={onTargetChange} disabled={disabled} />
            </div>
            <button className="button is-small" onClick={onDelete.bind(null, index)} disabled={disabled}>Delete</button>
        </div>
    );
}

const Sort = ({ provided, org, workspace, item, index, disabled, onChange, onDelete }: IItemProps) => {

    const onUpdateChange = (update: string) => onChange({ ...item, update }, index);
    const onSortByChange = (e: FormEvent<HTMLInputElement>) => onChange({ ...item, sortBy: e.currentTarget.value }, index);
    const onSortDirectionChange = (e: FormEvent<HTMLSelectElement>) => onChange({ ...item, sortDirection: e.currentTarget.value }, index);
    const onTargetChange = (target: string) => onChange({ ...item, target }, index);

    return (
        <div
            className="list-item sort"
            key={item.order}
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <span {...provided.dragHandleProps} style={{ fontSize: '2em' }}>&#9776;</span>
            <div className="field">
                <label className="label">Sort</label>
                <VariableFinder org={org} workspace={workspace} variable={item.update} onChange={onUpdateChange} disabled={disabled} />
            </div>
            <div className="field">
                <label className="label">Property to sort by</label>
                <input type="text" className="input" value={item.sortBy} onChange={onSortByChange} disabled={disabled} />
            </div>
            <div className="field">
                <label className="label">Sorting Direction</label>
                <div className="select">
                    <select value={item.sortDirection} onChange={onSortDirectionChange} disabled={disabled}>
                        <option value="desc">Descending</option>
                        <option value="asc">Ascending</option>
                    </select>
                </div>
            </div>
            <div className="field">
                <label className="label">Put sorted stuff into</label>
                <VariableFinder org={org} workspace={workspace} variable={item.target} onChange={onTargetChange} disabled={disabled} />
            </div>
            <button className="button is-small" onClick={onDelete.bind(null, index)} disabled={disabled}>Delete</button>
        </div>
    );
}

const Properties = ({ provided, org, workspace, item, index, disabled, onChange, onDelete }: IItemProps) => {
    
    const onUpdateChange = (update: string) => onChange({ ...item, update }, index);
    const onTargetChange = (target: string) => onChange({ ...item, target }, index);

    const onAddPropertyMapping = () => 
        onChange({ 
            ...item,
            properties: (item.properties || []).concat([{ from: '', to: '' }])
        }, index);
    
    const onPropertyMappingFromChange = (from: string, mappingIndex: number) => {
        item.properties[mappingIndex].from = from;
        onChange(item, index);
    }

    const onPropertyMappingToChange = (to: string, mappingIndex: number) => {
        item.properties[mappingIndex].to = to;
        onChange(item, index);
    }

    const onRemovePropertyMapping = (mappingIndex: number) => {
        onChange({
            ...item,
            properties: item.properties.filter((p, i) => i !== mappingIndex)
        }, index)
    }

    return (
        <div
            className="list-item map-properties"
            key={item.order}
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <span {...provided.dragHandleProps} style={{ fontSize: '2em' }}>&#9776;</span>
            <div className="field">
                <label className="label">From</label>
                <VariableFinder org={org} workspace={workspace} variable={item.update} onChange={onUpdateChange} disabled={disabled} />
            </div>
            <div className="field">
                <label className="label">To</label>
                <VariableFinder org={org} workspace={workspace} variable={item.target} onChange={onTargetChange} disabled={disabled} />
            </div>
            <div className="field mappings">
                <label className="label">Property Mappings</label>
                <table className="table is-striped is-fullwidth">
                    <tbody>
                        {
                            _.map(item.properties, (map, mappingIndex: number) => (
                                <tr>
                                    <td>
                                        <input className="input is-small" value={map.from} onChange={(e) => onPropertyMappingFromChange(e.currentTarget.value, mappingIndex)} />
                                    </td>
                                    <td>
                                        <input className="input is-small" value={map.to} onChange={(e) => onPropertyMappingToChange(e.currentTarget.value, mappingIndex)} />
                                    </td>
                                    <td style={{ width: '1%', whiteSpace: 'nowrap' }}>
                                        <button className="delete" onClick={() => onRemovePropertyMapping(mappingIndex)} disabled={disabled} />
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <button className="button is-small add-property" onClick={onAddPropertyMapping}>Add Property Mapping</button>
            <button className="button is-small" onClick={onDelete.bind(null, index)} disabled={disabled}>Delete</button>
        </div>
    );
}

const Change = ({ provided, org, workspace, item, index, disabled, onChange, onDelete }: IItemProps) => {

    const onUpdateChange = (update: string) => onChange({ ...item, update }, index);
    const onOperationChange = (e: FormEvent<HTMLSelectElement>) => onChange({ ...item, operation: e.currentTarget.value }, index);
    const onUpdateToChange = (to: IVariable | null) => onChange({ ...item, to }, index);

    return (
        <div
            className="list-item change"
            key={item.order}
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <span {...provided.dragHandleProps} style={{ fontSize: '2em' }}>&#9776;</span>
            <div className="field">
                <label className="label">Change</label>
                <VariableFinder org={org} workspace={workspace} variable={item.update} onChange={onUpdateChange} disabled={disabled} />
            </div>
            <div className="field">
                <label className="label">Operation</label>
                <div className="select">
                    <select value={item.operation} onChange={onOperationChange} disabled={disabled}>
                        <option value="equal">Equal To</option>
                        <option value="add">Add</option>
                        <option value="subtract">Subtract</option>
                        <option value="multiply">Multiply</option>
                        <option value="divide">Divide</option>
                        <option value="exponential">Exponential</option>
                    </select>
                </div>
            </div>
            <div className="field">
                <label className="label">Value</label>
                <ValueSelector
                    org={org}
                    workspace={workspace}
                    variable={item.to}
                    onChange={onUpdateToChange}
                    allowRules={true}
                    allowNothing={true}
                    disabled={disabled}
                />
            </div>
            <button className="button is-small" onClick={onDelete.bind(null, index)} disabled={disabled}>Delete</button>
        </div>
    );
}

const Merge = ({ provided, org, workspace, item, index, disabled, onChange, onDelete }: IItemProps) => {

    const onUpdateChange = (update: string) => onChange({ ...item, update }, index);
    const onByPropertyChange = (e: FormEvent<HTMLInputElement>) => onChange({ ...item, operation: e.currentTarget.value }, index);
    const onTargetChange = (target: string) => onChange({ ...item, target }, index);

    return (
        <div
            className="list-item merge"
            key={item.order}
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <span {...provided.dragHandleProps} style={{ fontSize: '2em' }}>&#9776;</span>
            <div className="field">
                <label className="label">Merge</label>
                <VariableFinder org={org} workspace={workspace} variable={item.update} onChange={onUpdateChange} disabled={disabled} />
            </div>
            <div className="field">
                <label className="label">By Property</label>
                <input className="input" value={item.byProperty} onChange={onByPropertyChange} />
            </div>
            <div className="field">
                <label className="label">Into</label>
                <VariableFinder org={org} workspace={workspace} variable={item.target} onChange={onTargetChange} disabled={disabled} />
            </div>
            <button className="button is-small" onClick={onDelete.bind(null, index)} disabled={disabled}>Delete</button>
        </div>
    );
}

export default ({ machine }: { machine: ActorRefFrom<StateMachine<Context<IActionUpdate>, any, Event<IActionUpdate>>> }) => {
    const [state, send] = useActor(machine);
    const [isNewVersionVisible, setNewVersionVisible] = useState(false);

    const onVersion = (version: string) => send({ type: 'VERSION', version });
  
    const onNewVersion = () => setNewVersionVisible(true);
    const onCloseNewVersion = () => setNewVersionVisible(false);

    const onNewVersionCreated = () => {
        setNewVersionVisible(false);
        send({ type: 'NEW_VERSION_CREATED' });
    }

    const onNameChange = (e: FormEvent<HTMLInputElement>) => send({ type: 'CHANGE', value: { name: e.currentTarget.value } });
    const onDelete = () => send('DELETE');

    const onDependencyClick = (item: IDependency) => send({ type: 'ITEM', itemType: item.type, id: item.id, version: item.version });

    const onAddItem = (type: UpdateType) => {
        send({
            type: 'CHANGE',
            value: {
                updates: _.concat(state.context.item?.updates, [{
                    order: _.size(state.context.item?.updates),
                    update: '',
                    type
                }])
            }
        });
    }

    const onAddChange = () => onAddItem('change');
    const onAddMerge = () => onAddItem('merge');
    const onAddFilter = () => onAddItem('filter');
    const onAddPick = () => onAddItem('pick');
    const onAddOmit = () => onAddItem('omit');
    const onAddSort = () => onAddItem('sort');
    const onAddProperties = () => onAddItem('properties');

    const onDeleteItem = (index: number) => {
        const updates = state.context.item?.updates || []

        send({
            type: 'CHANGE',
            value: {
                updates: updates.filter((u, i) => index !== i)
            }
        });
    };

    const onChangeItem = (item: Partial<IUpdate>, index: number) => {
        send({
            type: 'CHANGE',
            value: {
                updates: _.map(state.context.item?.updates, (update, i) => {
                    if (index === i)
                        update = { ...update, ...item }

                    return update;
                })
            }
        });
    }

    const onDragEnd = (result: DropResult) => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        const updates = state.context.item?.updates || []

        send({
            type: 'CHANGE',
            value: {
                updates: arrayMove(updates, source.index, destination.index)
                .map((update, index) => {
                    update.order = index;
                    return update;
                })
            }
        });
    };

    return (
        <div className="action-editor">
            <Header
                versions={state.context.versions}
                version={state.context.version}
                onVersion={onVersion}
                onNewVersion={onNewVersion}
                users={state.context.users}
                modified={state.context.item ? state.context.item.modified : null}
            />

            <div className="update p">
                {
                    state.matches('edit') && state.context.item ? (
                        <>
                            <div className="box">
                                <div className="is-flex justify-between mb">
                                    <div>
                                        <FontAwesomeIcon icon={faEquals} className="mr" />
                                        <strong>Update Variables</strong>
                                    </div>
                                    <button className="button is-danger is-small is-outlined" onClick={onDelete} disabled={state.context.item.readonly}>Delete</button>
                                </div>
                                <div className="field mb">
                                    <div className="control">
                                        <input className="input is-large" type="text" placeholder="Action Name" value={state.context.item.name} onChange={onNameChange} disabled={state.context.item.readonly} />
                                    </div>
                                </div>
                                <p className="mb has-text-grey-dark">Drag & drop to re-order individual updates</p>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId={machine.id}>
                                        {(provided, snapshot) => (
                                            <div
                                                className="list is-hoverable mb versionable-items"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {(state.context.item.updates || [])
                                                    .sort((a, b) => a.order - b.order)
                                                    .map((item, index: number) => (
                                                        <Draggable
                                                            key={item.order}
                                                            draggableId={item.order.toString()}
                                                            index={index}
                                                        >
                                                            {(provided, snaphost) => {
                                                                switch (item.type) {
                                                                    case 'filter':
                                                                        return <Filter provided={provided} org={state.context.org} workspace={state.context.workspace} item={item} index={index} disabled={state.context.item.readonly} onDelete={onDeleteItem} onChange={onChangeItem} />

                                                                    case 'omit':
                                                                        return <Omit provided={provided} org={state.context.org} workspace={state.context.workspace} item={item} index={index} disabled={state.context.item.readonly} onDelete={onDeleteItem} onChange={onChangeItem} />

                                                                    case 'pick':
                                                                        return <Pick provided={provided} org={state.context.org} workspace={state.context.workspace} item={item} index={index} disabled={state.context.item.readonly} onDelete={onDeleteItem} onChange={onChangeItem} />

                                                                    case 'sort':
                                                                        return <Sort provided={provided} org={state.context.org} workspace={state.context.workspace} item={item} index={index} disabled={state.context.item.readonly} onDelete={onDeleteItem} onChange={onChangeItem} />

                                                                    case 'properties':
                                                                        return <Properties provided={provided} org={state.context.org} workspace={state.context.workspace} item={item} index={index} disabled={state.context.item.readonly} onDelete={onDeleteItem} onChange={onChangeItem} />

                                                                    case 'merge':
                                                                        return <Merge provided={provided} org={state.context.org} workspace={state.context.workspace} item={item} index={index} disabled={state.context.item.readonly} onDelete={onDeleteItem} onChange={onChangeItem} />

                                                                    case 'change':
                                                                    default:
                                                                        return <Change provided={provided} org={state.context.org} workspace={state.context.workspace} item={item} index={index} disabled={state.context.item.readonly} onDelete={onDeleteItem} onChange={onChangeItem} />
                                                                }
                                                            }}
                                                        </Draggable>
                                                    )
                                                    )}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                <div className="buttons">
                                    <button className="button is-info" onClick={onAddChange} disabled={state.context.item.readonly}>Add Change</button>
                                    <button className="button is-info" onClick={onAddMerge} disabled={state.context.item.readonly}>Add Merge</button>
                                    <button className="button is-info" onClick={onAddFilter} disabled={state.context.item.readonly}>Add Filter</button>
                                    <button className="button is-info" onClick={onAddSort} disabled={state.context.item.readonly}>Add Sort</button>
                                    <button className="button is-info" onClick={onAddPick} disabled={state.context.item.readonly}>Add Pick</button>
                                    <button className="button is-info" onClick={onAddOmit} disabled={state.context.item.readonly}>Add Omit</button>
                                    <button className="button is-info" onClick={onAddProperties} disabled={state.context.item.readonly}>Add Map Properties</button>
                                </div>
                            </div>

                            <div className="columns">
                                <div className="column">
                                    <div className="card mb">
                                        <header className="card-header">
                                            <p className="card-header-title">
                                                Uses
                                            </p>
                                        </header>
                                        <div className="card-content">
                                            <Dependencies org={state.context.org} workspace={state.context.workspace} dependencies={state.context.uses} onClick={onDependencyClick} />
                                        </div>
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="card mb">
                                        <header className="card-header">
                                            <p className="card-header-title">
                                                Used By
                                            </p>
                                        </header>
                                        <div className="card-content">
                                            <Dependencies org={state.context.org} workspace={state.context.workspace} dependencies={state.context.usedBy} onClick={onDependencyClick} />
                                        </div>
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="card">
                                        <header className="card-header">
                                            <p className="card-header-title">
                                                History
                                            </p>
                                        </header>
                                        <div className="card-content">
                                            <History versions={state.context.versions} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null
                }
                {
                    isNewVersionVisible ? (
                        <NewVersion
                            org={state.context.org}
                            workspace={state.context.workspace}
                            id={state.context.id}
                            type="action"
                            onNewVersion={onNewVersionCreated}
                            onClose={onCloseNewVersion}
                        />
                        ) : null
                }
                <Delete state={state} send={send} />
            </div>
        </div>
    );
}