/* eslint-disable react/display-name */
import React, { FormEvent } from 'react';
import { marked } from 'marked';
import _ from 'lodash';
import { OpenAPIV3 } from 'openapi-types';
import { plugins } from '../../../socket';
import { IPluginOpenAPI } from '../../../../../types/plugin';
import SwaggerParser from '@apidevtools/swagger-parser';
import YAML from 'yaml'
import Loader from '../../loader';

const security = (document: OpenAPIV3.Document): string[] => {
    const securitySchemes = document.components?.securitySchemes;

    return _.chain(document.security)
        .flatMap(security => {
            if (typeof security === 'string')
                return security;
            else if (_.isObject(security))
                return _.keys(security)

            return '';
        })
        .filter(security => !_.isEmpty(security))
        .filter(security => {
            const securityScheme = securitySchemes?.[security] as OpenAPIV3.SecuritySchemeObject;
            return securityScheme?.type === 'openIdConnect' || securityScheme?.type === 'oauth2';
        })
        .value();
}

interface Props {
    org: string
    workspace: string
    environment: string
    item: IPluginOpenAPI
    onChange: (config: Partial<IPluginOpenAPI>) => void
}

export default ({ org, workspace, environment, item, onChange }: Props) => {
    const [search, setSearch] = React.useState('');
    const [document, setDocument] = React.useState<OpenAPIV3.Document | null>(null)
    const [uploadingDocument, setUploadingDocument] = React.useState<boolean>(false);

    React.useEffect(() => {
        plugins.spec.load(org, workspace, item.id, item.version, environment, setDocument);
    }, []);

    const onUploadSchema = (e: FormEvent<HTMLInputElement>) => {
        const file = e.currentTarget.files?.[0];

        if (file) {
            setUploadingDocument(true);

            const reader = new FileReader();
            reader.onloadend = async () => {
                if (reader.result) {
                    const parsed = YAML.parse(reader.result.toString());
                    const validatedDocument = await SwaggerParser.validate(parsed) as OpenAPIV3.Document;
                    
                    if (validatedDocument) {
                        plugins.spec.save(org, workspace, item.id, 'working', environment, validatedDocument, (spec) => {
                            setDocument(spec)
                            setUploadingDocument(false);
                        });
                    }
                }
            }
            reader.readAsText(file);
        }
    }

    const onServerChange = (e: FormEvent<HTMLSelectElement>) => onChange({ server: e.currentTarget.value });
    const onSecurityChange = (e: FormEvent<HTMLSelectElement>) => onChange({ securitySchemeId: e.currentTarget.value });
    const onClientIdChange = (e: FormEvent<HTMLInputElement>) => onChange({
        authorization: {
            ...item.authorization,
            clientId: e.currentTarget.value
        }
    });
    const onClientSecretChange = (e: FormEvent<HTMLInputElement>) => onChange({
        authorization: {
            ...item.authorization,
            clientSecret: e.currentTarget.value
        }
    });

    return (
        <>
            <div className="plugin-openapi-schema-buttons mb">
                <div className="file">
                    <label className="file-label">
                        <input className="file-input" type="file" name="OpenAPI Schema" onChange={onUploadSchema} />
                            <span className="file-cta">
                                <span className="file-label">
                                    Upload Schema
                                </span>
                            </span>
                    </label>
                </div>
                <a className="button" href={`${process.env.API_URL}/${org}/${workspace}/build/plugins/${item.id}/${item.version}/schema`} download={true} target="_blank" rel="noreferrer">Download Schema</a>
            </div>
            {
                document ? (
                    <>
                        <div className="mb">
                            <h4 className="is-size-4">{document.info.title}</h4>
                            <p>{document.info.description}</p>
                        </div>

                        <div className="field">
                            <label className="label">Server</label>
                            <div className="control">
                                <div className="select">
                                    <select value={item.server} onChange={onServerChange} disabled={item.readonly}>
                                        <option>Select server</option>
                                        {
                                            _.map(document.servers, (server) => <option value={server.url} key={server.url}>{server.url}</option>)
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Security</label>
                            <div className="control">
                                <div className="select">
                                    <select value={item.securitySchemeId} onChange={onSecurityChange} disabled={item.readonly}>
                                        <option>Select security</option>
                                        {
                                            _.map(security(document), (securityScheme) => <option value={securityScheme} key={securityScheme}>{securityScheme}</option>)
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        {
                            item.securitySchemeId ? (
                                <>
                                    <div className="field">
                                        <label className="label">Client Id</label>
                                        <div className="control">
                                            <input className="input" type="text" value={item.authorization.clientId} onChange={onClientIdChange} disabled={item.readonly} />
                                        </div>
                                    </div>

                                    <div className="field">
                                        <label className="label">Client Secret</label>
                                        <div className="control">
                                            <input className="input" type="password" value={item.authorization.clientSecret} onChange={onClientSecretChange} disabled={item.readonly} />
                                        </div>
                                    </div>
                                </>
                            ) : null
                        }
                        <h4 className="is-size-4 mb">Operations</h4>
                        <input className="input mb" type="search" placeholder="Search" value={search} onChange={(e) => setSearch(e.currentTarget.value)}></input>
                        <div className="new-plugin-operations">
                            <table className="table is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>Method</th>
                                        <th>Url</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _.chain(document?.paths)
                                            .keys()
                                            .filter(path => path.toUpperCase().indexOf(search.toUpperCase()) !== -1)
                                            .flatMap((path) => {
                                                if (!document?.paths[path])
                                                    return null;

                                                _.map(_.keys(document.paths[path]), (operation) => {
                                                    const description = marked(document.paths?.[path]?.[operation]?.description || '')

                                                    return (
                                                        <tr key={path + operation}>
                                                            <td>{operation.toUpperCase()}</td>
                                                            <td>{path}</td>
                                                            <td dangerouslySetInnerHTML={{ __html: description }}></td>
                                                        </tr>
                                                    )
                                                });
                                            })
                                            .value()
                                    }
                                </tbody>
                            </table>
                        </div>
                    </>
                ) : null
            }
            {
                uploadingDocument ? <Loader /> : null
            }
        </>
    );
} 