import React, { FormEvent, useState } from "react";
import { Context, Event } from '../../../machines/build/versionable-items';
import Plugin from './Plugin';
import './plugins.css';
import { useActor } from "@xstate/react";
import { ActorRef, StateMachine } from "xstate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import classnames from "classnames";
import Loader from "../../loader";

const Plugins = ({ machine }: { machine: ActorRef<StateMachine<Context, any, Event>> }) => {
    const [search, setSearch] = useState('');
    const [collapsed, setCollapsed] = useState(false);
    const [sort, setSort] = useState('name');
    const [state, send] = useActor(machine);

    const onSelect = (value: string) => send({ type: 'SELECT', value });

    const onNew = (itemType: string) => send({ type: 'NEW', itemType });

    const onSearchChange = (e: FormEvent<HTMLInputElement>) => setSearch(e.currentTarget.value);
    const onSortChange = (e: FormEvent<HTMLSelectElement>) => setSort(e.currentTarget.value);

    let editor: JSX.Element | null = null;
    if (state.context.selectedItemActor)
        editor = <Plugin machine={state.context.selectedItemActor} />
    else
        editor = <div className="has-text-centered is-flex justify-center flex-col flex-grow items-center">
            <h2 className="title mb is-size-1">Plugins</h2>
            <h3 className="subtitle">Connect to your APIs and datasources</h3>
            <svg xmlns="http://www.w3.org/2000/svg" width="600" height="220" className="mb">
                <path d="M542.757 26.875H387.249c-9.513 0-17.249 7.677-17.249 17.118v13.264c0 9.435 7.736 17.118 17.249 17.118h155.514c9.506 0 17.237-7.683 17.237-17.124V43.993c0-9.441-7.73-17.118-17.243-17.118zm5.368 30.382c0 2.892-2.405 5.243-5.368 5.243H387.249c-2.963 0-5.374-2.351-5.374-5.249V43.993c0-2.892 2.41-5.243 5.374-5.243h155.514c2.957 0 5.362 2.351 5.362 5.243v13.264z"/>
                <path d="M500.625 44.688h-89.062a5.94 5.94 0 00-5.938 5.937 5.94 5.94 0 005.938 5.938h89.062a5.94 5.94 0 005.937-5.938 5.94 5.94 0 00-5.937-5.937z"/>
                <path d="M398.114 46.41c-2.197-2.198-6.175-2.198-8.372 0-1.068 1.127-1.722 2.612-1.722 4.215 0 1.603.654 3.147 1.722 4.216 1.07 1.068 2.613 1.722 4.216 1.722 1.544 0 3.088-.66 4.157-1.722 1.128-1.128 1.78-2.613 1.78-4.216 0-1.544-.653-3.088-1.78-4.216z"/>
                <path d="M540.792 46.41c-2.197-2.198-6.175-2.198-8.372 0-1.128 1.127-1.781 2.671-1.781 4.215 0 1.603.653 3.088 1.781 4.216 1.069 1.128 2.613 1.722 4.157 1.722 1.603 0 3.087-.6 4.215-1.722 1.129-1.128 1.722-2.613 1.722-4.216 0-1.544-.594-3.088-1.722-4.216z"/>
                <path d="M522.86 46.41c-2.196-2.198-6.174-2.198-8.371 0-1.128 1.127-1.722 2.671-1.722 4.215 0 1.603.594 3.088 1.722 4.216 1.128 1.128 2.612 1.722 4.216 1.722 1.543 0 3.087-.6 4.156-1.722 1.128-1.128 1.781-2.613 1.781-4.216 0-1.544-.653-3.088-1.781-4.216z"/>
                <path d="M542.757 86.25H387.249c-9.513 0-17.249 7.683-17.249 17.13v13.246c0 9.44 7.736 17.124 17.249 17.124h155.514c9.506 0 17.237-7.683 17.237-17.124V103.38c0-9.447-7.73-17.13-17.243-17.13zm5.368 30.376c0 2.892-2.405 5.249-5.368 5.249H387.249c-2.963 0-5.374-2.357-5.374-5.25V103.38c0-2.903 2.41-5.255 5.374-5.255h155.514c2.957 0 5.362 2.352 5.362 5.255v13.247z"/>
                <path d="M500.625 104.063h-89.062a5.94 5.94 0 00-5.938 5.937 5.94 5.94 0 005.938 5.938h89.062a5.94 5.94 0 005.937-5.938 5.94 5.94 0 00-5.937-5.937z"/>
                <path d="M398.114 105.66c-2.197-2.198-6.175-2.198-8.372 0-1.068 1.128-1.722 2.612-1.722 4.215 0 1.603.654 3.088 1.722 4.216 1.07 1.069 2.613 1.722 4.216 1.722a5.99 5.99 0 004.157-1.722c1.128-1.128 1.78-2.672 1.78-4.216s-.653-3.087-1.78-4.216z"/>
                <path d="M540.792 105.66c-2.197-2.198-6.175-2.198-8.372 0-1.128 1.128-1.781 2.671-1.781 4.215s.653 3.088 1.781 4.216a5.99 5.99 0 004.157 1.722c1.603 0 3.087-.653 4.215-1.722 1.129-1.128 1.722-2.672 1.722-4.216s-.594-3.087-1.722-4.216z"/>
                <path d="M522.86 105.66c-2.196-2.198-6.174-2.198-8.371 0-1.128 1.128-1.722 2.671-1.722 4.215s.594 3.088 1.722 4.216c1.069 1.069 2.612 1.722 4.216 1.722a5.99 5.99 0 004.156-1.722c1.128-1.128 1.781-2.672 1.781-4.216s-.653-3.087-1.781-4.216z"/>
                <path d="M542.757 145.625H387.249c-9.513 0-17.249 7.683-17.249 17.13V176c0 9.44 7.736 17.124 17.249 17.124h155.514c9.506 0 17.237-7.683 17.237-17.124v-13.246c0-9.447-7.73-17.13-17.243-17.13zM548.125 176c0 2.891-2.405 5.249-5.368 5.249H387.249c-2.963 0-5.374-2.358-5.374-5.25v-13.246c0-2.903 2.41-5.255 5.374-5.255h155.514c2.957 0 5.362 2.352 5.362 5.255v13.247z"/>
                <path d="M500.625 163.437h-89.062a5.94 5.94 0 00-5.938 5.938 5.94 5.94 0 005.938 5.938h89.062a5.94 5.94 0 005.937-5.938 5.94 5.94 0 00-5.937-5.938z"/>
                <path d="M398.174 164.797c-2.257-2.197-6.235-2.197-8.432 0-1.068 1.128-1.722 2.612-1.722 4.216 0 1.603.654 3.087 1.722 4.215 1.129 1.069 2.613 1.722 4.216 1.722a5.99 5.99 0 004.157-1.722c1.128-1.128 1.78-2.672 1.78-4.215 0-1.544-.653-3.088-1.721-4.216z"/>
                <path d="M540.792 164.797c-2.197-2.197-6.175-2.197-8.372 0-1.128 1.128-1.781 2.672-1.781 4.216 0 1.543.653 3.087 1.781 4.215a5.99 5.99 0 004.157 1.722c1.603 0 3.087-.653 4.215-1.722 1.129-1.128 1.722-2.672 1.722-4.215 0-1.544-.594-3.088-1.722-4.216z"/>
                <path d="M522.92 164.797c-2.197-2.197-6.294-2.197-8.431 0-1.128 1.128-1.722 2.672-1.722 4.216 0 1.543.594 3.087 1.722 4.215 1.128 1.069 2.612 1.722 4.216 1.722 1.543 0 3.087-.653 4.215-1.722 1.07-1.128 1.722-2.672 1.722-4.215 0-1.544-.653-3.088-1.722-4.216z"/>
                <g>
                    <path d="M212.757 26.875H57.249C47.736 26.875 40 34.552 40 43.993v13.264c0 9.435 7.736 17.118 17.249 17.118h155.514c9.506 0 17.237-7.683 17.237-17.124V43.993c0-9.441-7.73-17.118-17.243-17.118zm5.368 30.382c0 2.892-2.405 5.243-5.368 5.243H57.249c-2.963 0-5.374-2.351-5.374-5.249V43.993c0-2.892 2.41-5.243 5.374-5.243h155.514c2.957 0 5.362 2.351 5.362 5.243v13.264z"/>
                    <path d="M170.625 44.688H81.563a5.94 5.94 0 00-5.938 5.937 5.94 5.94 0 005.938 5.938h89.062a5.94 5.94 0 005.937-5.938 5.94 5.94 0 00-5.937-5.937z"/>
                    <path d="M68.114 46.41c-2.197-2.198-6.175-2.198-8.372 0-1.068 1.127-1.722 2.612-1.722 4.215 0 1.603.654 3.147 1.722 4.216 1.07 1.068 2.613 1.722 4.216 1.722 1.544 0 3.088-.66 4.157-1.722 1.128-1.128 1.78-2.613 1.78-4.216 0-1.544-.653-3.088-1.78-4.216z"/>
                    <path d="M210.792 46.41c-2.197-2.198-6.175-2.198-8.372 0-1.128 1.127-1.781 2.671-1.781 4.215 0 1.603.653 3.088 1.781 4.216 1.069 1.128 2.613 1.722 4.157 1.722 1.603 0 3.087-.6 4.215-1.722 1.129-1.128 1.722-2.613 1.722-4.216 0-1.544-.594-3.088-1.722-4.216z"/>
                    <path d="M192.86 46.41c-2.196-2.198-6.174-2.198-8.371 0-1.128 1.127-1.722 2.671-1.722 4.215 0 1.603.594 3.088 1.722 4.216 1.128 1.128 2.612 1.722 4.216 1.722 1.543 0 3.087-.6 4.156-1.722 1.128-1.128 1.781-2.613 1.781-4.216 0-1.544-.653-3.088-1.781-4.216z"/>
                    <path d="M212.757 86.25H57.249C47.736 86.25 40 93.933 40 103.38v13.246c0 9.44 7.736 17.124 17.249 17.124h155.514c9.506 0 17.237-7.683 17.237-17.124V103.38c0-9.447-7.73-17.13-17.243-17.13zm5.368 30.376c0 2.892-2.405 5.249-5.368 5.249H57.249c-2.963 0-5.374-2.357-5.374-5.25V103.38c0-2.903 2.41-5.255 5.374-5.255h155.514c2.957 0 5.362 2.352 5.362 5.255v13.247z"/>
                    <path d="M170.625 104.063H81.563A5.94 5.94 0 0075.625 110a5.94 5.94 0 005.938 5.938h89.062a5.94 5.94 0 005.937-5.938 5.94 5.94 0 00-5.937-5.937z"/>
                    <path d="M68.114 105.66c-2.197-2.198-6.175-2.198-8.372 0-1.068 1.128-1.722 2.612-1.722 4.215 0 1.603.654 3.088 1.722 4.216 1.07 1.069 2.613 1.722 4.216 1.722a5.99 5.99 0 004.157-1.722c1.128-1.128 1.78-2.672 1.78-4.216s-.653-3.087-1.78-4.216z"/>
                    <path d="M210.792 105.66c-2.197-2.198-6.175-2.198-8.372 0-1.128 1.128-1.781 2.671-1.781 4.215s.653 3.088 1.781 4.216a5.99 5.99 0 004.157 1.722c1.603 0 3.087-.653 4.215-1.722 1.129-1.128 1.722-2.672 1.722-4.216s-.594-3.087-1.722-4.216z"/>
                    <path d="M192.86 105.66c-2.196-2.198-6.174-2.198-8.371 0-1.128 1.128-1.722 2.671-1.722 4.215s.594 3.088 1.722 4.216c1.069 1.069 2.612 1.722 4.216 1.722a5.99 5.99 0 004.156-1.722c1.128-1.128 1.781-2.672 1.781-4.216s-.653-3.087-1.781-4.216z"/>
                    <path d="M212.757 145.625H57.249c-9.513 0-17.249 7.683-17.249 17.13V176c0 9.44 7.736 17.124 17.249 17.124h155.514c9.506 0 17.237-7.683 17.237-17.124v-13.246c0-9.447-7.73-17.13-17.243-17.13zM218.125 176c0 2.891-2.405 5.249-5.368 5.249H57.249c-2.963 0-5.374-2.358-5.374-5.25v-13.246c0-2.903 2.41-5.255 5.374-5.255h155.514c2.957 0 5.362 2.352 5.362 5.255v13.247z"/>
                    <path d="M170.625 163.437H81.563a5.94 5.94 0 00-5.938 5.938 5.94 5.94 0 005.938 5.938h89.062a5.94 5.94 0 005.937-5.938 5.94 5.94 0 00-5.937-5.938z"/>
                    <path d="M68.174 164.797c-2.257-2.197-6.235-2.197-8.432 0-1.068 1.128-1.722 2.612-1.722 4.216 0 1.603.654 3.087 1.722 4.215 1.129 1.069 2.613 1.722 4.216 1.722a5.99 5.99 0 004.157-1.722c1.128-1.128 1.78-2.672 1.78-4.215 0-1.544-.653-3.088-1.721-4.216z"/>
                    <path d="M210.792 164.797c-2.197-2.197-6.175-2.197-8.372 0-1.128 1.128-1.781 2.672-1.781 4.216 0 1.543.653 3.087 1.781 4.215a5.99 5.99 0 004.157 1.722c1.603 0 3.087-.653 4.215-1.722 1.129-1.128 1.722-2.672 1.722-4.215 0-1.544-.594-3.088-1.722-4.216z"/>
                    <path d="M192.92 164.797c-2.197-2.197-6.294-2.197-8.431 0-1.128 1.128-1.722 2.672-1.722 4.216 0 1.543.594 3.087 1.722 4.215 1.128 1.069 2.612 1.722 4.216 1.722 1.543 0 3.087-.653 4.215-1.722 1.07-1.128 1.722-2.672 1.722-4.215 0-1.544-.653-3.088-1.722-4.216z"/>
                </g>
                <path className="flow flow-reverse" d="M242.784 48.158l114.794-.1" fill="none" stroke="#000" strokeWidth="15" stroke-dasharray="15,15"/>
                <path className="flow" d="M243.295 168.692l114.795-.1" fill="none" stroke="#000" strokeWidth="15" stroke-dasharray="15,15"/>
            </svg>
            <button className="button is-primary is-large">Create a new Plugin</button>
        </div>

    const plugins = (state.context.items || []).filter((action) => action.name.toUpperCase().indexOf(search.toUpperCase()) !== -1);

    const sidebarClassName = classnames('sidebar', { collapsed });

    return (
        <div className="plugins overflow-hidden">
            <div className={sidebarClassName}>
                <header>
                    <button className="button mb expand" onClick={() => setCollapsed(false)}>
                        <span className="icon">
                            <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </span>
                    </button>

                    <div className="is-flex mb mb self-end">
                        <div className="dropdown is-hoverable is-info mr">
                            <div className="dropdown-trigger">
                                <button className="button" aria-haspopup="true" aria-controls="dropdown-menu4">
                                    <span>New Plugin</span>
                                    <span className="icon is-small">
                                        <FontAwesomeIcon icon={faAngleDown} />
                                    </span>
                                </button>
                            </div>
                            <div className="dropdown-menu" role="menu">
                                <div className="dropdown-content">
                                    <div className="dropdown-item" role="menuitem" onClick={onNew.bind(null, 'connect')}>OpenAPI</div>
                                    <div className="dropdown-item" role="menuitem" onClick={onNew.bind(null, 'sql')}>SQL Database</div>
                                    <div className="dropdown-item" role="menuitem" onClick={onNew.bind(null, 'email')}>Email Server</div>
                                </div>
                            </div>
                        </div>

                        <button className="button collapse" onClick={() => setCollapsed(true)}>
                            <span className="icon">
                                <FontAwesomeIcon icon={faAngleDoubleLeft} />
                            </span>
                        </button>
                    </div>

                    <div className="sort-by">
                        <input
                            className="input"
                            type="text"
                            placeholder="Search"
                            value={search}
                            onChange={onSearchChange}
                        />
                        
                        <div className="select">
                            <select value={sort} onChange={onSortChange}>
                                <option value="name">Name</option>
                                <option value="when">Modified</option>
                            </select>
                        </div>
                    </div>
                </header>

                <ol>
                    {
                        _.chain(plugins)
                            .orderBy(sort, sort === 'name' ? 'asc' : 'desc')
                            .map(plugin => (
                                <li 
                                    className={`${state.context.selectedItemId && plugin.id === state.context.selectedItemId ? 'is-selected has-background-primary' : ''}`}
                                    onClick={onSelect.bind(null, plugin.id)}
                                    key={plugin.id + plugin.name}
                                    data-testid={plugin.id}
                                    id={plugin.id}
                                >
                                    {/* <span className="icon mr">
                                        <FontAwesomeIcon icon={icons[action.kind]} />
                                    </span> */}
                                    {plugin.name}
                                </li>
                            ))
                            .value()
                    }
                </ol>
                {state.matches('loading') ? <Loader /> : null}
            </div>
            {editor}
        </div>
    );
};

export default Plugins;
