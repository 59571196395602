/* eslint-disable react/display-name */
import React from 'react';
import ValueSelector from '../../variable/ValueSelector';
import _ from 'lodash';
import { OpenAPIV3 } from 'openapi-types';
import { getParameters } from '../../../../machines/build/utils/openapi';
import { IConnectorOperation } from '../../../../../../types/action';
import { IVariable } from '../../../../../../types/variable';

interface IParameterProps {
    org: string
    workspace: string
    operation: IConnectorOperation
    document: OpenAPIV3.Document | null
    onChange: (variable: IVariable | null, name: string) => void
    disabled: boolean
}

export default ({ org, workspace, operation, document, onChange, disabled }: IParameterProps) => {
    const parameters = getParameters(_.get(document, `paths[${operation.path}][${operation.method}]`)) as OpenAPIV3.OperationObject

    return (
        <div>
            <h5 className="title is-5">Parameters</h5>
            <table className="table is-fullwidth">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Required</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        _.map(_.values(parameters), (parameter: OpenAPIV3.ParameterObject) => {
                            return (
                                <tr key={parameter.name}>
                                    <td title={parameter.description}>{parameter.name}</td>
                                    <td>{parameter.required ? 'Yes' : 'No' }</td>
                                    <td>
                                        <ValueSelector
                                            org={org}
                                            workspace={workspace}
                                            variable={operation.parameters[parameter.name]}
                                            onChange={(variable) => onChange(variable, parameter.name)}
                                            disabled={disabled}
                                            small={true}
                                            allowNothing={true}
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}
