import React, { FormEvent } from 'react';
import ValueSelector from '../../../build/variable/ValueSelector';
import { ITestStep, ITestStepCheckValue } from '../../../../../../types/testing';
import { IVariable } from '../../../../../../types/variable';

type status = 'pass' | 'fail' | 'none';

interface Props {
    org: string
    workspace: string
    value: ITestStepCheckValue
    status?: status
    message?: string
    disabled: boolean
    onChange: (step: ITestStep) => void
    onDelete: (step: ITestStep) => void
}

const CheckValue = ({ org, workspace, value, onChange, onDelete, status, message, disabled }: Props) => {

    const onGetByChange = (e: FormEvent<HTMLSelectElement>) => {
        onChange({ ...value, getBy: e.currentTarget.value } as ITestStep);
    }

    const onIdentifierChange = (e: FormEvent<HTMLInputElement>) => {
        onChange({ ...value, identifier: e.currentTarget.value } as ITestStep);
    }

    const onCheckChange = (e: FormEvent<HTMLSelectElement>) => {
        onChange({ ...value, check: e.currentTarget.value } as ITestStep);
    }

    const onCriteriaChange = (e: FormEvent<HTMLSelectElement>) => {
        onChange({ ...value, criteria: e.currentTarget.value } as ITestStep);
    }

    const onValueChange = (variable: IVariable | null) => {
        onChange({ ...value, value: variable } as ITestStep);
    }

    return (
        <div className="assertion">
            {/* <div>
                <span className="icon">
                    <FontAwesomeIcon icon={faEquals} />
                </span>
                <h6 className="is-size-6">Check Component</h6>
            </div> */}
            <h6 className="is-size-6">Check Component</h6>
            <div className="buttons">
                {/* <button className="button is-primary is-small">Save</button> */}
                <button className="button is-small" onClick={() => onDelete(value)} disabled={disabled}>Delete</button>
            </div>
            <div className="check-component">
                <div className="field">
                    <label className="label">Get By</label>
                    <div className="control">
                        <div className="select">
                        <select value={value.getBy} onChange={onGetByChange} disabled={disabled}>
                                <option>Select an identifier type</option>
                                <option value="label">Label</option>
                                <option value="name">Name</option>
                                <option value="id">Id</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="field">
                    <label className="label">Label</label>
                    <div className="control">
                        <input className="input" type="text" value={value.identifier} onChange={onIdentifierChange} disabled={disabled} />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Check</label>
                    <div className="control">
                        <div className="select">
                            <select value={value.check} onChange={onCheckChange} disabled={disabled}>
                                <option>Select a check type</option>
                                <option value="value">Value</option>
                                <option value="content">Content</option>
                                <option value="visible">Visible</option>
                                <option value="enabled">Enabled</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="field">
                    <label className="label">Criteria</label>
                    <div className="control">
                        <div className="select">
                            <select value={value.criteria} onChange={onCriteriaChange} disabled={disabled}>
                                <option>Select critieria</option>
                                <option value="=">Is</option>
                                <option value="!=">Is Not</option>
                                <option value="~">Contains</option>
                                <option value="!~">Does Not Contain</option>
                                <option value=">">Greater Than</option>
                                <option value=">=">Greater Than Or Equal To</option>
                                <option value="<">Less Than</option>
                                <option value="<=">Less Than Or Equal To</option>
                            </select>
                        </div>
                    </div>
                </div>
                <ValueSelector org={org} workspace={workspace} disabled={disabled} onChange={onValueChange} variable={value.value} />
            </div>
            {
                status === 'pass' ? <article className="message is-success status"><div className="message-body">Passed</div></article> : null
            }
            {
                status === 'fail' ? <article className="message is-danger status"><div className="message-body">{message}</div></article> : null
            }
        </div>
    )
}

export default CheckValue;