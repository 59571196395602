import React, { FormEvent } from 'react';
import ValueSelector from '../../../build/variable/ValueSelector';
import { ITestStep, ITestStepSetValue } from '../../../../../../types/testing';
import { IVariable } from '../../../../../../types/variable';
import classnames from 'classnames';

type status = 'pass' | 'fail' | 'none';

interface Props {
    org: string
    workspace: string
    value: ITestStepSetValue
    status?: status
    message?: string
    disabled: boolean
    onChange: (step: ITestStep) => void
    onDelete: (step: ITestStep) => void
}

const SetComponentValue = ({ org, workspace, value, disabled, onChange, onDelete, status, message }: Props) => {

    const onGetByChange = (e: FormEvent<HTMLSelectElement>) => {
        onChange({ ...value, getBy: e.currentTarget.value } as ITestStep);
    }

    const onIdentifierChange = (e: FormEvent<HTMLInputElement>) => {
        onChange({ ...value, identifier: e.currentTarget.value } as ITestStep);
    }

    const onValueChange = (variable: IVariable | null) => {
        onChange({ ...value, value: variable } as ITestStep);
    }

    const className = classnames('step', { pass: status === 'pass' }, { fail: status === 'fail' });

    return (
        <div className={className}>
            <h6 className="is-size-6">Set Component&apos;s Value</h6>
            <div className="buttons">
                {/* <button className="button is-primary is-small">Save</button> */}
                <button className="button is-small" onClick={() => onDelete(value)}>Delete</button>
            </div>
            <div className="set-component-value">
                <div className="field">
                    <label className="label">Get By</label>
                    <div className="control">
                        <div className="select">
                            <select value={value.getBy} onChange={onGetByChange}>
                                <option>Select an identifier type</option>
                                <option value="label">Label</option>
                                <option value="name">Name</option>
                                <option value="id">Id</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="field">
                    <label className="label">Label</label>
                    <div className="control">
                        <input className="input" type="text" value={value.identifier} onChange={onIdentifierChange} />
                    </div>
                </div>
                <ValueSelector org={org} workspace={workspace} disabled={disabled} onChange={onValueChange} variable={value.value} type="string" />
            </div>
            {
                status === 'pass' ? <article className="message is-success status"><div className="message-body">Passed</div></article> : null
            }
            {
                status === 'fail' ? <article className="message is-danger status"><div className="message-body">{message}</div></article> : null
            }
        </div>
    )
}

export default SetComponentValue;