import React, { useEffect, useState } from "react";
import Actions from "./actions/Actions";
import Apps from './apps/Apps';
import Plugins from "./plugins/Plugins";
import Rules from "./rules/Rules";
import Screens from "./screens/Screens";
import Runners from "./runners/Runners";
import { State } from "xstate";
import { Context, Event } from "../../machines/root";
import Select from 'react-select'
import './build.css';
import { subscribe, unsubscribe } from "./versionable/references";
import _ from "lodash";

const Build = ({ state, send }: { state: State<Context, Event, any>, send: Function }) => {
	const [filterOptions, setFilterOptions] = useState<any[]>([]);
	const [selectedFilter, setSelectedFilter] = useState(null);

	const onClick = (tab: string) => send('TAB', { tab });

	const tabs = [
		{ title: 'Apps', type: 'apps' },
		{ title: 'Screens', type: 'screens' },
		{ title: 'Actions', type: 'actions' },
		{ title: 'Rules', type: 'rules' },
		{ title: 'Plugins', type: 'plugins' },
		{ title: 'Themes', type: 'runners' },
	];

	let editor: JSX.Element | null = null;
	switch (true) {
		case state.matches({ build: 'apps' }):
			editor = <Apps machine={state.context.apps} />;
			break;

		case state.matches({ build: 'plugins' }):
			editor = <Plugins machine={state.context.plugins as any}/>;
			break;

		case state.matches({ build: 'actions' }):
			editor = <Actions machine={state.context.actions} />;
			break;

		case state.matches({ build: 'rules' }):
			editor = <Rules machine={state.context.rules}/>;
			break;
		
		case state.matches({ build: 'screens' }):
			editor = <Screens machine={state.context.screens} />;
			break;

		case state.matches({ build: 'runners' }):
			editor = <Runners machine={state.context.runners} />;
			break;
	}

	const onFilterChange = (option) => {
		setSelectedFilter(option);
		send({ type: 'FILTER', app: option?.value });
	}

	useEffect(() => {
		const callback = (apps) => {
			setFilterOptions(
				_.map(apps, (app) => ({
					value: app.id,
					label: app.name
				}))
			);			
		}

		subscribe('app', callback);

		return () => {
			unsubscribe('app', callback)
		}
	}, []);

	return (
		<>
			<div className="build-header">
				<div className="tabs is-boxed flex-shrink-0">
					<ul>
						{
							tabs.map((tab: any) => (
								<li className={state.matches({ build: tab.type }) ? 'is-active' : ''} key={tab.type}>
									<a onClick={onClick.bind(null, tab.type)}>
										<span>{tab.title}</span>
									</a>
								</li>
							))
						}
					</ul>
				</div>
				<div className="filter">
					<Select 
						placeholder="Filter by App"
						isSearchable={true}
						isClearable={true}
						options={filterOptions}
						onChange={onFilterChange}
						value={selectedFilter}
					/>
				</div>
			</div>
			{editor}
		</>
	);
};

export default Build;
