import React from 'react';
import _ from 'lodash';
import Select from 'react-select';
import * as Icon from 'react-feather';

const spaceInsert = /([A-Z])/g;

export default ({ value, onChange, disabled }: { value: string, disabled: boolean, onChange: (icon: string) => void }) => {
    const onIconChange = (selected: any) => {
        onChange(selected.value);
    }

    const options = _.chain(Icon)
        .keys()
        .map(icon => ({
            value: icon,
            label: icon.replace(spaceInsert, ' $1').trim()
        }))
        .value();
    
    const formatOptionLabel = ({ value, label }) => {
        return (
            <div className="react-select-feather-icon-option">
                {React.createElement(Icon[value])}
                <div>{label}</div>
            </div>
        )
    }

    const selectedOption = options.find(option => option.value === value);

    return (
        <Select
            isDisabled={disabled}
            options={options}
            formatOptionLabel={formatOptionLabel}
            value={selectedOption}
            onChange={onIconChange}
        />
    )
}
