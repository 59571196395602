import React from "react";
import { LicenseType } from '../../machines/organization/organization'
import _ from "lodash";

const Licensing = ({ state, send }: { state: any, send: any }) => {

    const [users, setUsers] = React.useState(10);

    const onSubscribe = async () => {
        const result = await fetch(`${process.env.API_URL}/create-checkout-session`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                workspace: state.context.workspace,
                org: state.context.org,
                quantity: users,
                email: null // TODO
            })
        })
            .then((response) => response.json());

        const stripe = (window as any).Stripe(process.env.STRIPE_API_KEY);

        stripe.redirectToCheckout({
            sessionId: result.sessionId
        });
    }

    const onManage = async () => {
        const result = await fetch(`${process.env.API_URL}/customer-portal`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                org: state.context.org,
                workspace: state.context.workspace
            })
        })
            .then((response) => response.json());

        window.location.href = result.url;
    }

    if (!state.matches('edit'))
        return null;

    const orgUsage = {
        [LicenseType.Named]: _.sumBy(_.filter(state.context.usage, { type: LicenseType.Named }), 'usage'),
        [LicenseType.Unauthenticated]: _.sumBy(_.filter(state.context.usage, { type: LicenseType.Unauthenticated }), 'usage')
    }

    const workspaceUsage = _.chain(state.context.usage)
        .groupBy('id')
        .mapValues((item: any, id: string) => ({ ..._.groupBy(item, 'type'), id }))
        .mapValues((item: any) => {
            const name = _.get(_.head(item[LicenseType.Named]), 'name', '') || _.get(_.head(item[LicenseType.Unauthenticated]), 'name', '');

            return {
                id: item.id,
                name,
                [LicenseType.Named]: _.get(_.head(item[LicenseType.Named]), 'usage', 0),
                [LicenseType.Unauthenticated]: _.get(_.head(item[LicenseType.Unauthenticated]), 'usage', 0)
            }
        })
        .value();

    if (!state.context.license) {
        return (
            <div className="p licensing">
                <h4 className="is-size-4 mb">Not Licensed</h4>
                <p className="mb">
                    Your organization does not have an active AppWire license. You will still be able to run apps in the Development environment, however you will not be
                    able to deploy or run apps in the Testing or Production environment.
                </p>
                <p className="mb">
                    Licensing is based on monthly active users running apps in the Production environment. A running user is either named or anonymous, based on their email
                    address that is provided by an configured plugin.
                </p>
                <p className="mb">
                    Anonymous users are licensed at a 1:100 ratio to named licenses. For example, with 10 named user licenses you get 1000 anonymous user licenses.
                </p>
                <h5 className="is-size-5 mb">Named Users</h5>
                <p className="mb">
                    For example, a user runs an app that has an Authentication Permission defined and logs in as user@example.com. This will consume one user license for the billing period
                    (one month). This user can run many apps in the same billing period and only consume one user license, as long as they are identified as user@example.com.
                </p>
                <h5 className="is-size-5 mb">Anonymous Users</h5>
                <p className="mb">
                    For example, a user runs an app that does not have an Authentication Permission defined. This will consume one anonymous user license for the billing period (one month).
                    Each time this user runs an this app one more anonymous user license will be consumed.
                </p>
                <h5 className="is-size-5 mb">On Demand Users</h5>
                <p className="mb">
                    If you exceed your user license quota in a given billing period (one month) extra users licenses will be added at the on demand price of £50 per named user.
                </p>

                <div className="field">
                    <label className="label">Named User Licenses (£40 per license)</label>
                    <div className="control">
                        <input className="input" type="number" min={10} required value={users} onChange={(e) => setUsers(e.currentTarget.valueAsNumber)} style={{ width: 'auto' }} />
                    </div>
                </div>
                <button className="button is-success" onClick={onSubscribe} disabled={isNaN(users) || users < 10}>Subscribe</button>
            </div>
        )
    }
    else {
        return (
            <div className="p is-flex">
                <div className="flex-grow mr2">
                    {
                        state.context.license.active === false ? (
                            <article className="message is-warning mb">
                                <div className="message-header">
                                    <p>Payment Failed</p>
                                </div>
                                <div className="message-body">
                                    <button className="button is-primary">Manage</button>
                                </div>
                            </article>
                        ) : null
                    }

                    <h4 className="is-size-4 mb">Usage</h4>
                    <div className="mb">
                        <progress className="progress is-primary" value={_.get(orgUsage, LicenseType.Named, 0)} max={state.context.license.quota} />
                        <p className="has-text-centered"><strong>Users: {_.get(orgUsage, LicenseType.Named, 0)} / {state.context.license.quota}</strong></p>
                    </div>

                    <div className="mb">
                        <progress className="progress is-primary" value={_.get(orgUsage, LicenseType.Unauthenticated, 0)} max={state.context.license.quota * state.context.license.ratio} />
                        <p className="has-text-centered"><strong>Anonymous Users: {_.get(orgUsage, LicenseType.Unauthenticated, 0)} / {state.context.license.quota * state.context.license.ratio}</strong></p>
                    </div>

                    <h4 className="is-size-4 mb">Workspaces</h4>
                    {
                        _.keys(workspaceUsage).map((workspaceId: string) => {
                            const usage = workspaceUsage[workspaceId];

                            return (
                                <div className="mb" key={workspaceId}>
                                    <h5 className="is-size-5 mb">{usage.name}</h5>
                                    <div className="mb">
                                        <progress className="progress is-primary" value={usage[LicenseType.Named]} max={state.context.license.quota} />
                                        <p className="has-text-centered"><strong>Users: {usage[LicenseType.Named]} / {state.context.license.quota}</strong></p>
                                    </div>

                                    <div className="mb">
                                        <progress className="progress is-primary" value={usage[LicenseType.Unauthenticated]} max={state.context.license.quota * state.context.license.ratio} />
                                        <p className="has-text-centered"><strong>Anonymous Users: {usage[LicenseType.Unauthenticated]} / {state.context.license.quota * state.context.license.ratio}</strong></p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="box license">
                    <h4 className="is-size-4 mb">License</h4>

                    <div className="mb">
                        <h5 className="is-size-5">Users</h5>
                        <ul>
                            <li>{state.context.license.quota} Users</li>
                            <li>{state.context.license.quota * state.context.license.ratio} Anonymous Users</li>
                        </ul>
                    </div>

                    <div className="mb">
                        <h5 className="is-size-5">Billing Period</h5>
                        <p>{(new Date(state.context.license.current_period_start * 1000)).toLocaleDateString()} - {(new Date(state.context.license.current_period_end * 1000)).toLocaleDateString()}</p>
                    </div>

                    <div className="mb">
                        <p className="is-size-5">Standard: {state.context.license.rate}</p>
                        <p className="is-size-5">On Demand: {state.context.license.rate_demand}</p>
                    </div>

                    <button className="button is-primary" onClick={onManage}>Manage</button>
                </div>
            </div>
        );
    }
};

export default Licensing;

