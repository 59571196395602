import React, { FormEvent, useState } from "react";
import classnames from 'classnames';
import './login.css';

export default ({ send, error }: any) => {
    const [name, setName] = useState('');

    const onNameChange = (e: FormEvent<HTMLInputElement>) => setName(e.currentTarget.value);
    const onCreate = () => send({ type: 'ORGANIZATIONS.CREATE', name });

    return (
        <div className="box orgs">
            <h2 className="is-size-4 has-text-centered mb">Lets get started by creating a new Organization</h2>
            <div className="field">
                <div className="control mb">
                    <input className={classnames('input', { 'is-danger': error })} type="text" placeholder="Name" value={name} onChange={onNameChange}/>
                </div>
                {error ? <p className="help is-danger">{error}</p> : null}
            </div>
            <button className="button is-success" onClick={onCreate} disabled={!name}>Create</button>
        </div>
    );
}