import React, { FormEvent } from "react";
import classnames from 'classnames';
import "./navigation.css";
import _ from "lodash";

const Navigation = ({ state, send }: any) => {
	const onBuild = () => send({ type: 'NAVIGATE', target: 'build' });
	const onTesting = () => send({ type: 'NAVIGATE', target: 'testing' });
	const onDeployment = () => send({ type: 'NAVIGATE', target: 'deployment' });
	const onReporting = () => send({ type: 'NAVIGATE', target: 'reporting' });
	const onWorkspace = () => send({ type: 'NAVIGATE', target: 'workspace' });
	const onOrganization = () => send({ type: 'NAVIGATE', target: 'organization' });

	const onSwitchOrganization = (e: FormEvent<HTMLSelectElement>) => send({ type: 'SWITCH_ORGANIZATION', org: e.currentTarget.value });
	const onSwitchWorkspace = (e: FormEvent<HTMLSelectElement>) => send({ type: 'SWITCH_WORKSPACE', workspace: e.currentTarget.value });

	const onLogout = () => send('LOGOUT');

	const build = _.includes(state.context.user.roles, 'app-builder') ? <a className={classnames('navbar-item', { 'is-active': state.matches('build') })} onClick={onBuild}>Build</a> : null;
	const testing = _.includes(state.context.user.roles, 'app-builder') ? <a className={classnames('navbar-item', { 'is-active': state.matches('testing') })} onClick={onTesting}>Testing</a> : null;
	const deploy = _.includes(state.context.user.roles, 'app-admin') ? <a className={classnames('navbar-item', { 'is-active': state.matches('deployment') })} onClick={onDeployment}>Deploy</a> : null;
	const reporting = _.includes(state.context.user.roles, 'app-admin') ? <a className={classnames('navbar-item', { 'is-active': state.matches('reporting') })} onClick={onReporting}>Reporting</a> : null;
	const workspace = _.includes(state.context.user.roles, 'workspace-admin') ? <a className={classnames('navbar-item', { 'is-active': state.matches('workspace') })} onClick={onWorkspace}>Workspace</a> : null;
	const organization = _.includes(state.context.user.roles, 'organization-admin') ? <a className={classnames('navbar-item', { 'is-active': state.matches('organization') })} onClick={onOrganization}>Organization</a> : null;

	return (
		<nav
			className="navbar is-info"
			role="navigation"
			aria-label="main navigation"
		>
			<div className="navbar-brand">
				<h1 className="is-size-4 navbar-item">
					<img width="28" height="28" src="/icon_white.svg" alt="icon" />
					&nbsp;AppWire
				</h1>
			</div>

			<div className="navbar-menu">
				<div className="navbar-start">
					{build}
					{testing}
					{deploy}
					{reporting}
					{workspace}
					{organization}
				</div>

				<div className="navbar-end">
					<div className="navbar-item">
						<div className="select">
							<select value={state.context.org} onChange={onSwitchOrganization}>
								{state.context.organizations.map((org: any) => <option value={org.id} key={org.id}>{org.name}</option>)}
							</select>
						</div>
					</div>
					<div className="navbar-item">
						<div className="select">
							<select value={state.context.workspace} onChange={onSwitchWorkspace}>
								{state.context.workspaces.map((workspace: any) => <option value={workspace.id} key={workspace.id}>{workspace.name}</option>)}
							</select>
						</div>
					</div>
					<div className="navbar-item">
						<a className="has-text-white" onClick={onLogout}>Log Out</a>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Navigation;
