import { Machine, actions as stateActions, spawn } from 'xstate';
import { machine as environmentsMachine } from './environments/environments';
import { machine as releasesMachine } from './releases/releases';
import { machine as featureFlagsMachine } from './featureFlags/featureFlags';

const { assign } = stateActions;

export interface Schema {
    states: {
        edit: {},
    }
}

export interface Context {
    environments?: any
    releases?: any
    featureFlags?: any
    apps?: any
    plugins?: any
    org: string,
    workspace: string,
}

export const machine = Machine<Context, Schema, any>(
    {
        id: 'deployment',
        initial: 'edit',
        states: {
            edit: {
                entry: assign({
                    environments: (context) => spawn(environmentsMachine.withContext({ org: context.org, workspace: context.workspace }), { name: 'deployment-environments' }),
                    releases: (context) => spawn(releasesMachine.withContext({ org: context.org, workspace: context.workspace }), { name: 'deployment-releases' }),
                    featureFlags: (context) => spawn(featureFlagsMachine.withContext({ org: context.org, workspace: context.workspace }), { name: 'deployment-feature-flags' }),
                    // apps: (context) => spawn(appsMachine.withContext({ org: context.org, workspace: context.workspace })),
                    // plugins: (context) => spawn(pluginsMachine.withContext({ org: context.org, workspace: context.workspace })),
                })
            },
        }
    },
)