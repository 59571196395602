import _ from 'lodash';
import React, { FormEvent } from 'react';
import { IVariable } from '../../../../../types/variable';
import ValueSelector from '../variable/ValueSelector';
import VariableFinder from '../variable/VariableFinder';

type DatasetType = 'bar' | 'area' | 'line'

interface IDataset {
    type: DatasetType
    label: IVariable | null
    data: string
}

interface IDatasetProps {
    org: string
    workspace: string
    dataset: IDataset
    index: number
    disabled: boolean
    onChange: (value: IDataset, index: number) => void
    onRemove: (index: number) => void
}

const Dataset = ({ org, workspace, dataset, index, onChange, onRemove, disabled }: IDatasetProps) => {

    const onLabelChange = (label: IVariable | null) => onChange({ ...dataset, label }, index);
    const onTypeChange = (e: FormEvent<HTMLSelectElement>) => onChange({ ...dataset, type: e.currentTarget.value as DatasetType }, index);
    const onDataChange = (data: string) => onChange({ ...dataset, data }, index);

    return (
        <div className="box dataset">
            <button className="button is-small is-outlined" onClick={() => onRemove(index)}>Delete</button>
            <div className="field">
                <label className="label">Label</label>
                <ValueSelector
                    org={org}
                    workspace={workspace}
                    type="string"
                    variable={dataset.label}
                    onChange={onLabelChange}
                    allowRules={false}
                    small={true}
                    disabled={disabled}
                />
            </div>
            <div className="field">
                <label className="label">Type</label>
                <div className="control">
                    <div className="select">
                        <select value={dataset.type} onChange={onTypeChange}>
                            <option value="area">Area</option>
                            <option value="bar">Bar</option>
                            <option value="boxplot">Boxplot</option>
                            <option value="doughnut">Doughnut</option>
                            <option value="line">Line</option>
                            <option value="pie">Pie</option>
                            <option value="polarArea">Polar Area</option>
                            <option value="radar">Radar</option>
                            <option value="scatter">Scatter</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="field">
                <label className="label">Data</label>
                <div className="control">
                    <VariableFinder org={org} workspace={workspace} variable={dataset.data} onChange={onDataChange} disabled={disabled} />
                </div>
            </div>
        </div>
    );
}

interface IDatasetsProps {
    org: string
    workspace: string
    value: IDataset[]
    onChange: (datasets: IDataset[]) => void
    disabled: boolean
}

export default ({ org, workspace, value, onChange, disabled }: IDatasetsProps) => {

    const onAddDataset = () => {
        onChange([...value || [], { type: 'bar', label: null, data: '' }]);
    }

    const onDatasetChange = (dataset: IDataset, index: number) => {
        value[index] = dataset;
        onChange(value);
    }

    const onRemoveDataset = (index: number) => {
        onChange(value.filter((dataset: IDataset, i: number) => i !== index));
    }

    return (
        <div>
            {
                _.map(value, (dataset: IDataset, index: number) => (
                    <Dataset
                        org={org}
                        workspace={workspace}
                        dataset={dataset}
                        index={index}
                        disabled={disabled}
                        onChange={onDatasetChange}
                        onRemove={onRemoveDataset}
                    />
                ))
            }
            <button className="button is-small" onClick={onAddDataset} disabled={disabled}>Add Dataset</button>
        </div>
    );
}
