import { Machine, spawn, actions as stateActions, Interpreter } from 'xstate';
import { reporting } from '../../socket';
import { machine as appMachine, getUrlPart as getAppUrlPart } from './app';

const { assign } = stateActions;

export interface Schema {
    states: {
        loading: {},
        select: {}
        idle: {},
        error: {}
    }
}

export type Event =
    | { type: 'LOADED' }
    | { type: 'DISMISS' }
    | { type: 'SEARCH' }
    | { type: 'SELECT' }

export interface Context {
    error?: string | null,
    selected?: string | null,
    apps?: any[],
    search?: string,
    machine?: any,
    dev?: any,
    test?: any
    prod?: any
    org: string
    workspace: string
}

const urlApp = /reporting\/apps\/(.{7})/i

const realtime = (context: Context, event: any) => (callback: any, onReceive: any) => {
    const onLoad = (response: any) => callback({ type: 'LOADED', apps: response });

    reporting.apps(context.org, context.workspace, onLoad);

    onReceive((e: any) => {
        switch (e.type) {
            
        }
    })

    return () => { };
}

const history = (context: Context) => (callback: any, onReceive: any) => {
    const listener = (event: PopStateEvent) => {
        const match = urlApp.exec(window.location.pathname);

        if (match)
            callback({ type: 'SELECT', value: match![1] });
    }

    window.addEventListener('popstate', listener);
    return () => window.removeEventListener('popstate', listener);
}

export const machine = Machine<Context, Schema, Event>(
    {
        id: 'apps',
        initial: 'loading',
        invoke: [
            { id: 'socket', src: realtime },
            { id: 'history', src: history }
        ],
        states: {
            loading: {
                on: {
                    LOADED: [
                        { cond: 'url', actions: ['loaded'], target: 'select' },
                        { actions: ['loaded'], target: 'idle' }
                    ]
                } as any
            },
            select: {
                always: {
                    actions: assign<Context, Event>((context, event: any) => {
                        const match = urlApp.exec(window.location.pathname);

                        return {
                            ...context,
                            selected: match![1],
                            dev: (context: any, event: any) =>
                                spawn(appMachine.withContext({ org: context!.org, workspace: context!.workspace, id: match![1], environment: 'dev', tab: 'variables' }), { name: 'reporting-app-dev' }),
                            test: (context: any, event: any) =>
                                spawn(appMachine.withContext({ org: context!.org, workspace: context!.workspace, id: match![1], environment: 'test', tab: 'variables' }), { name: 'reporting-app-test' }),
                            prod: (context: any, event: any) =>
                                spawn(appMachine.withContext({ org: context!.org, workspace: context!.workspace, id: match![1], environment: 'prod', tab: 'variables' }), { name: 'reporting-app-prod' })
                        }
                    }),
                    target: 'idle'
                }
            },
            idle: {
                on: {
                    SEARCH: {
                        actions: assign({
                            search: (_, event: any) => event.value
                        }),
                        // target: 'loading'
                    },
                    SELECT: {
                        actions: [
                            assign({
                                selected: (context: Context, event: any) => {
                                    if (context.selected)
                                        reporting.close(context.org, context.workspace, context.selected);

                                    if (context.dev)
                                        context.dev.stop!();

                                    if (context.test)
                                        context.test.stop!();

                                    if (context.prod)
                                        context.prod.stop!();

                                    return event.value
                                },
                                dev: (context: any, event: any) =>
                                    spawn(appMachine.withContext({ org: context!.org, workspace: context!.workspace, id: event.value, environment: 'dev', tab: 'variables' }), { name: 'reporting-app-dev' }),
                                test: (context: any, event: any) =>
                                    spawn(appMachine.withContext({ org: context!.org, workspace: context!.workspace, id: event.value, environment: 'test', tab: 'variables' }), { name: 'reporting-app-test' }),
                                prod: (context: any, event: any) =>
                                    spawn(appMachine.withContext({ org: context!.org, workspace: context!.workspace, id: event.value, environment: 'prod', tab: 'variables' }), { name: 'reporting-app-prod' })
                            }),
                            // (context: Context, event: any) => {
                            //     window.history.pushState(null, '', `/${context.org}/${context.workspace}/build/apps/${event.value}`);
                            // }
                        ],
                    }
                } as any
            },
            error: {
                on: {
                    DISMISS: 'idle'
                }
            }
        }
    }, 
    {
        actions: {
            loaded: assign({
                apps: (_, event: any) => event.apps
            })
        },
        guards: {
            url: () => urlApp.test(window.location.pathname)
        }
    }
)

export const getUrlPart = (interpreter: Interpreter<Context, Schema, Event>) => {
    if (interpreter.state.context.machine)
        return `/reporting/apps/${interpreter.state.context.selected}${getAppUrlPart(interpreter.state.context.machine)}`;

    return '/reporting/apps';
}