/* eslint-disable react/display-name */
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import './component.css';
import _ from 'lodash';
import Property from './Property';
import classnames from 'classnames';
import Trigger from './Trigger';
import properties from '../../../machines/build/screens/properties';
import triggers from '../../../machines/build/screens/triggers';
import { subscribe, unsubscribe } from '../versionable/references';
import { IComponent } from '../../../../../types/screen';

interface Props {
    org: string
    workspace: string
    component: IComponent
    onChange: (component: IComponent) => void
    onDelete: (component: IComponent) => void
    disabled: boolean
}

export default ({ org, workspace, component, onChange, onDelete, disabled }: Props) => {
    const [tab, setTab] = useState(0);
    const [featureFlags, setFeatureFlags] = useState([]);

    const featureFlagCallback = useCallback((items: any) => {
        setFeatureFlags(items);
    }, [setFeatureFlags]);

    useEffect(() => {
        subscribe('featureFlag', featureFlagCallback);
        return () => unsubscribe('featureFlag', featureFlagCallback);
    }, []);

    const onNameChange = (e: FormEvent<HTMLInputElement>) => onChange({ ...component, name: e.currentTarget.value });
    const onFeatureFlagChange = (e: FormEvent<HTMLSelectElement>) => onChange({ ...component, featureFlags: [e.currentTarget.value] });
    const onClearFeatureFlags = () => onChange({ ...component, featureFlags: [] });

    const onTypeChange = (e: FormEvent<HTMLSelectElement>) => onChange({ 
        ...component,
        type: e.currentTarget.value,
        properties: _.mapValues(
            properties[component.type],
            (value) => {
                return properties[component.type][value.id] || {
                    ..._.pick(value, ['id', 'name', 'kind', 'type']),
                    value: value.default || null
                };
            }
        ),
        triggers: _.mapValues(
            triggers[component.type],
            (value) => {
                return triggers[component.type][value.id] || value
            }
        )
    });

    const onPropertyChange = (id: string, value: any) => {
        onChange({
            ...component,
            properties: {
                ...component.properties,
                [id]: {
                    ..._.pick(properties[component.type][id], ['id', 'name', 'kind', 'type']),
                    ...component.properties[id],
                    value
                }
            }
        })
    }

    const onTriggerChange = (id: string, value: any) => {
        onChange({
            ...component,
            triggers: {
                ...component.triggers,
                [id]: {
                    ...component.triggers[id],
                    ...value
                }
            }
        })
    }

    return (
        <div className="page-component overflow-hidden is-flex flex-col p-scroll flex-grow">
            <div className="is-flex items-center">
                <button className="button is-danger is-small is-outlined mr" onClick={onDelete.bind(null, component)} disabled={disabled}>Delete</button>
            </div>

            <div className="field mr">
                <label className="label">Type</label>
                <div className="control">
                    <div className="select is-fullwidth">
                        <select name="component-type" value={component.type} onChange={onTypeChange} disabled={disabled}>
                            <option>Select a type</option>
                            <option value="alert">Alert</option>
                            <option value="button">Button</option>
                            <option value="chart">Chart</option>
                            <option value="checkbox">Checkbox</option>
                            <option value="display">Display Content</option>
                            <option value="file-upload">File Upload</option>
                            <option value="heading">Heading</option>
                            <option value="image">Image</option>
                            <option value="date">Input Date</option>
                            <option value="date-time">Input Date & Time</option>
                            <option value="number">Input Number</option>
                            <option value="text">Input Text</option>
                            <option value="textarea">Input Text - Multiline</option>
                            <option value="time">Input Time</option>
                            <option value="list">List</option>
                            <option value="modal">Modal Dialog</option>
                            <option value="progress">Progress</option>
                            <option value="repeater">Repeater</option>
                            <option value="screen">Screen</option>
                            <option value="select">Select</option>
                            <option value="slider">Slider</option>
                            <option value="table">Table</option>
                            <option value="tabs">Tabs</option>
                            <option value="tree">Tree</option>
                            <option value="world-map">World Map</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className="field mr">
                <label className="label">Name</label>
                <div className="control">
                    <input className="input" value={component.name} onChange={onNameChange} disabled={disabled} />
                </div>
            </div>

            <div className="tabs is-boxed flex-shrink-0 pr">
                <ul>
                    <li className={classnames({ 'is-active': tab === 0 })}><a onClick={() => setTab(0)}>Properties</a></li>
                    <li className={classnames({ 'is-active': tab === 1 })}><a onClick={() => setTab(1)}>Triggers</a></li>
                    <li className={classnames({ 'is-active': tab === 2 })}><a onClick={() => setTab(2)}>Feature Flags</a></li>
                </ul>
            </div>

            <div className={classnames({ 'is-hidden': tab !== 0 }, 'overflow-y-auto', 'pr')}>
                {
                    Object
                        .values(properties[component.type])
                        .map((property: any) => (
                            <Property 
                                org={org}
                                workspace={workspace}
                                componentType={component.type}
                                id={property.id}
                                kind={property.kind}
                                value={component.properties[property.id]?.value}
                                onChange={onPropertyChange}
                                disabled={disabled}
                                key={property.id}
                            />
                        ))
                }
            </div>

            <div className={classnames({ 'is-hidden': tab !== 1 }, 'overflow-y-auto', 'pr')}>
                {
                    Object
                        .values(triggers[component.type])
                        .map((trigger: any) => (
                            <Trigger
                                org={org}
                                workspace={workspace}
                                componentType={component.type}
                                value={component.triggers[trigger.id]}
                                onChange={onTriggerChange}
                                id={trigger.id}
                                disabled={disabled}
                                key={trigger.id} 
                            />
                        ))
                }
            </div>

            <div className={classnames({ 'is-hidden': tab !== 2 }, 'overflow-y-auto', 'pr')}>
                <div className="field has-addons">
                    <div className="control is-expanded">
                        <div className="select is-fullwidth">
                            <select value={component.featureFlags?.[0] || ''} onChange={onFeatureFlagChange} disabled={disabled}>
                                <option>Select a Feature Flag</option>
                                {
                                    _.map(featureFlags, (featureFlag: any) => (
                                    <option value={featureFlag.id} key={featureFlag.id}>{featureFlag.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="control">
                        <a className="button" role="button" onClick={onClearFeatureFlags}>Clear</a>
                    </div>
                </div>
            </div>
        </div>
    );
}
