import { useActor } from '@xstate/react';
import classnames from 'classnames'
import React, { FormEvent } from 'react';
import { Context, Schema } from '../../../machines/reporting/app';
import _ from 'lodash';
import { ActorRefFrom, StateMachine } from 'xstate';

export default ({ machine, active }: { machine: ActorRefFrom<StateMachine<Context, Schema, any>> | null, active: boolean } ) => {
    if (!active)
        return null;

    const [state, send] = useActor(machine!);

    const onTab = (tab: string) => send({ type: 'TAB', tab });
    const onSelect = (run: string) => send({ type: 'SELECT', run });
    const onVersion = (e: FormEvent<HTMLInputElement>) => send({ type: 'VERSION', value: e.currentTarget.value });
    const onState = (e: FormEvent<HTMLInputElement>) => send({ type: 'STATE', value: e.currentTarget.value });
    const onFrom = (e: FormEvent<HTMLInputElement>) => send({ type: 'FROM', value: e.currentTarget.value });
    const onTo = (e: FormEvent<HTMLInputElement>) => send({ type: 'TO', value: e.currentTarget.value });

    let runs = Object.values(state.context.runs || {})
        .filter((run: any) => {
            return (
                _.toLower(run.app_version).includes(state.context.version || '') &&
                _.toLower(run.state).includes(state.context.state || '') &&
                (state.context.build ? run.build === state.context.build : true)
            );
        })
        .map((run: any) => (
            <tr onClick={onSelect.bind(null, run.id)} key={run.id} className={classnames({ 'is-selected': run.id === state.context.selected })}>
                <td><a href={`${process.env.API_URL}/run?org=${state.context.org}&workspace=${state.context.workspace}&app=${state.context.id}&environment=${state.context.environment}&version=${run.app_version}&run=${run.id}`} target="blank" rel="noopener noreferrer">{run.id}</a></td>
                <td>{run.app_version || 'working'}</td>
                <td>{(new Date(run.started)).toLocaleString()}</td>
                <td>{(new Date(run.modified)).toLocaleString()}</td>
                <td>{run.state}</td>
            </tr>
        ));

    return (
        <>
            <div className="pr is-flex flex-col overflow-hidden">
                <div className="is-flex mb">
                    <div className="field mr">
                        <label className="label">Version</label>
                        <input className="input" type="text" placeholder="Version" value={state.context.version} onChange={onVersion} />
                    </div>
                    <div className="field mr">
                        <label className="label">State</label>
                        <input className="input" type="text" placeholder="State" value={state.context.state} onChange={onState} />
                    </div>
                    <div className="field mr">
                        <label className="label">From</label>
                        <div className="control">
                            <input className="input" type="date" value={state.context.from} onChange={onFrom} />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">To</label>
                        <div className="control">
                            <input className="input" type="date" value={state.context.to} onChange={onTo} />
                        </div>
                    </div>
                </div>
                <div className="flex-grow overflow-y-auto">
                    <table className="table is-fullwidth">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Version</th>
                                <th>Started On</th>
                                <th>Modified On</th>
                                <th>State</th>
                            </tr>
                        </thead>
                        <tbody>
                        {runs}
                        </tbody>
                    </table>
                </div>
            </div>
            {
                state.context.selected ? (
                    <div className="overflow-hidden is-flex flex-col">
                        <div className="tabs is-boxed">
                            <ul>
                                <li className={classnames({'is-active': state.context.tab === 'variables' })} onClick={onTab.bind(null, 'variables')}>
                                    <a>Variables</a>
                                </li>
                                <li className={classnames({'is-active': state.context.tab === 'log' })} onClick={onTab.bind(null, 'log')}>
                                    <a>Log</a>
                                </li>
                            </ul>
                        </div>
                        {
                            state.context.tab === 'variables' ? (
                                <pre className="is-family-code overflow-y-auto flex-grow">
                                    {state.context.variables ? JSON.stringify(state.context.variables, null, 4) : ''}
                                </pre>
                            ) : null
                        }    
                        {
                            state.context.tab === 'log' ? (
                                <ol className="overflow-y-auto flex-grow">
                                    {
                                        _.chain(state.context.log)
                                            .keys()
                                            .map(key => <p>{(new Date(_.toNumber(key))).toLocaleString()} - {state.context.log[key]}</p>)
                                            .value()
                                    }
                                </ol>
                            ) : null
                        }
                    </div>
                ) : null
        }
        </>
    );
}