/* eslint-disable react/display-name */
import React from 'react';
import { IDependency } from '../../../../../types/versionable';

interface Props {
    org: string
    workspace: string
    dependencies: IDependency[] | undefined
    onClick?: (item: IDependency) => void
}

export default ({ org, workspace, dependencies, onClick }: Props ) => {
    if (!dependencies || dependencies.length === 0)
        return <p>This item does not reference any other items</p>

    const onAnchorClick = (e: any, item: IDependency) => {
        e.preventDefault();
        e.stopPropagation();

        onClick && onClick(item);

        return false;
    }

    const rows = dependencies.map((item) => {
        let url = `${process.env.APP_URL}/${org}/${workspace}/build/${item.type}s/${item.id}`;
        
        if (item.version !== 'working')
            url += `/${item.version}`;

        return (
            <tr key={item.id}>
                <td><a href={url} onClick={(e) => onAnchorClick(e, item)}>{item.name}</a></td>
                <td>{item.version}</td>
                <td>{item.type}</td>
            </tr>
        );
    })

    return (
        <table className="table is-striped is-fullwidth">
            <thead>
                <tr>
                    <td>Name</td>
                    <td>Version</td>
                    <td>Type</td>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
    );
}