/* eslint-disable react/display-name */
import React, { FormEvent, useState } from 'react';
import Header from '../editing/Header';
import History from '../editing/History';
import Delete from '../actions/Delete';
import { useActor } from "@xstate/react";
import { Context, Event } from '../../../machines/build/versionable-item';
import OpenAPI from './OpenAPI';
import SQL from './SQL';
import Email from './Email';
import NewVersion from '../editing/NewVersion';
import { ActorRefFrom, StateMachine } from 'xstate';
import { IPlugin, IPluginEmail, IPluginOpenAPI, IPluginSQL } from '../../../../../types/plugin';
import { IDependency } from '../../../../../types/versionable';
import Dependencies from '../editing/Dependencies';

export default ({ machine }: { machine: ActorRefFrom<StateMachine<Context<IPlugin>, any, Event<IPlugin>>> }) => {
    const [state, send] = useActor(machine);
    const [isNewVersionVisible, setNewVersionVisible] = useState(false);

    const onVersion = (version: string) => send({ type: 'VERSION', version });
  
    const onNewVersion = () => setNewVersionVisible(true);
    const onCloseNewVersion = () => setNewVersionVisible(false);

    const onNewVersionCreated = () => {
        setNewVersionVisible(false);
        send({ type: 'NEW_VERSION_CREATED' });
    }

    const onNameChange = (e: FormEvent<HTMLInputElement>) => send({ type: 'CHANGE', value: { name: e.currentTarget.value } });
    const onChange = (plugin: Partial<IPlugin>) => send({ type: 'CHANGE', value: plugin });

    const onDelete = () => send('DELETE');

    const onDependencyClick = (item: IDependency) => send({ type: 'ITEM', itemType: item.type, id: item.id, version: item.version });

    let editor: JSX.Element | null = null;
    if (state.matches('edit')) {
        switch (state.context.item?.type.toUpperCase()) {
            case 'OPENAPI':
                editor = <OpenAPI
                    org={state.context.org}
                    workspace={state.context.workspace}
                    environment='dev'
                    item={state.context.item as IPluginOpenAPI}
                    onChange={onChange}
                />
                break;

            case 'SQL':
                editor = <SQL item={state.context.item as IPluginSQL} onChange={onChange} />
                break;

            case 'EMAIL':
                editor = <Email item={state.context.item as IPluginEmail} onChange={onChange} />
                break;
        }
    }

    return (
        <div className="plugin-editor flex-grow overflow-hidden is-flex flex-col">
            <Header
                versions={state.context.versions}
                version={state.context.version}
                onVersion={onVersion}
                onNewVersion={onNewVersion}
                users={state.context.users}
                modified={state.context.item?.modified}
            />

            <div className="update p overflow-y-auto">
                <div>
                {
                    state.matches('edit') || state.matches('spec') ? (
                        <>
                            <div className="box">
                                <div className="is-flex mb">
                                    <div className="field flex-grow mr">
                                        <div className="control">
                                            <input className="input is-large" type="text" placeholder="Plugin Name" value={state.context.item?.name} onChange={onNameChange} disabled={state.context.item?.readonly} />
                                        </div>
                                    </div>
                                    <button className="button is-danger is-small is-outlined" onClick={onDelete} disabled={state.context.item?.readonly}>Delete</button>
                                </div>
                                {editor}  
                            </div>

                            <div className="columns">
                                    <div className="column">
                                        <div className="card mb">
                                            <header className="card-header">
                                                <p className="card-header-title">
                                                    Uses
                                                </p>
                                            </header>
                                            <div className="card-content">
                                                <Dependencies org={state.context.org} workspace={state.context.workspace} dependencies={state.context.uses} onClick={onDependencyClick} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="column">
                                        <div className="card mb">
                                            <header className="card-header">
                                                <p className="card-header-title">
                                                    Used By
                                                </p>
                                            </header>
                                            <div className="card-content">
                                                <Dependencies org={state.context.org} workspace={state.context.workspace} dependencies={state.context.usedBy} onClick={onDependencyClick} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="column">
                                        <div className="card">
                                            <header className="card-header">
                                                <p className="card-header-title">
                                                    History
                                                </p>
                                            </header>
                                            <div className="card-content">
                                                <History versions={state.context.versions} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </>
                    ) : null
                }
                </div>
                {
                    isNewVersionVisible ? (
                        <NewVersion
                            org={state.context.org}
                            workspace={state.context.workspace}
                            id={state.context.id}
                            type="plugin"
                            onNewVersion={onNewVersionCreated}
                            onClose={onCloseNewVersion}
                        />
                        ) : null
                }
                <Delete state={state} send={send} />
            </div>
        </div>
    );
}