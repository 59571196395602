import React from "react";
import { useMachine } from "@xstate/react";
import { machine } from '../machines/root';
import Application from "./Application";
import Organisations from "./login/Organisations";
import NewOrganization from './login/NewOrganization';
import Workspaces from "./login/Workspaces";
import NewWorkspace from "./login/NewWorkspace";

const Root = () => {
    const [state, send] = useMachine(machine);

    const onSelectOrganisation = (org: any) => send({ type: 'SELECT', value: org });
    const onSelectWorkspace = (workspace: any) => send({ type: 'SELECT', value: workspace });

	switch (true) {
        case state.matches('idle'):
        case state.matches('validating'):
            return <p>loading</p>;

        case state.matches('newOrganization'):
            return <NewOrganization send={send} error={state.context.organizationError} />;

        case state.matches('newWorkspace'):
            return <NewWorkspace send={send} org={state.context.org} error={state.context.organizationError} />;

        case state.matches('organizations'):
            return <Organisations items={state.context.organizations} onSelect={onSelectOrganisation} />

        case state.matches('workspaces'):
            return <Workspaces items={state.context.workspaces} onSelect={onSelectWorkspace} />

        case state.matches('build'):
        case state.matches('testing'):
        case state.matches('deployment'):
        case state.matches('reporting'):
        case state.matches('workspace'):
        case state.matches('organization'):
            return <Application state={state as any} send={send} />
    }

    return null;
};

export default Root;
