/* eslint-disable react/display-name */
import React, { FormEvent, useState } from 'react';
import ValueSelector from '../variable/ValueSelector';
import Header from '../editing/Header';
import Dependencies from '../editing/Dependencies';
import Delete from './Delete';
import History from '../editing/History';
import { useActor } from "@xstate/react";
import { Context, Event } from '../../../machines/build/versionable-item';
import VersionableItems from '../versionable/VersionableItems';
import './action-editor.css';
import NewVersion from '../editing/NewVersion';
import { ActorRefFrom, StateMachine } from 'xstate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { IActionEmail } from '../../../../../types/action';
import { IDependency, IVersionableReferenceSelected } from '../../../../../types/versionable';
import { IVariable } from '../../../../../types/variable';
import MarkdownEditor from '../editing/MarkdownEditor';

export default ({ machine }: { machine: ActorRefFrom<StateMachine<Context<IActionEmail>, any, Event<IActionEmail>>> }) => {
    const [state, send] = useActor(machine);
    const [selectedTab, setSelectedTab] = React.useState<"write" | "preview">("write");
    const [isNewVersionVisible, setNewVersionVisible] = useState(false);

    const onVersion = (version: string) => send({ type: 'VERSION', version });
  
    const onNewVersion = () => setNewVersionVisible(true);
    const onCloseNewVersion = () => setNewVersionVisible(false);

    const onNewVersionCreated = () => {
        setNewVersionVisible(false);
        send({ type: 'NEW_VERSION_CREATED' });
    }

    const onNameChange = (e: FormEvent<HTMLInputElement>) => send({ type: 'CHANGE', value: { name: e.currentTarget.value } });
    const onFromChange = (from: IVariable | null) => send({ type: 'CHANGE', value: { from } });
    const onToChange = (to: IVariable | null) => send({ type: 'CHANGE', value: { to } });
    const onCCChange = (cc: IVariable | null) => send({ type: 'CHANGE', value: { cc } });
    const onSubjectChange = (subject: IVariable | null) => send({ type: 'CHANGE', value: { subject } });
    const onContentChange = (content: string) => send({ type: 'CHANGE', value: { content } });
    const onUsingChange = (using: IVersionableReferenceSelected[]) => send({ type: 'CHANGE', value: { using: using[0] }});

    const onDependencyClick = (item: IDependency) => send({ type: 'ITEM', itemType: item.type, id: item.id, version: item.version });

    const onDelete = () => send('DELETE');

    return (
        <div className="action-editor">
            <Header
                versions={state.context.versions}
                version={state.context.version}
                onVersion={onVersion}
                onNewVersion={onNewVersion}
                users={state.context.users}
                modified={state.context.item ? state.context.item.modified : null}
            />

            <div className="email p">
                {
                    state.matches('edit') && state.context.item ? (
                        <>
                            <div className="box">
                                <div className="is-flex justify-between mb">
                                    <div>
                                        <FontAwesomeIcon icon={faEnvelope} className="mr" />
                                        <strong>Send Email</strong>
                                    </div>
                                    <button className="button is-danger is-small is-outlined" onClick={onDelete} disabled={state.context.item.readonly}>Delete</button>
                                </div>
                                <div className="field mb">
                                    <div className="control">
                                        <input className="input is-large" type="text" placeholder="Action Name" value={state.context.item.name} onChange={onNameChange} disabled={state.context.item.readonly} />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label">Plugin</label>
                                    <VersionableItems
                                        id="using"
                                        value={state.context.item?.using ? [state.context.item.using] : []}
                                        onChange={onUsingChange}
                                        type="plugin"
                                        disabled={state.context.item.readonly} 
                                    />
                                </div>
                                <div className="field">
                                    <label className="label">From</label>
                                    <ValueSelector
                                        org={state.context.org}
                                        workspace={state.context.workspace}
                                        variable={state.context.item.from}
                                        onChange={onFromChange}
                                        small={true}
                                        disabled={state.context.item.readonly}
                                    />
                                </div>
                                <div className="field">
                                    <label className="label">To</label>
                                    <ValueSelector
                                        org={state.context.org}
                                        workspace={state.context.workspace}
                                        variable={state.context.item.to}
                                        onChange={onToChange}
                                        small={true}
                                        disabled={state.context.item.readonly}
                                    />
                                </div>
                                <div className="field">
                                    <label className="label">CC</label>
                                    <ValueSelector
                                        org={state.context.org}
                                        workspace={state.context.workspace}
                                        variable={state.context.item.cc}
                                        onChange={onCCChange}
                                        small={true}
                                        disabled={state.context.item.readonly}
                                        allowNothing={true}
                                    />
                                </div>
                                <div className="field">
                                    <label className="label">Subject</label>
                                    <ValueSelector
                                        org={state.context.org}
                                        workspace={state.context.workspace}
                                        variable={state.context.item.subject}
                                        onChange={onSubjectChange}
                                        small={true}
                                        disabled={state.context.item.readonly}
                                    />
                                </div>
                                <div className="field">
                                    <label className="label">Content</label>
                                    <MarkdownEditor
                                        id="action-email-content"
                                        value={state.context.item!.content}
                                        onChange={onContentChange}
                                        disabled={state.context.item.readonly}
                                    />
                                </div>
                            </div>

                            <div className="columns">
                                <div className="column">
                                    <div className="card mb">
                                        <header className="card-header">
                                            <p className="card-header-title">
                                            Uses
                                            </p>
                                        </header>
                                        <div className="card-content">
                                            <Dependencies org={state.context.org} workspace={state.context.workspace} dependencies={state.context.uses} onClick={onDependencyClick} />
                                        </div>
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="card mb">
                                        <header className="card-header">
                                            <p className="card-header-title">
                                            Used By
                                            </p>
                                        </header>
                                        <div className="card-content">
                                            <Dependencies org={state.context.org} workspace={state.context.workspace} dependencies={state.context.usedBy} onClick={onDependencyClick} />
                                        </div>
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="card">
                                        <header className="card-header">
                                            <p className="card-header-title">
                                                History
                                    </p>
                                        </header>
                                        <div className="card-content">
                                            <History versions={state.context.versions} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null
                }
                {
                    isNewVersionVisible ? (
                        <NewVersion
                            org={state.context.org}
                            workspace={state.context.workspace}
                            id={state.context.id}
                            type="action"
                            onNewVersion={onNewVersionCreated}
                            onClose={onCloseNewVersion}
                        />
                        ) : null
                }
                <Delete state={state} send={send} />
            </div>
        </div>
    );
}