import { actions as stateActions, createMachine, Sender, Receiver } from 'xstate';
import { applyPatch, Operation, compare } from 'fast-json-patch';
import { edit, change, close, remove, versions } from '../../socket';
import { ItemEvent } from '../root';
import _ from 'lodash';
import { IDependency, IVersion, IVersionableItem } from '../../../../types/versionable';
import { IUser } from '../../../../types/user';

const { sendTo, assign, sendParent, cancel, forwardTo } = stateActions;

export type ChangeEvent<T extends IVersionableItem> = { type: 'CHANGE', value: Partial<T> };
export type PatchedEvent = { type: 'PATCHED', patches: Operation[] };
export type ChangedEvent = { type: 'CHANGED', patch: Operation[] }
export type LoadedEvent<T extends IVersionableItem> = { type: 'LOADED', item: T, versions: IVersion[], uses?: IDependency[], usedBy?: IDependency[], users?: IUser[] }
export type VersionEvent = { type: 'VERSION', version: string };
export type JoinEvent = { type: 'JOIN', version: string }
export type PatchEvent = { type: 'PATCH', itemId: string, patches: Operation[] }
export type DismissEvent = { type: 'DISMISS' }
export type DelteEvent = { type: 'DELETE' }
export type ConfirmEvent = { type: 'CONFIRM_DELETE' }
export type CancelEvent = { type: 'CANCEL_DELETE' }
export type DeletedEvent = { type: 'DELETED' }
export type NewVersionCreatedEvent = { type: 'NEW_VERSION_CREATED' }
export type VersionsEvent = { type: 'VERSIONS', versions: IVersion[] }
export type UndoEvent = { type: 'UNDO' }
export type RedoEvent = { type: 'REDO' }
type UserJoinedEvent = { type: 'JOINED', user: IUser }
type UserLeftEvent = { type: 'LEFT', user: string }

export type Event<T extends IVersionableItem> =
    | ChangeEvent<T>
    | LoadedEvent<T>
    | DismissEvent
    | DelteEvent
    | ConfirmEvent
    | CancelEvent
    | ChangedEvent
    | VersionEvent
    | PatchedEvent
    | JoinEvent
    | PatchEvent
    | DeletedEvent
    | NewVersionCreatedEvent
    | VersionsEvent
    | ItemEvent
    | UndoEvent
    | RedoEvent
    | UserJoinedEvent
    | UserLeftEvent

export interface Context<T extends IVersionableItem> {
    org: string,
    workspace: string,
    id: string,
    version: string,
    error?: string,
    item?: T,
    versions?: IVersion[],
    uses?: IDependency[]
    usedBy?: IDependency[]
    users?: IUser[],
    confirmDelete?: boolean
}

export interface IPatch {
    patch: Operation[];
}

type OnLoadResponse<T extends IVersionableItem> = {
    item: T,
    versions: IVersion[],
    uses?: IDependency[],
    usedBy?: IDependency[],
    users?: IUser[]
}

export const versionableItemMachine = <T extends IVersionableItem>(id: string, type: string) => {
    
    const realtime = (context: Context<T>) => (callback: Sender<Event<T>>, onReceive: Receiver<Event<T>>) => {
        const join = (version: string) => {
            const onLoad = (response: OnLoadResponse<T>) => callback({ type: 'LOADED', ...response });
            const onChange = (patch: IPatch) => callback({ type: 'CHANGED', ...patch });
            const onJoined = (user: IUser) => callback({ type: 'JOINED', user });
            const onLeft = (user: string) => callback({ type: 'LEFT', user });
            const onDelete = () => null;
    
            edit(context.org, context.workspace, context.id, version, type, onLoad, onChange, onDelete, onJoined, onLeft);
        }
    
        join(context.version);
    
        onReceive((e: Event<T>) => {
            switch (e.type) {
                case 'PATCH':
                    change(context.org, context.workspace, e.itemId, type, e.patches);
                    callback({ type: 'PATCHED', patches: e.patches });
                    break;
    
                case 'JOIN':
                    close(context.org, context.workspace, context.id, e.version, type);
                    join(e.version);
                    break;
    
                case 'DELETE':
                    remove(context.org, context.workspace, context.id, type);
                    callback('DELETED')
                    break;
    
                case 'NEW_VERSION_CREATED':
                    versions(context.org, context.workspace, context.id, type, (response) => callback({ type: 'VERSIONS', versions: response }));
                    break;
            }
        })
    }
    
    return createMachine(
    {
        predictableActionArguments: true,
        schema: {
            context: {} as Context<T>,
            events: {} as Event<T>
        },
        id,
        initial: 'loading',
        invoke: {
            id: 'socket',
            src: realtime
        },
        on: {
            ITEM: {
                actions: sendParent((context: Context<T>, event: ItemEvent) => event)
            }
        },
        states: {
            loading: {
                on: {
                    LOADED: {
                        actions: ['loaded'],
                        target: 'edit'
                    }
                }
            },
            edit: {
                on: {
                    LOADED: {
                        actions: ['loaded'],
                    },
                    DELETE: {
                        cond: 'editable',
                        actions: 'confirmDelete',
                    },
                    CANCEL_DELETE: {
                        actions: 'cancelDelete'
                    },
                    CONFIRM_DELETE: {
                        target: 'deleting'
                    },
                    CHANGE: {
                        cond: 'editable',
                        actions: ['cancelPatch', 'patch', 'change']
                    },
                    CHANGED: {
                        actions: 'changed'
                    },
                    UNDO: {
                        actions: 'undo'
                    },
                    REDO: {
                        actions: 'redo'
                    },
                    VERSION: {
                        actions: ['version', 'versionJoin']
                    },
                    NEW_VERSION_CREATED: {
                        actions: forwardTo('socket')
                    },
                    VERSIONS: {
                        actions: 'versions'
                    },
                    JOINED: {
                        actions: 'joined'
                    },
                    LEFT: {
                        actions: 'left'
                    },
                },
            },
            deleting: {
                entry: 'deleting',
                on: {
                    DELETED: 'deleted',
                }
            },
            deleted: {
                entry: 'deleted'
            },
            error: {
                on: {
                    DISMISS: 'edit'
                }
            }
        }
    },
    {
        actions: {
            loaded: assign<Context<T>, LoadedEvent<T>>({
                item: (_, event) => event.item,
                versions: (_, event) => event.versions,
                uses: (_, event) => event.uses,
                usedBy: (_, event) => event.usedBy,
                users: (_, event) => event.users
            }),
            change: assign<Context<T>, ChangeEvent<T>>({
                item: (context, event) => ({ ...context.item, ...event.value }) as T
            }),
            patch: sendTo(
                'socket',
                (context: Context<T>, event: ChangeEvent<T>) => ({
                    type: 'PATCH',
                    itemId: context.item?.id,
                    patches: compare(context.item || {}, { ...context.item, ...event.value }),
                }),
                {
                    id: 'patch',
                    to: 'socket',
                    delay: 500
                }
            ),
            cancelPatch: cancel('patch'),
            changed: assign<Context<T>, ChangedEvent>({
                item: (context, event) => ({
                    ...context.item,
                    ...applyPatch(context.item, event.patch).newDocument
                } as T)
            }),
            undo: assign<Context<T>, UndoEvent>({
                
            }),
            redo: assign<Context<T>, RedoEvent>({

            }),
            version: assign<Context<T>, VersionEvent>({
                version: (context, event) => event.version
            }),
            versionJoin: sendTo(
                'socket',
                (context, event: VersionEvent) => ({
                    type: 'JOIN',
                    version: event.version,
                })
            ),
            versions: assign<Context<T>, VersionsEvent>({
                versions: (context, event) => event.versions
            }),
            confirmDelete: assign({
                confirmDelete: () => true
            }),
            cancelDelete: assign({
                confirmDelete: () => false
            }),
            deleting: sendTo(
                'socket',
                (context: Context<T>) => ({
                    type: 'DELETE',
                    id: context.item?.id
                }),
            ),
            deleted: sendParent(
                (context: Context<T>) => ({
                    type: 'ON_DELETED',
                    id:  context.item?.id
                })
            ),
            joined: assign<Context<T>, UserJoinedEvent>({
                users: (context, event) => _.concat(context.users, [event.user]) as IUser[]
            }),
            left: assign<Context<T>, UserLeftEvent>({
                users: (context, event) => (context.users || []).filter(user => user.id !== event.user)
            })
        },
        guards: {
            editable: (context: Context<T>) => context.item ? !context.item.readonly : false
        }
    })
}

